// Generated by Elbowgrease, do not modify by hand
// noinspection JSUnusedGlobalSymbols,SpellCheckingInspection

import { AppState, IHasLoadingAndError, IRecordHasLoadingAndError } from "./models";

export type DateString = string;

// PhotoInspection.Types.Model.InfoNotification
export interface IInfoNotification {
  id?: string;
  country?: WorkshopCountryCode | null;
  message?: string;
  end?: DateString | null;
  start?: DateString | null;
  timestamp: DateString;
}

// PhotoInspection.Types.Model.Feedback
export interface IFeedback {
  id?: string;
  text: string; // Required
  metadata?: IFeedbackMetadata;
  userId?: string;
  userEmail?: string;
  context?: string;
  appState: AppState;
  timestamp?: DateString | null;
}

// PhotoInspection.Types.Model.FeedbackMetadata
export interface IFeedbackMetadata {
  url?: string;
  timeUTC?: string;
  timeLocal?: string;
  userAgent?: string;
  locale?: string;
  width: number;
  height: number;
}

// PhotoInspection.Types.Requests.UserFeedbackResponse
export interface IUserFeedbackResponse {
  feedbacks?: IUserFeedback[];
  totalInspections: number;
}

// PhotoInspection.Types.Model.UserFeedback
export interface IUserFeedback {
  id?: string;
  timestamp?: string;
  positive: boolean;
  text?: string;
  translatedText?: string;
  brand: Brand;
  countryCode?: string;
  createdBySystem?: string;
  createdBy?: string;
}

// PhotoInspection.Types.Model.Brand
export enum Brand {
  If = 0,
  Volvia = 1,
  Renault = 2,
  Volkswagen = 3,
  Audi = 4,
  BMW = 5,
  Kia = 6,
  NotBranded = 7,
  Mercedes = 8,
  Skoda = 9,
  Nissan = 10,
  Polestar = 11,
  Dacia = 12,
  Lynk = 13,
  Ford = 14,
}

// PhotoInspection.UI.Controllers.AdminController+AdminSmsTemplateRequest
export interface IAdminSmsTemplateRequest {
  template?: string;
  countryCode?: string;
  brand: Brand;
  ips: boolean;
}

// PhotoInspection.UI.Infrastructure.CspRequest+CspReportRequest
export interface ICspReportRequest {
  cspReport?: ICspReport;
}

// PhotoInspection.UI.Infrastructure.CspRequest+CspReport
export interface ICspReport {
  documentUri?: string;
  referrer?: string;
  violatedDirective?: string;
  effectiveDirective?: string;
  originalPolicy?: string;
  blockedUri?: string;
  statusCode: number;
}

// PhotoInspection.UI.Controllers.UserAuthData
export interface IUserAuthData {
  isAuthenticated: boolean;
  isAdministrator: boolean;
  isImpersonated: boolean;
  flags?: IIFlags;
  intakeBaseUrl?: Record<string, string>;
}

// PhotoInspection.Types.IFlags
export interface IIFlags {
  prefillIntake: boolean;
  glassAPI: boolean;
  glassUI: boolean;
  sendAutomaticReminders: boolean;
  volvoBrandingForNorwayIntakes: boolean;
  disablePlutoIdpForApiClients: boolean;
  disablingOfPhotoInspectionService: boolean;
  allowAuthenticationHints: boolean;
  updateConfirmationSmsMessageSweden: boolean;
  showCurityBanner: boolean;
  sendMorningNewInspectionEmails: boolean;
  sendMorningNewInspectionEmailsNO: boolean;
  updateConfirmationSmsMessageNorway: boolean;
  documentStoreUseCurity: boolean;
  temporaryExtraLogging: boolean;
  migratedApiEndpointsAreDisabled: boolean;
  sendNewIntakeUrls: boolean;
  useNewFeedbackFilters: boolean;
  allowUploadInNewStatusOnly: boolean;
  includeGlassInSummerClosedWorkshop: boolean;
}

// PhotoInspection.Types.Requests.BodyInspectionResponse
export interface IBodyInspectionResponse {
  inspection?: IRecord;
  workshop?: IWorkshopInfo;
}

// PhotoInspection.Types.Model.WorkshopInfo
export interface IWorkshopInfo {
  id?: string;
  name?: string;
  city?: string;
  cabasId?: string;
}

// PhotoInspection.Types.Requests.GlassInspectionResponse
export interface IGlassInspectionResponse {
  inspection?: IGlassInspection;
  workshop?: IWorkshopInfo;
}

// PhotoInspection.Types.Model.GlassInspection
export interface IGlassInspection extends IRecordHasLoadingAndError {
  coordinates?: string;
  workshopDecision: GlassDecision;
  aIDecision: GlassDecision;
  photoSource: PhotoSource;
  businessDaysOld: number;
  recordId?: string;
  claimNumber?: string;
  customer?: ICustomer;
  workshopId: string; // Required
  dateCreated: DateString;
  brand: Brand;
  status: Status;
  confirmationSmsSentDate?: DateString | null;
  claimDate?: DateString | null;
  description?: string;
  decidedBy?: string;
  dateDecided?: DateString | null;
  comments?: IComment[];
  locationOfIncident?: string;
  version?: string;
}

// PhotoInspection.Types.Model.Comment
export interface IComment {
  date?: string;
  author?: string;
  text?: string;
}

// PhotoInspection.Types.Model.Status
export enum Status {
  Open = 0,
  IntakeCompleted = 1,
  New = 2,
  InProgress = 3,
  OnHold = 4,
  Closed = 5,
  SmsSendingError = 6,
  Deleted = 7,
}

// PhotoInspection.Types.Model.Customer
export interface ICustomer {
  registrationNumber: string; // Required
  name?: string;
  phoneNumber?: string;
  email?: string;
}

// PhotoInspection.Types.Model.PhotoSource
export enum PhotoSource {
  Unknown = 0,
  Workshop = 1,
  Customer = 2,
}

// PhotoInspection.Types.Model.GlassDecision
export enum GlassDecision {
  NotAvailable = 0,
  Repair = 1,
  Replace = 2,
}

// PhotoInspection.UI.Controllers.AddCommentRequest
export interface IAddCommentRequest {
  comment?: string;
  recordId?: string;
}

// PhotoInspection.Types.Model.GlassInspectionRequest
export interface IGlassInspectionRequest {
  workshopId: string; // Required
  claimNumber?: string;
  registrationNumber: string; // Required
  brand: Brand; // Required
  coordinates: string; // Required
  workshopDecision: GlassDecision; // Required
  aIDecision: GlassDecision;
  photoSource: PhotoSource;
  status: Status;
  customer?: IGlassCustomer;
  claimDate?: DateString | null;
  description?: string;
  locationOfIncident?: string;
}

// PhotoInspection.Types.Model.GlassCustomer
export interface IGlassCustomer {
  name?: string;
  phoneNumber?: string;
  email?: string;
}

// PhotoInspection.Types.Model.ChangeGlassStatusRequest
export interface IChangeGlassStatusRequest {
  inspectionId: string; // Required
  status?: Status | null;
  decision?: GlassDecision | null;
}

// PhotoInspection.Types.Model.IntakeInformation
export interface IIntakeInformation extends IHasLoadingAndError {
  customer?: ICustomer;
  brand: Brand;
  claimDate?: DateString | null;
  hasClaimNumber: boolean;
  isIPS: boolean;
  isBluePilot: boolean;
  workshopCountryCode?: string;
  workshopName?: string;
  workshopEmail?: string;
  notFound: boolean;
  expired: boolean;
  serviceLevelAgreement?: ServiceLevelAgreement | null;
}

// PhotoInspection.Types.Model.ServiceLevelAgreement
export enum ServiceLevelAgreement {
  SomeDays = 0,
  OneDay = 1,
  TwoDays = 2,
  ThreeDays = 3,
}

// PhotoInspection.Types.Model.InspectionIntakeRequest
export interface IInspectionIntakeRequest {
  customer?: ICustomer;
  mileage: number; // Required
  description?: string;
  termsOfServiceAccepted: boolean;
  claimDate?: DateString | null;
  locationOfIncident?: string;
}

// PhotoInspection.Types.Model.InspectionFeedback
export interface IInspectionFeedback {
  positive: boolean;
  text?: string;
  translatedText?: string;
  userAgent?: string;
  timestamp?: string;
}

// PhotoInspection.Types.Model.Document
export interface IDocument {
  uploadTimestamp: DateString;
  recordId?: string;
  id?: string;
  photoId?: string;
  name?: string;
  tag?: string;
  fileName?: string;
  size: number;
  scanStatus: ScanStatus;
  rotation: number;
  hash?: string;
  meta?: string;
}

// PhotoInspection.Types.Model.ScanStatus
export enum ScanStatus {
  NotScanned = 0,
  Accepted = 1,
  Rejected = 2,
}

// PhotoInspection.Types.Requests.BodyInspectionsResponse
export interface IBodyInspectionsResponse {
  inspections?: IRecord[];
  workshops?: IWorkshopInfo[];
}

// PhotoInspection.Types.Requests.GlassInspectionsResponse
export interface IGlassInspectionsResponse {
  inspections?: IGlassInspection[];
  workshops?: IWorkshopInfo[];
}

// PhotoInspection.Types.Model.Record
export interface IRecord extends IRecordHasLoadingAndError {
  mileage?: number | null;
  dateOfIntake?: DateString | null;
  intakeSmsSentDate?: DateString | null;
  intakeReminderSmsSentDate?: DateString | null;
  decision: WorkshopDecision;
  createdBy?: string;
  feedback?: IInspectionFeedback;
  noCustomer: boolean;
  isBluePilot: boolean;
  isValidForIntake: boolean;
  externalId?: string;
  intakeUrl?: string;
  shortIntakeUrl?: string;
  businessDaysOld: number;
  recordId?: string;
  claimNumber?: string;
  customer?: ICustomer;
  workshopId: string; // Required
  dateCreated: DateString;
  brand: Brand;
  status: Status;
  confirmationSmsSentDate?: DateString | null;
  claimDate?: DateString | null;
  description?: string;
  decidedBy?: string;
  dateDecided?: DateString | null;
  comments?: IComment[];
  locationOfIncident?: string;
  version?: string;
}

// PhotoInspection.Types.Model.WorkshopDecision
export enum WorkshopDecision {
  Undecided = 0,
  PreInspection = 1,
  Repair = 2,
}

// PhotoInspection.Types.Model.ChangeStatusRequest
export interface IChangeStatusRequest {
  recordId: string; // Required
  status?: Status | null;
  decision?: WorkshopDecision | null;
}

// PhotoInspection.Types.Model.NewInspectionRequest
export interface INewInspectionRequest extends IHasLoadingAndError {
  workshopId: string; // Required
  brand: Brand;
  claimNumber?: string;
  customer: ICustomer; // Required
  sendSms: boolean; // Required
  createdBySystem?: string;
  externalReference?: string;
  createdBy: string; // Required
}

// PhotoInspection.Types.Model.NewLightInspectionRequest
export interface INewLightInspectionRequest {
  workshopId: string; // Required
  brand: Brand; // Required
  createdBy: string; // Required
  createdBySystem?: string;
  externalReference?: string;
}

// PhotoInspection.Types.Model.UserInfo
export interface IUserInfo extends IHasLoadingAndError {
  isImpersonated: boolean;
  name?: string;
  email?: string;
  preferredLanguageCode?: string;
  workshopObjectList?: IWorkshopInfo[];
  glassWorkshops?: IWorkshopInfo[];
  permissions?: UserPermission[];
  isDeleted: boolean;
}

// PhotoInspection.Types.Model.UserPermission
export enum UserPermission {
  AllData = 0,
  Admin = 1,
  AllDataSE = 2,
  AllDataFI = 3,
  AllDataNO = 4,
  AllDataDK = 5,
}

// PhotoInspection.Types.Model.WorkshopObject
export interface IWorkshop {
  countryCode?: string;
  phone?: string;
  email?: string;
  phinEmail?: string;
  glassEmail?: string;
  brands?: Brand[];
  serviceUnavailabilityStartDate?: DateString | null;
  serviceUnavailabilityEndDate?: DateString | null;
  hasCustomerServiceDefined: boolean;
  id?: string;
  name?: string;
  city?: string;
  cabasId?: string;
}

// PhotoInspection.Types.Model.DisableServiceRequest
export interface IDisableServiceRequest {
  workshopId: string; // Required
  startDate?: DateString | null;
  endDate?: DateString | null;
}

// PhotoInspection.Types.Model.ClientEnvironment
export interface IClientEnvironment {
  iKey?: string;
  isDevelopment: boolean;
  runtimeEnvironment?: string;
  environmentName?: string;
  flags?: IIFlags;
  enabledBrands?: Record<WorkshopCountryCode, Brand[]>;
  notifications?: IInfoNotification[];
  overrideUserAgent?: string;
  version?: string;
  intakeBaseUrl?: Record<string, string>;
}

// PhotoInspection.Types.Model.WorkshopCountryCode
export enum WorkshopCountryCode {
  SE = 0,
  FI = 1,
  DK = 2,
  NO = 3,
}

// Microsoft.AspNetCore.Mvc.ValidationProblemDetails
export interface IValidationProblemDetails {
  errors?: Record<string, string[]>;
  type?: string;
  title?: string;
  status?: number | null;
  detail?: string;
  instance?: string;
  extensions?: Record<string, any>;
}

// PhotoInspection.Types.Model.InspectionBase
export interface IInspectionBase {
  recordId?: string;
  claimNumber?: string;
  customer?: ICustomer;
  workshopId: string; // Required
  dateCreated: DateString;
  brand: Brand;
  status: Status;
  confirmationSmsSentDate?: DateString | null;
  claimDate?: DateString | null;
  description?: string;
  decidedBy?: string;
  dateDecided?: DateString | null;
  comments?: IComment[];
  locationOfIncident?: string;
  version?: string;
}

export const Constants = {
  "ImpersonationCookie": "admin-impersonate"
}