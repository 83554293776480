import { SagaIterator } from "redux-saga";
import { all, put, takeLatest } from "redux-saga/effects";
import * as Actions from "./actions";
import * as AdminActions from "./actions";
import { showError } from "../sagas/common";
import { Backend } from "../backend";

function* fetchFeedback() {
  try {
    let result = yield* Backend.Admin.Feedback();
    yield put(Actions.fetchFeedbackSuccess(result));
  } catch (err) {
    yield showError(err);
    yield all([put(Actions.fetchFeedbackError(err))]);
  }
}
export default function* fetchFeedbackSaga(): SagaIterator {
  yield takeLatest(AdminActions.fetchFeedback, fetchFeedback);
}
