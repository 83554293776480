import React, { useEffect, useState } from "react";
import "./feedback.scss";
import { formatDateTime } from "../../utility";
import { IUserFeedbackResponse } from "../../backend-models";
import { Backend } from "backend";
import Loader from "../../components/shared/loader";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";

function fetchData(setData: (any: any) => void, days: number) {
  fetch(Backend.URL.Admin.UserFeedback() + "?days=" + days.toString())
    .then((resp) => resp.json())
    .then(setData);
}

const Feedback: React.FC = () => {
  const history = useHistory();
  const [data, setData] = useState<IUserFeedbackResponse>(null);
  const [days, setDays] = useState(3);

  useEffect(() => {
    if (!data) {
      fetchData(setData, days);
    }
  }, [data, days]);

  useEffect(() => {
    setData(null);
    //fetchData(setData, days);
  }, [days]);

  const unsorted = data?.feedbacks ?? [];

  const totalRecords = data?.totalInspections ?? 0;
  const feedbacks = unsorted.sort((a, b) => b.timestamp.localeCompare(a.timestamp));
  const positiveCount = feedbacks.filter((r) => r.positive).length;

  const csvData = feedbacks.map((feedback) => ({
    ...feedback,
    timestamp: formatDateTime(feedback.timestamp),
  }));

  if (data === null) {
    return <Loader />;
  }

  return (
    <div className="padded-block feedback-page">
      <h1>Feedback for the last {days} days</h1>
      <button className="if button secondary" onClick={() => setDays(14)}>
        Get 2 weeks
      </button>
      <button className="if button secondary" onClick={() => setDays(30)}>
        Get 1 month
      </button>
      <button className="if button secondary" onClick={() => setDays(90)}>
        Get 3 months
      </button>
      <CSVLink data={csvData} filename="feedback.csv">
        <button className="if button secondary export-button">Export current comments as CSV</button>
      </CSVLink>
      <div className="stats">
        <div className="stats-box">
          <div className="text">
            <div className="header">Total</div>
            <div className="val">{feedbacks.length}</div>
          </div>
          <div className="if icon symbol chatbot blue" />
        </div>
        <div className="stats-box">
          <div className="text">
            <div className="header">Positive</div>
            <div className="val">{((positiveCount / feedbacks.length) * 100).toFixed(0)}%</div>
            <div className="subval">{positiveCount} votes</div>
          </div>
          <div className="if icon symbol heart-person blue" />
        </div>
        <div className="stats-box">
          <div className="text">
            <div className="header">Engagement</div>
            <div className="val">{((feedbacks.length / totalRecords) * 100).toFixed(0)}%</div>
            <div className="subval">{totalRecords} inspections</div>
          </div>
          <div className="if icon symbol chart-bar blue" />
        </div>
      </div>

      <div className="if table-container box">
        <table className="if table record-table" data-cy="tableView">
          <tbody className="if">
            {feedbacks
              .filter((f) => !!f.text)
              .map((feedback, i) => (
                <tr
                  className="if"
                  key={i}
                  onClick={() => history.push("/details/" + feedback.id)}
                  title={feedback.translatedText}
                >
                  <td className={"thumb " + (feedback.positive ? "up" : "down")}>
                    <img src={"/img/intake/thumb-" + (feedback.positive ? "up.svg" : "down.svg")} alt="" />
                  </td>
                  <td className="timestamp">{formatDateTime(feedback.timestamp)}</td>
                  <td className="text">
                    {feedback.translatedText}
                    <br />
                    <br />
                    {feedback.text}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Feedback;
