import * as Actions from "../../actions";
import reducer from "../reducer";
import { IRecords } from "../../models";
import { updateRecordReducer, updateRecordSuccessReducer } from "./updateRecord";
import { recordError } from "./index";

export default reducer<IRecords>(
  { isLoading: false, records: [], loadError: undefined, workshopFilter: {} },
  (on) => {
    on(Actions.addComment, updateRecordReducer);
    on(Actions.addCommentSuccess, updateRecordSuccessReducer);
    on(Actions.addCommentError, (_, action) => {
      return recordError(_, action.payload, "Failed to add comment");
    });
  }
);
