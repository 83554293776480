import React from "react";
import { withAITracking, reactPlugin } from "../infrastructure/applicationInsights";
import { FormattedMessage } from "react-intl";

const Notfound = () => {
  return (
    <div className="if container padded-block">
      <h1 className="if heading largest center" data-cy="notFoundHeader">
        <FormattedMessage id="app.notFound" />
      </h1>
      <p className="if text lead center">
        {document.location.pathname.replace("/missing", "")}
      </p>
    </div>
  );
};

export default withAITracking(reactPlugin, Notfound);
