import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "../actions";
import { processGlassInspectionResponse, processInspectionResponse } from "../models";
import { showError } from "./common";
import { Backend } from "../backend";
import { IAddCommentRequest } from "../backend-models";

function* addComment(action: Action<IAddCommentRequest>) {
  try {
    const result = yield* Backend.Comment.Add(action.payload);
    yield put(Actions.addCommentSuccess(processInspectionResponse(result)));
  } catch (err) {
    yield showError(err);
    yield put(Actions.addCommentError({ ...err, recordId: action.payload.recordId }));
  }
}

export default function* addCommentSaga(): SagaIterator {
  yield takeLatest(Actions.addComment, addComment);
}

function* addGlassComment(action: Action<IAddCommentRequest>) {
  try {
    const result = yield* Backend.Comment.AddGlass(action.payload);
    yield put(Actions.addGlassCommentSuccess(processGlassInspectionResponse(result)));
  } catch (err) {
    yield showError(err);
    yield put(Actions.addGlassCommentError({ ...err, recordId: action.payload.recordId }));
  }
}

export function* addGlassCommentSaga(): SagaIterator {
  yield takeLatest(Actions.addGlassComment, addGlassComment);
}
