import * as React from "react";
import { IWizard } from "../../../intakeModels";
import { useEffect } from "react";

const ErrorStep: React.FC<{ wizard: IWizard, goStep }> = (props) => {
  useEffect(() => {
    if (props.wizard.prevClicked) props.goStep(-1);
  }, [props]);
  return (
    <div className="if padded-block darkBeige">
      <div className="if notification error">Application error, our team has been notified.</div>
    </div>
  );
};
export default ErrorStep;
