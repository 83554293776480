import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "../../actions";
import { Action } from "redux-actions";
import { Backend } from "../../backend";
import { IInspectionFeedback } from "../../backend-models";

function* submitIntakeFeedback(action: Action<{ id; data: IInspectionFeedback }>) {
  try {
    yield* Backend.Intake.SubmitIntakeFeedback(action.payload.data, action.payload.id);

    yield put(Actions.submitIntakeFeedbackSuccess());
  } catch (err) {
    yield put(Actions.submitIntakeFeedbackError(err));
  }
}
export default function* submitIntakeFeedbackSaga(): SagaIterator {
  yield takeLatest(Actions.submitIntakeFeedback, submitIntakeFeedback);
}
