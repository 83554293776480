import React from "react";
import { withAITracking, reactPlugin } from "../infrastructure/applicationInsights";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

interface ForbiddenParams {
  id: string;
}

const Forbidden: React.FC = () => {
  let { id } = useParams<ForbiddenParams>();
  return (
    <div className="main-container" style={{ padding: "15px" }} data-cy="forbidden-container">
      <FormattedMessage id="app.forbiddenRecord" values={{ recordId: id }} />
    </div>
  );
};

export default withAITracking(reactPlugin, Forbidden);
