import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "../actions";
import { NotificationType } from "../models";
import { showError } from "./common";
import { Backend } from "../backend";
import { INewInspectionRequest, IGlassInspectionRequest } from "../backend-models";
import { aiEvent } from "../infrastructure/applicationInsights";

function* newInspection(action: Action<INewInspectionRequest | IGlassInspectionRequest>) {
  function isGlassInspectionRequest(request): request is IGlassInspectionRequest {
    return !(("createdBy" as keyof INewInspectionRequest) in request);
  }

  try {
    aiEvent("Workshop: Send new inspection");

    let id;

    // duck typing test, if glass or normal inspection
    if (isGlassInspectionRequest(action.payload)) {
      id = yield* Backend.Glass.Inspection(action.payload);
      yield put(Actions.fetchGlassInspections());
    } else {
      let record = yield* Backend.Record.NewInspection(action.payload);
      id = record.recordId;
      yield put(Actions.fetchRecords());
    }

    yield put(Actions.newInspectionSuccess());

    yield put(
      Actions.showNotification({
        type: NotificationType.Success,
        time: new Date(),
        message: "New inspection sent successfully",
        dataCy: { id },
      })
    );
  } catch (err) {
    yield showError(err);
    yield put(Actions.newInspectionError({ ...err }));
  }
}

export default function* newInspectionSaga(): SagaIterator {
  yield takeLatest(Actions.newInspection, newInspection);
}
