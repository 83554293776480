import "./summaryStep.scss";
import * as React from "react";
import { EditStep, IWizardStepProps, PhotoKey } from "../../../intakeModels";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../models";
import { cachedCreateObjectURL, shouldShowClaimDate } from "../../../intakeUtility";

const InfoLine: React.FC<{ id: string; value: string | number; fullRow?: boolean }> = ({
  id,
  value,
  fullRow,
}) => {
  let infoLine = (
    <>
      <span className="header">
        <FormattedMessage id={"intake.SummaryStep." + id} />
      </span>
      <span className="data" data-cy={"field-" + id}>
        <b>{value}</b>
      </span>
    </>
  );

  return <div className={`info-line ${fullRow ? "span-full-row" : ""}`}>{infoLine}</div>;
};

const EditButton: React.FC<{ stepName: string; photoKey?: string; editStep: EditStep }> = (props) => {
  return (
    <button
      className="if button tertiary"
      type="button"
      data-cy={"editButton-" + props.stepName + (props.photoKey ? "-" + props.photoKey : "")}
      onClick={() => props.editStep(props.stepName, props.photoKey)}
    >
      <span className="if icon ui direct-edit blue" />
      <FormattedMessage id="link.edit" />
    </button>
  );
};

const SummaryStep: React.FC<IWizardStepProps> = (props) => {
  const intl = useIntl();
  useEffect(() => {
    if (props.wizard.prevClicked) props.goStep(-1);
  });

  let submission = useSelector((state: AppState) => state.intakeSubmission);
  let intakeInfo = useSelector((state: AppState) => state.intake);

  let showClaimDate = shouldShowClaimDate(intakeInfo);

  let data = props.wizard.data;

  const extraPhotoStepKey = "extra";

  const renderImages = (photoKeys: PhotoKey[]) =>
    photoKeys.map((photokey) => {
      const files = data.photos[photokey].files;

      if (files.length < 1) return null;

      return (
        <React.Fragment key={photokey}>
          <div className={`summary-photo-container${photokey === extraPhotoStepKey ? ' extra' : ''}`}>
            <h4
              className={`if summary-photo-header for-${files.length}-photo`}>
              <FormattedMessage id={"intake.PhotoStep.header." + photokey} />
              <EditButton stepName="PhotoStep" photoKey={photokey} editStep={props.editStep} />
            </h4>
            <div className="summary-images-container">
              {files.map((file, i) => (
                <img
                  key={`${photokey}-${i}`}
                  data-cy="summaryPhoto"
                  src={cachedCreateObjectURL(file)}
                  className="if image summary-image"
                  alt={intl.formatMessage({ id: "intake.alt.imageSummary." + photokey })}
                />
              ))}
            </div>
          </div>
        </React.Fragment>
      )
    });

  return (
    <>
      <h1 className="if header-text-with-back-button" data-cy="summaryHeader">
        <FormattedMessage id="intake.SummaryStep.header" />
      </h1>
      <p className="if basic-text">
        <FormattedMessage id="intake.SummaryStep.text" values={{ br: <br /> }} />
      </p>

      <h4 className="if section-header">
        <FormattedMessage id="intake.SummaryStep.information.header" />
        <EditButton stepName="ContactInformationStep" editStep={props.editStep} />
      </h4>
      <div className="summary-information-container">
        <span className="summary-information-section">
          <InfoLine id="customer.name.header" value={data.customer.name} />
          <InfoLine id="customer.phoneNumber.header" value={data.customer.phoneNumber} />
          <InfoLine id="customer.email.header" value={data.customer.email} fullRow={true} />
        </span>
        <br />
        <span className="summary-information-section">
          <InfoLine id="customer.registrationNumber.header" value={data.customer.registrationNumber} />
          <InfoLine id="mileage.header" value={data.mileage} />
          {showClaimDate && (
            <InfoLine
              id="claimDate.header"
              value={data.claimDate?.toLocaleDateString(props.wizard.locale)}
              fullRow={true}
            />
          )}
        </span>
      </div>
      <br />

      <div data-cy="summaryDescription" className="summary-description-container">
        <h4 className="if section-header">
          <FormattedMessage id="intake.DescriptionStep.header" />
          <EditButton stepName="DescriptionStep" editStep={props.editStep} />
        </h4>
        <p className="if basic-text" data-cy="summaryDescription">
          {data.description || <FormattedMessage id="intake.SummaryStep.description.missing" />}
        </p>
      </div>

      <h4 className="if section-header">
        <FormattedMessage id="intake.SummaryStep.photos.header" />
      </h4>
      <div className="summary-photos-container">
        {renderImages(props.wizard.photoStepKeys)}
      </div>

      {!!submission.loadError && (
        <p className="if basic-text error-text">
          <span className="if icon ui warning" />
          <FormattedMessage id="intake.SummaryStep.error" />
        </p>
      )}
    </>
  );
};

export default SummaryStep;
