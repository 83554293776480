import { IAdminState, IRecords } from "../models";
import reducer from "../reducers/reducer";
import * as Actions from "./actions";

function removeRecord(records: IRecords, recordId: string) {
  records = { ...records };
  let found = records.records.find((r) => r.recordId === recordId);
  let index = records.records.indexOf(found);
  records.records.splice(index, 1);
  return records;
}
export default reducer<IRecords>(
  { isLoading: false, records: [], loadError: undefined, workshopFilter: {} },
  (on) => {
    on(Actions.purgeRecordSuccess, (records, action) => {
      return removeRecord(records, action.payload);
    });
    on(Actions.purgeTestRecordsSuccess, (records, _) => {
      records = { ...records };
      let found = records.records.filter((r) => r.workshopId === "PHINTEST" && r.createdBy === "TEST");
      found.forEach((r) => {
        records = removeRecord(records, r.recordId);
      });
      return records;
    });
    on(Actions.modifyNotificationsSuccess, (_, action) => {
      window.ClientEnvironment.notifications = action.payload;
      return _;
    });
  }
);

export const adminStateReducer = reducer<IAdminState>(
  {
    workshopFeedback: {
      feedback: [],
      isLoading: false,
    },
  },
  (on) => {
    on(Actions.fetchFeedback, (_) => {
      return {
        ..._,
        workshopFeedback: {
          ..._.workshopFeedback,
          isLoading: true,
          loadError: undefined,
        },
      };
    });
    on(Actions.fetchFeedbackSuccess, (_, action) => {
      return {
        ..._,
        workshopFeedback: {
          ..._.workshopFeedback,
          isLoading: false,
          feedback: action.payload,
        },
      };
    });
    on(Actions.fetchFeedbackError, (_, action) => {
      return {
        ..._,
        workshopFeedback: {
          ..._.workshopFeedback,
          isLoading: false,
          loadError: "Error: " + action.payload,
        },
      };
    });
  }
);
