import "./landingStep.scss";
import { FormattedMessage, useIntl } from "react-intl";

import React, { useEffect, useState } from "react";
import { IIntakeInformationState, IWizardStepProps } from "../../../intakeModels";
import Loader from "../../shared/loader";
import { Brand, WorkshopCountryCode } from "../../../backend-models";
import { useSelector } from "react-redux";
import { AppState } from "../../../models";
import { doesNeedConsent, testFlagSet } from "../../../intakeUtility";
import { TermsOfServiceIfSe } from "./termsOfService/termsOfServiceIfSe";
import { TermsOfServiceIfNo } from "./termsOfService/termsOfServiceIfNo";
import { TermsOfServiceIfFi } from "./termsOfService/termsOfServiceIfFi";
import { TermsOfServiceIfDk } from "./termsOfService/termsOfServiceIfDk";
import { TermsOfServiceRenault } from "./termsOfService/termsOfServiceRenault";
import { TermsOfServiceVolkswagen } from "./termsOfService/termsOfServiceVolkswagen";
import { TermsOfServiceAudi } from "./termsOfService/termsOfServiceAudi";
import { TermsOfServiceKia } from "./termsOfService/termsOfServiceKia";
import { TermsOfServiceMercedes } from "./termsOfService/termsOfServiceMercedes";
import { TermsOfServiceSkoda } from "./termsOfService/termsOfServiceSkoda";
import { TermsOfServiceNissan } from "./termsOfService/termsOfServiceNissan";
import { ConsentCheckbox } from "./consentCheckbox";
import { LanguageSelector } from "../../shared/languageSelector";

export const LandingStep: React.FC<IWizardStepProps> = ({ wizard, goStep, brand }) => {
  let intl = useIntl();
  const [imageLoading, setImageLoading] = useState(true);
  const [showTermsOfService, setShowTermsOfService] = useState(false);
  const [termsOfServiceAccepted, setTermsOfServiceAccepted] = useState(testFlagSet("prefill"));

  const intakeInfo: IIntakeInformationState = useSelector((state: AppState) => state.intake);
  const needConsent = doesNeedConsent(intakeInfo);

  const brandValue = Brand[brand];

  function onNext() {
    goStep(1, (data) => ({ ...data, termsOfServiceAccepted: !needConsent ? true : termsOfServiceAccepted }));
  }
  useEffect(() => {
    if (wizard.nextClicked) onNext();
  });
  let privacyPolicyUrl =
    {
      [Brand.Renault]: {
        [WorkshopCountryCode.SE]: "https://www.renaultforsakring.se/hantering-av-personuppgifter",
      },
      [Brand.Volvia]: {
        [WorkshopCountryCode.SE]: "https://www.volvia.se/hantering-av-personuppgifter",
        [WorkshopCountryCode.NO]: "https://www.volvia.no/behandling-av-personopplysninger",
      },
    }[brand]?.[wizard.workshopCountryCode] ??
    {
      [WorkshopCountryCode.SE]: "https://www.if.se/om-webbplatsen/hantering-av-personuppgifter",
      [WorkshopCountryCode.FI]: "https://www.if.fi/henkilotietojen-kasittely",
      [WorkshopCountryCode.NO]: "https://www.if.no/behandling-av-personopplysninger",
      [WorkshopCountryCode.DK]:
        "https://www.if.dk/privat/information/om-hjemmesiden/haandtering-af-personoplysninger",
    }[wizard.workshopCountryCode] ??
    "https://www.if-insurance.com/about-the-website/handling-of-personal-data";

  if (showTermsOfService) {
    let termsOfService =
      {
        [Brand.Renault]: <TermsOfServiceRenault />,
        [Brand.Volkswagen]: <TermsOfServiceVolkswagen />,
        [Brand.Audi]: <TermsOfServiceAudi />,
        [Brand.Kia]: <TermsOfServiceKia />,
        [Brand.Mercedes]: <TermsOfServiceMercedes />,
        [Brand.Skoda]: <TermsOfServiceSkoda />,
        [Brand.Nissan]: <TermsOfServiceNissan />,
      }[brand] ??
      {
        [WorkshopCountryCode.SE]: <TermsOfServiceIfSe />,
        [WorkshopCountryCode.NO]: <TermsOfServiceIfNo />,
        [WorkshopCountryCode.FI]: <TermsOfServiceIfFi />,
        [WorkshopCountryCode.DK]: <TermsOfServiceIfDk />,
      }[wizard.workshopCountryCode];

    return (
      <div className="consent-container" data-cy="tos">
        <a
          href="#"
          className="if back-to-link"
          onClick={(e) => {
            setShowTermsOfService(false);
            e.preventDefault();
          }}
          data-cy="back-link"
        >
          <FormattedMessage id="intake.alt.back" />
        </a>
        {termsOfService}
      </div>
    );
  }
  const branded = ![Brand.NotBranded, Brand.BMW].includes(brand);

  let landingImageSrc = "/img/";
  if ([Brand.Mercedes, Brand.Audi, Brand.Volkswagen, Brand.Nissan, Brand.Ford].includes(brand)) {
    landingImageSrc += Brand[brand].toLowerCase() + "/";
  }
  landingImageSrc += "landing-car.jpg";

  const landingImageAlt = intl.formatMessage({ id: "intake.alt.imageCar" });

  return (
    <>
      <LanguageSelector
        locale={wizard.locale}
        setLocale={wizard.setLocale}
        defaultLocale={wizard.workshopCountryCode}
        intake={true}
      />
      {branded && (
        <div className="header-logo" data-cy="header-logo-container">
          <img
            className="brand-logo"
            src={`/img/${brandValue.toLowerCase()}/logo.svg`}
            alt={`${brandValue}`}
          />
        </div>
      )}
      <div className="landing-container">
        <h1 className="if header-text-centered" data-cy="landingHeader">
          <FormattedMessage id="intake.LandingStep.header" />
        </h1>
        <p className="if basic-text-centered">
          <FormattedMessage id="intake.LandingStep.text" />
        </p>
        {imageLoading && (
          <div className="loader-container" style={{ display: imageLoading ? "block" : "none" }}>
            <Loader />
          </div>
        )}
        <div style={{ display: imageLoading ? "none" : "block" }} className="landing-image-container">
          <img
            className="if image landing-image"
            src={landingImageSrc}
            alt={landingImageAlt}
            onLoad={() => setImageLoading(false)}
          />
        </div>
        {needConsent && (
          <ConsentCheckbox
            consent={termsOfServiceAccepted}
            setConsent={setTermsOfServiceAccepted}
            onTermsOfServiceClick={() => setShowTermsOfService(true)}
          />
        )}
      </div>
      <button
        className="if button primary"
        disabled={needConsent && !termsOfServiceAccepted}
        data-cy="startInspectionBtn"
        onClick={() => onNext()}
      >
        <FormattedMessage id="intake.wizard.buttonStart" />
      </button>
      <p className="if text disclaimer">
        <FormattedMessage id="intake.LandingStep.privacyPolicy.text" />
        <a href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer" data-cy="privacy-policy-link">
          <FormattedMessage id="intake.LandingStep.privacyPolicy.link.text" />
        </a>
      </p>
      {wizard.workshopCountryCode === WorkshopCountryCode.FI && (
        <div className="accessibility-statement-container">
          <a
            className="if standalone"
            href="https://www.if.fi/saavutettavuus"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="intake.LandingStep.accessibilityStatement.link.text" />
          </a>
        </div>
      )}
      {!branded && (
        <div className="if text disclaimer made-by">
          <FormattedMessage id="intake.LandingStep.madeBy" />{" "}
          <img className="brand-logo" src={`img/if/logo.svg`} alt="Logo If" />
        </div>
      )}
    </>
  );
};
