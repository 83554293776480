import * as React from "react";
import { IWizardStepProps } from "../../../intakeModels";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import InputField from "../fields/inputField";
import "./descriptionStep.scss";

const DescriptionStep: React.FC<IWizardStepProps> = (props) => {
  let intl = useIntl();
  const MAX_LENGTH: number = 200;

  let [description, setDescription] = useState(props.wizard.data.description);
  let [validate, setValidate] = useState(false);

  function validator(description) {
    return description.length <= MAX_LENGTH;
  }

  useEffect(() => {
    const save = (data) => ({ ...data, description: description.trim() });

    if (validator(description)) {
      setValidate(true);
    }

    if (props.wizard.nextClicked) {
      if (!validator(description)) {
        setValidate(true);
        props.cancelNextStep();
      } else {
        props.goStep(1, save);
      }
    }
    if (props.wizard.prevClicked) props.goStep(-1, save);
  }, [props, description]);

  return (
    <div data-cy="decscriptionStep">
      <h1 className="if header-text-with-back-button" data-cy="descriptionHeader">
        <FormattedMessage id="intake.DescriptionStep.header" />
      </h1>
      <p className="if basic-text">
        <FormattedMessage id="intake.DescriptionStep.help" />
      </p>
      <div className="description-container">
        <div className="description-input-container" data-cy="descriptionField">
          <InputField
            onChange={(e) => setDescription(e.target.value)}
            isValid={validate ? validator(description) : true}
            value={description}
            intlPrefix="intake.DescriptionStep."
            name="description"
            type="textarea"
            inputProps={{
              placeholder: intl.formatMessage({
                id: "intake.DescriptionStep.placeholder",
              }),
            }}
            fieldMaxLength={MAX_LENGTH}
            first={true}
            optional={true}
          />
        </div>
      </div>
      {description.length <= MAX_LENGTH && (
        <span className="description-length">
          <FormattedMessage
            id="intake.DescriptionStep.description.statusMessage"
            values={{
              descriptionLength: MAX_LENGTH - description.length,
            }}
          />
        </span>
      )}
    </div>
  );
};
export default DescriptionStep;
