import "./inputField.scss";

import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IIntakeData } from "../../../intakeModels";
import { ChangeEvent, InputHTMLAttributes, TextareaHTMLAttributes } from "react";
import { Props as PhoneInputProps } from "react-phone-number-input";

export interface IIntakeFieldProps {
  value: string;
  onChange(value: ChangeEvent<HTMLInputElement>);
  onChange(value: ChangeEvent<HTMLTextAreaElement>);
  onChange(value: string, chosenCountryCode: string);
  isValid?: boolean;
  name: keyof IIntakeData | "feedbackText";
  intlPrefix: string;
  customClasses?: string;
  type?: string;
  inputProps?:
    | Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange">
    | Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">
    | Omit<PhoneInputProps<HTMLInputElement>, "onChange">;
  fieldMaxLength?: number;
  optional?: boolean;
  autoComplete?: string;
  first?: boolean;
  noValidation?: boolean;
}

const InputField: React.FC<IIntakeFieldProps> = (props) => {
  const intl = useIntl();
  InputField.defaultProps = { type: "text" };
  let name = props.name;
  let classes = props.customClasses ?? "";
  if (!props.isValid && !props.noValidation) classes += " is-invalid";

  return (
    <div className="if input-wrapper">
      {props.type === "textarea" ? (
        <textarea
          id={`textarea-${name}`}
          className={"if textarea textarea-dimensions " + (props.optional ? "" : "is-required ") + classes}
          name={name}
          {...(props.inputProps as TextareaHTMLAttributes<HTMLTextAreaElement>)}
          onChange={props.onChange}
          value={props.value ?? ""}
          autoFocus={props.first}
        />
      ) : (
        <input
          id={`input-${name}`}
          className={"if input-field normal-input " + (props.optional ? "" : "is-required ") + classes}
          name={name}
          {...(props.inputProps as InputHTMLAttributes<HTMLInputElement>)}
          onChange={props.onChange}
          value={props.value ?? ""}
          autoFocus={props.first}
          autoComplete={props.autoComplete}
        />
      )}
      <label className="if" htmlFor={name}>
        {intl.formatMessage({
          id: props.intlPrefix + name + ".header",
        })}
      </label>
      {!props.noValidation && (
        <span className="if input-error validation-message" aria-live="polite">
          <FormattedMessage
            id={props.intlPrefix + name + ".validationMessage"}
            values={{ fieldLength: props.value ? props.value.length - props.fieldMaxLength : 0 }}
          />
        </span>
      )}
    </div>
  );
};
export default InputField;
