import "./intakeUi.scss";

import * as React from "react";
import { withAITracking, reactPlugin, appInsights, aiEvent } from "../../infrastructure/applicationInsights";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import validator from "validator";

import { fetchIntakeInfo, fetchIntakeInfoSuccess } from "../../actions";
import { AppState } from "../../models";
import Loader from "../shared/loader";
import InspectionWizard from "./inspectionWizard";
import InvalidLink from "./invalidLink";
import { Brand, ICustomer, IIntakeInformation, WorkshopCountryCode } from "../../backend-models";
import { IIntakeInformationState } from "../../intakeModels";
import { decodeIntakeUrlData, parseBrand, testableVal, testFlagSet } from "../../intakeUtility";
import { ITelemetryItem } from "@microsoft/applicationinsights-core-js";
import { mapToCountry, mapToLocale, perfMetrics } from "../../utility";

const IntakeUi: React.FC<{ setLocale }> = ({ setLocale }) => {
  let { id, brand, data } = useParams<{ id: string; brand: string; data: string }>();
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isValidLink, setValidLink] = React.useState(true);
  const [localeOverride, setlocaleOverride] = React.useState(null);
  const intakeInfo: IIntakeInformationState = useSelector((state: AppState) => state.intake);
  let urlParams = new URLSearchParams(location.search);

  let selectedBrand: Brand = null;
  let parsedCountryCode: WorkshopCountryCode = null;

  if (data) {
    let parsed = decodeIntakeUrlData(data);
    if (parsed) {
      id = parsed.id;
      selectedBrand = parsed.brand;
      parsedCountryCode = parsed.countryCode;
    }
  }
  if (!selectedBrand) {
    selectedBrand = parseBrand(brand, intakeInfo.brand);
  }

  /*
   * locale priority
   * 1. override (chosen in drop down)
   * 2. set as url param (&lang=), used in testing
   * 3. parsed from short intake url
   * 4. fetched intake info workshops country code (shouldn't be used, mainly for cypress testing
   * 5. en as default
   */
  let urlLocale = urlParams.get("lang");
  if (urlLocale) {
    parsedCountryCode = mapToCountry(urlLocale);
  }
  let localeValue = localeOverride ?? urlLocale ?? parsedCountryCode ?? intakeInfo?.workshopCountryCode;
  let locale = mapToLocale(localeValue, "en");

  let prefillCustomer: ICustomer = {
    registrationNumber: "ABC123",
    email: "test@example.com",
    name: "test case",
    phoneNumber: "+46123456789",
  };
  React.useEffect(() => {
    if (id === "__test") {
      let phoneNumber = testableVal("", "phoneNumber");
      let customer: ICustomer = {
        registrationNumber: testableVal("", "registrationNumber"),
        email: testableVal("", "email"),
        name: testableVal("", "name"),
        phoneNumber: phoneNumber ? "+" + phoneNumber : "",
      };
      let slaDays = 3; //default
      switch (parsedCountryCode) {
        case WorkshopCountryCode.DK:
          switch (selectedBrand) {
            case Brand.If:
              if (!intakeInfo.isIPS) {
                slaDays = 2;
              }
              break;
            case Brand.NotBranded:
              slaDays = 2;
              break;
          }
          break;
        default:
          switch (selectedBrand) {
            case Brand.Volvia:
            case Brand.Renault:
              slaDays = 2;
              break;
          }
      }
      if (testFlagSet("prefill")) {
        customer = prefillCustomer;
      }
      dispatch(
        fetchIntakeInfoSuccess({
          brand: Brand[urlParams.get("brand")],
          isIPS: !!urlParams.get("isIPS"),
          isBluePilot: !!urlParams.get("isBluePilot"),
          customer: customer,
          workshopCountryCode: WorkshopCountryCode[parsedCountryCode] ?? "SE",
          workshopName: "Workshop",
          workshopEmail: "email",
          serviceLevelAgreement: slaDays,
        } as IIntakeInformation)
      );
    } else if (validator.isUUID(id)) {
      dispatch(fetchIntakeInfo({ id, customer: testFlagSet("prefill") ? prefillCustomer : null }));
    } else {
      setValidLink(false);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [dispatch, id]);

  React.useEffect(() => {
    document.documentElement.classList.add("intake", `brand-${Brand[selectedBrand].toLowerCase()}`);
    document.documentElement.classList.remove("workshop-ui");
    document.documentElement.dataset.cy = "intake";
  }, [selectedBrand]);

  React.useEffect(() => {
    setLocale(locale);
    document.title = intl.formatMessage({
      id: "intake.ui.page.title.tabHeader",
    });
    document.documentElement.dataset.cyLocale = locale;
    document.documentElement.lang = locale;
  }, [intl, locale, setLocale]);

  const addIntakeData = (item: ITelemetryItem) => {
    item.data.recordId = id;
    item.data.locale = locale;
    item.data.brand = Brand[selectedBrand];
  };

  let content = (
    <div className="centered padded">
      <Loader />
    </div>
  );

  if (!isValidLink || intakeInfo?.loadError || intakeInfo?.notFound || intakeInfo?.expired)
    content = <InvalidLink brand={selectedBrand} intakeInfo={intakeInfo} />;

  let valid =
    intakeInfo && !intakeInfo.isLoading && intakeInfo.customer && !intakeInfo.notFound && !intakeInfo.expired;

  React.useEffect(() => {
    if (valid) {
      appInsights.addTelemetryInitializer(addIntakeData);
      aiEvent("Intake: Started");
      perfMetrics.intakeRender = perfMetrics.intakeRender ?? new Date();
    }
  }, [id, valid]);

  if (valid) {
    content = (
      <InspectionWizard
        recordId={id}
        locale={locale}
        setLocale={setlocaleOverride}
        customerInformation={intakeInfo.customer}
        brand={selectedBrand}
        workshopCountryCode={parsedCountryCode}
      />
    );
  }
  return content;
};

export default withAITracking(reactPlugin, IntakeUi);
