import { put, PutEffect } from "redux-saga/effects";
import * as Actions from "../actions";
import { IError, INotification, NotificationType } from "../models";
import { Action } from "redux-actions";
import { appInsights } from "../infrastructure/applicationInsights";

export function showError(err: IError | Error): PutEffect<Action<INotification>> {
  const stackTrace = Error().stack;
  console.error(err);
  console.error(stackTrace);

  //don't show a 401 error or log it to AI if we're already re-logging in
  if((err as IError).redirecting)
    return;

  appInsights.trackException({
    exception: {
      ...(err as Error),
      message: "Visible exception: " + err.message + ". Original stack: " + ((err as Error).stack ?? ""),
      stack: stackTrace,
    },
  });
  let message;
  try {
    message = JSON.parse(err.message).detail;
  } catch {}
  message ??= err.message;
  return put(
    Actions.showNotification({
      message: message,
      type: NotificationType.Error,
      time: new Date(),
    })
  );
}
