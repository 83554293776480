import React from "react";
import dayjs from "dayjs";

export const TestTimeFormats: React.FC = () => {
  let dt = dayjs();
  return (
    <>
      <h2>Time formats</h2>
      <table>
        <tbody>
          <tr>
            <td>L</td>
            <td>{dt.format("L")}</td>
          </tr>
          <tr>
            <td>LL</td>
            <td>{dt.format("LL")}</td>
          </tr>
          <tr>
            <td>LLL</td>
            <td>{dt.format("LLL")}</td>
          </tr>
          <tr>
            <td>LLLL</td>
            <td>{dt.format("LLLL")}</td>
          </tr>
          <tr>
            <td>LT</td>
            <td>{dt.format("LT")}</td>
          </tr>
          <tr>
            <td>LTS</td>
            <td>{dt.format("LTS")}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
