import React from "react";
import { Icon } from "@ids/react-icon";
import "./tablePagination.scss";

const MaxPageLinks = 9;
const MaxPageLinksPerSide = Math.floor(MaxPageLinks / 2);
export const TablePagination: React.FC<{
  goToPage: (index: number) => void;
  currentPage: number;
  totalPages: number;
  from: number;
  to: number;
  total: number;
  totalUnfiltered: number;
}> = (props) => {
  const { currentPage, totalPages, goToPage } = props;
  let pageLinks = [];
  if (totalPages <= MaxPageLinks) {
    pageLinks = [...Array(totalPages).keys()];
  } else {
    function range(n: number, offset: number = 0) {
      return Array(Math.max(0, n))
        .fill(0)
        .map((_, i) => i + offset);
    }

    const pagesBefore = range(currentPage);
    const pagesAfter = range(totalPages - currentPage - 1, currentPage + 1);

    if (totalPages > MaxPageLinks) {
      if (pagesBefore.length > MaxPageLinksPerSide) {
        pagesBefore.splice(1, pagesBefore.length - MaxPageLinksPerSide + 1, null);
      }
      const pageLinksLeft = MaxPageLinks - 1 - pagesBefore.length;
      if (pagesAfter.length > pageLinksLeft) {
        const start = pageLinksLeft - 2;
        pagesAfter.splice(start, pagesAfter.length - start - 1, null);
      }
    }

    pageLinks.push(...pagesBefore);
    pageLinks.push(currentPage);
    pageLinks.push(...pagesAfter);
  }

  function NavButton(
    className: string,
    disabled: boolean,
    onClick: () => void,
    key = undefined,
    content = null
  ) {
    return (
      <li className="if" key={key}>
        <button className={"if " + className} disabled={disabled} onClick={onClick} type="button">
          {className === "first" ? (
            <Icon
              className="if icon"
              group={"ui"}
              size={32}
              icon={"arrows-left"}
              style={{ color: disabled ? "#cdc8c4" : "" }}
            />
          ) : className === "previous" ? (
            <Icon
              className="if icon"
              group={"ui"}
              size={32}
              icon={"arrow-sm-left"}
              style={{ color: disabled ? "#cdc8c4" : "" }}
            />
          ) : className === "next" ? (
            <Icon
              className="if icon"
              group={"ui"}
              size={32}
              icon={"arrow-sm-right"}
              style={{ color: disabled ? "#cdc8c4" : "" }}
            />
          ) : className === "last" ? (
            <Icon
              className="if icon"
              group={"ui"}
              size={32}
              icon={"arrows-right"}
              style={{ color: disabled ? "#cdc8c4" : "" }}
            />
          ) : (
            ""
          )}
          {content}
        </button>
      </li>
    );
  }

  const lastPage = totalPages - 1;
  const isLast = currentPage === lastPage;
  const isFirst = currentPage === 0;
  const goToFirst = () => goToPage(0);
  const goToPrevious = () => goToPage(Math.max(0, currentPage - 1));
  const goToNext = () => goToPage(Math.min(lastPage, currentPage + 1));
  const goToLast = () => goToPage(lastPage);
  return (
    <nav className="if pagination">
      <ul className="if">
        {NavButton("first", isFirst, goToFirst)}
        {NavButton("previous", isFirst, goToPrevious)}
        {pageLinks.map((page, i) =>
          NavButton(
            page === currentPage ? "current page-number" : "page-number",
            false,
            () => (page != null ? goToPage(page) : void 0),
            i,
            page != null ? page + 1 : "..."
          )
        )}
        {NavButton("next", isLast, goToNext)}
        {NavButton("last", isLast, goToLast)}
      </ul>
      <span className="if description">
        Showing
        <strong className="if">
          {props.total === 0 ? (
            "0"
          ) : (
            <>
              {props.from}-{props.to}
            </>
          )}
        </strong>
        of
        <strong className="if">
          {props.total === props.totalUnfiltered ? (
            props.total
          ) : (
            <>
              {props.total} ({props.totalUnfiltered})
            </>
          )}
        </strong>
      </span>
    </nav>
  );
};
