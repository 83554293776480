import React, { useEffect, useState } from "react";
import "./devMenu.scss";
import { IIFlags } from "../../backend-models";
import { useHistory, useLocation } from "react-router-dom";
import { decodeIntakeUrlData } from "../../intakeUtility";
import { useAuth } from "../../auth/authContext";

const flags = window.ClientEnvironment.flags;
let html = document.documentElement;

function isIntake() {
  return (
    document.location.pathname.startsWith("/intake") || document.location.pathname.match(/^\/[a-z0-9_-]{23}/)
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FlagButton: React.FC<{ name: keyof IIFlags; reset }> = ({ name, reset }) => {
  return (
    <button
      onClick={() => {
        flags[name] = !flags[name];
        html.classList.toggle("flag-" + name);
        reset();
      }}
    >
      🚩 {name}
    </button>
  );
};
const LocationButton: React.FC<{ url; reset; text? }> = ({ url, reset, text }) => {
  const history = useHistory();
  return (
    <button
      onClick={() => {
        history.push(url);
        reset();
      }}
    >
      {text || url}
    </button>
  );
};
const LocaleButton: React.FC<{ locale; setLocale }> = ({ locale, setLocale }) => {
  return (
    <button
      onClick={() => {
        if (isIntake()) {
          let urlParams = new URLSearchParams(document.location.search);
          urlParams.set("lang", locale);
          document.location.search = urlParams.toString();
        } else {
          setLocale(locale);
        }
      }}
    >
      {locale}
    </button>
  );
};
export const DevMenu: React.FC<{ reset(); setLocale }> = ({ reset, setLocale }) => {
  const { isAuthenticated, isAdministrator, logout } = useAuth();
  const location = useLocation();
  let html = document.documentElement;
  const [show, setShow] = useState(false);
  useEffect(() => {
    html.onkeyup = (ev) => {
      if (ev.code === "F9") {
        setShow(!show);
      }
    };
  });
  let intakeID = null;
  if (document.location.pathname.startsWith("/intake")) {
    intakeID = location.pathname.substr(8, 36);
  } else if (document.location.pathname.startsWith("/details")) {
    intakeID = location.pathname.substr(9, 36);
  } else {
    try {
      if (isIntake()) intakeID = decodeIntakeUrlData(location.pathname.substr(1, 23)).id;
    } catch {}
  }
  if (intakeID === "__test") intakeID = null;
  const reload = () => document.location.reload();
  let relativeUrl = location.pathname + location.search;

  if (isAdministrator && show)
    return (
      <div className="dev-menu">
        <div>
          <div className="spacer" />
          <LocationButton text="intake" url="/intake/__test" reset={reload} />
          <LocationButton text="intake summary" url="/intake/__test?prefill&lang=se&step=8" reset={reload} />
          <LocationButton
            text="intake prefilled"
            url="/intake/__test?prefill&lang=se&step=1"
            reset={reload}
          />
          <div className="spacer" />
          <LocationButton text="intake Volvia" url="/intake/__test?brand=Volvia&lang=se" reset={reload} />
          <LocationButton
            text="intake Volvia summary"
            url="/intake/__test?prefill&brand=Volvia&lang=se&step=8"
            reset={reload}
          />
          <div className="spacer" />
          <LocationButton url="/new" reset={reset} />
          <LocationButton url="/inbox" reset={reset} />
          <LocationButton url="/inProgress" reset={reset} />
          <LocationButton url="/open" reset={reset} />
          <LocationButton url="/test" reset={reset} />
          <div className="spacer" />
          <button
            onClick={() => {
              document.cookie.split(";").forEach((cookie) => {
                const eqPos = cookie.indexOf("=");
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=";
              });
              window.localStorage.clear();
              window.sessionStorage.clear();
              if (isAuthenticated) {
                logout();
              }
              reload();
            }}
          >
            clear all data and logout
          </button>
          <div className="spacer" />
          <LocaleButton locale="en" setLocale={setLocale} />
          <LocaleButton locale="sv" setLocale={setLocale} />
          <LocaleButton locale="da" setLocale={setLocale} />
          <LocaleButton locale="nb" setLocale={setLocale} />
          <LocaleButton locale="fi" setLocale={setLocale} />
          <LocaleButton locale="id" setLocale={setLocale} />

          <div className="spacer" />
          {intakeID && <LocationButton url={"/details/" + intakeID} reset={reset} />}
          {intakeID && <LocationButton url={"/intake/" + intakeID} reset={reset} />}

          <div className="spacer" />
          <button
            onClick={() => {
              document.location.href = "https://localhost:44370" + relativeUrl;
            }}
          >
            local
          </button>
          <button
            onClick={() => {
              document.location.href =
                "https://photo-inspection-workshop-ui-stest.azurewebsites.net" + relativeUrl;
            }}
          >
            stest
          </button>
          <button
            onClick={() => {
              document.location.href =
                "https://photo-inspection-workshop-ui-atest.azurewebsites.net" + relativeUrl;
            }}
          >
            atest
          </button>
          <button
            onClick={() => {
              document.location.href = "https://photo-inspection-workshop-ui.azurewebsites.net" + relativeUrl;
            }}
          >
            prod
          </button>
        </div>
      </div>
    );
  return null;
};
