import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../models";
import "./header.scss";
import { Link, useHistory } from "react-router-dom";
import { Version } from "../shared/version";
import { FormattedMessage } from "react-intl";
import { fetchUserInfo } from "../../actions";
import { IUserInfo, UserPermission } from "../../backend-models";
import { LanguageSelector } from "../shared/languageSelector";
import { useAuth } from "auth/authContext";
import { appInsights } from "../../infrastructure/applicationInsights";
import { ITelemetryItem } from "@microsoft/applicationinsights-core-js";

const Header: React.FC<{ locale: string; setLocale(locale: string) }> = (props) => {
  const userInfo: IUserInfo = useSelector((state: AppState) => state.userInfo);
  const { isAuthenticated, isAdministrator } = useAuth();
  const history = useHistory();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUserInfo());
    }
  }, [isAuthenticated, dispatch]);

  const logIn = async () => {
    history.push("/login");
  };

  function logOut() {
    history.push("/logout");
  }

  if (userInfo?.name) {
    //used to disable ai events tracking for admins
    (window.ClientEnvironment as any).isAdmin = isAdministrator;
    appInsights.setAuthenticatedUserContext(userInfo.name, userInfo.email, true);
    appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
      let resolution = "n/a";
      try {
        resolution = `${window.screen.width}x${window.screen.height}`;
      } catch {}
      item.data.userName = userInfo.name;
      item.data.userEmail = userInfo.email;
      item.data.isAdmin = isAdministrator;
      item.data.resolution = resolution;
      //PHINTEST:PHIN TEST (PHINTEST),SE000851:Bilcenter (Halmstad)
      item.data.workshops = JSON.stringify(userInfo.workshopObjectList.map((w) => w.id)).replace(
        /["{}]/g,
        ""
      );
    });
  }

  return (
    <header className="if header photo-inspection-header">
      <script data-cy="authState" data-auth-state={isAuthenticated} />
      <div className="header-container">
        <div className="icon-header-container">
          <Link to="/" className="if gb logo header-logo" data-cy="phinLogo">
            <img src="/img/logo-phin.svg" alt="Phin" />
          </Link>
          <div className="if font family sans header-text" data-cy="headerText">
            PHOTO INSPECTION
            <Version />
          </div>
        </div>
        <div className="header-links-container">
          <div className="if">
            <LanguageSelector
              locale={props.locale}
              setLocale={props.setLocale}
              defaultLocale={userInfo?.preferredLanguageCode}
            />
          </div>

          <div>
            {isAuthenticated ? (
              <div className="auth-container">
                {!userInfo?.isLoading && (
                  <>
                    <span className="if icon ui person username-icon" />
                    <span className="if user-name" data-cy="nameField" title={userInfo.email}>
                      {userInfo.name || userInfo.loadError}
                      {userInfo?.isImpersonated && <strong>&ensp;(impersonated)</strong>}
                      {Boolean(userInfo.permissions?.length) ? (
                        <>&ensp;({userInfo.permissions.map((x) => UserPermission[x])})</>
                      ) : null}
                    </span>
                  </>
                )}
              </div>
            ) : (
              <div onClick={() => logIn} className="log-container">
                <span className="if icon ui log-in logout-icon" />
                <span className="if user-name">
                  <FormattedMessage id="action.logIn" data-cy="loginLink" />
                </span>
              </div>
            )}
          </div>

          <div className="log-container logout" onClick={logOut}>
            <span className="if icon ui log-out logout-icon" />
            <span className="if" data-cy="logOutLink">
              <FormattedMessage id="action.logOut" />
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
