import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "../actions";
import { processInspectionResponse } from "../models";
import { showError } from "./common";
import { Backend } from "../backend";
import { IRecord } from "../backend-models";

function* updateRecord(action: Action<IRecord>) {
  try {
    const result = yield* Backend.Record.UpdateRecord(action.payload);
    yield put(Actions.updateRecordSuccess(processInspectionResponse(result)));
  } catch (err) {
    yield showError(err);
    yield put(Actions.updateRecordError({ ...err, recordId: action.payload.recordId }));
  }
}

export default function* updateRecordSaga(): SagaIterator {
  yield takeLatest(Actions.updateRecord, updateRecord);
}
