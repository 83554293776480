import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { showNotification } from "../../actions";
import { NotificationType } from "../../models";
import { LoaderIcon } from "./loader";
import "./photoViewer.scss";
import { FormattedMessage } from "react-intl";

const SaveAllLink: React.FC<{ filename: string; url: string }> = (props) => {
  enum LinkState {
    Ready,
    Preparing,
    Downloaded,
    Errored,
  }

  const dispatch = useDispatch();
  const [linkState, setLinkState] = useState(LinkState.Ready);
  const [href, setHref] = useState(null);
  const [download, setDownload] = useState(null);

  const link = useRef<HTMLAnchorElement>();
  // const token = useSelector(getAccessToken);

  var linkTextId = "photos.saveAll";

  switch (linkState) {
    case LinkState.Ready:
      linkTextId = "photos.saveAll";
      break;
    case LinkState.Preparing:
      linkTextId = "photos.saveAllPreparing";
      break;
    case LinkState.Downloaded:
      linkTextId = "photos.saveAllDownloaded";
      break;
    case LinkState.Errored:
      linkTextId = "app.error";
      break;
    default:
      break;
  }

  const handleAction = async () => {
    if (linkState !== LinkState.Ready) return;
    let result: Response;

    setLinkState(LinkState.Preparing);

    try {
      result = await fetch(props.url);
    } catch {
      dispatch(
        showNotification({
          message: `Failed to prepare the archive: Network error`,
          time: new Date(),
          type: NotificationType.Error,
        })
      );
    }

    if (result.status === 200) {
      setHref(props.url);
      setDownload(props.filename);

      link.current.click();

      setLinkState(LinkState.Downloaded);
    } else {
      dispatch(
        showNotification({
          message: `Failed to prepare the archive: ${result.status}`,
          time: new Date(),
          type: NotificationType.Error,
        })
      );

      setLinkState(LinkState.Errored);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      onClick={handleAction}
      className={linkState !== LinkState.Ready ? "disabled" : ""}
      ref={link}
      href={href}
      download={download}
    >
      <FormattedMessage id={linkTextId} tagName="span" />
      {linkState === LinkState.Preparing && <LoaderIcon />}
    </a>
  );
};

export default SaveAllLink;
