import { Action, handleActions, Reducer } from "redux-actions";

type IActionCreator<Payload> = (payload: Payload) => Action<Payload>;

export type IActionReducer<State, Payload> = (state: State, action: Action<Payload>) => State;

interface IActionReducers<State> {
  [key: string]: IActionReducer<State, any>;
}

type IRegisterReducer<State> = <Payload>(
  actionCreator: IActionCreator<Payload>,
  reducer: IActionReducer<State, Payload>
) => void;

type IActionHandlerCallback<State> = (registerReducer: IRegisterReducer<State>) => void;

export default function reducer<State>(
  initialState: State,
  callback: IActionHandlerCallback<State>
): Reducer<State, any> {
  const actionReducers: IActionReducers<any> = {};

  callback(
    <Payload>(
      actionCreator: IActionCreator<Payload>,
      actionReducer: IActionReducer<State, Payload>
    ) => {
      const actionInstance = actionCreator({} as any);
      if (actionReducers[actionInstance.type]) {
        throw new Error(
          `You already registered the action type ${actionInstance.type} for this reducer `
        );
      }

      actionReducers[actionInstance.type] = actionReducer;
    }
  );

  return handleActions<State, State>(actionReducers, initialState);
}
