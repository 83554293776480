import * as Actions from "../actions";
import reducer from "./reducer";
import { IUserInfo } from "../backend-models";

export default reducer<IUserInfo>({} as IUserInfo, (on) => {
  on(Actions.fetchUserInfo, (_) => {
    return {
      ..._,
      isLoading: true,
    };
  });

  on(Actions.fetchUserInfoSuccess, (_, action) => {
    return {
      ..._,
      ...action.payload,
      isLoading: false,
    };
  });

  on(Actions.fetchUserInfoError, (_, action) => {
    return {
      ..._,
      isLoading: false,
      loadError: `Failed to load user information: ${action.payload.message}`,
    };
  });

  on(Actions.clearUserInfo, () => ({} as IUserInfo));
});
