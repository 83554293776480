import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "../actions";
import { showError } from "./common";
import { Backend } from "../backend";

function* fetchWorkshops() {
  try {
    const result = yield* Backend.Workshop.GetWorkshops();
    yield put(Actions.fetchWorkshopsSuccess(result));
  } catch (err) {
    yield showError(err);
    yield put(Actions.fetchWorkshopsError(err));
  }
}
export default function* fetchWorkshopsSaga(): SagaIterator {
  yield takeLatest(Actions.fetchWorkshops, fetchWorkshops);
}
