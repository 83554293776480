import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "../actions";
import { showError } from "./common";
import { Backend } from "../backend";
import { Action } from "redux-actions";
import { history } from "../store";
import { processGlassInspectionResponse } from "../models";

function* fetchGlassInspection(action: Action<string>) {
  try {
    const result = yield* Backend.Record.GlassInspection(action.payload);
    yield put(Actions.fetchGlassInspectionSuccess(processGlassInspectionResponse(result)));
  } catch (err) {
    if(err.status === 403) {
      yield history.push("/forbidden/" + action.payload);
    }
    if (err.status !== 404) {
      yield showError(err);
    }

    yield put(Actions.fetchGlassInspectionError({ ...err, recordId: action.payload }));
  }
}
export default function* fetchGlassInspectionSaga(): SagaIterator {
  yield takeLatest(Actions.fetchGlassInspection, fetchGlassInspection);
}
