import { Action } from "redux-actions";
import { Reducer } from "redux";

import { IError, IInspectionStateType, IRecords } from "../../models";
import { IInspectionBase, IRecord } from "../../backend-models";

import addRecord from "./addRecord";
import updateRecordReducer from "./updateRecord";
import fetchRecord from "./fetchRecord";
import fetchRecords from "./fetchRecords";
import addComment from "./addComment";
import workshopFilter from "./workshopFilter";
import adminReducers from "../../admin/adminReducers";

export function recordLoading<T extends IInspectionStateType>(_: T, recordID: string): T {
  return updateRecord(_, {
    recordId: recordID,
    isLoading: true,
  } as IRecord);
}
export function recordError<T extends IInspectionStateType>(_: T, error: IError, errorFlavor: string): T {
  return updateRecord(_, {
    recordId: error.recordId,
    isLoading: false,
    loadError: `${errorFlavor}: ${error.message}`,
  } as IRecord);
}
export function updateRecord<T extends IInspectionBase, U extends IInspectionStateType>(records: U, freshRecord: T): U {
  records = { ...records };
  let listKey = "records";
  if (!(listKey in records)) {
    listKey = "glassInspections";
  }
  let found = records[listKey].find((r) => r.recordId === freshRecord.recordId);
  if (!found) {
    if (!records[listKey]) {
      records[listKey] = [];
    }
    records[listKey].push(freshRecord);
  } else {
    let index = records[listKey].indexOf(found);
    records[listKey].splice(index, 1, { ...found, ...freshRecord });
  }
  return records;
}

export const recordsReducer: Reducer<IRecords, any> = (state: IRecords, action: Action<any>) => {
  state = addRecord(state, action);
  state = fetchRecord(state, action);
  state = updateRecordReducer(state, action);
  state = fetchRecords(state, action);
  state = addComment(state, action);
  state = workshopFilter(state, action);
  state = adminReducers(state, action);
  return state;
};
