import React, { MouseEvent as ReactMouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPhotos, updatePhoto } from "../../actions";
import { AppState, IInspection, IPhotos, IsRecord } from "../../models";
import { ILightboxState, Lightbox } from "./lightbox";
import Loader from "./loader";
import "./photoViewer.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { Backend } from "../../backend";
import { IDocument, Status } from "../../backend-models";
import { ExtraPhotoKey, PhotoKey } from "../../intakeModels";
import SaveAllLink from "./saveAllLink";

interface IPhotoViewerState {
  currentPhoto: number;
  fullscreen: boolean;
  lightboxKey: number;
  rotations: Record<number, number>;
  shouldSave: boolean;
}

const PhotoViewer: React.FC<{ record: IInspection }> = ({ record }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  let [state, setState] = useState<IPhotoViewerState>({
    currentPhoto: 0,
    fullscreen: false,
    lightboxKey: new Date().getTime(),
    rotations: {},
    shouldSave: false,
  });

  let photos: IPhotos = useSelector((state: AppState) => state.photos);

  type SortOrder<E> = PhotoKey & E;

  let sortOrder: { [key in SortOrder<ExtraPhotoKey>]: number } = {
    back: 0,
    "anon-back": 0,
    distance: 1,
    "anon-distance": 1,
    closeup: 2,
    "anon-closeup": 2,
    "diagonal-front": 3,
    "anon-diagonal-front": 3,
    "diagonal-back": 4,
    "anon-diagonal-back": 4,
    "extra-1": 5,
    "anon-extra-1": 5,
    "extra-2": 5,
    "anon-extra-2": 5,
  };

  photos.photos.sort((a, b) => {
    let result = 0;
    let aOrder = sortOrder[a.tag] ?? 9;
    let bOrder = sortOrder[b.tag] ?? 9;

    if (aOrder > bOrder) result = 1;
    if (aOrder < bOrder) result = -1;

    return result;
  });

  const isGlass = !IsRecord(record);

  useEffect(() => {
    if (record.recordId) {
      let isNewRecord = photos.recordId !== record.recordId;
      let moreThanMinuteAgo = new Date().getTime() - photos.timestamp > 60 * 1000;
      if (isNewRecord || moreThanMinuteAgo) {
        dispatch(fetchPhotos(record));
      }
    }
  }, [dispatch, photos.timestamp, photos.recordId, record, isGlass]);

  if (!photos || photos.isLoading || photos.recordId !== record.recordId) return <Loader />;

  if (photos.loadError) return <div className="if notification">{photos.loadError}</div>;

  if (photos.photos.length === 0) {
    const noPhotos = record.status === Status.Open && !isGlass;

    return (
      <div className="no-photos-message">
        <FormattedMessage id={noPhotos ? "photos.noPhotos" : "photos.photosProcessing"} />
      </div>
    );
  }

  if (Object.keys(state.rotations).length === 0) {
    let rotations = {};
    photos.photos.forEach((photo, i) => {
      rotations[i] = photo.rotation;
    });
    setState({ ...state, rotations });
  }

  const photoUrl = (photo: IDocument) =>
    Backend.URL.Photo.GetPhoto(record.workshopId, photo.recordId, photo.photoId) + (isGlass ? "?glass=true" : "");

  function switchFullscreen(e: ReactMouseEvent = null) {
    if (state.fullscreen) {
      saveRotation();
    }
    setState({ ...state, fullscreen: !state.fullscreen });

    if (e) e.stopPropagation();
  }

  function setPhoto(photo: number) {
    setState({
      ...state,
      currentPhoto: photo,
      lightboxKey: new Date().getTime(),
    });
  }

  function fileName(index: number): string {
    let photo = photos.photos[index];
    return photo.fileName;
  }

  function saveRotation() {
    let oldPhoto = photos.photos[state.currentPhoto];
    let newRotation = state.rotations[state.currentPhoto] ?? 0;
    if (state.shouldSave) {
      let newPhoto: IDocument = { ...oldPhoto, rotation: newRotation };
      dispatch(updatePhoto(newPhoto));
      state.shouldSave = false;
      setState({ ...state, shouldSave: false });
    }
  }

  function prev() {
    if (state.currentPhoto > 0) {
      saveRotation();
      setPhoto(state.currentPhoto - 1);
    }
  }

  function next() {
    if (state.currentPhoto < photos.photos.length - 1) {
      saveRotation();
      setPhoto(state.currentPhoto + 1);
    }
  }

  function lightboxStateUpdated(lightboxState: ILightboxState) {
    let rotations = {
      ...state.rotations,
      [state.currentPhoto]: lightboxState.rotate,
    };
    photos.photos[state.currentPhoto].rotation = lightboxState.rotate;
    setState({ ...state, rotations: rotations, shouldSave: true });
  }

  function archiveName(record: IInspection): string {
    let now = new Date();
    let today = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(
      2
    )}`;
    return `${record.customer.registrationNumber}_${today}_${record.claimNumber ?? record.recordId}.zip`;
  }

  let currentPhoto = photos.photos[state.currentPhoto];

  let photo = {
    url: photoUrl(currentPhoto),
    title: `Photo ${state.currentPhoto + 1} of ${photos.photos.length}`,
    rotation: currentPhoto.rotation ?? 0,
  };

  const photoData = photos.photos.map((photo, i) => ({
    url: photoUrl(photo),
    index: i,
    rotation: photo.rotation ?? 0,
  }));

  return (
    <>
      <Lightbox
        image={photo.url}
        title={photo.title}
        onClose={switchFullscreen}
        //changing key will reset the component state, in this case zoom, rotation, etc.
        key={state.lightboxKey}
        show={state.fullscreen}
        stateChanged={lightboxStateUpdated}
        initialRotation={photo.rotation}
      />
      {state.fullscreen ? (
        <>
          <div
            className="fullscreenPrev if icon ui drop-left white"
            onClick={(e) => {
              prev();
              e.stopPropagation();
            }}
          />
          <div
            className="fullscreenNext if icon ui drop-right white"
            onClick={(e) => {
              next();
              e.stopPropagation();
            }}
          />
        </>
      ) : null}
      <div className="if photo-viewer">
        <div className="photo-header">
          <FormattedMessage
            id="photos.count"
            values={{
              current: state.currentPhoto + 1,
              total: photos.photos.length,
            }}
          />
          <div className="buttons">
            <a download={fileName(state.currentPhoto)} href={photo.url}>
              <FormattedMessage id="photos.save" />
            </a>
            <SaveAllLink
              filename={archiveName(record)}
              url={Backend.URL.Photo.GetArchive(record.recordId) + (isGlass ? "?glass=true" : "")}
            />
          </div>
        </div>
        <div className="if display" onClick={switchFullscreen}>
          {photoData.map((photo) => (
            <img
              key={photo.url}
              src={photo.url}
              className="if display-photo"
              alt={intl.formatMessage(
                { id: "photos.count" },
                { current: photo.index + 1, total: photos.photos.length }
              )}
              style={{
                transform: "rotate(" + photo.rotation.toString() + "deg)",
                display: state.currentPhoto === photo.index ? "block" : "none",
              }}
            />
          ))}
          <button
            className="prev-photo if icon ui drop-left white"
            onClick={(e) => {
              prev();
              e.stopPropagation();
            }}
          />
          <button
            className="next-photo if icon ui drop-right white"
            onClick={(e) => {
              next();
              e.stopPropagation();
            }}
          />
        </div>
        {/* If separate fullscreen button wanted:
          <div className='if fullscreen-button' onClick={switchFullscreen}>
            <div className='if icon ui fullscreen black offset'/>
            <div className='if icon ui fullscreen white'/>
          </div>
          */}
        <div className="if thumbnails">
          <div className="if scroller">
            {photos.photos.map((x, i) => {
              return (
                <div
                  className={"if thumbnail " + (state.currentPhoto === i ? "selected" : "")}
                  key={i}
                  onClick={() => setPhoto(i)}
                >
                  <ImgOrLoader
                    className="if thumbnail-photo"
                    src={photoUrl(x)}
                    style={{
                      transform: "rotate(" + x.rotation.toString() + "deg)",
                    }}
                    alt={(i + 1).toString()}
                  />
                  <div className="if number">{i + 1}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
const ImgOrLoader: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const style = props.style ?? {};
  return (
    <>
      {" "}
      <img
        alt=""
        {...props}
        style={{ ...style, display: loaded ? "block" : "none" }}
        onLoad={() => {
          setLoaded(true);
        }}
        onError={() => {
          setError(true);
        }}
      />
      {!loaded && !error && <Loader />}
      {error && (
        <div className="if notification error">
          <FormattedMessage id="app.error" />
        </div>
      )}
    </>
  );
};
export default PhotoViewer;
