import React from "react";
import { withAITracking, reactPlugin } from "../infrastructure/applicationInsights";
import { useAuth } from "../auth/authContext";

const Logout: React.FC = () => {
  const { isAuthenticated, logout } = useAuth();

  React.useEffect(() => {
    if (isAuthenticated) {
      logout();
    }
    window.localStorage.clear();
    window.sessionStorage.clear();

    //remove all cookies
    let cookies = document.cookie.split("; ");
    for (let cookieIndex = 0; cookieIndex < cookies.length; cookieIndex++) {
      let domains = window.location.hostname.split(".");
      while (domains.length > 0) {
        let cookieBase =
          encodeURIComponent(cookies[cookieIndex].split(";")[0].split("=")[0]) +
          "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
          domains.join(".") +
          " ;path=";
        let pathNames = window.location.pathname.split("/");
        document.cookie = cookieBase + "/";
        while (pathNames.length > 0) {
          document.cookie = cookieBase + pathNames.join("/");
          pathNames.pop();
        }
        domains.shift();
      }
    }
  }, [isAuthenticated, logout]);

  return <div className="padded-block">Logging out...</div>;
};
export default withAITracking(reactPlugin, Logout);
