import * as React from "react";
import { Route, RouteProps } from "react-router-dom";
import { Login } from "../components/login";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../infrastructure/applicationInsights";
import { useAuth } from "./authContext";

const AuthRoute: React.FunctionComponent<RouteProps> = ({ component: Component, ...rest }) => {
  let FaultTolerantComponent = faultTolerant(Component);
  const { isAuthenticated, loginIfNeeded } = useAuth();
  loginIfNeeded();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <FaultTolerantComponent {...props} />;
        }
        return <Login />;
      }}
    />
  );
};

function faultTolerant<P>(Component: React.ComponentType<P>): React.ComponentClass<P> {
  return class extends React.Component<P> {
    public render() {
      return (
        <AppInsightsErrorBoundary
          appInsights={reactPlugin}
          onError={() => (
            <div className="if padded-block darkBeige">
              <div className="if notification error">Application error, our team has been notified.</div>
            </div>
          )}
        >
          <Component {...this.props} />
        </AppInsightsErrorBoundary>
      );
    }
  };
}

export default AuthRoute;
