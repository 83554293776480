import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { IWorkshopFilter } from "../../models";
import "./tableFilter.scss";
import { formatWorkshopName } from "../../utility";

export const TableFilter: React.FC<{
  items: IWorkshopFilter;
  toggle: (key: string) => void;
  toggleAll: (set: boolean) => void;
}> = ({ items, toggle, toggleAll }) => {
  let [state, setState] = React.useState({
    isOpen: false,
  });

  const wrapperRef = React.useRef(null);

  useEffect(() => {
    function onClick(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        //clicked outside of target
        setState((state) => {
          return { ...state, isOpen: false };
        });
      }
    }
    document.addEventListener("mousedown", onClick);
    return () => {
      document.removeEventListener("mousedown", onClick);
    };
  }, [wrapperRef]);

  function toggleMenu() {
    setState({ ...state, isOpen: !state.isOpen });
  }
  let all = Object.keys(items).every((k) => items[k].show);

  let total = Object.values(items).length;
  let shown = Object.values(items).filter((i) => i.show).length;
  let filterText = "";
  if (shown < total) {
    filterText = ` ${shown} / ${total}`;
  }

  let sorted = Object.keys(items).sort((a, b) => items[a].name.localeCompare(items[b].name));

  ["PHINTEST", "VOLVIATEST", "FITEST", "NOTEST", "DKTEST"].forEach((id, idx) => {
    let i = sorted.indexOf(id);
    if (i > -1) {
      sorted.splice(i, 1);
      sorted.splice(idx, 0, id);
    }
  });

  return (
    <div className="if table-filters" ref={wrapperRef}>
      <span className="if title">Filter:</span>
      <ul className="if filter-groups">
        <li className="if">
          <button type="button" className={"if " + (shown < total ? "filtered" : "")} onClick={toggleMenu}>
            <FormattedMessage id="field.workshopName" />
            {filterText}
          </button>
          <div className={"if menu center " + (state.isOpen ? "is-open" : "")}>
            <ul className="if">
              <li className="if" key="all">
                <input
                  type="checkbox"
                  className="if selection-control"
                  checked={all}
                  onChange={() => toggleAll(!all)}
                  id="filter-all"
                  name="filter-all"
                />
                <label className="if" htmlFor="filter-all">
                  All
                </label>
              </li>
              {sorted.map((k) => (
                <li className="if" key={k}>
                  <input
                    type="checkbox"
                    className="if selection-control"
                    checked={items[k].show}
                    onChange={() => toggle(k)}
                    id={"filter-" + k}
                    name={"filter-" + k}
                  />
                  <label className="if" htmlFor={"filter-" + k}>
                    {formatWorkshopName({workshopCity: items[k].city, workshopName: items[k].name})} ({items[k].id})
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
};
