import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { TestBrandSelect } from "./brandSelect";
import { TestLangSelect } from "./langSelect";
import { TestPrefillCheckbox } from "./prefillCheckbox";

export const TestPermanentIntakeUrl: React.FC = () => {
  const history = useHistory();
  let [state, setState] = useState({
    prefill: false,
    lang: "SE",
    brand: "If",
  });

  let testRecordUrl = "/intake/__test";
  if (state.brand !== "If") {
    testRecordUrl += "/" + state.brand.toLowerCase();
  }
  testRecordUrl += "?lang=" + state.lang;
  if (state.prefill) testRecordUrl += "&prefill";

  return (
    <>
      <br />
      <h2>Permanent intake link generator without ID</h2>
      <TestLangSelect value={state.lang} onChange={(v) => setState({ ...state, lang: v })} name="perm" />
      <TestBrandSelect value={state.brand} onChange={(v) => setState({ ...state, brand: v })} name="perm" />
      <TestPrefillCheckbox
        value={state.prefill}
        onChange={(v) => setState({ ...state, prefill: v })}
        name="perm"
      />
      <br />
      {/* eslint-disable-next-line*/}
      <a className="if button primary" onClick={() => history.push(testRecordUrl)} target="_blank">
        Intake &nbsp;
        <span style={{ position: "relative", top: "0.1em" }} className="if icon white ui arrow-right" />
      </a>
      {testRecordUrl}
    </>
  );
};
