import * as Actions from "../actions";
import reducer from "./reducer";
import { IIntakeLinkReminders } from "../models";

export default reducer<IIntakeLinkReminders>(
  {
    records: [],
  },
  (on) => {
    on(Actions.sendIntakeLinkReminder, (_, action) => {
      action.payload.record.isLoading = true;
      return {
        records: _.records.push(action.payload.record),
        ..._,
      };
    });

    on(Actions.sendIntakeLinkReminderError, (_, action) => {
      let i = _.records.findIndex((p) => p.recordId === action.payload.recordId);
      if (i >= 0) {
        _.records[i].isLoading = false;
        _.records[i].loadError = `Failed to send SMS: ${action.payload.message}`;
      }
      return {
        ..._,
      };
    });

    on(Actions.sendIntakeLinkReminderSuccess, (_, action) => {
      let i = _.records.findIndex((p) => p.recordId === action.payload.recordId);
      if (i >= 0) {
        let copy = _.records;
        copy.splice(i, 1);
        _.records = copy;
      }
      return { ..._ };
    });
  }
);
