import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "./actions";
import { Backend } from "../backend";
import { showError } from "../sagas/common";
import { showNotification } from "../actions";
import { NotificationType } from "../models";

function* purgeTestRecords() {
  try {
    yield* Backend.Record.PurgeTestRecords();
    yield put(Actions.purgeTestRecordsSuccess());
    yield put(
      showNotification({
        message: "Deleted all test records",
        type: NotificationType.Success,
        time: new Date(),
      })
    );
  } catch (err) {
    yield showError(err);
    yield put(Actions.purgeTestRecordsError(err));
  }
}

export default function* purgeTestRecordsSaga(): SagaIterator {
  yield takeLatest(Actions.purgeTestRecords, purgeTestRecords);
}
