import "./inspectionWizardButtons.scss";
import { FormattedMessage } from "react-intl";

import * as React from "react";
import { useSelector } from "react-redux";
import { AppState, IntakeSendingState } from "../../models";
import Loader from "../shared/loader";

export type IProps = {
  currentStep: number;
  totalSteps: number;
  isEditing: boolean;
  onNext();
  onPrev();
  onSubmit();
  onCancelSubmit();
};

const InspectionWizardButtons: React.FC<IProps> = ({
  currentStep,
  totalSteps,
  isEditing,
  onNext,
  onPrev,
  onSubmit,
  onCancelSubmit,
}) => {
  let submission = useSelector((state: AppState) => state.intakeSubmission);

  const nextButton = (
    <div className={window.innerWidth < 1366 ? "single-button" : "double-button"}>
      <button className="if button secondary" data-cy="nextBtn" onClick={onNext}>
        <FormattedMessage id={isEditing ? "intake.wizard.buttonSave" : "intake.wizard.buttonNext"} />
      </button>
    </div>
  );
  if (!currentStep) return null;

  const submitButton = (
    <div className="double-button">
      <button className="if button primary" data-cy="submitButton" onClick={onSubmit}>
        <FormattedMessage id="intake.wizard.buttonSubmit" />
      </button>
    </div>
  );

  let buttons = <>{currentStep < totalSteps - 1 ? nextButton : submitButton}</>;

  if (submission.sendingState === IntakeSendingState.SendingInfo) {
    buttons = (
      <div className="submit-btn-holder">
        <div className="submit-status-holder">
          <FormattedMessage id="intake.wizard.sendingInformation" />
          <Loader />
        </div>
      </div>
    );
  } else if (submission.sendingState === IntakeSendingState.SendingFiles) {
    buttons = (
      <div className="submit-btn-holder">
        <div className="submit-status-holder">
          <span className="submit-status-text">
            <FormattedMessage
              id="intake.wizard.sendingFile"
              values={{
                current: Math.min(submission.filesUploaded + 1, submission.filesTotal),
                total: submission.filesTotal,
              }}
            />
          </span>
          <div className="submit-status-progress">
            <div
              className="bar"
              style={{ width: (submission.uploadProgres * 100).toFixed(0) + "%" }}
              key={submission.filesUploaded}
            />
          </div>
        </div>
        <button
          className="if button primary cancel-button"
          data-cy="submitCancelButton"
          onClick={onCancelSubmit}
        >
          <FormattedMessage id="intake.PhotoStep.cancelButton" />
        </button>
      </div>
    );
  }

  return <div className="back-next-btn-holder">{buttons}</div>;
};
export default InspectionWizardButtons;
