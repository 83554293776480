import React from "react";

export default (<div>
  <br />
  <strong className="if error-text">
    Warning: Internet Explorer is not supported! Please download &nbsp;
    <a className="if" href="//getfirefox.com/">
      Firefox
    </a>
  </strong>
</div>);
