// Generated by Elbowgrease, do not modify by hand
// noinspection JSUnusedGlobalSymbols,SpellCheckingInspection

import { call } from "redux-saga/effects";
import { apiCall } from "./sagas/api";
import {
  IInfoNotification,
  IFeedback,
  IUserFeedbackResponse,
  IAdminSmsTemplateRequest,
  ICspReportRequest,
  IUserAuthData,
  IBodyInspectionResponse,
  IGlassInspectionResponse,
  IAddCommentRequest,
  IGlassInspectionRequest,
  IChangeGlassStatusRequest,
  IIntakeInformation,
  IInspectionIntakeRequest,
  IInspectionFeedback,
  IDocument,
  IBodyInspectionsResponse,
  IGlassInspectionsResponse,
  IRecord,
  IChangeStatusRequest,
  INewInspectionRequest,
  INewLightInspectionRequest,
  IUserInfo,
  IWorkshop,
  IDisableServiceRequest,
} from "./backend-models";

export type DateString = string;

export let Backend = {
  Admin: {
    *NotificationDelete(notification: IInfoNotification) {
      return (yield call(apiCall, `/api/Admin/NotificationDelete`, {
        body: notification,
        method: "POST",
      })) as IInfoNotification[];
    },
    *NotificationAdd(notification: IInfoNotification) {
      return (yield call(apiCall, `/api/Admin/NotificationAdd`, {
        body: notification,
        method: "POST",
      })) as IInfoNotification[];
    },
    *SmsTemplate(request: IAdminSmsTemplateRequest) {
      return (yield call(apiCall, `/api/Admin/SmsTemplate`, {
        body: request,
        method: "POST",
      })) as string;
    },
    *Feedback() {
      return (yield call(apiCall, `/api/Admin/Feedback`)) as IFeedback[];
    },
    *UserFeedback() {
      return (yield call(apiCall, `/api/Admin/UserFeedback`)) as IUserFeedbackResponse;
    },
    *SendNotificationEmails() {
      return (yield call(apiCall, `/api/Admin/SendNotificationEmails`)) as IFeedback[];
    },
  },
  App: {
    *Version() {
      return (yield call(apiCall, `/api/Version`, {
        anonymous: true,
      })) as string;
    },
    *ClientEnvironment() {
      yield call(apiCall, `/api/ClientEnvironment`, {
        anonymous: true,
      });
    },
    *Feedback(feedback: IFeedback) {
      yield call(apiCall, `/api/Feedback`, {
        anonymous: true,
        body: feedback,
        method: "POST",
      });
    },
    *CspReport(request: ICspReportRequest) {
      yield call(apiCall, `/api/CspReport`, {
        anonymous: true,
        body: request,
        method: "POST",
      });
    },
  },
  Auth: {
    *LogIn() {
      yield call(apiCall, `/api/Auth/LogIn`, {
        anonymous: true,
      });
    },
    *LogOut() {
      yield call(apiCall, `/api/Auth/LogOut`);
    },
    *GetUser() {
      return (yield call(apiCall, `/api/Auth/GetUser`, {
        anonymous: true,
      })) as IUserAuthData;
    },
  },
  Comment: {
    *Add(request: IAddCommentRequest) {
      return (yield call(apiCall, `/api/Comment/Add`, {
        body: request,
        method: "POST",
      })) as IBodyInspectionResponse;
    },
    *AddGlass(request: IAddCommentRequest) {
      return (yield call(apiCall, `/api/Comment/AddGlass`, {
        body: request,
        method: "POST",
      })) as IGlassInspectionResponse;
    },
  },
  Glass: {
    *Inspection(data: IGlassInspectionRequest) {
      yield call(apiCall, `/api/Glass/Inspection`, {
        body: data,
        method: "POST",
      });
    },
    *Photo(recordId: string, tag: string, file: FormData) {
      yield call(apiCall, `/api/Glass/Photo/${recordId}/${tag}`, {
        body: file,
        method: "POST",
      });
    },
    *ChangeStatus(request: IChangeGlassStatusRequest) {
      return (yield call(apiCall, `/api/Glass/ChangeStatus`, {
        body: request,
        method: "POST",
      })) as IGlassInspectionResponse;
    },
  },
  Intake: {
    *GetIntakeInformation(recordGuid: string) {
      return (yield call(apiCall, `/api/Intake/GetIntakeInformation/${recordGuid}`, {
        anonymous: true,
      })) as IIntakeInformation;
    },
    *SubmitFile(recordId: string, tag: string, file: FormData) {
      yield call(apiCall, `/api/Intake/SubmitFile/${recordId}/${tag}`, {
        anonymous: true,
        body: file,
        method: "POST",
      });
    },
    *Submit(data: IInspectionIntakeRequest, recordId: string) {
      yield call(apiCall, `/api/Intake/Submit/${recordId}`, {
        anonymous: true,
        body: data,
        method: "POST",
      });
    },
    *SubmitIntakeFeedback(data: IInspectionFeedback, recordId: string) {
      yield call(apiCall, `/api/Intake/SubmitIntakeFeedback/${recordId}`, {
        anonymous: true,
        body: data,
        method: "POST",
      });
    },
  },
  Photo: {
    *List(recordId: string) {
      return (yield call(apiCall, `/api/Photo/List/${recordId}`)) as IDocument[];
    },
    *GetPhoto(workshopId: string, recordId: string, documentId: string) {
      yield call(apiCall, `/api/Photo/GetPhoto/${workshopId}/${recordId}/${documentId}`);
    },
    *GetArchive(recordId: string) {
      yield call(apiCall, `/api/Photo/GetArchive/${recordId}`);
    },
    *Update(photo: IDocument) {
      return (yield call(apiCall, `/api/Photo/Update`, {
        body: photo,
        method: "POST",
      })) as IDocument;
    },
    *Cabas(cabasPhotoId: string) {
      yield call(apiCall, `/api/Photo/Cabas/${cabasPhotoId}`, {
        anonymous: true,
      });
    },
  },
  Record: {
    *GetRecords() {
      return (yield call(apiCall, `/api/Record/GetRecords`)) as IBodyInspectionsResponse;
    },
    *GetRecordsTest() {
      yield call(apiCall, `/api/Record/GetRecordsTest`);
    },
    *GlassInspections() {
      return (yield call(apiCall, `/api/Record/GlassInspections`)) as IGlassInspectionsResponse;
    },
    *GetRecord(recordId: string) {
      return (yield call(apiCall, `/api/Record/GetRecord/${recordId}`)) as IBodyInspectionResponse;
    },
    *GlassInspection(recordId: string) {
      return (yield call(apiCall, `/api/Record/GlassInspection/${recordId}`)) as IGlassInspectionResponse;
    },
    *UpdateRecord(record: IRecord) {
      return (yield call(apiCall, `/api/Record/UpdateRecord`, {
        body: record,
        method: "POST",
      })) as IBodyInspectionResponse;
    },
    *ChangeStatus(request: IChangeStatusRequest) {
      return (yield call(apiCall, `/api/Record/ChangeStatus`, {
        body: request,
        method: "POST",
      })) as IBodyInspectionResponse;
    },
    *SearchRecords(searchText: string) {
      return (yield call(apiCall, `/api/Record/SearchRecords/${searchText}`)) as IBodyInspectionsResponse;
    },
    *NewInspection(request: INewInspectionRequest) {
      return (yield call(apiCall, `/api/Record/NewInspection`, {
        body: request,
        method: "POST",
      })) as IRecord;
    },
    *ExternalNewInspection(request: INewInspectionRequest) {
      return (yield call(apiCall, `/api/Record/ExternalNewInspection`, {
        body: request,
        method: "POST",
      })) as IRecord;
    },
    *ExternalNewInspectionLight(request: INewLightInspectionRequest) {
      return (yield call(apiCall, `/api/Record/ExternalNewInspectionLight`, {
        anonymous: true,
        body: request,
        method: "POST",
      })) as IRecord;
    },
    *SendIntakeSmsReminder(recordId: string) {
      return (yield call(apiCall, `/api/Record/SendIntakeSmsReminder/${recordId}`, {
        body: null,
        method: "POST",
      })) as IBodyInspectionResponse;
    },
    *Add(record: IRecord) {
      return (yield call(apiCall, `/api/Record/Add`, {
        body: record,
        method: "POST",
      })) as IBodyInspectionResponse;
    },
    *Purge(recordId: string) {
      yield call(apiCall, `/api/Record/Purge/${recordId}`, {
        body: null,
        method: "POST",
      });
    },
    *PurgeTestRecords() {
      yield call(apiCall, `/api/Record/PurgeTestRecords`, {
        body: null,
        method: "POST",
      });
    },
    *SendToCabas(record: IRecord) {
      yield call(apiCall, `/api/Record/SendToCabas`, {
        body: record,
        method: "POST",
      });
    },
  },
  UserInfo: {
    *GetUserInfo() {
      return (yield call(apiCall, `/api/UserInfo/GetUserInfo`)) as IUserInfo;
    },
  },
  Workshop: {
    *GetWorkshops() {
      return (yield call(apiCall, `/api/Workshop/GetWorkshops`)) as IWorkshop[];
    },
    *DisablePhotoInspectionService(request: IDisableServiceRequest) {
      yield call(apiCall, `/api/Workshop/DisablePhotoInspectionService`, {
        body: request,
        method: "POST",
      });
    },
  },
  URL: {
    Admin: {
      NotificationDelete(notification: IInfoNotification) {
        return `/api/Admin/NotificationDelete`;
      },
      NotificationAdd(notification: IInfoNotification) {
        return `/api/Admin/NotificationAdd`;
      },
      SmsTemplate(request: IAdminSmsTemplateRequest) {
        return `/api/Admin/SmsTemplate`;
      },
      Feedback() {
        return `/api/Admin/Feedback`;
      },
      UserFeedback() {
        return `/api/Admin/UserFeedback`;
      },
      SendNotificationEmails() {
        return `/api/Admin/SendNotificationEmails`;
      },
    },
    App: {
      Version() {
        return `/api/Version`;
      },
      ClientEnvironment() {
        return `/api/ClientEnvironment`;
      },
      Feedback(feedback: IFeedback) {
        return `/api/Feedback`;
      },
      CspReport(request: ICspReportRequest) {
        return `/api/CspReport`;
      },
    },
    Auth: {
      LogIn() {
        return `/api/Auth/LogIn`;
      },
      LogOut() {
        return `/api/Auth/LogOut`;
      },
      GetUser() {
        return `/api/Auth/GetUser`;
      },
    },
    Comment: {
      Add(request: IAddCommentRequest) {
        return `/api/Comment/Add`;
      },
      AddGlass(request: IAddCommentRequest) {
        return `/api/Comment/AddGlass`;
      },
    },
    Glass: {
      Inspection(data: IGlassInspectionRequest) {
        return `/api/Glass/Inspection`;
      },
      Photo(recordId: string, tag: string, file: FormData) {
        return `/api/Glass/Photo/${recordId}/${tag}`;
      },
      ChangeStatus(request: IChangeGlassStatusRequest) {
        return `/api/Glass/ChangeStatus`;
      },
    },
    Intake: {
      GetIntakeInformation(recordGuid: string) {
        return `/api/Intake/GetIntakeInformation/${recordGuid}`;
      },
      SubmitFile(recordId: string, tag: string, file: FormData) {
        return `/api/Intake/SubmitFile/${recordId}/${tag}`;
      },
      Submit(data: IInspectionIntakeRequest, recordId: string) {
        return `/api/Intake/Submit/${recordId}`;
      },
      SubmitIntakeFeedback(data: IInspectionFeedback, recordId: string) {
        return `/api/Intake/SubmitIntakeFeedback/${recordId}`;
      },
    },
    Photo: {
      List(recordId: string) {
        return `/api/Photo/List/${recordId}`;
      },
      GetPhoto(workshopId: string, recordId: string, documentId: string) {
        return `/api/Photo/GetPhoto/${workshopId}/${recordId}/${documentId}`;
      },
      GetArchive(recordId: string) {
        return `/api/Photo/GetArchive/${recordId}`;
      },
      Update(photo: IDocument) {
        return `/api/Photo/Update`;
      },
      Cabas(cabasPhotoId: string) {
        return `/api/Photo/Cabas/${cabasPhotoId}`;
      },
    },
    Record: {
      GetRecords() {
        return `/api/Record/GetRecords`;
      },
      GetRecordsTest() {
        return `/api/Record/GetRecordsTest`;
      },
      GlassInspections() {
        return `/api/Record/GlassInspections`;
      },
      GetRecord(recordId: string) {
        return `/api/Record/GetRecord/${recordId}`;
      },
      GlassInspection(recordId: string) {
        return `/api/Record/GlassInspection/${recordId}`;
      },
      UpdateRecord(record: IRecord) {
        return `/api/Record/UpdateRecord`;
      },
      ChangeStatus(request: IChangeStatusRequest) {
        return `/api/Record/ChangeStatus`;
      },
      SearchRecords(searchText: string) {
        return `/api/Record/SearchRecords/${searchText}`;
      },
      NewInspection(request: INewInspectionRequest) {
        return `/api/Record/NewInspection`;
      },
      ExternalNewInspection(request: INewInspectionRequest) {
        return `/api/Record/ExternalNewInspection`;
      },
      ExternalNewInspectionLight(request: INewLightInspectionRequest) {
        return `/api/Record/ExternalNewInspectionLight`;
      },
      SendIntakeSmsReminder(recordId: string) {
        return `/api/Record/SendIntakeSmsReminder/${recordId}`;
      },
      Add(record: IRecord) {
        return `/api/Record/Add`;
      },
      Purge(recordId: string) {
        return `/api/Record/Purge/${recordId}`;
      },
      PurgeTestRecords() {
        return `/api/Record/PurgeTestRecords`;
      },
      SendToCabas(record: IRecord) {
        return `/api/Record/SendToCabas`;
      },
    },
    UserInfo: {
      GetUserInfo() {
        return `/api/UserInfo/GetUserInfo`;
      },
    },
    Workshop: {
      GetWorkshops() {
        return `/api/Workshop/GetWorkshops`;
      },
      DisablePhotoInspectionService(request: IDisableServiceRequest) {
        return `/api/Workshop/DisablePhotoInspectionService`;
      },
    },
  },
};
