import * as Actions from "../actions";
import reducer from "./reducer";
import { IPhotos } from "../models";

const reset = {
  photos: [],
  loadError: undefined,
  recordId: undefined,
  timestamp: 0,
};
export default reducer<IPhotos>(
  {
    isLoading: true,
    ...reset,
  },
  (on) => {
    on(Actions.fetchPhotos, (_, action) => {
      return {
        ..._,
        ...reset,
        isLoading: true,
        timestamp: new Date().getTime(),
        recordId: action.payload.recordId,
      };
    });

    on(Actions.fetchPhotosSuccess, (_, { payload }) => {
      return {
        ..._,
        isLoading: false,
        photos: payload,
        timestamp: new Date().getTime(),
      };
    });

    on(Actions.fetchPhotosError, (_, action) => {
      return {
        ..._,
        ...reset,
        recordId: action.payload.recordId,
        isLoading: false,
        loadError: `Failed to load photos: ${action.payload.message}`,
      };
    });

    on(Actions.updatePhotoSuccess, (_, action) => {
      let i = _.photos.findIndex((p) => p.photoId === action.payload.photoId);
      if (i >= 0) {
        _.photos[i] = action.payload;
      }
      return { ..._ };
    });
  }
);
