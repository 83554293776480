import React from "react";

export const TestBrandSelect: React.FC<{ value; onChange; name }> = (props) => {
  const availableBrands = "If,Volvia,Renault,Volkswagen,Audi,Kia,Nissan,Ford,NotBranded".split(",");
  return (
    <div className="if input-wrapper" style={{ margin: 0 }}>
      <div className="if selection-controls horizontal">
        {availableBrands.map((brand) => (
          <React.Fragment key={brand}>
            <input
              type="radio"
              id={"brand-" + brand + props.name}
              className="if selection-control"
              name={"brand-" + props.name}
              checked={brand === props.value}
              value={props.value}
              onChange={(e) => {
                props.onChange(brand);
              }}
            />
            <label htmlFor={"brand-" + brand + props.name} style={{ marginRight: "1.6em" }}>
              {brand}
            </label>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
