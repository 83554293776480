import {WorkshopCountryCode} from "../../../../backend-models";

export function TermsOfServiceVolvia(props) {
    function TermsOfServiceVolviaSE() {
        return (
            <div className="if consent-container">
                <h1 className="if heading">Användarvillkor för Volvias fotobesiktningstjänst</h1>
                <p className="if text body">
                    Vi på Volvia är glada över att du har valt att använda Volvias fotobesiktningstjänst (”Tjänsten”).
                    För
                    att använda Tjänsten behöver du först godkänna dessa användarvillkor. Användarvillkoren utgör ett
                    avtal (”Avtalet”) mellan dig och Volvia, org.nr 516401-8102 (”Tjänsteleverantören”).
                </p>
                <h2 className="if heading">1. Tjänsten</h2>
                <p className="if text body">
                    1.1 Genom Tjänsten får du som råkat ut för en skada på ditt fordon möjlighet att överföra
                    fotografier
                    på fordonet till den verkstad som du använder dig av förutsatt att verkstaden är ansluten till
                    Tjänsten och accepterat att du överför fotografier genom Tjänsten.
                </p>
                <p className="if text body">
                    1.2 Tjänsten levereras ”i befintligt skick” och i det utförande som den har vid respektive tidpunkt.
                </p>
                <h2 className="if heading">2. Hantering av personuppgifter</h2>
                <p className="if text body">
                    2.1 Genom att använda Tjänsten får Tjänsteleverantören tillgång till de uppgifter som du anger eller
                    laddar upp i Tjänsten, t.ex. namn, telefonnummer och registreringsnummer på det fordon som ska bli
                    föremål för fotobesiktning. Tjänsteleverantören överför dessa uppgifter till aktuell verkstad på
                    uppdrag av dig och sparar uppgifterna så länge som det är nödvändigt för att dokumentera uppdragets
                    utförande. Om Tjänsten används inom ramen för ett skadeärende hos Tjänsteleverantören sparas
                    uppgifterna i skadeärendet.
                </p>
                <p className="if text body">
                    2.2 De fotografier som du laddar upp i Tjänsten kan komma att användas för analysändamål, t.ex. för
                    analys av fordonets skador.
                </p>
                <p className="if text body">
                    2.3 Du kan läsa mer om Tjänsteleverantörens hantering av personuppgifter och dina rättigheter på
                    <a href="https://www.volvia.se/hantering-av-personuppgifter">
                        www.volvia.se/hantering-av-personuppgifter
                    </a>
                    .
                </p>
                <h2 className="if heading">3. Immateriella rättigheter och villkor för att använda Tjänsten</h2>
                <p className="if text body">
                    3.1 Samtliga immateriella rättigheter till Tjänsten, inbegripet det material som visas eller finns i
                    Tjänsten, innehas av Tjänsteleverantören eller av leverantörer till Tjänsteleverantören avseende
                    Tjänsten eller annan tredje part. Under förutsättning att du godkänner och uppfyller villkoren i
                    Avtalet ges du härmed en personlig, icke-exklusiv och icke-överlåtbar rätt att använda de tjänster
                    och
                    funktioner som tillhandahålls i Tjänsten. Denna rätt upphör automatiskt om Tjänsteleverantören
                    inaktiverar Tjänsten eller om Avtalet upphör att gälla genom uppsägning eller på annat sätt.
                </p>
                <p className="if text body">3.2 Du har inte rätt att:</p>
                <p className="if text body">
                    (a) kopiera eller använda Tjänsten för andra ändamål än vad den är avsedd för;
                </p>
                <p className="if text body">
                    (b) sälja, distribuera eller på annat sätt överföra Tjänsten till tredje part;
                </p>
                <p className="if text body">(c) skapa bearbetningar av Tjänsten;</p>
                <p className="if text body">
                    (d) använda Tjänsten för att överföra olagliga eller kränkande uppgifter eller sådana uppgifter som
                    utgör en olaglig kopia av tredje parts uppgifter;
                </p>
                <p className="if text body">(e) tillåta tredje part att göra något av ovan nämnda åtgärder.</p>
                <p className="if text body">
                    3.3 Genom att ladda upp ett fotografi i Tjänsten ger du Tjänsteleverantören en icke-exklusiv och
                    överlåtbar rätt att på obegränsad tid använda fotografiet för analysandamål. Denna punkt ska
                    fortsätta
                    att gälla även för det fall att Avtalet sägs upp eller på annat sätt upphör att gälla.
                </p>
                <h2 className="if heading">4. Datatrafik</h2>
                <p className="if text body">
                    För att använda Tjänsten krävs att du har uppkoppling till internet. Du ansvarar för samtliga
                    kostnader hänförlig till denna uppkoppling, inbegripet eventuella kostnader för datatrafik och
                    roaming.
                </p>
                <h2 className="if heading">5. Ansvar och ansvarsbegränsning</h2>
                <p className="if text body">
                    Tjänsteleverantörens ambition är att Tjänsten ska finnas tillgänglig dygnet runt. Tjänsten kan dock
                    vara otillgänglig t.ex. till följd av serviceavbrott eller tekniska problem. Fotografier och annat
                    material som du har laddat upp i Tjänsten kan av oförutsedd orsak raderas och försvinna för gott.
                    Det
                    är ditt ansvar att ha säkerhetskopior på allt material som du laddar upp i Tjänsten.
                    Tjänsteleverantören är inte ansvarig för skada eller förlust hänförlig till Tjänsten, oavsett om den
                    är direkt eller indirekt.
                </p>
                <h2 className="if heading">6. Tvist</h2>
                <p className="if text body">
                    En tvist mellan dig och Tjänsteleverantören med anledning av de förhållanden som regleras av Avtalet
                    ska avgöras av svensk domstol och med tillämpning av svensk rätt, dock utan tillämpning av svenska
                    lagvalsregler. Tjänsteleverantören får även väcka talan vid domstol i ett annat land där du har din
                    hemvist eller där du har tillgångar.
                </p>
                <h2 className="if heading">7. Övrigt</h2>
                <p className="if text body">
                    7.1 Tjänsteleverantören förbehåller sig rätten att när som helst säga upp Avtalet eller upphöra med
                    att tillhandahålla Tjänsten.
                </p>
                <p className="if text body">
                    7.2 Sådana bestämmelser i Avtalet som till sin natur är ägnade att vara tillämpliga även efter
                    uppsägning, ska fortsätta att vara tillämpliga.
                </p>
                <p className="if text body">
                    7.3 Om något eller några av de villkor som dessa användarvillkor bildar konstateras vara ogiltiga
                    eller inte kunna verkställas, tolkas övriga villkor i enlighet med tillämplig lag att så exakt som
                    möjligt motsvara det ursprungliga syftet med dessa villkor och till övriga delar kvarstår villkoren
                    till fullo i kraft.
                </p>
                <hr/>
                <p className="if text disclaimer">
                    Dessa villkor upprättades den 8 januari 2021. Volvia är en del av If Skadeförsäkring AB (publ),
                    org.nr
                    516401-8102, med adress 106 80 Stockholm. If Skadeförsäkring AB (publ) är ett försäkringsbolag och
                    står under tillsyn av Finansinspektionen (Finansinspektionen, Box 7821, 103 97 Stockholm, 08-408 980
                    00, finansinspektionen@fi.se, fi.se). If står även under Konsumentverkets tillsyn vad gäller
                    marknadsföring och reklam (Konsumentverket, Box 48, 651 02 Karlstad, 0771-42 33 00,
                    konsumentverket@konsumentverket.se, konsumentverket.se).
                </p>
            </div>
        );
    }

    function TermsOfServiceVolviaNO() {
        return (
            <div className="if consent-container">
                <h1 className="if heading">Brukervilkår for Volvias fotobesiktelsestjeneste</h1>
                <p className="if text body">
                    Vi i Volvia er glade for at du har valgt å bruke Volvias tjeneste for fotobesiktelse (“Tjenesten”).
                    For å bruke Tjenesten må du først godta disse brukervilkårene. Brukervilkårene utgjør en avtale
                    («Avtalen") mellom deg og Volvia, organisasjonsnummer 516401-8102 ("Tjenesteleverandøren").
                </p>
                <h2 className="if heading">1. Tjenesten</h2>
                <p className="if text body">
                    1.1 Ved å benytte Tjenesten har du som har fått skade på kjøretøyet ditt mulighet til å overføre
                    bilder av kjøretøyet til verkstedet du bruker, forutsatt at verkstedet er koblet til Tjenesten og
                    godtar at du overfører bilder gjennom Tjenesten.
                </p>
                <p className="if text body">
                    1.2 Tjenesten leveres i den stand og i den versjon den har på det tidspunktet du benytter Tjenesten.
                </p>
                <h2 className="if heading">2. Behandling av personopplysninger</h2>
                <p className="if text body">
                    2.1 Ved å bruke Tjenesten får Tjenesteleverandøren tilgang til informasjonen du legger inn eller
                    laster opp i Tjenesten, f.eks. navn, telefonnummer og registreringsnummer på kjøretøyet som skal
                    fotograferes. Tjenesteleverandøren overfører denne informasjonen til det aktuelle verkstedet på dine
                    vegne og lagrer informasjonen så lenge det er nødvendig for å dokumentere gjennomføringen av
                    oppdraget. Dersom Tjenesten benyttes i forbindelse med et skadeoppgjør hos Tjenesteleverandøren,
                    lagres informasjonen i den aktuelle skadesaken.
                </p>
                <p className="if text body">
                    2.2 Du kan lese mer om Tjenesteleverandørens behandling av personopplysninger og dine rettigheter
                    på www.volvia.no/behandling-av-personopplysninger.
                </p>
                
                <h2 className="if heading">3. Immaterielle rettigheter og vilkår for bruk av Tjenesten</h2>
                <p className="if text body">
                    3.1 Alle immaterielle rettigheter i Tjenesten, inkludert materialet som vises eller eksisterer i
                    Tjenesten, tilhører Tjenesteleverandøren eller en av dens underleverandører.  Forutsatt at du godtar
                    og oppfyller vilkårene i Avtalen, får du herved en personlig, ikke-eksklusiv og ikke-overførbar rett
                    til å bruke Tjenesten og funksjonene som tilbys der. Denne retten opphører automatisk hvis
                    Tjenesteleverandøren deaktiverer Tjenesten eller hvis Avtalen opphører å gjelde ved oppsigelse eller
                    på annen måte.
                </p>
                <p className="if text body">3.2 Du har ikke rett til å:</p>
                <p className="if text body">
                    (a) kopiere eller bruke Tjenesten til andre formål enn beskrevet i Avtalen;
                </p>
                <p className="if text body">
                    (b) selge, distribuere eller på annen måte overføre Tjenesten til tredjepart;
                </p>
                <p className="if text body">(c) lage tilpasninger av Tjenesten;</p>
                <p className="if text body">
                    (d) bruke Tjenesten til å overføre ulovlige eller krenkende data eller slike data som utgjør en
                    ulovlig kopi av tredjepartsdata;
                </p>
                <p className="if text body">(e) tillate tredjeparter å treffe noen av de ovennevnte tiltakene.</p>
                <p className="if text body">
                    3.3 Ved å laste opp et fotografi i Tjenesten, gir du Tjenesteleverandøren en ikke-eksklusiv og
                    overførbar rett til å bruke fotografiet til analyseformål på ubestemt tid. Eventuelle
                    personopplysninger som knytter seg til et fotografi skal ved slik bruk anonymiseres (slik som navn
                    og adresse på bileier, registreringsnummer), slik at fotografiet ikke lenger kan knyttes til en
                    person. Denne klausulen skal fortsette å gjelde også for det tilfelle Avtalen blir sagt opp eller
                    på annen måte opphører.
                </p>
                <h2 className="if heading">4. Datatrafikk</h2>
                <p className="if text body">
                    For å bruke Tjenesten må du ha en internettforbindelse. Du er ansvarlig for alle kostnader knyttet
                    til denne forbindelsen, inkludert kostnader for datatrafikk og roaming.
                </p>
                <h2 className="if heading">5. Ansvar og ansvarsbegrensning </h2>
                <p className="if text body">
                    Tjenesteleverandørens ambisjon er at Tjenesten skal være tilgjengelig døgnet rundt. Det kan
                    imidlertid oppstå tjenesteavbrudd eller tekniske problemer. Fotografier og annet materiale du har
                    lastet opp i Tjenesten kan av uforutsette grunner slettes og forsvinne for godt. Det er ditt ansvar
                    å ha sikkerhetskopier av alt materiale du laster opp til Tjenesten. Tjenesteleverandøren er ikke
                    ansvarlig for skade eller tap som kan tilskrives Tjenesten, uavhengig av om den er direkte eller
                    indirekte.
                </p>
                <h2 className="if heading">6. Tvist</h2>
                <p className="if text body">
                    Dersom det oppstår en tvist mellom deg og Tjenesteleverandøren på grunn av omstendigheter reguler
                    i Avtalen skal tvisten avgjøres ved en norsk domstol og med anvendelse av norsk rett.
                </p>
                <h2 className="if heading">7. Annet</h2>
                <p className="if text body">
                    7.1 Tjenesteleverandøren forbeholder seg retten til å si opp Avtalen når som helst eller å slutte å
                    levere Tjenesten.
                </p>
                <p className="if text body">
                    7.2 Bestemmelser i avtalen som etter sin art er egnet til å gjelde også etter avtalens opphør, skal
                    fortsatte være gjeldende etter opphør.
                </p>
                <p className="if text body">
                    7.3 Hvis det er vilkår eller betingelser i Avtalen som blir ansett ugyldige eller som ikke kan
                    håndheves, skal de øvrige bestemmelsene fortsette å gjelde eller tolkes i samsvar med gjeldende
                    lov for å tilsvare så presist som mulig det opprinnelige formålet med disse bestemmelsene.
                </p>
                <hr/>
                <p className="if text disclaimer">
                    Disse vilkårene ble opprettet 11. februar 2021.
                    If Skadeforsikring NUF
                    Foretaksregisteret, org.nr: 981 290 666
                    Postboks 240, 1326 Lysaker
                    Besøksadresse: Drammensveien 264, 0283 Oslo
                    Telefon: 21 49 24 00
                    www.if.no

                    Filial av:
                    If Skadeförsäkring AB (publ)
                    Hovedkontor: S-106 80 Stockholm
                    Bolagsverket, reg.nr.: 516401-8102
                </p>
            </div>
        );
    }
    
    if(props.locale === WorkshopCountryCode.NO) {
        return TermsOfServiceVolviaNO();
    }
    
    return TermsOfServiceVolviaSE();
    
}
