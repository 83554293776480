import React from 'react';
import { useIntl } from 'react-intl';

interface IConfirmProps {
    title: string;
    text: string;
    show?: boolean;
    confirmAction: () => void;
    cancel?: () => void;
}

const Confirm: React.FC<IConfirmProps> = (props) => {
    const { title, text, show, confirmAction, cancel } = props;
    const intl = useIntl();

    if (!show) {
        return null;
    }

    return (
        <>
            <div className='if backdrop is-open' data-cy='confirmDelete' />
            <div className='if modal'>
                <div className='if title'>{title}</div>
                <div className='if content'>
                    <p className='if'>{text}</p>
                </div>
                <div className='if footer'>
                    <button
                        type='button'
                        className='if button primary'
                        onClick={() => confirmAction()}
                    >
                        {intl.formatMessage({ id: "confirm.delete.yes" })}
                    </button>
                    <button type='button' className='if button' onClick={() => cancel()}>
                        {intl.formatMessage({ id: "confirm.delete.cancel" })}
                    </button>
                </div>
            </div>
        </>
    );
};

export const useConfirm = (): {
    confirmPopUp: JSX.Element;
    setConfirmPopUp: (options: IConfirmProps) => void;
} => {
    const initialValues = {
        title: '',
        text: '',
        show: false,
        confirmAction: () => { },
        cancel: () => { },
    };

    const [confirmValues, setConfirmValues] = React.useState(initialValues);

    const setConfirmPopUp = (options) => {
        setConfirmValues({
            title: options.title,
            text: options.text,
            show: true,
            confirmAction: () => {
                setConfirmValues(initialValues);
                options.confirmAction();
            },
            cancel: () => {
                setConfirmValues(initialValues);
                if (options.cancel) options.cancel();
            },
        });
    };

    const confirmPopUp = <Confirm {...confirmValues} />;

    return { confirmPopUp, setConfirmPopUp };
};
