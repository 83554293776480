import "./tableView.scss";

import React, { useContext, useReducer } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { AppContext, IInspection, IRecordColumns, ISortConfig, IsRecord, TableColumn, toDisplay } from "models";
import { deepGet, formatColumnName, getSortConfig, perfMetrics, storeSortConfig } from "utility";
import Loader from "./loader";
import StatusAction from "./statusAction";
import { Status } from "backend-models";
import * as Actions from "actions";
import { useDispatch } from "react-redux";
import { TableFilter } from "./tableFilter";
import { TablePagination } from "./tablePagination";
import { useStateSelector } from "store";

interface IProps {
  columns: IRecordColumns;
  records: IInspection[];
  route: string;
  altRoute?: string;
  showActionColumn?: boolean;
  defaultSortColumn?: TableColumn;
  pageSize?: number;
  pageBase?: string;
  hideFilter?: boolean;
  showWarning?: boolean;
  isGlass?: boolean;
}
const TableView: React.FC<IProps> = (props) => {
  perfMetrics.table = perfMetrics.table ?? new Date();
  let sortedRecords = props.records;
  const history = useHistory();

  let dispatch = useDispatch();
  const appContext = useContext(AppContext);

  let sortConfig = getSortConfig(props.pageBase, props.defaultSortColumn);

  let bodyWorkshopFilter = useStateSelector((state) => state.records.workshopFilter);
  let glassWorkshopFilter = useStateSelector((state) => state.glassInspections.workshopFilter);
  const workshopFilter = props.isGlass ? glassWorkshopFilter : bodyWorkshopFilter;

  sortedRecords = sortedRecords.filter((r) => {
    let filter = workshopFilter[r.workshopId];
    if (filter) return workshopFilter[r.workshopId].show;
    return true;
  });

  let pageSize = props.pageSize ?? 7;
  let maxPages = Math.ceil(sortedRecords.length / pageSize);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  function setSortConfig(config: ISortConfig) {
    storeSortConfig(props.pageBase, config);
    forceUpdate();
  }

  if (sortConfig.currentPage > Math.max(maxPages, 1) - 1) {
    setSortConfig({ ...sortConfig, currentPage: 0 });
  }
  if (sortConfig.field && !props.columns.includes(sortConfig.field)) {
    setSortConfig({ ...sortConfig, field: props.defaultSortColumn });
  }

  const sortBy = (field: TableColumn) => {
    if (field === sortConfig.field) {
      setSortConfig({
        ...sortConfig,
        ascending: !sortConfig.ascending,
        currentPage: 0,
      });
    } else {
      setSortConfig({
        ...sortConfig,
        field: field,
        ascending: true,
        currentPage: 0,
      });
    }
  };
  const currentlySorted = (field: string) => {
    let classes = "if ";
    if (field === sortConfig.field) classes += sortConfig.ascending ? "is-descending" : "is-ascending";
    return classes;
  };

  function onRecordClick(record: IInspection, e: React.MouseEvent) {
    appContext.mutate((context) => ({ ...context, lastSection: props.pageBase }));

    if (e.altKey && e.ctrlKey && props.altRoute) {
      if (props.altRoute === "{intake}" && IsRecord(record)) {
        let baseUrl = window.ClientEnvironment.intakeBaseUrl[record.workshopId.substring(0, 2)];
        baseUrl ??= window.ClientEnvironment.intakeBaseUrl["SE"];
        window.location.href = baseUrl + record.shortIntakeUrl.substring(1);
      } else {
        history.push(props.altRoute + record.recordId);
      }
    } else {
      history.push(props.route + record.recordId);
    }
  }

  function pageClick(index: number) {
    setSortConfig({ ...sortConfig, currentPage: index });
  }

  if (sortConfig.field) {
    sortedRecords.sort((a, b) => {
      let left = deepGet(a, sortConfig.field);

      let right = deepGet(b, sortConfig.field);

      let result = 0;
      if (left == null && right == null) {
        //both are null, try to sort by creation date
        if (sortConfig.field === "dateOfIntake") {
          if (left == null) left = deepGet(a, "dateCreated");

          if (right == null) right = deepGet(b, "dateCreated");
        }
        if (left == null && right == null) return 0;
        if (left < right || left == null) result = -1;
        if (left > right || right == null) result = 1;
        return result;
      }
      if (left < right || left == null) result = -1;
      if (left > right || right == null) result = 1;
      return result;
    });
    if (!sortConfig.ascending) {
      sortedRecords.reverse();
    }
  }

  const columns = props.columns;
  const columnNames = {};
  columns.map((c) => (columnNames[c] = formatColumnName(c)));

  if (!props.records || (props.records && props.records.length === 0)) {
    return <div className="if no-items">{props.children}</div>;
  }
  let currentFrom = sortConfig.currentPage * pageSize;
  let currentTo = Math.min(sortConfig.currentPage * pageSize + pageSize, sortedRecords.length);
  let slice = sortedRecords.slice(currentFrom, currentTo);

  return (
    <div className="if table-container box">
      <div className="if table-toolbar">
        {!props.hideFilter && (
          <TableFilter items={workshopFilter} toggle={toggleWorkshop} toggleAll={toggleAllWorkshops} />
        )}
      </div>
      <table className="if table record-table" data-cy="tableView">
        <thead className="if">
          <tr>
            {columns.map((col) => (
              <th className={currentlySorted(col)} onClick={() => sortBy(col)} key={col}>
                {columnNames[col]}
                {col === sortConfig.field && <span className="if sort" />}
              </th>
            ))}
            {props.showActionColumn && <th className="if actions actionHeader" />}
          </tr>
        </thead>
        <tbody className="if">
          {slice.map((record) => (
            <RecordRow
              key={record.recordId}
              record={record}
              columns={columns}
              click={(e) => onRecordClick(record, e)}
              showActions={props.showActionColumn}
              showWarning={props.showWarning}
            />
          ))}
        </tbody>
      </table>
      <TablePagination
        goToPage={pageClick}
        currentPage={sortConfig.currentPage}
        totalPages={maxPages}
        from={currentFrom + 1}
        to={currentTo}
        total={sortedRecords.length}
        totalUnfiltered={sortedRecords.length}
      />
    </div>
  );

  function toggleAllWorkshops(show: boolean) {
    if (props.isGlass) {
      dispatch(Actions.toggleAllGlassWorkshopFilter(show));
    } else {
      dispatch(Actions.toggleAllBodyWorkshopFilter(show));
    }
    setSortConfig({ ...sortConfig, currentPage: 0 });
  }

  function toggleWorkshop(key: string) {
    if (props.isGlass) {
      dispatch(Actions.toggleGlassWorkshopFilter(key));
    } else {
      dispatch(Actions.toggleBodyWorkshopFilter(key));
    }
    setSortConfig({ ...sortConfig, currentPage: 0 });
  }
};

interface IRecordRowProperties {
  columns: IRecordColumns;
  record: IInspection;
  click: React.MouseEventHandler;
  showActions: boolean;
  showWarning: boolean;
}
const RecordRow: React.FC<IRecordRowProperties> = ({ record, columns, click, showActions, showWarning }) => {
  let warnClass = "";
  if (showWarning && record.businessDaysOld > 4) {
    warnClass = " is-warning-phin";
  }
  let intl = useIntl();
  return (
    <tr className={"if record-row" + warnClass} data-cy-row={record.recordId}>
      {record.isLoading ? (
        <td className="if" colSpan={columns.length + 1}>
          <Loader />
        </td>
      ) : (
        <>
          {columns.map((col) => (
            <td className="if mappedColumn" key={record.recordId + col} onClick={click}>
              {warnClass && col === "dateOfIntake" && (
                <div className="icon-offset">
                  <span
                    data-cy="old-warning"
                    className="if icon ui info"
                    title={record["businessDaysOld"].toString() + " " + intl.formatMessage({ id: "table.old" })}
                  />
                </div>
              )}
              <span data-cy="tableDataColumn">{toDisplay(record, col)}</span>
            </td>
          ))}

          {showActions && (
            <td className="if actionColumn">
              <StatusAction record={record} showSendReminder={record.status === Status.Open} />
            </td>
          )}
        </>
      )}
    </tr>
  );
};
export default TableView;
