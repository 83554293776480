//GENERATED AUTOMATICALLY, DO NOT EDIT
//generate-translations.js

const translations = {
  "id": "sv",
  "app.error": "Fel",
  "app.notFound": "Sidan hittades inte",
  "app.redirecting": "Omdirigerar",
  "app.na": "N/A",
  "app.noItemsOnHold": "Det finns inga pausade ärenden",
  "app.noItemsInProgress": "Det finns inga pågående ärenden",
  "app.noItemsInInbox": "Det finns inga nya ärenden",
  "app.noItemsInArchive": "Det finns inga arkiverad ärenden",
  "app.noItemsInOpen": "Det finns inga öppnade ärenden",
  "app.recordNotFound": "Posten hittades inte",
  "app.forbiddenRecord": "Du har inte behörighet att öppna det här ärendet: {recordId}",
  "app.adblock": "Om du upplever problem, vänligen pröva stäng av din annonsblockerare.",
  "app.loggedOut": "Du har blivit utloggad",
  "app.workshopVacation": "Semesterstängning uppdaterad",
  "brand.notbranded": "Annat försäkringsbolag",
  "action.logIn": "Logga in",
  "action.logOut": "Logga ut",
  "action.action": "Hantera",
  "action.close": "Arkivera",
  "action.OnHold": "Pausa",
  "action.moveTo": "Flytta till",
  "action.delete": "Radera",
  "action.delete.success": "Ärendet är raderat",
  "action.save": "Spara",
  "action.clear": "Rensa",
  "table.of": "av",
  "table.old": "dagar gammal",
  "page.inbox": "Inbox",
  "page.inProgress": "Pågående",
  "page.onHold": "Pausade",
  "page.archive": "Arkiv",
  "page.search": "Sök",
  "page.new": "Skicka fotobesiktning",
  "page.open": "Skickade",
  "page.glass": "Volvia Glas",
  "page.details": "Detaljer",
  "page.statusChanged.message": "Ärendet är flyttat till {status}",
  "page.settings": "Semesterinställningar",
  "field.photoSource": "Källa",
  "field.recordId": "ID-nummer",
  "field.claimNumber": "Skadenummer",
  "field.customer.name": "Namn",
  "field.customer.registrationNumber": "Reg.nummer",
  "field.customer.phoneNumber": "Telefonnummer",
  "field.workshopName": "Verkstad",
  "field.workshopId": "Verkstad ID",
  "field.customer.email": "E-post",
  "field.mileage": "Mätarställning (km)",
  "field.description": "Beskrivning",
  "field.status": "Status",
  "field.decision": "Beslut",
  "field.decidedBy": "Beslutet togs av",
  "field.dateDecided": "Beslutet togs den",
  "field.createdBy": "Skickat av",
  "field.dateCreated": "Skickat den",
  "field.glass.dateCreated": "Ärende skapat den",
  "field.dateOfIntake": "Ärende skapat den",
  "field.comments": "Kommentarer",
  "field.intakeLink": "Intake",
  "field.claimDate": "Skadedag",
  "field.city": "Stad",
  "field.availability": "Fotobesiktning",
  "field.summerWorkshopInstructions": "Kommer ni att hålla stängt för semester under sommaren? Då är det viktigt att ni markerar fotobesiktningen som stängd för den aktuella perioden.\r\n\r\nDet är viktigt att ni anger datum en vecka innan ni stänger så att vi minskar risken för att fotobesiktningar blir obehandlade.",
  "field.workshopDecision": "Beslut",
  "link.back": "Tillbaka",
  "link.edit": "Redigera",
  "link.createNewInspection": "Skicka fotobesiktning",
  "newInspection.claimNumber": "Skadenummer (valfritt)",
  "newInspection.customerPhoneNumber": "Kundens telefonnummer",
  "newInspection.sendLinkToCustomer": "Skicka länk till kund",
  "newInspection.sent": "Länken har skickats",
  "newInspection.registrationNumber": "Registreringsnummer",
  "newInspection.workshop": "Verkstad",
  "newInspection.brand": "Försäkring",
  "newInspection.registrationNumber.validationMessage": "Registreringsnumret är inte giltigt",
  "newInspection.claimNumber.validationMessage": "Skadenummer är inte giltigt",
  "newInspection.phone.validationMessage": "Telefonnummer är inte giltigt",
  "newInspection.workshop.validationMessage": "Verkstad kan inte vara tomt",
  "newInspection.brand.validationMessage": "Märke kan inte vara tomt",
  "newInspection.select.workshop": "Välj verkstad",
  "newInspection.select.brand": "Välj försäkring",
  "newInspection.customerName": "Kundens namn",
  "newInspection.customerName.validationMessage": "Kundens namn är inte giltigt",
  "decision.Undecided": "-",
  "decision.Repair": "Reparation",
  "decision.PreInspection": "Förbesiktning",
  "glassDecision.NotAvailable": "-",
  "glassDecision.Repair": "Reparation",
  "glassDecision.Replace": "Byte",
  "status.Open": "Öppet",
  "status.New": "Nytt",
  "status.InProgress": "Pågående",
  "status.OnHold": "Pausat",
  "status.Closed": "Avslutat",
  "status.Deleted": "Raderade",
  "photos.count": "Bild {current} av {total}",
  "photos.save": "Spara bild",
  "photos.saveAll": "Spara alla bilder",
  "photos.saveAllPreparing": "Förbereder",
  "photos.saveAllDownloaded": "Nedladdad",
  "photos.photosProcessing": "Foton behandlas, kom tillbaka senare.",
  "photos.noPhotos": "Väntar på bilder från kund",
  "comment.placeholder": "Skriv din kommentar här",
  "comment.buttonAdd": "Lägg till",
  "comment.tooLong": "Kommentaren är för lång (max 500 tecken)",
  "search.placeHolder": "Sökord",
  "action.sendIntakeLinkReminder": "Skicka SMS-påminnelse",
  "action.sendIntakeLinkReminder.success": "Påminnelse har skickats",
  "action.sendIntakeLinkReminder.hasBeenSent": "Påminnelse har skickats",
  "action.sendToCabas": "Skapa kalkyl (CABAS)",
  "action.sendToCabas.success": "Kalkylen skapas i CABAS",
  "action.sendToCabas.error": "Kalkylen kunde inte skapas",
  "workshop.ui.page.title": "Verkstadsportalen",
  "feedback.title": "Ge oss feedback",
  "feedback.info": "Vi strävar alltid efter att förbättra våra tjänster. Berätta för oss vad du tycker om fotobesiktningen, samt om det finns någonting som skulle kunna göra den ännu bättre och enklare för dig att använda.\\nOm du upplever något systemfel som gör att du inte kan använda tjänsten, kontakta systemansvarig istället för att använda detta formulär.",
  "feedback.input": "Kommentar",
  "feedback.cancel": "Avbryt",
  "feedback.send": "Skicka feedback",
  "feedback.success": "Tack för din feedback!",
  "inactive.user.disabled.text": "Ditt konto har spärrats på grund av inaktivitet, kontakta",
  "inactive.user.contact.email.address.text": "support",
  "intake.ui.page.title": "Skicka skadefoto",
  "intake.ui.page.title.tabHeader": "Fotobesiktning - Skicka skadefoto",
  "intake.LandingStep.header": "Fotobesiktning",
  "intake.LandingStep.madeBy": "En tjänst skapad av",
  "intake.LandingStep.text": "Denna fotobesiktning genomför du enklast vid din bil. Följ instruktionerna för fotografering av skadan och svara på några korta frågor, det tar endast några minuter.",
  "intake.LandingStep.text2": "Vi hanterar dina personuppgifter i enlighet med Dataskyddsförordningen (GDPR), annan tillämplig lagstiftning och myndigheters föreskrifter.",
  "intake.LandingStep.link.text": "Här kan du läsa mer om vår hantering av personuppgifter och dina rättigheter.",
  "intake.LandingStep.consentCheckbox.text": "Jag har läst och godkänner",
  "intake.LandingStep.consent.link.text": "användarvillkoren",
  "intake.LandingStep.privacyPolicy.text": "Läs mer om vår hantering av personuppgifter och dina rättigheter genom att",
  "intake.LandingStep.privacyPolicy.link.text": "klicka här",
  "intake.LandingStep.accessibilityStatement.link.text": "Tillgänglighetsutlåtande",
  "intake.alt.imageCar": "Bil",
  "intake.alt.imageSummary.back": "Foto av bilen taget bakifrån",
  "intake.alt.imageSummary.distance": "Foto av bilen taget på avstånd",
  "intake.alt.imageSummary.closeup": "Foto av den tagna bilen i närbild",
  "intake.alt.imageSummary.diagonal-front": "Foto av bilen taget från en vinkel",
  "intake.alt.imageSummary.diagonal-back": "Foto av bilen taget från en andra vinkel",
  "intake.alt.back": "Tillbaka",
  "intake.alt.imageIllustration.back": "Illustration av en bil från baksikt",
  "intake.alt.imageIllustration.distance": "Illustration av en bil på avstånd",
  "intake.alt.imageIllustration.closeup": "Illustration av en bil på nära håll",
  "intake.alt.imageIllustration.diagonal-front": "Illustration av en bil från en vinkel",
  "intake.alt.imageIllustration.diagonal-back": "Illustration av en bil från en andra vinkel",
  "intake.wizard.buttonStart": "Starta fotobesiktningen",
  "intake.wizard.buttonNext": "Nästa",
  "intake.wizard.buttonSubmit": "Skicka till verkstaden",
  "intake.wizard.buttonBack": "Tillbaka",
  "intake.wizard.sendingInformation": "Din information skickas till verkstaden...",
  "intake.wizard.sendingFile": "Laddar upp bilder: {current} av {total}",
  "intake.wizard.buttonSave": "Spara",
  "intake.ContactInformationStep.header": "Dina uppgifter",
  "intake.ContactInformationStep.text": "Först så behöver vi lite information om dig och din bil.",
  "intake.ContactInformationStep.helpText": "Fält markerade med * är obligatoriska",
  "intake.ContactInformationStep.customer.name.header": "Namn",
  "intake.ContactInformationStep.customer.name.validationMessage": "Namn kan inte vara tomt",
  "intake.ContactInformationStep.customer.phoneNumber.header": "Telefonnummer",
  "intake.ContactInformationStep.customer.phoneNumber.validationMessage": "Telefonnumret är inte giltigt",
  "intake.ContactInformationStep.customer.email.header": "E-post",
  "intake.ContactInformationStep.customer.email.validationMessage": "E-posten är inte giltig",
  "intake.ContactInformationStep.customer.registrationNumber.header": "Registreringsnummer",
  "intake.ContactInformationStep.customer.registrationNumber.validationMessage": "Registreringsnumret är inte giltigt",
  "intake.ContactInformationStep.mileage.header": "Mätarställning (km)",
  "intake.ContactInformationStep.mileage.validationMessage": "Fyll i bilens mätarställning i kilometer",
  "intake.ContactInformationStep.claimDate.header": "Skadedatum",
  "intake.ContactInformationStep.claimDate.validationMessage": "Skadedatumet är inte giltigt",
  "intake.DescriptionStep.header": "Beskrivning av skadan",
  "intake.DescriptionStep.help": "Skriv en kort beskrivning av själva skadan och var på bilen skadan sitter.",
  "intake.DescriptionStep.description.header": "Beskrivning",
  "intake.DescriptionStep.description.validationMessage": "Max 200 tecken ({fieldLength} för många)",
  "intake.DescriptionStep.description.statusMessage": "Max 200 tecken ({descriptionLength} kvar)",
  "intake.DescriptionStep.placeholder": "Kort beskrivning",
  "intake.PhotoStep.header": "Bild",
  "intake.PhotoStep.header.back": "Foto på bilens baksida",
  "intake.PhotoStep.text.back": "Ta ett foto på bilens baksida där bilens registreringsnummer och bilmodellen syns.",
  "intake.PhotoStep.header.distance": "Foto på avstånd",
  "intake.PhotoStep.text.distance": "Ta ett foto från ca 3 meters avstånd. Försök att få med hela sidan av bilen där skadan sitter.",
  "intake.PhotoStep.header.closeup": "Foto som visar närbild på skadan",
  "intake.PhotoStep.text.closeup": "Ta en närbild på skadan rakt framifrån.",
  "intake.PhotoStep.header.diagonal-front": "Foto snett framifrån",
  "intake.PhotoStep.text.diagonal-front": "Ta ett foto på skadan snett framifrån",
  "intake.PhotoStep.header.diagonal-back": "Foto snett bakifrån",
  "intake.PhotoStep.text.diagonal-back": "Ta ett foto på skadan snett bakifrån",
  "intake.PhotoStep.header.extra": "Extra foton (valfritt)",
  "intake.PhotoStep.text.extra": "Här kan du ta upp till 2 extra foton av skadan. Du kan också hoppa över detta och gå direkt till nästa sida.",
  "intake.PhotoStep.add-button": "Ta eller hämta foto",
  "intake.PhotoStep.deleteQuestion": "Radera foto?",
  "intake.PhotoStep.cancelButton": "Avbryt",
  "intake.PhotoStep.deleteButton": "Radera",
  "intake.PhotoStep.validationMessage": "Du måste lägga till en bild för att gå vidare",
  "intake.PhotoStep.validationMessage.tooLargePhoto": "Det bifogade fotot är för stort. Maximal filstorlek är {fileSize}.",
  "intake.PhotoStep.validationMessage.wrongFileType": "Det bifogade fotot har en ogiltig filtyp. Endast PNG eller JPG är tillåtet.",
  "intake.PhotoStep.validationMessage.duplicatePhoto": "Det här fotot är redan använt, vänligen försök igen med ett nytt foto.",
  "intake.SummaryStep.header": "Sammanfattning",
  "intake.SummaryStep.text": "Nästan klar! Här kan du se den information och de foton som kommer att skickas till verkstaden. Önskar du ändra något kan du gå tillbaka genom att använda pilen i vänster hörn och ändra i respektive steg. Ser allt bra ut så avsluta genom att trycka på \"Skicka\" längst ner.",
  "intake.SummaryStep.error": "Något gick fel, vänligen kontrollera din nätverksanslutning.",
  "intake.SummaryStep.photos.header": "Foton",
  "intake.SummaryStep.description.missing": "Ingen beskrivning är tillagd",
  "intake.SummaryStep.information.header": "Dina uppgifter",
  "intake.SummaryStep.customer.name.header": "Namn",
  "intake.SummaryStep.customer.phoneNumber.header": "Telefonnum.",
  "intake.SummaryStep.customer.email.header": "E-post",
  "intake.SummaryStep.customer.registrationNumber.header": "Reg. num.",
  "intake.SummaryStep.mileage.header": "Miltal",
  "intake.SummaryStep.claimDate.header": "Skadedatum",
  "intake.ConfirmationStep.header": "Tack!",
  "intake.ConfirmationStep.textIf": "Fotobesiktningen är genomförd och dina foton har skickats till verkstaden. {workShopName} kommer att kontakta dig för att boka nästa steg.",
  "intake.ConfirmationStep.textWhiteLabel": "Fotobesiktningen är genomförd och dina foton har skickats till verkstaden. {workShopName} kommer att kontakta dig inom {slaDays} arbetsdagar för att boka nästa steg.",
  "intake.ConfirmationStep.feedbackHeader": "Vad tyckte du om fotobesiktningen?",
  "intake.ConfirmationStep.feedbackText.header": "Kommentar (frivillig)",
  "intake.ConfirmationStep.feedbackText.placeholder": "Skriv en kommentar om du vill berätta för oss hur du upplevde fotobesiktningen",
  "intake.ConfirmationStep.feedbackButton": "Skicka",
  "intake.ConfirmationStep.feedbackConfirmation": "Tack för din feedback!",
  "intake.invalid.header": "Oops, förlåt!",
  "intake.invalid.expiredOrDone": "Den här länken är inte längre giltig",
  "intake.invalid.error": "Något gick fel, vänligen försök igen.",
  "intake.invalid.text": "Vi hittar inte sidan du försöker nå. Det är möjligt att vi har flyttat sidan eller tagit bort den.",
  "intake.VolviaLandingStep.header": "Fotobesiktning",
  "intake.VolviaLandingStep.text": "Fotobesiktningen genomför du enklast vid din bil. Följ instruktionerna för fotografering av skadan och svara på några korta frågor. Det tar bara några minuter.",
  "intake.VolviaLandingStep.link.text": "Läs om vår hantering av personuppgifter.",
  "intake.InstructionStep.header": "Så här går det till",
  "intake.InstructionStep.text": "Vi kommer först att be dig fylla i dina kontaktuppgifter, information om bilen samt om själva skadan.",
  "intake.InstructionStep.text2": "Därefter kommer du att få ta eller ladda upp 5 olika bilder på skadan  för att hjälpa verkstaden med sin bedömning. Bilderna de önskar är en på bilens baksida, en på avstånd där hela skadan är synlig, samt tre närbilder på skadan från olika håll. Se nedan för exempel.",
  "confirm.delete.title": "Radera ärende",
  "confirm.delete.text": "Detta är en länk som är skickad till kund av er eller av handläggare hos If. Raderar du ärendet kan kunden inte längre skicka in foton till er verkstad.",
  "confirm.delete.cancel": "Avbryt",
  "confirm.delete.yes": "Radera",
  "curityMigrationBannerText": "Vi har gjort en teknisk uppdatering av Photo Inspection appen. För att logga in, vänligen ändra ditt lösenord via e-postmeddelandet som har skickats till dig. Därefter kan du logga in som vanligt.",
  "section.bodyInspections": "Karosseri",
  "section.glassInspections": "Glas",
  "section.admin": "Admin panel",
  "glass.customerPhotos": "Kundfoton",
  "glass.inbox": "Inbox, glas",
  "glass.workshopPhotos": "Verkstadsfoton",
  "photoSource.Customer": "Kundfoton",
  "photoSource.Workshop": "Verkstadsfoton"
};
export default translations;