import "./editNotifications.scss";
import React, { useState } from "react";
import { reactPlugin, withAITracking } from "../../infrastructure/applicationInsights";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCurrentNotifications } from "../../utility";
import * as Actions from "../actions";
import { IInfoNotification, WorkshopCountryCode } from "../../backend-models";
import dayjs from "dayjs";
import { useAuth } from "auth/authContext";

const Notification: React.FC<{
  notification: IInfoNotification;
  save?(notification: IInfoNotification);
}> = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(props.notification);
  const nullify = (e) => (e.target.value === "" ? null : e.target.value);

  if (!data) return null;

  let isNew = !data.id;

  function deleteNotification() {
    dispatch(Actions.deleteNotification(props.notification));
    setData(null);
  }

  if (isNew)
    return (
      <div className="notification new">
        <div>
          <span className="if icon symbol globe" />
          <select
            className="if"
            value={data.country == null ? "" : data.country}
            onChange={(e) => {
              let val = e.target.value;
              setData({ ...data, country: val ? Number(val) : null });
            }}
          >
            <option value="">All</option>
            {[0, 1, 2, 3].map((country) => (
              <option key={country} value={country as WorkshopCountryCode}>
                {WorkshopCountryCode[country]}
              </option>
            ))}
          </select>
        </div>
        <div>
          <span className="if icon symbol wrench-tool" />
          {data.id || "new"}
        </div>
        <div>
          <span className="if icon ui clock" />
          From
        </div>
        <div className="date">
          <div className="if input-wrapper">
            <input
              className="if"
              type="text"
              value={data.start || ""}
              onChange={(e) => setData({ ...data, start: nullify(e) })}
            />
          </div>
          <div>{data.start ? dayjs(data.start).format("LLL") : "Right away"}</div>
        </div>
        <div>
          <span className="if icon ui clock" />
          To
        </div>
        <div className="date">
          <div className="if input-wrapper">
            <input
              className="if"
              type="text"
              value={data.end || ""}
              onChange={(e) => setData({ ...data, end: nullify(e) })}
            />
          </div>
          <div>{data.end ? dayjs(data.end).format("LLL") : "Forever"}</div>
        </div>
        <div>
          <span className="if icon ui edit" />
          Message
        </div>
        <div className="if input-wrapper">
          <textarea
            className="if"
            onChange={(e) => setData({ ...data, message: nullify(e) })}
            value={data.message || ""}
          />
        </div>
        <div />
        <div>
          <button
            className="if button primary"
            onClick={() => {
              props.save(data);
            }}
          >
            Add
          </button>
        </div>
      </div>
    );
  return (
    <div className="notification">
      <div>
        <span className="if icon symbol globe" />
        {data.country === null ? "All" : WorkshopCountryCode[data.country]}
      </div>
      <div>
        <span className="if icon symbol wrench-tool" />
        {data.id || "new"}
        <button
          className="if button tertiary"
          onClick={() => {
            deleteNotification();
          }}
        >
          <span className="if icon ui trashcan blue" />
          Delete
        </button>
      </div>
      <div>
        <span className="if icon ui clock" />
        From
      </div>
      <div>{data.start ? dayjs(data.start).format("LLL") : "Right away"}</div>
      <div>
        <span className="if icon ui clock" />
        To
      </div>
      <div>{data.end ? dayjs(data.end).format("LLL") : "Forever"}</div>
      <div>
        <span className="if icon ui edit" />
        Message
      </div>
      <div className="if input-wrapper">{data.message || ""}</div>
    </div>
  );
};
const EditNotifications: React.FC = () => {
  const history = useHistory();
  const { isAdministrator } = useAuth();
  if (!isAdministrator) history.push("/");

  const dispatch = useDispatch();
  let [notifications, setNotifications] = useState(getCurrentNotifications());
  const getNewNotification = () =>
    (({
      start: dayjs().toISOString().substr(0, 13) + ":00:00Z",
      _id: Math.random(),
    } as any) as IInfoNotification);

  let [newNotification, setNewNotification] = useState(getNewNotification());

  let reset = () => {
    setNewNotification(getNewNotification());
    setNotifications(getCurrentNotifications());
  };

  function save(notification: IInfoNotification) {
    dispatch(Actions.addNotification({ notification, reset }));
  }

  return (
    <div className="padded-block edit-notifications">
      <h1>Notifications</h1>
      {notifications.map((n, i) => (
        <Notification notification={n} key={i} />
      ))}

      <pre className="info">
        Date and time format is YYYY-MM-DDTHH:mm:ssZ i.e. {dayjs().format("YYYY-MM-DDTHH:mm:ss") + "Z"}
        <br />
        <b>Time is in GMT+0 timezone</b>
      </pre>
      <Notification notification={newNotification} key={(newNotification as any)._id} save={save} />
    </div>
  );
};

export default withAITracking(reactPlugin, EditNotifications);
