import * as Actions from "actions";
import reducer from "./reducer";
import { IGlassInspections } from "models";
import { recordError, recordLoading, updateRecord } from "./records";
import { IGlassInspection } from "backend-models";
import * as AdminActions from "admin/actions";
import { getGlassWorkshopFilter, toggleGlassWorkshopFilter, toggleAllGlassWorkshopFilters } from "workshopFilter";

export default reducer<IGlassInspections>(
  { isLoading: false, glassInspections: [], loadError: undefined, workshopFilter: {} } as IGlassInspections,
  (on) => {
    on(Actions.fetchGlassInspections, (_) => {
      return {
        ..._,
        isLoading: true,
      };
    });

    on(AdminActions.fetchAllRecords, (_) => {
      return { ..._, isLoading: true };
    });

    on(Actions.fetchGlassInspectionsSuccess, (_, action) => {
      const newData = {
        ..._,
        glassInspections: action.payload.inspections,
        isLoading: false,
        loadError: undefined,
        everLoaded: true,
        lastLoaded: new Date(),
        workshopFilter: _.workshopFilter,
      } as IGlassInspections;

      console.debug(
        "fetch glass, oldest inspection: " + action.payload.inspections.map((x) => x.dateCreated).sort()[0]
      );
      for (const oldGlassInspection of _.glassInspections) {
        // If previous version of data had an inspection
        // that new data doesn't have, add it to new data
        //
        // Happens when you open an inspection by link
        // that is not loaded with fetchGlassInspections
        // for ex if it's old
        if (newData.glassInspections.findIndex((r) => r.recordId === oldGlassInspection.recordId) === -1) {
          newData.glassInspections.push(oldGlassInspection);
        }
      }

      newData.workshopFilter = getGlassWorkshopFilter(action.payload.workshops);
      return newData;
    });

    on(Actions.fetchGlassInspectionsError, (_, action) => {
      return {
        ..._,
        isLoading: false,
        loadError: `Failed to load glass inspections: ${action.payload.message}`,
      };
    });

    on(Actions.fetchGlassInspection, (_, action) => {
      return recordLoading(_, action.payload);
    });

    on(Actions.fetchGlassInspectionSuccess, (_, action) => {
      return updateRecord({ ..._, exists: true }, { ...action.payload, isStatusLoading: false, isLoading: false });
    });

    on(Actions.changeGlassInspectionStatus, (_, action) => {
      return updateRecord({ ..._, exists: true }, {
        recordId: action.payload.inspectionId,
        isStatusLoading: true,
      } as IGlassInspection);
    });
    on(Actions.updateGlassInspectionSuccess, (_, action) => {
      return updateRecord({ ..._, exists: true }, { ...action.payload, isStatusLoading: false, isLoading: false });
    });

    on(Actions.fetchGlassInspectionError, (_, action) => {
      return { ...recordError(_, action.payload, "Failed to load glass inspection"), exists: false };
    });

    on(Actions.updateGlassInspectionError, (_, action) => {
      return { ...recordError(_, action.payload, "Failed to update glass inspection"), exists: false };
    });

    on(Actions.addGlassComment, (_, action) => {
      return updateRecord({ ..._, exists: true }, {
        recordId: action.payload.recordId,
        isStatusLoading: true,
      } as IGlassInspection);
    });
    on(Actions.addGlassCommentSuccess, (_, action) => {
      return updateRecord({ ..._, exists: true }, { ...action.payload, isStatusLoading: false, isLoading: false });
    });
    on(Actions.addGlassCommentError, (_, action) => {
      return { ...recordError(_, action.payload, "Failed to add comment"), exists: false };
    });

    // filter reducers
    on(Actions.fetchUserInfoSuccess, (_, { payload }) => {
      return { ..._, workshopFilter: getGlassWorkshopFilter(payload.glassWorkshops) };
    });
    on(Actions.toggleGlassWorkshopFilter, (_, { payload: id }) => {
      return { ..._, workshopFilter: toggleGlassWorkshopFilter(id, _.workshopFilter) };
    });
    on(Actions.toggleAllGlassWorkshopFilter, (_, { payload: show }) => {
      return { ..._, workshopFilter: toggleAllGlassWorkshopFilters(show, _.workshopFilter) };
    });
  }
);
