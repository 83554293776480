import * as React from "react";
import { withAITracking, reactPlugin } from "../infrastructure/applicationInsights";
import { useSelector, useDispatch } from "react-redux";
import { AppState, ISearchResults, IRecordColumns, IRecords } from "../models";
import TableView from "./shared/tableView";
import Loadable from "./shared/loadable";
import { fetchRecords, searchRecords } from "../actions";
import { FormattedMessage, useIntl } from "react-intl";
import ViewHeader from "./shared/viewHeader";
import { dispatchIfNeeded } from "../utility";
import { useEffect, useState } from "react";
import { Brand, Status, WorkshopDecision } from "../backend-models";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SearchBackend: React.FC = () => {
  const columns: IRecordColumns = [
    "customer.name",
    "customer.registrationNumber",
    "customer.phoneNumber",
    "workshopName",
    "dateOfIntake",
    "decision",
    "status",
  ];

  const results: ISearchResults = useSelector((state: AppState) => state.searchResults);
  const dispatch = useDispatch();

  const dispatchSearchRecord = (e) => {
    let searchText = e.target.value;
    if (searchText) {
      dispatch(searchRecords(searchText));
    }
  };
  let intl = useIntl();
  return (
    <div className="padded-block">
      <ViewHeader>
        <FormattedMessage id="page.search" />
      </ViewHeader>
      <div className="if input-wrapper">
        <label className="if help" htmlFor="search-text">
          {/*<FormattedMessage id="search.label"/>*/}
        </label>
        <input
          placeholder={intl.formatMessage({ id: "search.placeHolder" })}
          id="search-text"
          name="search-text"
          type="search"
          className="if input-field"
          onChange={dispatchSearchRecord}
        />
      </div>

      <Loadable target={results}>
        <TableView
          showActionColumn={true}
          columns={columns}
          records={results.searchResults}
          route="/details/"
          pageBase="/search"
        />
      </Loadable>
    </div>
  );
};

const SearchClient: React.FC = () => {
  const dispatch = useDispatch();

  const columns: IRecordColumns = [
    "customer.name",
    "customer.registrationNumber",
    "customer.phoneNumber",
    "workshopName",
    "dateOfIntake",
    "decision",
    "status",
  ];
  const [state, setState] = useState({
    searchText: "",
  });
  const records: IRecords = useSelector((state: AppState) => state.records);

  useEffect(() => {
    dispatchIfNeeded(dispatch, fetchRecords, records);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let intl = useIntl();

  let filtered = records?.records ?? [];

  let terms = state.searchText.toLowerCase().split(" ");
  filtered = filtered.filter((r) => {
    return terms.every((term) => {
      function matches(val) {
        return (val ?? "").toLowerCase().indexOf(term) !== -1;
      }
      return [
        r.workshopCity,
        r.workshopName,
        r.workshopId,
        r.recordId,
        r.customer.name,
        r.customer.email,
        r.customer.phoneNumber,
        r.customer.registrationNumber,
        Status[r.status],
        WorkshopDecision[r.decision],
        r.claimNumber,
        Brand[r.brand],
        r.description,
        r.externalId,
      ].some(matches);
    });
  });

  return (
    <div className="padded-block">
      <ViewHeader>
        <FormattedMessage id="page.search" />
      </ViewHeader>
      <div className="if input-wrapper">
        <input
          placeholder={intl.formatMessage({ id: "search.placeHolder" })}
          id="search-text"
          type="search"
          value={state.searchText}
          className="if input-field"
          onChange={(e) => setState({ ...state, searchText: e.target.value })}
        />
      </div>

      <Loadable target={records}>
        <TableView
          showActionColumn={true}
          columns={columns}
          records={filtered}
          route="/details/"
          pageBase="/search"
        />
      </Loadable>
    </div>
  );
};

export default withAITracking(reactPlugin, SearchClient);
