import { SagaIterator } from "redux-saga";
import { Action } from "redux-actions";
import { delay, put, takeEvery } from "redux-saga/effects";
import * as Actions from "../actions";
import { INotification, NotificationType } from "../models";

function* showNotification(action: Action<INotification>) {
  let notification = action.payload;
  yield put(Actions.addNotification(notification));
  if (notification.type === NotificationType.Success) {
    yield delay(5000);
    yield put(Actions.closeNotification(notification));
  }
}

function* closeNotification(action: Action<INotification>) {
  let notification = action.payload;
  yield put(Actions.fadeNotification(notification));
  yield delay(450);
  yield put(Actions.removeNotification(notification));
}

export default function* notificationSaga(): SagaIterator {
  yield takeEvery(Actions.showNotification, showNotification);
  yield takeEvery(Actions.closeNotification, closeNotification);
}
