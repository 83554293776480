import * as Actions from "../actions";
import reducer from "./reducer";
import { IWorkshops } from "../models";

export default reducer<IWorkshops>(
  { workshops: [], isLoading: false, loadError: undefined },
  (on) => {
    on(Actions.fetchWorkshops, (_, action) => {
      return { ..._, isLoading: true, loadError: undefined };
    });
    on(Actions.fetchWorkshopsSuccess, (_, action) => {
      return {
        workshops: action.payload,
        isLoading: false,
        loadError: undefined,
      };
    });

    on(Actions.fetchWorkshopsError, (_, action) => {
      return { ..._, isLoading: false, loadError: action.payload.message };
    });
  }
);
