import React from "react";
import preval from "preval.macro";
import dayjs from "dayjs";

const buildTimestamp = preval`module.exports = new Date().getTime();`;

export const Version: React.FC = () => {
  //"20241028.1"
  //replaced in the pipeline with yyyyMMdd.r
  let v = window.ClientEnvironment.version;
  if (!v || v.startsWith("__")) {
    v = "built " + dayjs(buildTimestamp).format("YYYY-MM-DD HH:mm");
  }
  return <span className="version">{v}</span>;
};
