import React from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { disableCustomerService } from "actions";
import { DateString } from "backend-models";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./dateRangePicker.scss";
import { FormattedMessage } from "react-intl";

type DateRangePickerProps = {
  workshopId: string;
  isInputDisabled: boolean;
  startDate?: DateString;
  endDate?: DateString;
};

export function DateRangePicker(props: DateRangePickerProps) {
  const [dateRange, setDateRange] = React.useState([
    props.startDate && new Date(props.startDate),
    props.endDate && new Date(props.endDate),
  ]);
  const [startDate, endDate] = dateRange;

  const dispatch = useDispatch();

  const datePickerRef = useRef<DatePicker>(null);

  const currentDate = new Date();
  const startOfJune = new Date(currentDate.getFullYear(), 5, 1);
  const endOfAugust = new Date(currentDate.getFullYear(), 7, 31);

  function handleSave() {
    dispatch(disableCustomerService({ id: props.workshopId, startDate, endDate }));
    datePickerRef.current?.setOpen(false);
  }

  let oneDateFieldIsEmpty = !startDate !== !endDate;

  return (
    <DatePicker
      ref={datePickerRef}
      monthsShown={2}
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update: Date[]) => {
        setDateRange(update);
      }}
      shouldCloseOnSelect={false}
      placeholderText="Click to select date"
      withPortal={true}
      className="input-style"
      fixedHeight={true}
      calendarStartDay={1}
      minDate={startOfJune}
      maxDate={endOfAugust}
      disabled={props.isInputDisabled}
      dateFormat="dd/MM/yyyy"
    >
      <div style={{ width: "max-content", marginLeft: "270px" }}>
        <button className="if button secondary" onClick={() => setDateRange([null, null])}>
          <FormattedMessage id="action.clear" />
        </button>
        <button
          className="if button primary"
          disabled={oneDateFieldIsEmpty}
          onClick={handleSave}
        >
          <FormattedMessage id="action.save" />
        </button>
      </div>
    </DatePicker>
  );
}
