//GENERATED AUTOMATICALLY, DO NOT EDIT
//generate-translations.js

const translations = {
  "id": "fi",
  "app.error": "Virhe",
  "app.notFound": "Sivua ei löydetty",
  "app.redirecting": "Uudelleenohjataan",
  "app.na": "N/A",
  "app.noItemsOnHold": "Ei pysäyttettyjä tapahtumia",
  "app.noItemsInProgress": "Ei käynnissä olevia tapahtumia",
  "app.noItemsInInbox": "Ei uusia tapahtumia",
  "app.noItemsInArchive": "Ei arkistoituja tapahtumia",
  "app.noItemsInOpen": "Ei avoimia tapahtumia",
  "app.recordNotFound": "Tapausta ei löytynyt",
  "app.forbiddenRecord": "Sinulla ei ole oikeuksia avata tätä tapausta: {recordId}",
  "app.adblock": "Jos kohtaat ongelmia sivuston kanssa, laitathan selaimen mainostenesto-ohjelman pois päältä.",
  "app.loggedOut": "Olet kirjautunut ulos",
  "app.workshopVacation": "Lomamuutokset tallennettu",
  "brand.notbranded": "Muu vakuutusyhtiö",
  "action.logIn": "Kirjaudu sisään",
  "action.logOut": "Kirjaudu ulos",
  "action.action": "Hallinta",
  "action.close": "Arkistoi",
  "action.OnHold": "Odottaa lisätietoa",
  "action.moveTo": "Siirrä",
  "action.delete": "Poista",
  "action.delete.success": "Tarkastus poistettu",
  "action.save": "Tallenna",
  "action.clear": "Tyhjennä",
  "table.of": "/",
  "table.old": "päivää vanha",
  "page.inbox": "Saapuneet",
  "page.inProgress": "Meneillään",
  "page.onHold": "Pysäytetyt",
  "page.archive": "Arkisto",
  "page.search": "Etsi",
  "page.new": "Lähetä kuvatarkastus",
  "page.open": "Avoimet",
  "page.details": "Yksityiskohdat",
  "page.statusChanged.message": "Tapaus on siirretty {status}",
  "page.settings": "Loma-asetukset",
  "field.photoSource": "Lähde",
  "field.recordId": "ID numero",
  "field.claimNumber": "Vahinkotunnus",
  "field.customer.name": "Nimi",
  "field.customer.registrationNumber": "Rekisteritunnus",
  "field.customer.phoneNumber": "Puhelinnumero",
  "field.workshopName": "Korjaamo",
  "field.workshopId": "Korjaamo ID",
  "field.customer.email": "Sähköposti",
  "field.mileage": "Kilometrilukema",
  "field.description": "Kuvaus",
  "field.status": "Tila",
  "field.decision": "Päätös",
  "field.decidedBy": "Päätöksen tehnyt",
  "field.dateDecided": "Päätöspvm",
  "field.createdBy": "Lähettäjä",
  "field.dateCreated": "Lähetys pvm",
  "field.glass.dateCreated": "Luotu pvm",
  "field.dateOfIntake": "Luotu pvm",
  "field.comments": "Kommentit",
  "field.intakeLink": "Intake",
  "field.claimDate": "Vahinkopäivä",
  "field.city": "Kaupunki",
  "field.availability": "Kuvatarkastus",
  "field.summerWorkshopInstructions": "Mikäli aiotte sulkea korjaamon kesän aikana, on tärkeää, että merkitsette myös valokuvatarkastuksen suljetuksi samalle ajanjaksolle.\r\n\r\nPyydämme merkitsemään toimipisteen suljetuksi viikkoa ennen varsinaista loma-aikaa, jotta asiakkaat eivät saa kutsua valokuvatarkastukseen sillä aikaa kun olette kiinni.",
  "field.workshopDecision": "Päätös",
  "link.back": "Takaisin",
  "link.edit": "Muokkaa",
  "link.createNewInspection": "Lähetä kuvatarkastus",
  "newInspection.claimNumber": "Vahinkotunnus (valinnainen)",
  "newInspection.customerPhoneNumber": "Asiakkaan puhelin numero",
  "newInspection.sendLinkToCustomer": "Lähetä linkki asiakkaalle",
  "newInspection.sent": "Linkki on lähetetty",
  "newInspection.registrationNumber": "Rekisteritunnus",
  "newInspection.workshop": "Korjaamo",
  "newInspection.brand": "Vakuutus",
  "newInspection.registrationNumber.validationMessage": "Rekisterinumero ei kelpaa",
  "newInspection.claimNumber.validationMessage": "Vahinkotunnus ei kelpaa",
  "newInspection.phone.validationMessage": "Puhelinnumero ei kelpaa",
  "newInspection.workshop.validationMessage": "Korjaamoa ei ole valittu",
  "newInspection.brand.validationMessage": "Brändiä ei ole valittu",
  "newInspection.select.workshop": "Valitse korjaamo",
  "newInspection.select.brand": "Valitse vakuutus",
  "newInspection.customerName": "Asiakkaan nimi",
  "newInspection.customerName.validationMessage": "Asiakkaan nimi ei kelpaa",
  "decision.Undecided": "-",
  "decision.Repair": "Valokuvatarkastus",
  "decision.PreInspection": "Tarkastus korjaamolla",
  "glassDecision.NotAvailable": "-",
  "glassDecision.Repair": "Korjaus",
  "glassDecision.Replace": "Vaihda",
  "status.Open": "Avoin",
  "status.New": "Uusi",
  "status.InProgress": "Meneillään",
  "status.OnHold": "Pysäytetty",
  "status.Closed": "Suljettu",
  "status.Deleted": "Poistettu",
  "photos.count": "Kuva {current} / {total}",
  "photos.save": "Tallenna kuva",
  "photos.saveAll": "Tallenna kaikki kuvat",
  "photos.saveAllPreparing": "Valmistellaan",
  "photos.saveAllDownloaded": "Ladattu",
  "photos.photosProcessing": "Kuvia käsitellään, palaathan takaisin myöhemmin.",
  "photos.noPhotos": "Kuvia ei ole vielä lähetetty",
  "comment.placeholder": "Kirjoita kommentti tähän",
  "comment.buttonAdd": "Lisää",
  "comment.tooLong": "Kommentin teksti on liian pitkä (enintään 500 merkkiä)",
  "search.placeHolder": "Etsi",
  "action.sendIntakeLinkReminder": "Lähetä SMS muistutus",
  "action.sendIntakeLinkReminder.success": "Muistutus lähetetty",
  "action.sendIntakeLinkReminder.hasBeenSent": "Muistutus lähetetty",
  "workshop.ui.page.title": "Korjaamosivusto",
  "feedback.title": "Lähetä palautetta",
  "feedback.info": "Pyrimme aina parantamaan palvelujamme ja arvostamme mielipidettäsi Valokuvatarkastuksesta sekä ehdotuksista sen edelleen kehottämisestä.\\nMikäli huomioit teknisen virheen älä täytä lomaketta vaan ota yhteyttä järjestelmästä vastaavaan henkilöön.",
  "feedback.input": "Kommentti",
  "feedback.cancel": "Keskeytä",
  "feedback.send": "Lähetä palautetta",
  "feedback.success": "Kiitos palautteestasi!",
  "inactive.user.disabled.text": "Tunnuksesi on poistettu käytöstä vähäisen käytön vuoksi. Ota yhteyttä",
  "inactive.user.contact.email.address.text": "tukeen",
  "intake.ui.page.title": "Lähetä vahinkokuva",
  "intake.ui.page.title.tabHeader": "Kuvatarkastus - Lähetä vahinkokuva",
  "intake.LandingStep.header": "Valokuvatarkastus",
  "intake.LandingStep.madeBy": "Palvelun tarjoaa",
  "intake.LandingStep.text": "Lähetä kuvat vaurioista korjaamolle sen sijaan, että ajaisit korjaamolle ennakkotarkastukseen. Se kestää vain muutaman minuutin. Korjaamo on sinuun yhteydessä sopiakseen jatkosta.",
  "intake.LandingStep.text2": "Käsittelemme asiakkaidemme henkilötietoja tietosuoja-asetusten sekä muun tietosuoja- ja vakuutuslainsäädännön mukaisesti.",
  "intake.LandingStep.link.text": "Täältä voit lukea lisää henkilätietojemme käsittelystä.",
  "intake.LandingStep.consentCheckbox.text": "Olen lukenut ja hyväksyn",
  "intake.LandingStep.consent.link.text": "Sopimusehdot",
  "intake.LandingStep.privacyPolicy.text": "Voit lukea lisää henkilätietojemme käsittelystä",
  "intake.LandingStep.privacyPolicy.link.text": "täältä",
  "intake.LandingStep.accessibilityStatement.link.text": "Saavutettavuusseloste",
  "intake.alt.imageCar": "Auto",
  "intake.alt.imageSummary.back": "Kuva autostasi takaa katsottuna",
  "intake.alt.imageSummary.distance": "Kaukaa otettu valokuva autosta",
  "intake.alt.imageSummary.closeup": "Kuva autosta, joka on otettu lähikuvana",
  "intake.alt.imageSummary.diagonal-front": "Kuva autosta otettuna kulmasta",
  "intake.alt.imageSummary.diagonal-back": "Kuva autosta toisesta kulmasta otettuna",
  "intake.alt.back": "Takaisin",
  "intake.alt.imageIllustration.back": "Kuva autosta taustanäkymästä",
  "intake.alt.imageIllustration.distance": "Kuva autosta kaukaa",
  "intake.alt.imageIllustration.closeup": "Esimerkki auton lähikuvasta",
  "intake.alt.imageIllustration.diagonal-front": "Kuva autosta kulmasta",
  "intake.alt.imageIllustration.diagonal-back": "Kuva autosta toisesta kulmasta",
  "intake.wizard.buttonStart": "Aloita valokuvatarkastus",
  "intake.wizard.buttonNext": "Seuraava",
  "intake.wizard.buttonSubmit": "Lähetä korjaamolle",
  "intake.wizard.buttonBack": "Takaisin",
  "intake.wizard.sendingInformation": "Tiedot lähetetään korjaamolle.",
  "intake.wizard.sendingFile": "Ladataan kuvia: {current}/{total}",
  "intake.wizard.buttonSave": "Tallenna",
  "intake.ContactInformationStep.header": "Sinun tietosi",
  "intake.ContactInformationStep.text": "Tarvitsemme tietoja, jotta voimme ottaa sinuun yhteyttä ja varata ajanvarauksen",
  "intake.ContactInformationStep.helpText": "* Merkityt kentät ovat pakollisia",
  "intake.ContactInformationStep.customer.name.header": "Nimi",
  "intake.ContactInformationStep.customer.name.validationMessage": "Nimi kenttä ei voi olla tyhjä",
  "intake.ContactInformationStep.customer.phoneNumber.header": "Puhelinnumero",
  "intake.ContactInformationStep.customer.phoneNumber.validationMessage": "Puhelinnumero ei kelpaa",
  "intake.ContactInformationStep.customer.email.header": "Sähköpostiosoite",
  "intake.ContactInformationStep.customer.email.validationMessage": "Sähköpostiosoite ei kelpaa",
  "intake.ContactInformationStep.customer.registrationNumber.header": "Rekisterinumero",
  "intake.ContactInformationStep.customer.registrationNumber.validationMessage": "Rekisterinumero ei kelpaa",
  "intake.ContactInformationStep.mileage.header": "Kilometrilukema",
  "intake.ContactInformationStep.mileage.validationMessage": "Kilometrilukema ei kelpaa",
  "intake.ContactInformationStep.claimDate.header": "Vahinkopäivä",
  "intake.ContactInformationStep.claimDate.validationMessage": "Vahinkopäivä ei kelpaa",
  "intake.DescriptionStep.header": "Vahingon kuvaus",
  "intake.DescriptionStep.help": "Lisää lyhyt kuvaus vaurioista ja sen sijainnista",
  "intake.DescriptionStep.description.header": "Kuvaus",
  "intake.DescriptionStep.description.validationMessage": "Max 200 merkkiä ({fieldLength} liikaa merkkejä)",
  "intake.DescriptionStep.description.statusMessage": "Max 200 merkkiä ({descriptionLength} jäljellä)",
  "intake.DescriptionStep.placeholder": "Esimerkki: Vahinkona kaksi pientä kolhua, jotka sijaitsevat kuljettajan puolella lähellä etupyörän takaosaa ja matkustajan ovea",
  "intake.PhotoStep.header": "Kuvien lataus",
  "intake.PhotoStep.header.back": "Auton takapää",
  "intake.PhotoStep.text.back": "Ota valokuva tai lataa kuva auton takaosasta niin, että rekisterinumero näkyy kuvassa.",
  "intake.PhotoStep.header.distance": "Yleiskuva vaurioista",
  "intake.PhotoStep.text.distance": "Ota kuva vaurioista noin 3m etäisyydeltä.",
  "intake.PhotoStep.header.closeup": "Lähikuva vaurioista",
  "intake.PhotoStep.text.closeup": "Ota lähikuva vaurioista suorassa kulmassa.",
  "intake.PhotoStep.header.diagonal-front": "Lähikuva sivulta",
  "intake.PhotoStep.text.diagonal-front": "Ota lähikuva vaurioista viistosta kulmasta.",
  "intake.PhotoStep.header.diagonal-back": "Lähikuva toisesta suunnasta",
  "intake.PhotoStep.text.diagonal-back": "Ota lähikuva vaurioista vastakkaisesta viistosta kulmasta.",
  "intake.PhotoStep.header.extra": "Lisäkuvat (valinnainen)",
  "intake.PhotoStep.text.extra": "Ota 2 lisäkuvaa vaurioista. Voit myös ohittaa tämän vaiheen ja siirtyä suoraan seuraavaan vaiheeseen.",
  "intake.PhotoStep.add-button": "Ota tai hae kuva",
  "intake.PhotoStep.deleteQuestion": "Posta kuva?",
  "intake.PhotoStep.cancelButton": "Keskeytä",
  "intake.PhotoStep.deleteButton": "Poista",
  "intake.PhotoStep.validationMessage": "Sinun täytyy ladata kuva, jotta pääset eteenpäin.",
  "intake.PhotoStep.validationMessage.tooLargePhoto": "Liittämäsi kuva on liian suuri. Tiedoston enimmäiskoko on {fileSize}.",
  "intake.PhotoStep.validationMessage.wrongFileType": "Liittämässäsi kuvassa on väärä tiedostotyyppi. Vain PNG tai JPG on sallittu.",
  "intake.PhotoStep.validationMessage.duplicatePhoto": "Tämä kuva on jo käytössä. Käytäthän aina uutta kuvaa.",
  "intake.SummaryStep.header": "Yhteenveto",
  "intake.SummaryStep.text": "Melkein valmis! Tarkasta valokuvat ja korjaamolle lähetettävät tiedot.",
  "intake.SummaryStep.error": "Tapahtui virhe, tarkasta verkkoyhteys.",
  "intake.SummaryStep.photos.header": "Valokuvat",
  "intake.SummaryStep.description.missing": "Kuvausta ei ole asetettu",
  "intake.SummaryStep.information.header": "Sinun tietosi",
  "intake.SummaryStep.customer.name.header": "Nimi",
  "intake.SummaryStep.customer.phoneNumber.header": "Puh. num.",
  "intake.SummaryStep.customer.email.header": "Sähköp.",
  "intake.SummaryStep.customer.registrationNumber.header": "Rek. num.",
  "intake.SummaryStep.mileage.header": "Kilometril.",
  "intake.SummaryStep.claimDate.header": "Vahinkopäivä",
  "intake.ConfirmationStep.header": "Kiitos!",
  "intake.ConfirmationStep.textIf": "Valokuvasi on lähetetty korjaamolle {workShopName}. He ovat sinuun yhteydessä seuraavaksi.",
  "intake.ConfirmationStep.textWhiteLabel": "Kuvasi on lähetetty korjaamolle. {workShopName} on sinuun yhteydessä kolmen arkipäivän kuluessa varatakseen korjausajan.",
  "intake.ConfirmationStep.feedbackHeader": "Millainen mielikuva sinulle jäi valokuvien perusteella tehdystä vahinkotarkastuksesta?",
  "intake.ConfirmationStep.feedbackText.header": "Vapaa kommentti (ei pakollinen)",
  "intake.ConfirmationStep.feedbackText.placeholder": "Lisää kommentti mikäli haluat jakaa kokemuksesi valokuvien perusteella tehdystä vahinkotarkastuksesta",
  "intake.ConfirmationStep.feedbackButton": "Lähetä",
  "intake.ConfirmationStep.feedbackConfirmation": "Kiitos palautteestasi!",
  "intake.invalid.header": "Oops..Sivua ei löydy",
  "intake.invalid.expiredOrDone": "Tämä linkki ei ole enää voimassa",
  "intake.invalid.error": "Jotain meni pieleen, yritä uudelleen.",
  "intake.invalid.text": "Valitettavasti sivua johon yritit päästä, ei ole olemassa. Se on joka siirretty tai käyttämäsi URL on väärä.",
  "intake.VolviaLandingStep.header": "Valokuvatarkastus",
  "intake.VolviaLandingStep.text": "Säästä rahaa ja aikaa. Ota kuvat vahingoista saadaksesi digitaalisen selvityksen ja varauksen korjaamolle kolmessa päivän sisällä.",
  "intake.VolviaLandingStep.link.text": "Henkilötietojen käsittely",
  "intake.InstructionStep.header": "This is how it works",
  "intake.InstructionStep.text": "We will first ask you to enter your contact details, some information about your car and also about the damage.",
  "intake.InstructionStep.text2": "Then you will be asked to take or upload 5 photos of the damage to help the workshop with the assessment. The photos you need are one on the car from behind, one where the whole damage is visible and three close ups of the damage from different angles. See examples below.",
  "confirm.delete.title": "Poista tehtävä",
  "confirm.delete.text": "Tämän linkin asiakkaalle on lähettänyt joko sinä tai asiakasneuvoja Ifistä. Jos poistat tämän tehtävän asiakas ei pysty enää lähettämään kuvia korjaamollesi.",
  "confirm.delete.cancel": "Peruuta",
  "confirm.delete.yes": "Poista",
  "curityMigrationBannerText": "Olemme tehneet teknisen päivityksen Photo Inspection -sovellukseen. Kirjaudu sisään vaihtamalla salasanasi sinulle lähetetyn sähköpostin kautta. Sen jälkeen voit kirjautua sisään normaalisti.",
  "section.bodyInspections": "Korikorjaus",
  "section.glassInspections": "Lasi",
  "section.admin": "Admin panel",
  "glass.customerPhotos": "Asiakkaan valokuvat",
  "glass.inbox": "Saapuneet",
  "glass.workshopPhotos": "Korjaamon valokuvat",
  "photoSource.Customer": "Asiakkaan valokuvat",
  "photoSource.Workshop": "Korjaamon valokuvat"
};
export default translations;