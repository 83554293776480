import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { reactPlugin, withAITracking } from "infrastructure/applicationInsights";
import { IGlassInspectionWithWorkshop } from "models";
import { Brand } from "backend-models";

import PhotoViewer from "../shared/photoViewer";
import { GlassInfo } from "../shared/recordInfo";

const _GlassDetailsWorkshop: React.FC<{ inspection: IGlassInspectionWithWorkshop }> = (props) => {
  const history = useHistory();
  const record = props.inspection;
  return (
    <div className="if padded-block darkBeige detail-view">
      <div className="detail-row">
        <div className="detail-column info-detail-column">
          <section>
            <button className="if back-to-link button tertiary" onClick={history.goBack}>
              <FormattedMessage id="link.back" />
            </button>

            <div className="header-container">
              <div className="header-heading-container">
                <h1 className="if heading">{record.customer?.registrationNumber}</h1>
                <div className="glass-icon if icon coverage windshield-damage white" />
              </div>
              {![Brand.NotBranded, Brand.BMW].includes(record.brand) && (
                <img
                  className="header-brand-logo"
                  src={`/img/${Brand[record.brand]}/logo.svg`}
                  alt={Brand[record.brand]}
                />
              )}
            </div>
          </section>
          <GlassInfo glassInspection={record} key={record.recordId} />
        </div>
        <div className="detail-column photo-detail-column">
          <PhotoViewer record={record} />
        </div>
      </div>
    </div>
  );
};

export const GlassDetailsWorkshop = withAITracking(reactPlugin, _GlassDetailsWorkshop);
