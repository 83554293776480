import * as Actions from "actions";
import reducer from "./reducer";
import { IRecords, ISearchResults } from "models";
import { updateRecord } from "./records";

export default reducer<ISearchResults>({ isLoading: false, searchResults: [], loadError: undefined }, (on) => {
  on(Actions.searchRecords, (_) => {
    return { ..._, isLoading: true };
  });

  on(Actions.searchRecordsSuccess, (_, action) => {
    return {
      searchResults: action.payload.inspections,
      isLoading: false,
      loadError: undefined,
    };
  });

  on(Actions.searchRecordsError, (_, action) => {
    return {
      searchResults: [],
      isLoading: false,
      loadError: `Failed to search records: ${action.payload.message}`,
    };
  });

  on(Actions.updateRecordSuccess, (_, action) => {
    let records = updateRecord({ records: _.searchResults } as IRecords, action.payload);
    return { ..._, searchResults: records.records };
  });
});
