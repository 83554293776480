import * as React from "react";
import "./loader.css";

export const LoaderIcon = (props) => {
  const { size = "" } = props;
  return (
    <svg
      className={"if loader dots horizontal " + size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
    >
      <g className="if svg g">
        <circle className="if svg circle loader" cx="8" cy="32" r="8" />
        <circle className="if svg circle loader" cx="32" cy="32" r="8" />
        <circle className="if svg circle loader" cx="56" cy="32" r="8" />
      </g>
    </svg>
  );
};

const Loader = () => {
  return (
    <div className="loaderContainer">
      <LoaderIcon />
    </div>
  );
};

export default Loader;
