import * as Actions from "../../actions";
import reducer, { IActionReducer } from "../reducer";
import { IRecords } from "../../models";
import { recordError, recordLoading, updateRecord } from "./index";
import { IAddCommentRequest, IRecord } from "../../backend-models";

export const updateRecordReducer: IActionReducer<IRecords, IAddCommentRequest | IRecord> = (_, action) => {
  return recordLoading(_, action.payload.recordId);
};

export const updateRecordSuccessReducer: IActionReducer<IRecords, IRecord> = (_, action) => {
  return updateRecord({ ..._, exists: true }, { ...action.payload, isStatusLoading: false, isLoading: false });
};
export default reducer<IRecords>(
  { isLoading: false, records: [], loadError: undefined, workshopFilter: {} },
  (on) => {
    on(Actions.updateRecord, updateRecordReducer);
    on(Actions.changeRecordStatus, (_, action) => {
      return updateRecord({ ..._, exists: true }, {
        recordId: action.payload.recordId,
        isStatusLoading: true,
      } as IRecord);
    });
    on(Actions.recordSendToCabas, (_, action) => {
      return updateRecord({ ..._, exists: true }, {
        recordId: action.payload.recordId,
        isStatusLoading: true,
      } as IRecord);
    });
    on(Actions.recordSendToCabasSuccess, (_, action) => {
      return updateRecord({ ..._, exists: true }, {
        recordId: action.payload.recordId,
        isStatusLoading: false,
      } as IRecord);
    });
    on(Actions.recordSendToCabasError, (_, action) => {
      return updateRecord({ ..._, exists: true }, {
        recordId: action.payload.recordId,
        isStatusLoading: false,
      } as IRecord);
    });
    on(Actions.updateRecordSuccess, updateRecordSuccessReducer);
    on(Actions.updateRecordError, (_, action) => {
      return recordError(_, action.payload, "Failed to update record");
    });
  }
);
