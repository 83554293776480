import React, { useContext } from "react";
import "./sideNavigationItem.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Translation } from "../../utility";
import { AppContext } from "../../models";

interface IProps {
  name: string;
  text: Translation;
  icon: string;
  messageCount?: string | number;
  admin?: boolean;
}

function getBaseLocation(pathname: string): string {
  if (pathname.split("/").length - 1 > 1) {
    return pathname.substring(0, pathname.lastIndexOf("/"));
  } else {
    return pathname;
  }
}

const SideNavigationItem: React.FC<IProps> = ({ name, text, icon, messageCount, admin }) => {
  const location = useLocation();
  const history = useHistory();
  const appContext = useContext(AppContext);

  let baseLocation = getBaseLocation(location.pathname);

  if (["/details", "/glass"].includes(baseLocation)) {
    baseLocation = appContext.lastSection;
  }

  const isSelected = baseLocation === name;

  return (
    <Link
      to={name}
      className={classNames("sidenav-item-container", { selected: isSelected, admin })}
      onClick={(e) => {
        history.push(name);
        e.preventDefault();
      }}
      data-cy={"sidenav-" + name.substring(1)}
    >
      <div className="icon-text">
        <div className={`${icon} icon ${baseLocation === name ? "white" : ""}`} />
        <div className="text">{text}</div>
      </div>
      <span className="counter">{messageCount}</span>
    </Link>
  );
};

export default SideNavigationItem;
