import "./languageSelector.scss";
import React from "react";
import { aiEvent } from "../../infrastructure/applicationInsights";
import { mapToLocale } from "../../utility";
import { useAuth } from "auth/authContext";

export const LanguageSelector: React.FC<{
  locale: string;
  setLocale(value: string);
  defaultLocale: any;
  intake?: boolean;
}> = (props) => {
  const { isAdministrator } = useAuth();
  let availableLocales = [];

  //"own"/se language at the top
  availableLocales.push(mapToLocale(props.defaultLocale));

  const add = (locale: string) => {
    if (!availableLocales.includes(locale)) availableLocales.push(locale);
  };
  add("en");
  add("sv");
  add("nb");
  add("fi");
  add("da");
  if (!props.intake && isAdministrator) add("id");

  return (
    <div className="if language-selector" data-cy="languageSelector">
      <select
        className="if dropdown-select"
        required
        value={props.locale}
        onChange={(e) => {
          aiEvent("Application: Set language to " + e.target.value);
          props.setLocale(e.target.value);
        }}
      >
        {availableLocales.map((locale) => {
          return (
            <option key={locale} value={locale}>
              {locale.toUpperCase()}
            </option>
          );
        })}
      </select>
    </div>
  );
};
