//GENERATED AUTOMATICALLY, DO NOT EDIT
//generate-translations.js

const translations = {
  "id": "id",
  "app.error": "app.error",
  "app.notFound": "app.notFound",
  "app.redirecting": "app.redirecting",
  "app.na": "app.na",
  "app.noItemsOnHold": "app.noItemsOnHold",
  "app.noItemsInProgress": "app.noItemsInProgress",
  "app.noItemsInInbox": "app.noItemsInInbox",
  "app.noItemsInArchive": "app.noItemsInArchive",
  "app.noItemsInOpen": "app.noItemsInOpen",
  "app.recordNotFound": "app.recordNotFound",
  "app.forbiddenRecord": "app.forbiddenRecord",
  "app.adblock": "app.adblock",
  "app.loggedOut": "app.loggedOut",
  "app.workshopVacation": "app.workshopVacation",
  "brand.notbranded": "brand.notbranded",
  "action.logIn": "action.logIn",
  "action.logOut": "action.logOut",
  "action.action": "action.action",
  "action.close": "action.close",
  "action.OnHold": "action.OnHold",
  "action.moveTo": "action.moveTo",
  "action.delete": "action.delete",
  "action.delete.success": "action.delete.success",
  "action.save": "action.save",
  "action.clear": "action.clear",
  "table.of": "table.of",
  "table.old": "table.old",
  "page.inbox": "page.inbox",
  "page.inProgress": "page.inProgress",
  "page.onHold": "page.onHold",
  "page.archive": "page.archive",
  "page.search": "page.search",
  "page.new": "page.new",
  "page.open": "page.open",
  "page.glass": "page.glass",
  "page.details": "page.details",
  "page.statusChanged.message": "page.statusChanged.message",
  "page.decisionChanged.message": "page.decisionChanged.message",
  "page.settings": "page.settings",
  "page.test": "page.test",
  "page.stats": "page.stats",
  "field.photoSource": "field.photoSource",
  "field.recordId": "field.recordId",
  "field.claimNumber": "field.claimNumber",
  "field.customer.name": "field.customer.name",
  "field.customer.registrationNumber": "field.customer.registrationNumber",
  "field.customer.phoneNumber": "field.customer.phoneNumber",
  "field.workshopName": "field.workshopName",
  "field.workshopId": "field.workshopId",
  "field.customer.email": "field.customer.email",
  "field.mileage": "field.mileage",
  "field.description": "field.description",
  "field.status": "field.status",
  "field.decision": "field.decision",
  "field.decidedBy": "field.decidedBy",
  "field.dateDecided": "field.dateDecided",
  "field.createdBy": "field.createdBy",
  "field.dateCreated": "field.dateCreated",
  "field.glass.dateCreated": "field.glass.dateCreated",
  "field.dateOfIntake": "field.dateOfIntake",
  "field.comments": "field.comments",
  "field.intakeLink": "field.intakeLink",
  "field.claimDate": "field.claimDate",
  "field.city": "field.city",
  "field.availability": "field.availability",
  "field.summerWorkshopInstructions": "field.summerWorkshopInstructions",
  "field.workshopDecision": "field.workshopDecision",
  "link.back": "link.back",
  "link.edit": "link.edit",
  "link.createNewInspection": "link.createNewInspection",
  "newInspection.claimNumber": "newInspection.claimNumber",
  "newInspection.customerPhoneNumber": "newInspection.customerPhoneNumber",
  "newInspection.sendLinkToCustomer": "newInspection.sendLinkToCustomer",
  "newInspection.sent": "newInspection.sent",
  "newInspection.registrationNumber": "newInspection.registrationNumber",
  "newInspection.workshop": "newInspection.workshop",
  "newInspection.brand": "newInspection.brand",
  "newInspection.registrationNumber.validationMessage": "newInspection.registrationNumber.validationMessage",
  "newInspection.claimNumber.validationMessage": "newInspection.claimNumber.validationMessage",
  "newInspection.phone.validationMessage": "newInspection.phone.validationMessage",
  "newInspection.workshop.validationMessage": "newInspection.workshop.validationMessage",
  "newInspection.brand.validationMessage": "newInspection.brand.validationMessage",
  "newInspection.select.workshop": "newInspection.select.workshop",
  "newInspection.select.brand": "newInspection.select.brand",
  "newInspection.customerName": "newInspection.customerName",
  "newInspection.customerName.validationMessage": "newInspection.customerName.validationMessage",
  "decision.Undecided": "decision.Undecided",
  "decision.Repair": "decision.Repair",
  "decision.PreInspection": "decision.PreInspection",
  "glassDecision.NotAvailable": "glassDecision.NotAvailable",
  "glassDecision.Repair": "glassDecision.Repair",
  "glassDecision.Replace": "glassDecision.Replace",
  "status.Open": "status.Open",
  "status.New": "status.New",
  "status.InProgress": "status.InProgress",
  "status.OnHold": "status.OnHold",
  "status.Closed": "status.Closed",
  "status.Deleted": "status.Deleted",
  "photos.count": "photos.count",
  "photos.save": "photos.save",
  "photos.saveAll": "photos.saveAll",
  "photos.saveAllPreparing": "photos.saveAllPreparing",
  "photos.saveAllDownloaded": "photos.saveAllDownloaded",
  "photos.photosProcessing": "photos.photosProcessing",
  "photos.noPhotos": "photos.noPhotos",
  "comment.placeholder": "comment.placeholder",
  "comment.buttonAdd": "comment.buttonAdd",
  "comment.tooLong": "comment.tooLong",
  "search.placeHolder": "search.placeHolder",
  "action.sendIntakeLinkReminder": "action.sendIntakeLinkReminder",
  "action.sendIntakeLinkReminder.success": "action.sendIntakeLinkReminder.success",
  "action.sendIntakeLinkReminder.hasBeenSent": "action.sendIntakeLinkReminder.hasBeenSent",
  "action.sendToCabas": "action.sendToCabas",
  "action.sendToCabas.success": "action.sendToCabas.success",
  "action.sendToCabas.error": "action.sendToCabas.error",
  "workshop.ui.page.title": "workshop.ui.page.title",
  "feedback.title": "feedback.title",
  "feedback.info": "feedback.info",
  "feedback.input": "feedback.input",
  "feedback.cancel": "feedback.cancel",
  "feedback.send": "feedback.send",
  "feedback.success": "feedback.success",
  "inactive.user.disabled.text": "inactive.user.disabled.text",
  "inactive.user.contact.email.address.text": "inactive.user.contact.email.address.text",
  "intake.ui.page.title": "intake.ui.page.title",
  "intake.ui.page.title.tabHeader": "intake.ui.page.title.tabHeader",
  "intake.LandingStep.header": "intake.LandingStep.header",
  "intake.LandingStep.madeBy": "intake.LandingStep.madeBy",
  "intake.LandingStep.text": "intake.LandingStep.text",
  "intake.LandingStep.text2": "intake.LandingStep.text2",
  "intake.LandingStep.link.text": "intake.LandingStep.link.text",
  "intake.LandingStep.consentCheckbox.text": "intake.LandingStep.consentCheckbox.text",
  "intake.LandingStep.consent.link.text": "intake.LandingStep.consent.link.text",
  "intake.LandingStep.privacyPolicy.text": "intake.LandingStep.privacyPolicy.text",
  "intake.LandingStep.privacyPolicy.link.text": "intake.LandingStep.privacyPolicy.link.text",
  "intake.LandingStep.accessibilityStatement.link.text": "intake.LandingStep.accessibilityStatement.link.text",
  "intake.alt.imageCar": "intake.alt.imageCar",
  "intake.alt.imageSummary.back": "intake.alt.imageSummary.back",
  "intake.alt.imageSummary.distance": "intake.alt.imageSummary.distance",
  "intake.alt.imageSummary.closeup": "intake.alt.imageSummary.closeup",
  "intake.alt.imageSummary.diagonal-front": "intake.alt.imageSummary.diagonal-front",
  "intake.alt.imageSummary.diagonal-back": "intake.alt.imageSummary.diagonal-back",
  "intake.alt.back": "intake.alt.back",
  "intake.alt.imageIllustration.back": "intake.alt.imageIllustration.back",
  "intake.alt.imageIllustration.distance": "intake.alt.imageIllustration.distance",
  "intake.alt.imageIllustration.closeup": "intake.alt.imageIllustration.closeup",
  "intake.alt.imageIllustration.diagonal-front": "intake.alt.imageIllustration.diagonal-front",
  "intake.alt.imageIllustration.diagonal-back": "intake.alt.imageIllustration.diagonal-back",
  "intake.wizard.buttonStart": "intake.wizard.buttonStart",
  "intake.wizard.buttonNext": "intake.wizard.buttonNext",
  "intake.wizard.buttonSubmit": "intake.wizard.buttonSubmit",
  "intake.wizard.buttonBack": "intake.wizard.buttonBack",
  "intake.wizard.sendingInformation": "intake.wizard.sendingInformation",
  "intake.wizard.sendingFile": "intake.wizard.sendingFile",
  "intake.wizard.buttonSave": "intake.wizard.buttonSave",
  "intake.ContactInformationStep.header": "intake.ContactInformationStep.header",
  "intake.ContactInformationStep.text": "intake.ContactInformationStep.text",
  "intake.ContactInformationStep.helpText": "intake.ContactInformationStep.helpText",
  "intake.ContactInformationStep.customer.name.header": "intake.ContactInformationStep.customer.name.header",
  "intake.ContactInformationStep.customer.name.validationMessage": "intake.ContactInformationStep.customer.name.validationMessage",
  "intake.ContactInformationStep.customer.phoneNumber.header": "intake.ContactInformationStep.customer.phoneNumber.header",
  "intake.ContactInformationStep.customer.phoneNumber.validationMessage": "intake.ContactInformationStep.customer.phoneNumber.validationMessage",
  "intake.ContactInformationStep.customer.email.header": "intake.ContactInformationStep.customer.email.header",
  "intake.ContactInformationStep.customer.email.validationMessage": "intake.ContactInformationStep.customer.email.validationMessage",
  "intake.ContactInformationStep.customer.registrationNumber.header": "intake.ContactInformationStep.customer.registrationNumber.header",
  "intake.ContactInformationStep.customer.registrationNumber.validationMessage": "intake.ContactInformationStep.customer.registrationNumber.validationMessage",
  "intake.ContactInformationStep.mileage.header": "intake.ContactInformationStep.mileage.header",
  "intake.ContactInformationStep.mileage.validationMessage": "intake.ContactInformationStep.mileage.validationMessage",
  "intake.ContactInformationStep.claimDate.header": "intake.ContactInformationStep.claimDate.header",
  "intake.ContactInformationStep.claimDate.validationMessage": "intake.ContactInformationStep.claimDate.validationMessage",
  "intake.DescriptionStep.header": "intake.DescriptionStep.header",
  "intake.DescriptionStep.help": "intake.DescriptionStep.help",
  "intake.DescriptionStep.description.header": "intake.DescriptionStep.description.header",
  "intake.DescriptionStep.description.validationMessage": "intake.DescriptionStep.description.validationMessage",
  "intake.DescriptionStep.description.statusMessage": "intake.DescriptionStep.description.statusMessage",
  "intake.DescriptionStep.placeholder": "intake.DescriptionStep.placeholder",
  "intake.PhotoStep.header": "intake.PhotoStep.header",
  "intake.PhotoStep.header.back": "intake.PhotoStep.header.back",
  "intake.PhotoStep.text.back": "intake.PhotoStep.text.back",
  "intake.PhotoStep.header.distance": "intake.PhotoStep.header.distance",
  "intake.PhotoStep.text.distance": "intake.PhotoStep.text.distance",
  "intake.PhotoStep.header.closeup": "intake.PhotoStep.header.closeup",
  "intake.PhotoStep.text.closeup": "intake.PhotoStep.text.closeup",
  "intake.PhotoStep.header.diagonal-front": "intake.PhotoStep.header.diagonal-front",
  "intake.PhotoStep.text.diagonal-front": "intake.PhotoStep.text.diagonal-front",
  "intake.PhotoStep.header.diagonal-back": "intake.PhotoStep.header.diagonal-back",
  "intake.PhotoStep.text.diagonal-back": "intake.PhotoStep.text.diagonal-back",
  "intake.PhotoStep.header.extra": "intake.PhotoStep.header.extra",
  "intake.PhotoStep.text.extra": "intake.PhotoStep.text.extra",
  "intake.PhotoStep.add-button": "intake.PhotoStep.add-button",
  "intake.PhotoStep.deleteQuestion": "intake.PhotoStep.deleteQuestion",
  "intake.PhotoStep.cancelButton": "intake.PhotoStep.cancelButton",
  "intake.PhotoStep.deleteButton": "intake.PhotoStep.deleteButton",
  "intake.PhotoStep.validationMessage": "intake.PhotoStep.validationMessage",
  "intake.PhotoStep.validationMessage.tooLargePhoto": "intake.PhotoStep.validationMessage.tooLargePhoto",
  "intake.PhotoStep.validationMessage.wrongFileType": "intake.PhotoStep.validationMessage.wrongFileType",
  "intake.PhotoStep.validationMessage.duplicatePhoto": "intake.PhotoStep.validationMessage.duplicatePhoto",
  "intake.SummaryStep.header": "intake.SummaryStep.header",
  "intake.SummaryStep.text": "intake.SummaryStep.text",
  "intake.SummaryStep.error": "intake.SummaryStep.error",
  "intake.SummaryStep.photos.header": "intake.SummaryStep.photos.header",
  "intake.SummaryStep.description.missing": "intake.SummaryStep.description.missing",
  "intake.SummaryStep.information.header": "intake.SummaryStep.information.header",
  "intake.SummaryStep.customer.name.header": "intake.SummaryStep.customer.name.header",
  "intake.SummaryStep.customer.phoneNumber.header": "intake.SummaryStep.customer.phoneNumber.header",
  "intake.SummaryStep.customer.email.header": "intake.SummaryStep.customer.email.header",
  "intake.SummaryStep.customer.registrationNumber.header": "intake.SummaryStep.customer.registrationNumber.header",
  "intake.SummaryStep.mileage.header": "intake.SummaryStep.mileage.header",
  "intake.SummaryStep.claimDate.header": "intake.SummaryStep.claimDate.header",
  "intake.ConfirmationStep.header": "intake.ConfirmationStep.header",
  "intake.ConfirmationStep.textIf": "intake.ConfirmationStep.textIf",
  "intake.ConfirmationStep.textWhiteLabel": "intake.ConfirmationStep.textWhiteLabel",
  "intake.ConfirmationStep.feedbackHeader": "intake.ConfirmationStep.feedbackHeader",
  "intake.ConfirmationStep.feedbackText.header": "intake.ConfirmationStep.feedbackText.header",
  "intake.ConfirmationStep.feedbackText.placeholder": "intake.ConfirmationStep.feedbackText.placeholder",
  "intake.ConfirmationStep.feedbackButton": "intake.ConfirmationStep.feedbackButton",
  "intake.ConfirmationStep.feedbackConfirmation": "intake.ConfirmationStep.feedbackConfirmation",
  "intake.invalid.header": "intake.invalid.header",
  "intake.invalid.expiredOrDone": "intake.invalid.expiredOrDone",
  "intake.invalid.error": "intake.invalid.error",
  "intake.invalid.text": "intake.invalid.text",
  "intake.VolviaLandingStep.header": "intake.VolviaLandingStep.header",
  "intake.VolviaLandingStep.text": "intake.VolviaLandingStep.text",
  "intake.VolviaLandingStep.link.text": "intake.VolviaLandingStep.link.text",
  "intake.InstructionStep.header": "intake.InstructionStep.header",
  "intake.InstructionStep.text": "intake.InstructionStep.text",
  "intake.InstructionStep.text2": "intake.InstructionStep.text2",
  "confirm.delete.title": "confirm.delete.title",
  "confirm.delete.text": "confirm.delete.text",
  "confirm.delete.cancel": "confirm.delete.cancel",
  "confirm.delete.yes": "confirm.delete.yes",
  "curityMigrationBannerText": "curityMigrationBannerText",
  "section.bodyInspections": "section.bodyInspections",
  "section.glassInspections": "section.glassInspections",
  "section.admin": "section.admin",
  "glass.customerPhotos": "glass.customerPhotos",
  "glass.inbox": "glass.inbox",
  "glass.workshopPhotos": "glass.workshopPhotos",
  "photoSource.Customer": "photoSource.Customer",
  "photoSource.Workshop": "photoSource.Workshop"
};
export default translations;