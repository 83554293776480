import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { withAITracking, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { IConfiguration, ICustomProperties } from "@microsoft/applicationinsights-core-js";
import * as Common from "@microsoft/applicationinsights-common";
import { checkIE, ComponentWithPath } from "../utility";
import { ComponentType } from "react";
import { IClientEnvironment } from "../backend-models";

enum loggingLevelTelemetry {
  off = 0, //default, no app insights errors are logged
  critical = 1,
  everything = 2,
}
const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const env = window.ClientEnvironment ?? ({} as IClientEnvironment);

let testingMode = false;
if (env.iKey === "cypress") {
  testingMode = true;
}

let config: IConfiguration & Common.IConfig = {
  loggingLevelConsole: loggingLevelTelemetry.off,
  instrumentationKey: env.iKey,
  distributedTracingMode: Common.DistributedTracingModes.W3C,
  disableFetchTracking: false,
  maxBatchInterval: 5000,
  extensions: [reactPlugin],
  extensionConfig: {
    [reactPlugin.identifier]: { history: browserHistory },
  },
};

// when developing we send telemetry without delay,
// and log all app insight errors to the console
if (env.isDevelopment) {
  config = {
    ...config,
    loggingLevelConsole: loggingLevelTelemetry.everything,
    enableDebug: true,
    maxBatchInterval: 0,
  };
}

let isIE11 = checkIE();

// when running automated tests or on <= IE11 we don't send anything,
// and ignore all errors
if (testingMode || isIE11 || window.ClientEnvironment.isDevelopment) {
  config = {
    ...config,
    loggingLevelConsole: loggingLevelTelemetry.off,
    disableExceptionTracking: true,
    disableTelemetry: true,
    disableFetchTracking: true,
    disableAjaxTracking: true,
  };
}

console.debug("ai config", config);

const appInsights = new ApplicationInsights({ config });

if (env.iKey) appInsights.loadAppInsights();
else {
  console.error("No ApplicationInsights key defined");
}

const ignoreAdminEvents = true;

function aiEvent(name: string, props?: ICustomProperties) {
  if (testingMode) return;
  if (window.ClientEnvironment.isDevelopment) return;
  if (ignoreAdminEvents && (window.ClientEnvironment as any).isAdmin === true) {
    console.log("(ignored) AI EVENT:", name);
  } else {
    console.log("AI EVENT:", name);
    appInsights.trackEvent({ name }, props);
  }
}

function withPathAndAITracking<T extends ComponentType>(Path: string, Component: T) {
  const result = withAITracking(reactPlugin, Component) as ComponentWithPath;
  result.Path = Path;
  return result;
}

export { withPathAndAITracking, withAITracking, reactPlugin, appInsights, aiEvent };
