import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "../actions";
import { NotificationType, processInspectionResponse } from "../models";
import { showError } from "./common";
import { Backend } from "../backend";
import { IChangeStatusRequest, Status } from "../backend-models";
import { FormattedMessage } from "react-intl";
import React from "react";
import { formatDecision, formatStatus, Translation } from "../utility";

const addStatusChangeComment = false;
const notifyAboutDecisionChange = false;

function* changeRecordStatus(action: Action<IChangeStatusRequest>) {
  try {
    const result = yield* Backend.Record.ChangeStatus(action.payload);

    function* writeMessage(message: Translation) {
      if (addStatusChangeComment) {
        yield* Backend.Comment.Add({
          recordId: action.payload.recordId,
          comment: message as any,
        });
      }
      yield put(
        Actions.showNotification({
          message,
          time: new Date(),
          type: NotificationType.Success,
        })
      );
    }

    if (action.payload.status !== null && action.payload.status !== undefined) {
      yield* writeMessage(
        <FormattedMessage
          id={
            action.payload.status !== Status.Deleted ? "page.statusChanged.message" : "action.delete.success"
          }
          values={{ status: formatStatus(action.payload.status) }}
        />
      );
    }
    if (
      notifyAboutDecisionChange &&
      action.payload.decision !== null &&
      action.payload.decision !== undefined
    ) {
      yield* writeMessage(
        <FormattedMessage
          id="page.decisionChanged.message"
          values={{ decision: formatDecision(action.payload.decision) }}
        />
      );
    }

    yield put(Actions.updateRecordSuccess(processInspectionResponse(result)));
  } catch (err) {
    yield showError(err);
    yield put(Actions.updateRecordError({ ...err, recordId: action.payload.recordId }));
  }
}

export default function* changeRecordStatusSaga(): SagaIterator {
  yield takeLatest(Actions.changeRecordStatus, changeRecordStatus);
}
