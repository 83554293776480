// noinspection UnnecessaryLocalVariableJS

import dayjs from "dayjs";
import React, { ComponentType } from "react";
import { FormattedMessage, IntlShape } from "react-intl";
import { Dispatch } from "redux";
import { ActionFunctionAny } from "redux-actions";
import {
  DateString,
  IGlassInspections,
  IGlassInspectionWithWorkshop,
  IInspectionStateType,
  IRecords,
  ISortConfig,
  IStoredSortConfigs,
  TableColumn,
} from "models";
import {
  GlassDecision,
  IWorkshop,
  PhotoSource,
  Brand,
  Status,
  WorkshopCountryCode,
  WorkshopDecision,
} from "backend-models";
import enCountryLabels from "react-phone-number-input/locale/en.json";
import svCountryLabels from "react-phone-number-input/locale/sv.json";
import fiCountryLabels from "react-phone-number-input/locale/fi.json";
import nbCountryLabels from "react-phone-number-input/locale/nb.json";

export function deepGet(object, property, nullPropagate = true) {
  if (nullPropagate) return property.split(".").reduce((a, b) => (a == null ? null : a[b]), object);
  else return property.split(".").reduce((a, b) => a[b], object);
}

export function deepSet(object, property: string, value) {
  //foo, bar, baz
  let props = property.split(".");
  //baz
  let name = props.pop();
  let parent = props.reduce((a, b) => a[b], object);
  //bar[baz] = value
  parent[name] = value;
  return object;
}

// not supported yet by react-scripts
// export type DeepProps<T> = T extends string ? [] : {
//   [K in Extract<keyof T, string>]: [K, ...DeepProps<T[K]>]
// }[Extract<keyof T, string>];
//
// export type Join<T extends string[]> =
//   T extends [] ? never :
//   T extends [infer F] ? F :
//   T extends [infer F, ...infer R] ?
//   F extends string ? string extends F ? string :
//   `${F}.${Join<Extract<R, string[]>>}` : never : string;

export type ComponentWithPath = ComponentType & { Path: string };

export type Translation = JSX.Element | string;

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function getTranslation(id: string, intl?: IntlShape): Translation {
  if (intl) return intl.formatMessage({ id });
  else return <FormattedMessage id={id} />;
}

export function formatDateTime(dt: DateString, intl?: IntlShape): Translation {
  if (dt) {
    return dayjs(dt).format("L LT");
  }

  return getTranslation("app.na", intl);
}

export function formatDate(dt: DateString, intl?: IntlShape): Translation {
  if (dt) {
    return dayjs(dt).format("L");
  }

  return getTranslation("app.na", intl);
}

export function formatStatus(status: Status, intl?: IntlShape): Translation {
  if (status === undefined) return "";

  return getTranslation("status." + Status[status], intl);
}

export function formatPhotoSource(photoSource: PhotoSource, intl?: IntlShape): Translation {
  if (photoSource === undefined) return "";
  if (photoSource === PhotoSource.Unknown) photoSource = PhotoSource.Workshop;

  return getTranslation("photoSource." + PhotoSource[photoSource], intl);
}
export function formatWorkshopName(record: { workshopName: string; workshopCity: string }) {
  if (!record.workshopName) return "";

  let city = record.workshopCity ?? "";
  let workshopName = record.workshopName;

  //don't add city, if it's in the name
  if (!city || workshopName.toLowerCase().indexOf(city.toLowerCase()) >= 0 || workshopName.endsWith(" TEST"))
    return workshopName;

  //capitalize if a single word
  if (city.indexOf(" ") === -1) {
    city = city.substring(0, 1).toUpperCase() + city.substring(1).toLowerCase();
  }

  return `${workshopName} (${city})`;
}

export function formatDecision(decision: WorkshopDecision, intl?: IntlShape): Translation {
  if (decision === undefined) return "";

  return getTranslation("decision." + WorkshopDecision[decision], intl);
}

export function formatGlassDecision(decision: GlassDecision, intl?: IntlShape): Translation {
  if (decision === undefined) return "";

  return getTranslation("glassDecision." + GlassDecision[decision], intl);
}

export function formatColumnName(column: TableColumn, intl?: IntlShape): Translation {
  return getTranslation("field." + column, intl);
}

export function formatBrandName(brand: Brand, intl?: IntlShape): Translation {
  // noinspection SpellCheckingInspection
  let name =
    {
      [Brand.Mercedes]: "Mercedes-Benz",
      [Brand.Skoda]: "ŠKODA",
    }[brand] ?? Brand[brand]?.toLowerCase();
  if (name) {
    let id = "brand." + name;
    if (intl && id in intl.messages) {
      return getTranslation(id, intl);
    } else {
      return capitalizeFirstLetter(name);
    }
  }
  return "";
}

export function daysToMilliseconds(days: number) {
  let hours = days * 24,
    minutes = hours * 60,
    seconds = minutes * 60,
    ms = seconds * 1000;
  return ms;
}

export function filterStatus(records: IRecords, ...statuses: Status[]) {
  let result = records.records.filter((r) => statuses.includes(r.status));

  if (statuses.length === 1 && statuses[0] === Status.Open) {
    result = result.filter((x) => x.isValidForIntake && !x.isBluePilot);
  }

  return result;
}

export function filterStatusGlass(inspections: IGlassInspections, ...statuses: Status[]) {
  let result = inspections.glassInspections.filter((r) => statuses.includes(r.status));
  return result;
}

export function filterStatusWithWorkshopFilter(records: IRecords, ...statuses: Status[]) {
  return filterStatus(records, ...statuses).filter((r) => {
    let filter = records.workshopFilter[r.workshopId];
    return filter ? filter.show : true;
  });
}

export function filterStatusWithWorkshopFilterGlass(inspections: IGlassInspections, ...statuses: Status[]) {
  return filterStatusGlass(inspections, ...statuses).filter((r) => {
    let filter = inspections.workshopFilter[r.workshopId];
    return filter ? filter.show : true;
  });
}

export function filterNew(records: IRecords, useWorkshopFilter: boolean = false) {
  return (useWorkshopFilter ? filterStatusWithWorkshopFilter : filterStatus)(records, Status.New);
}
export function filterNewGlass(inspections: IGlassInspections, useWorkshopFilter: boolean = false) {
  const filter = useWorkshopFilter ? filterStatusWithWorkshopFilterGlass : filterStatusGlass;
  let result = filter(inspections, Status.New);
  return result;
}
export function filterGlassType(inspections: IGlassInspectionWithWorkshop[], ...types: PhotoSource[]) {
  return inspections.filter((i) => types.includes(i.photoSource));
}

export function dispatchIfOlderThan(
  dispatch: Dispatch<any>,
  action: ActionFunctionAny<any>,
  lastAction: Date,
  seconds: number = 10
) {
  if (lastAction) {
    if (new Date().getTime() - lastAction.getTime() < seconds * 1000) {
      return;
    }
  }

  dispatch(action());
}

export function dispatchIfNeeded(
  dispatch: Dispatch<any>,
  action: ActionFunctionAny<any>,
  records: IInspectionStateType,
  seconds: number = 60 * 5
) {
  let loading = records?.isLoading;
  if (loading) {
    return;
  }
  let lastAction = records?.lastLoaded;
  if (lastAction) {
    if (new Date().getTime() - lastAction.getTime() < seconds * 1000) {
      return;
    }
  }
  dispatch(action());
}

export function useLocalStorage(key: string, initialValue: any) {
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      let valueToStore: any;
      if (value instanceof Function) {
        setStoredValue((v: any) => {
          valueToStore = value(v);
          return valueToStore;
        });
      } else {
        valueToStore = value;
        setStoredValue(value);
      }
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {}
  };

  return [storedValue, setValue];
}

export function hasValueInLocalStorage(key: string): boolean {
  try {
    const item = window.localStorage.getItem(key);
    return Boolean(item);
  } catch (error) {
    return false;
  }
}

const StoredSortConfigsKey = "tableSortConfig";

function getStoredSortConfigs(): IStoredSortConfigs {
  let rawValue = window.localStorage.getItem(StoredSortConfigsKey);
  let config: IStoredSortConfigs = {};

  if (rawValue) {
    config = JSON.parse(rawValue);
  }

  return config;
}

export function getSortConfig(key: string, defaultSortColumn: TableColumn): ISortConfig {
  let config = getStoredSortConfigs();

  let tableConfig = {
    field: defaultSortColumn,
    currentPage: 0,
    ascending: false,
  } as ISortConfig;

  try {
    tableConfig = config[key] || tableConfig;
  } catch {}
  return tableConfig;
}

export function storeSortConfig(key: string, sortConfig: ISortConfig) {
  let config = getStoredSortConfigs();
  config[key] = sortConfig;
  window.localStorage.setItem(StoredSortConfigsKey, JSON.stringify(config));
}

export let currentUser = "any";

export function setCurrentUser(email: string) {
  currentUser = email;
}

const adminWorkshopVisibilityCookieKey = "admin-workshop-visibility";
export function adminGetWorkshopVisibilityCookie(): [string, boolean] {
  const cookie = document.cookie.split("; ").find((x) => x.startsWith(adminWorkshopVisibilityCookieKey));
  let cookieValue = "";
  if (cookie) {
    cookieValue = cookie.split("=")[1];
  }
  return [cookieValue, !cookieValue || cookieValue === "all"];
}
export function adminSetWorkshopVisibilityCookie(data) {
  document.cookie = adminWorkshopVisibilityCookieKey + "=" + encodeURIComponent(data);
}
export function adminIsWorkshopVisible(id) {
  const [cookieValue, all] = adminGetWorkshopVisibilityCookie();

  return all || cookieValue.includes(id);
}

export function getCountryLabels(locale: string) {
  switch (locale.toLowerCase()) {
    case "en":
      return enCountryLabels;
    case "sv":
      return svCountryLabels;
    case "fi":
      return fiCountryLabels;
    case "nb":
      return nbCountryLabels;
    default:
      return enCountryLabels;
  }
}

const localeToCountryMap = {
  sv: WorkshopCountryCode.SE,
  da: WorkshopCountryCode.DK,
  nb: WorkshopCountryCode.NO,
  fi: WorkshopCountryCode.FI,
  en: null,
  id: null,
};
// noinspection SpellCheckingInspection
export const localeNames = {
  sv: "Svenska",
  da: "Dansk",
  nb: "Norsk",
  fi: "Suomi",
  en: "English",
  id: "ID",
};
const locales = Object.keys(localeToCountryMap);
const countryToLocaleMap = (() => {
  let result = {} as Record<WorkshopCountryCode, string>;
  locales.forEach((locale) => {
    let country = localeToCountryMap[locale];
    if (country !== null) result[country] = locale;
  });
  return result;
})();

export function mapToLocale(countryCodeOrLocale: string | WorkshopCountryCode, _default: string = "sv"): string {
  if (countryCodeOrLocale === null || countryCodeOrLocale === undefined) return _default;

  let country: WorkshopCountryCode = null;
  if (typeof countryCodeOrLocale === "string") {
    let locale = countryCodeOrLocale.toLowerCase();

    let alreadyIsLocale = locales.includes(locale);
    if (alreadyIsLocale) return locale;

    country = WorkshopCountryCode[countryCodeOrLocale.toUpperCase()];
  } else {
    country = countryCodeOrLocale;
  }

  return countryToLocaleMap[country] ?? _default;
}
export function mapToCountry(
  countryCodeOrLocale: string | WorkshopCountryCode,
  _default: WorkshopCountryCode = WorkshopCountryCode.SE
): WorkshopCountryCode {
  if (countryCodeOrLocale === null || countryCodeOrLocale === undefined) return _default;

  let country = _default;
  if (typeof countryCodeOrLocale === "string") {
    let possiblyLocale = countryCodeOrLocale.toLowerCase();
    if (locales.includes(possiblyLocale)) return localeToCountryMap[possiblyLocale] ?? _default;
    country = WorkshopCountryCode[countryCodeOrLocale.toUpperCase()];
  } else {
    country = countryCodeOrLocale;
  }
  return country;
}

export function checkIE() {
  const userAgent = window.navigator.userAgent;
  const hasTrident = userAgent.includes("Trident/");
  const hasRevision = userAgent.includes("rv:");
  const hasMsie = userAgent.includes("MSIE ");
  let isIE11orLower = (hasTrident && hasRevision) || hasMsie;
  return isIE11orLower;
}

window._photoinspection = window._photoinspection ?? ({ perfMetrics: {} } as any);
window._photoinspection.perfMetrics.report = function () {
  let perf = window._photoinspection.perfMetrics;
  function log(name: string, timestamp: Date) {
    if (timestamp) console.log(name, Number(timestamp) - Number(perf.head));
  }
  log("app", perf.app);
  log("records", perf.records);
  log("table", perf.table);
  log("intake info", perf.intakeInfo);
  log("intake render", perf.intakeRender);
};

export const perfMetrics = window._photoinspection.perfMetrics;
export function getCurrentNotifications() {
  let notifications = window.ClientEnvironment.notifications ?? [];
  return [...notifications.sort((a, b) => a.timestamp.localeCompare(b.timestamp))];
}

export function isValidYyMmDd(date: string): any {
  let year: number = Number(date.substring(0, 2));
  let month: number = Number(date.substring(2, 4));
  let day: number = Number(date.substring(4, 6));

  if (!/^\d{6}$/.test(date) || month === 0 || day === 0) return false;

  let maxMonths: number = 12;
  let maxDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  if (year % 4 === 0) maxDays[1] = 29;

  return month <= maxMonths && day <= maxDays[month - 1];
}

export function sortWorkshops(workshops: IWorkshop[]): IWorkshop[] {
  let testWorkshops = [];
  let realWorkshops = [];

  // split test and real workshops
  for (let workshop of workshops) {
    if (workshop.id.toLowerCase().endsWith("test")) {
      testWorkshops.push(workshop);
    } else {
      realWorkshops.push(workshop);
    }
  }

  // combine them sorted and so that test workshops are first
  return testWorkshops
    .sort((a, b) => a.name.localeCompare(b.name))
    .concat(realWorkshops.sort((a, b) => a.name.localeCompare(b.name)));
}
