import React from "react";
import "./tableView.scss";
import {
  AppState,
  getPossibleStatusChanges,
  IInspection,
  IIntakeLinkReminders,
  IsRecord,
} from "../../models";
import * as Actions from "../../actions";
import { changeGlassInspectionStatus, changeRecordStatus } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import TableMenu, { IMenuItem } from "./tableMenu";
import { IRecord, Status } from "../../backend-models";
import { useIntl } from "react-intl";
import { LoaderIcon } from "./loader";
import { useConfirm } from "./useConfirm";

const StatusAction: React.FC<{ record: IInspection; showSendReminder: boolean }> = ({
  record,
  showSendReminder,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { confirmPopUp, setConfirmPopUp } = useConfirm();
  const isGlass = !IsRecord(record);
  const menuItems = getPossibleStatusChanges(record, intl).map(
    (statusChange) =>
      ({
        action() {
          const confirmAction = () => {
            if (isGlass) {
              dispatch(
                changeGlassInspectionStatus({
                  inspectionId: record.recordId,
                  status: statusChange.status,
                  decision: null,
                })
              );
            } else {
              dispatch(
                changeRecordStatus({
                  recordId: record.recordId,
                  status: statusChange.status,
                  decision: null,
                })
              );
            }
          };
          if (statusChange.status === Status.Deleted) {
            setConfirmPopUp({
              title: intl.formatMessage({ id: "confirm.delete.title" }),
              text: intl.formatMessage({ id: "confirm.delete.text" }),
              confirmAction: confirmAction,
            });
          } else {
            confirmAction();
          }
        },
        text: (
          <>
            {statusChange.status !== Status.Deleted ? (
              <span className="if icon ui corner-left-round menu-icon" />
            ) : null}
            {statusChange.text}
          </>
        ),
      } as IMenuItem)
  );

  if (record.status !== Status.Open)
    menuItems.unshift({
      text: intl.formatMessage({ id: "action.moveTo" }),
    });

  let intakeLinkReminders: IIntakeLinkReminders = useSelector((state: AppState) => state.intakeLinkReminders);

  function isLoading() {
    const rec = intakeLinkReminders?.records?.find((r) => r.recordId === record.recordId);
    return rec && rec.isLoading;
  }

  if (showSendReminder && IsRecord(record)) {
    function sendIntakeLinkReminder() {
      dispatch(Actions.sendIntakeLinkReminder({ record: record as IRecord, intl }));
    }
    let disabled = Boolean(record.intakeReminderSmsSentDate);

    if (!window.ClientEnvironment.flags.sendAutomaticReminders) {
      menuItems.push({
        action: sendIntakeLinkReminder,
        text: intl.formatMessage({ id: "action.sendIntakeLinkReminder" }),
        disabled,
        tooltipText: disabled
          ? intl.formatMessage({ id: "action.sendIntakeLinkReminder.hasBeenSent" })
          : null,
      });
    }
  }

  return (
    <div>
      {confirmPopUp}
      {isLoading() ? <LoaderIcon size="small" /> : <TableMenu menuItems={menuItems} />}
    </div>
  );
};

export default StatusAction;
