import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootReducers } from "./reducers";
import { rootSaga } from "./sagas";
import { createBrowserHistory } from "history";
import { useSelector } from "react-redux";
import { AppState } from "./models";

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const composeEnhancer =
  (process.env.NODE_ENV !== "production" && window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]) || compose;

export const store = configureStore();

export function configureStore() {
  const store = createStore(rootReducers, {}, composeEnhancer(applyMiddleware(sagaMiddleware)));
  return store;
}

sagaMiddleware.run(rootSaga);

export const useStateSelector = useSelector as <T>(selector: (state: AppState) => T) => T;
