import { SagaIterator } from "redux-saga";
import { all, call, put, takeLatest } from "redux-saga/effects";
import * as Actions from "actions";
import * as AdminActions from "./actions";
import { processGlassInspectionsResponse, processInspectionsResponse } from "models";
import { showError } from "sagas/common";
import { apiCall } from "sagas/api";
import { IBodyInspectionsResponse, IGlassInspectionsResponse } from "backend-models";
import { Backend } from "backend";

function* fetchAllRecords() {
  try {
    let bodyResponse = yield call(apiCall, `${Backend.URL.Record.GetRecords()}?all=true`) as IBodyInspectionsResponse;

    let glassResponse = yield call(
      apiCall,
      `${Backend.URL.Record.GlassInspections()}?all=true`
    ) as IGlassInspectionsResponse;

    yield put(Actions.fetchRecordsSuccess(processInspectionsResponse(bodyResponse)));

    yield put(Actions.fetchGlassInspectionsSuccess(processGlassInspectionsResponse(glassResponse)));
  } catch (err) {
    yield showError(err);
    yield all([put(Actions.fetchRecordsError(err))]);
  }
}
export default function* fetchAllRecordsSaga(): SagaIterator {
  yield takeLatest(AdminActions.fetchAllRecords, fetchAllRecords);
}
