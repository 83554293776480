import * as Actions from "actions";
import reducer from "../reducer";
import { IRecords } from "models";
import { getBodyWorkshopFilter, toggleAllBodyWorkshopFilters, toggleBodyWorkshopFilter } from "workshopFilter";

export default reducer<IRecords>({ isLoading: false, records: [], loadError: undefined, workshopFilter: {} }, (on) => {
  on(Actions.fetchUserInfoSuccess, (_, { payload }) => {
    let filter = getBodyWorkshopFilter(payload.workshopObjectList);
    return { ..._, workshopFilter: filter };
  });
  on(Actions.fetchRecordsSuccess, (records, { payload: response }) => {
    let filter = getBodyWorkshopFilter(response.workshops);
    return { ...records, workshopFilter: filter };
  });
  on(Actions.addRecordSuccess, (records) => {
    let filter = getBodyWorkshopFilter([]);
    return { ...records, workshopFilter: filter };
  });
  on(Actions.toggleBodyWorkshopFilter, (_, { payload: id }) => {
    return { ..._, workshopFilter: toggleBodyWorkshopFilter(id, _.workshopFilter) };
  });
  on(Actions.toggleAllBodyWorkshopFilter, (_, { payload: show }) => {
    return { ..._, workshopFilter: toggleAllBodyWorkshopFilters(show, _.workshopFilter) };
  });
});
