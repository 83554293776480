import { combineReducers } from "redux";
import { AppState } from "../models";
import { recordsReducer } from "./records";
import glassInspectionsReducer from "./glassInspections";
import notifications from "./notifications";
import searchResults from "./searchResults";
import workshops from "./workshops";
import newInspection from "./newInspection";
import sendIntakeLinkReminder from "./sendIntakeLinkReminder";
import photos from "./photos";
import intakeInfo from "./intakeInfo";
import intakeSubmission from "./intakeSubmission";
import userInfo from "./userInfo";
import smsTemplate from "./smsTemplate";
import workshopFeedback from "./workshopFeedback";
import { adminStateReducer } from "../admin/adminReducers";

export const rootReducers = combineReducers<AppState>({
  records: recordsReducer,
  glassInspections: glassInspectionsReducer,
  notifications: notifications,
  searchResults: searchResults,
  workshops: workshops,
  newInspection: newInspection,
  photos: photos,
  intake: intakeInfo,
  intakeSubmission: intakeSubmission,
  intakeLinkReminders: sendIntakeLinkReminder,
  userInfo: userInfo,
  smsTemplate: smsTemplate,
  workshopFeedback: workshopFeedback,
  admin: adminStateReducer,
});
