// eslint-disable-next-line import/prefer-default-export
export const KEYS = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
  ESC: 27,
  PLUS: 43,
  A: 65,
  Z: 90,
  SPACE: 32,
  TAB: 9,
}
