import React, { useState, useContext, createContext, useEffect } from "react";
import { apiCallRequestOptions, OnApiRequest, request } from "../sagas/api";
import { Backend } from "../backend";
import { IUserAuthData } from "../backend-models";
import { history } from "../store";
import { useDispatch } from "react-redux";
import * as Actions from "../actions";
import { NotificationType } from "../models";

interface AuthContextType extends IUserAuthData {
  logout(): void;
  loginIfNeeded(): void;
  setLoggedOut(): void;
}

const AuthContext = createContext(undefined as AuthContextType);
export const useAuth = () => useContext(AuthContext);

export let redirectingToLogin = false;

export function login(pathname: string = undefined) {
  if (redirectingToLogin) return;
  const path = "/api/auth/login?redirectUri=" + encodeURIComponent(pathname ?? window.location.pathname);
  authLog("login", path);
  window.location.href = path;
  redirectingToLogin = true;
}

function getUser(): Promise<IUserAuthData> {
  return request(Backend.URL.Auth.GetUser(), apiCallRequestOptions({ anonymous: true }));
}

const initTime = new Date().getTime();

let authHeartbeatTimeout: number | undefined = undefined;

const authHeartbeatTimer = 1000 * 60 * 5;

function authLog(source: string, other: string = undefined) {
  let now = new Date().getTime();
  let timeOffset = ((now - initTime) / 1000).toFixed(1);
  console.debug(new Date().toISOString(), timeOffset.toString().padStart(5), source, other ?? "");
}

// called when no API request has been made in authHeartbeatTimer milliseconds
// runs a getUser() API call which has a two-fold effect:
//  - refresh cookie if needed
//  - indicate the auth status
// if we are not authenticated, i.e. logged out, it will dump you to
// "you have been logged out" page
function authHeartbeat() {
  authLog("authHeartbeat");
  //this calls resetHeartbeat() implicitly
  getUser().then((data) => {
    if (!data.isAuthenticated) {
      authLog("authHeartbeat", "logged out");
      history.push("/logged-out");
      clearHeartbeat();
    }
  });
}

// called every time an API request is made by request() in /sagas/api.ts
// keeps authHeartbeat() call authHeartbeatTimer milliseconds away
// from any API call, since that call revalidated and refreshed our
// cookie if it was needed
function resetHeartbeat(url: string = undefined) {
  clearHeartbeat("resetHeartbeat", url);
  if (!("authHeartbeatStop" in window)) {
    authHeartbeatTimeout = window.setTimeout(authHeartbeat, authHeartbeatTimer);
  }
}
function clearHeartbeat(source = "clearHeartbeat", url = undefined) {
  authLog(source, url);
  if (authHeartbeatTimeout) {
    try {
      window.clearTimeout(authHeartbeatTimeout);
    } catch {}
  }
}
function defaultState(): IUserAuthData {
  return {
    isAuthenticated: false,
    isAdministrator: false,
    isImpersonated: false,
  };
}
export const AuthProvider: React.FC<{ requiresAuth: boolean }> = (props) => {
  const [contextState, setContextState] = useState<IUserAuthData>(defaultState());
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.requiresAuth) {
      setIsLoading(false);
      return;
    }
    (async () => {
      let authData: IUserAuthData = defaultState();

      try {
        authData = await getUser();
      } catch (e) {
        console.error(e);
        dispatch(
          Actions.showNotification({
            message: "Error loading user: " + e.message,
            type: NotificationType.Error,
            time: new Date(),
          })
        );
        throw e;
      }
      console.debug("getUser", authData);
      if (authData.isAuthenticated !== contextState.isAuthenticated) {
        setContextState(authData);
      }
      setIsLoading(false);
      if (authData.isAuthenticated) {
        window.ClientEnvironment.flags = authData.flags;
        window.ClientEnvironment.intakeBaseUrl = authData.intakeBaseUrl;
        if (!OnApiRequest.includes(resetHeartbeat)) {
          OnApiRequest.push(resetHeartbeat);
        }
        resetHeartbeat();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.requiresAuth]);

  return (
    <AuthContext.Provider
      value={{
        ...contextState,
        logout: () => {
          window.location.href = "/api/auth/logout";
        },
        loginIfNeeded: () => {
          if (!contextState.isAuthenticated) {
            login();
          }
        },
        setLoggedOut() {
          if (contextState.isAuthenticated) {
            authLog("setLoggedOut");
            setContextState(defaultState());
          }
        },
      }}
    >
      {!isLoading && props.children}
    </AuthContext.Provider>
  );
};
