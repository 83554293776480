import "./volviaLandingStep.scss";

import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { IWizardStepProps } from "../../../../intakeModels";
import Loader from "../../../shared/loader";
import { ConsentCheckbox } from "../consentCheckbox";
import { TermsOfServiceVolvia } from "../termsOfService/termsOfServiceVolvia";
import { testFlagSet } from "../../../../intakeUtility";
import { LanguageSelector } from "../../../shared/languageSelector";

export const VolviaLandingStep: React.FC<IWizardStepProps> = ({ wizard, goStep }) => {
  const intl = useIntl();
  const [imageLoading, setImageLoading] = useState(true);
  const [termsOfServiceAccepted, setTermsOfServiceAccepted] = useState(testFlagSet("prefill"));
  const [showTermsOfService, setShowTermsOfService] = useState(false);
  function onNext() {
    goStep(1, (data) => ({ ...data, termsOfServiceAccepted: termsOfServiceAccepted }));
  }
  useEffect(() => {
    if (wizard.nextClicked) onNext();
  });
  if (showTermsOfService) {
    return (
      <div className="consent-container" data-cy="tos">
        <a
          href="#"
          className="if back-to-link"
          onClick={(e) => {
            setShowTermsOfService(false);
            e.preventDefault();
          }}
          data-cy="back-link"
        >
          <FormattedMessage id="intake.alt.back" />
        </a>
        <TermsOfServiceVolvia locale={wizard.workshopCountryCode} />
      </div>
    );
  }
  return (
    <>
      <LanguageSelector
        locale={wizard.locale}
        setLocale={wizard.setLocale}
        defaultLocale={wizard.workshopCountryCode}
        intake={true}
      />
      <div className="volvia-header-logo">
        <img src="/img/volvia/logo.svg" alt="Logo Volvia" />
      </div>
      <div className="volvia-landing-container">
        <h1 className="if header-text-centered" data-cy="landingHeader">
          <FormattedMessage id="intake.VolviaLandingStep.header" />
        </h1>
        {imageLoading && (
          <div className="loader-container" style={{ display: imageLoading ? "block" : "none" }}>
            <Loader />
          </div>
        )}
        <div style={{ display: imageLoading ? "none" : "block" }} className="landing-image-container">
          <img
            className="if image volvia-landing-image"
            src="/img/volvia/landing-car.jpg"
            alt={intl.formatMessage({ id: "intake.alt.imageCar" })}
            onLoad={() => setImageLoading(false)}
          />
        </div>
        <p className="basic-text-centered">
          <FormattedMessage id="intake.VolviaLandingStep.text" />
        </p>
        <ConsentCheckbox
          consent={termsOfServiceAccepted}
          setConsent={setTermsOfServiceAccepted}
          onTermsOfServiceClick={() => setShowTermsOfService(true)}
        />

        <button
          className="if button primary"
          disabled={!termsOfServiceAccepted}
          data-cy="startInspectionBtn"
          onClick={() => onNext()}
        >
          <FormattedMessage id="intake.wizard.buttonStart" />
        </button>

        <p className="if text disclaimer">
          <FormattedMessage id="intake.LandingStep.privacyPolicy.text" />
          <a
            href="https://www.volvia.se/hantering-av-personuppgifter"
            target="_blank"
            rel="noopener noreferrer"
            data-cy="privacy-policy-link"
          >
            <FormattedMessage id="intake.LandingStep.privacyPolicy.link.text" />
          </a>
        </p>
      </div>
    </>
  );
};
