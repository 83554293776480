import * as Actions from "../../actions";
import reducer, { IActionReducer } from "../reducer";
import { IRecord } from "../../backend-models";
import { IRecords } from "../../models";
import { recordError, updateRecord } from "./index";

const addRecordSuccessReducer: IActionReducer<IRecords, IRecord> = (_, action) => {
  return updateRecord(_, action.payload);
};
export default reducer<IRecords>(
  { isLoading: false, records: [], loadError: undefined, workshopFilter: {} },
  (on) => {
    on(Actions.addRecordSuccess, addRecordSuccessReducer);
    on(Actions.addRecordError, (_, action) => {
      return recordError(_, action.payload, "Failed to add record");
    });
  }
);
