import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "./actions";
import { Backend } from "../backend";
import { Action } from "redux-actions";
import { showError } from "../sagas/common";
import { NotificationType } from "../models";
import { showNotification } from "../actions";

function* purgeRecord(action: Action<string>) {
  try {
    yield* Backend.Record.Purge(action.payload);
    yield put(Actions.purgeRecordSuccess());
    yield put(
      showNotification({
        message: "Deleted record " + action.payload,
        type: NotificationType.Success,
        time: new Date(),
      })
    );
  } catch (err) {
    yield showError(err);
    yield put(Actions.purgeRecordError({ ...err, recordId: action.payload }));
  }
}

export default function* purgeRecordSaga(): SagaIterator {
  yield takeLatest(Actions.purgeRecord, purgeRecord);
}
