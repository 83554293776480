import "./progressBar.scss";

import React from "react";

type IProps = {
  currentStep: number;
  total: number;
};

const ProgressBar = (props: IProps) => {
  if (props.currentStep === 0) {
    return null;
  }
  const widthStyle = {
    width: `${(props.currentStep / props.total) * 100}%`,
  };
  return (
    <div className="progress-container">
      <div className="bar-container" data-cy="progressBar">
        <div className="progres-bar-styles" style={widthStyle}></div>
      </div>
      <span data-cy="progressBarCounter">{`${props.currentStep} / ${props.total}`}</span>
    </div>
  );
};

export default ProgressBar;
