import { Brand, IIntakeInformation, WorkshopCountryCode } from "./backend-models";
import { IIntakeInformationState, IPhotoSet, PhotoKey } from "./intakeModels";
import * as CryptoJS from "crypto-js";

export function shouldShowClaimDate(intakeInfo: IIntakeInformation) {
  return intakeInfo.brand === Brand.Volvia || !intakeInfo.hasClaimNumber;
}

export function doesNeedConsent(intakeInfo: IIntakeInformationState) {
  return !(intakeInfo.isBluePilot || intakeInfo.isIPS);
}
export function cachedCreateObjectURL(file: File) {
  const key = "cachedCreateObjectURL";
  if (key in file) {
    return (file as any)[key];
  } else {
    let url = URL.createObjectURL(file);
    file[key] = url;
    return url;
  }
}
export function parseBrand(brand: string, defaultBrand: Brand = Brand.If) {
  let parsed =
    Brand[
      Object.keys(Brand).find((b) => {
        return b.toLowerCase() === brand?.toLowerCase();
      })
    ];
  return parsed ?? defaultBrand;
}

export function decodeIntakeUrlData(data: string, failSilently: boolean = false) {
  if (!data || data.length < 17 || data.length > 24) return null;

  try {
    let bits = atob(data.replace(/_/g, "/").replace(/-/g, "+"));
    let id = decodeBase64Guid(bits.substr(0, 16));
    let brandAndLang = bits.charCodeAt(16);
    let brand = (brandAndLang & 0x0f) as Brand;
    let countryCode = (brandAndLang >> 4) as WorkshopCountryCode;

    return { id, brand, countryCode };
  } catch (e) {
    if (!failSilently) {
      console.error("Unable to parse intake data", e);
    }
  }
}

function decodeBase64Guid(data: string) {
  //data is a string of 16 bytes

  let chars = data.split("");

  //convert endianness
  chars = chars
    .slice(0, 4)
    .reverse()
    .concat(chars.slice(4, 6).reverse())
    .concat(chars.slice(6, 8).reverse())
    .concat(chars.slice(8));

  function byteToHex(byte: string) {
    return byte.charCodeAt(0).toString(16).padStart(2, "0");
  }
  //without dashes
  let guid = chars.map(byteToHex);

  guid.splice(4, 0, "-");
  guid.splice(7, 0, "-");
  guid.splice(10, 0, "-");
  guid.splice(13, 0, "-");

  return guid.join("");
}

export function fileHash(file: File) {
  return new Promise<string>((resolve) => {
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => {
      let words = CryptoJS.lib.WordArray.create(reader.result as any);
      let hash = CryptoJS.MD5(words).toString();
      (file as any).hash = hash;
      resolve(hash);
    };
  });
}

const preloadedImages = {};
export function preloadImage(url) {
  if (!preloadedImages[url]) {
    let image = new Image();
    image.src = url;
    preloadedImages[url] = image;
  }
}

export function testableNumber(val: number, key: string) {
  return testableVal(val, key, Number.parseInt);
}
export function testMode() {
  let path = document.location.pathname;
  return (
    path.substr(path.indexOf("/", 1)).startsWith("/__test") ||
    new URLSearchParams(document.location.search).get("__test") !== null
  );
}
export function testableVal<T>(val: T, key: string, parser: (string) => T = (x) => x): T {
  if (testMode()) {
    let urlParams = new URLSearchParams(document.location.search);
    let param = urlParams.get(key);
    if (param) {
      return parser(param);
    }
  }
  return val;
}
export function testFlagSet(key: string) {
  return testMode() && new URLSearchParams(document.location.search).get(key) !== null;
}
function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
function generateTestImage(key: string) {
  const w = 400;
  const h = 300;
  let canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;
  let ctx = canvas.getContext("2d");

  ctx.fillStyle = "#0054f0";
  ctx.fillRect(0, 0, w, h);

  ctx.rotate((33 * Math.PI) / 180);
  ctx.fillStyle = "white";
  ctx.font = "bold 50px If Sans, Arial";
  ctx.fillText(key, 245 - key.length * 12, 30);

  return canvas.toDataURL("image/jpeg");
}
export function testPhotos(keys: string[]) {
  let photos: { [key in PhotoKey]?: IPhotoSet } = {};
  keys.forEach((key) => {
    photos[key] = {
      files: [dataURLtoFile(generateTestImage(key), key + ".jpg")],
      key: key,
    } as IPhotoSet;
  });
  return photos;
}
