// noinspection UnnecessaryLocalVariableJS

import { call } from "redux-saga/effects";
import { IError } from "models";
import { IValidationProblemDetails } from "backend-models";
import { login, redirectingToLogin } from "auth/authContext";
import { history } from "store";
import { Relogin } from "page/relogin";

const GEN_API_ROOT = "";

function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return response.json();
  }
  return response;
}

async function checkStatus(response: Response, ignoreUnauthorized: boolean = false): Promise<Response> {
  //if we're already redirecting to login, return a promise that never resolves
  if (redirectingToLogin) return new Promise(() => {});
  console.debug("checkStatus", response.status);
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  async function throwError(redirecting = false) {
    let message = response.statusText;
    try {
      let problem = JSON.parse(await response.text()) as IValidationProblemDetails;
      if (!message && problem.errors) {
        message = Object.values(problem.errors)
          .map((e) => e.join(", "))
          .join(", ");
      }
    } catch {}

    const error: IError = {
      message: response.status.toString() + " " + message,
      status: response.status,
      redirecting,
    };
    throw error;
  }
  switch (response.status) {
    case 403: //forbidden
      history.push("/forbidden");
      await throwError();
      break;
    case 500:
    case 400: //bad request
    case 404: //not found
    case 409: //conflict
      await throwError();
      break;
    case 401:
      if (!ignoreUnauthorized) {
        //actually login
        login(window.location.pathname);
        //show "redirecting..."
        history.push(Relogin.Path);
      }
      await throwError(true);
      break;
  }

  return new Promise((_, reject) => {
    response.text().then((text) => {
      const error: IError = {
        message: text,
      };
      reject(error);
    });
  });
}

export type ApiRequestEvent = (url: string, options: RequestInit) => void;

export const OnApiRequest: ApiRequestEvent[] = [];

export async function request(url: string, options: RequestInit) {
  OnApiRequest.forEach((handler) => {
    handler(url, options);
  });

  try {
    let response = await fetch(url, options);
    response = await checkStatus(response);
    return await parseJSON(response);
  } catch (reason) {
    console.log("Failed to fetch: ", reason);
    if (reason instanceof TypeError && reason.message === "Failed to fetch") {
      throw Error("Connectivity error");
    }
    throw reason;
  }
}

export async function tryUsePreloaded(url: string) {
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    mode: "no-cors",
  });
  response = await checkStatus(response, true);
  return parseJSON(response);
}

export interface PostOptions {
  body?: any;
  anonymous?: boolean;
  method?: string;
}

export function apiCallRequestOptions(options: PostOptions = {}): RequestInit {
  options = {
    body: null,
    anonymous: false,
    method: "GET",
    ...options,
  };
  let headers = {
    Accept: "application/json",
  };

  if (options.method !== "GET" && !(options.body instanceof FormData)) {
    options.body = JSON.stringify(options.body);
    headers["Content-type"] = "application/json";
  }

  return {
    body: options.body,
    headers: new Headers(headers),
    method: options.method,
  };
}
export function* apiCall(path: string, options: PostOptions = {}) {
  let url = GEN_API_ROOT.concat(path);
  return yield call(request, url, apiCallRequestOptions(options));
}
