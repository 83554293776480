import { SagaIterator } from "redux-saga";
import { all, put, takeLatest } from "redux-saga/effects";
import * as Actions from "./actions";
import * as AdminActions from "./actions";
import { showError } from "../sagas/common";
import { Backend } from "../backend";

function* sendNotificationEmails() {
  try {
    yield* Backend.Admin.SendNotificationEmails();
    yield put(Actions.sendNotificationEmailsSuccess());
  } catch (err) {
    yield showError(err);
    yield all([put(Actions.sendNotificationEmailsError(err))]);
  }
}
export function* sendNotificationEmailsSaga(): SagaIterator {
  yield takeLatest(AdminActions.sendNotificationEmails, sendNotificationEmails);
}
