export function TermsOfServiceAudi() {
  return (
    <div className="if consent-container">
      <h1 className="if heading">Användarvillkor för Audi Försäkrings fotobesiktningstjänst</h1>
      <p className="if text body">
        Vi på Audi Försäkring är glada över att du har valt att använda Audi Försäkrings fotobesiktningstjänst
        (”Tjänsten”). För att använda Tjänsten behöver du först godkänna dessa användarvillkor.
        Användarvillkoren utgör ett avtal (”Avtalet”) mellan dig och Audi Försäkring, org.nr 516401-8102
        (”Tjänsteleverantören”).
      </p>
      <h2 className="if heading">1. Tjänsten</h2>
      <p className="if text body">
        1.1 Genom Tjänsten får du som råkat ut för en skada på ditt fordon möjlighet att överföra fotografier
        på fordonet till den verkstad som du använder dig av förutsatt att verkstaden är ansluten till
        Tjänsten och accepterat att du överför fotografier genom Tjänsten.
      </p>
      <p className="if text body">
        1.2 Tjänsten levereras ”i befintligt skick” och i det utförande som den har vid respektive tidpunkt.
      </p>
      <h2 className="if heading">2. Hantering av personuppgifter</h2>
      <p className="if text body">
        2.1 Genom att använda Tjänsten får Tjänsteleverantören tillgång till de uppgifter som du anger eller
        laddar upp i Tjänsten, t.ex. namn, telefonnummer och registreringsnummer på det fordon som ska bli
        föremål för fotobesiktning. Tjänsteleverantören överför dessa uppgifter till aktuell verkstad på
        uppdrag av dig och sparar uppgifterna så länge som det är nödvändigt för att dokumentera uppdragets
        utförande. Om Tjänsten används inom ramen för ett skadeärende hos Tjänsteleverantören sparas
        uppgifterna i skadeärendet.
      </p>
      <p className="if text body">
        2.2 De fotografier som du laddar upp i Tjänsten kan komma att användas för analysändamål, t.ex. för
        analys av fordonets skador.
      </p>
      <p className="if text body">
        2.3 Du kan läsa mer om Tjänsteleverantörens hantering av personuppgifter och dina rättigheter på
        <a href="https://www.if.se/om-webbplatsen/hantering-av-personuppgifter">
          www.if.se/om-webbplatsen/hantering-av-personuppgifter
        </a>
        .
      </p>
      <h2 className="if heading">3. Immateriella rättigheter och villkor för att använda Tjänsten</h2>
      <p className="if text body">
        3.1 Samtliga immateriella rättigheter till Tjänsten, inbegripet det material som visas eller finns i
        Tjänsten, innehas av Tjänsteleverantören eller av leverantörer till Tjänsteleverantören avseende
        Tjänsten eller annan tredje part. Under förutsättning att du godkänner och uppfyller villkoren i
        Avtalet ges du härmed en personlig, icke-exklusiv och icke-överlåtbar rätt att använda de tjänster och
        funktioner som tillhandahålls i Tjänsten. Denna rätt upphör automatiskt om Tjänsteleverantören
        inaktiverar Tjänsten eller om Avtalet upphör att gälla genom uppsägning eller på annat sätt.
      </p>
      <p className="if text body">3.2 Du har inte rätt att:</p>
      <p className="if text body">
        (a) kopiera eller använda Tjänsten för andra ändamål än vad den är avsedd för;
      </p>
      <p className="if text body">
        (b) sälja, distribuera eller på annat sätt överföra Tjänsten till tredje part;
      </p>
      <p className="if text body">(c) skapa bearbetningar av Tjänsten;</p>
      <p className="if text body">
        (d) använda Tjänsten för att överföra olagliga eller kränkande uppgifter eller sådana uppgifter som
        utgör en olaglig kopia av tredje parts uppgifter;
      </p>
      <p className="if text body">(e) tillåta tredje part att göra något av ovan nämnda åtgärder.</p>
      <p className="if text body">
        3.3 Genom att ladda upp ett fotografi i Tjänsten ger du Tjänsteleverantören en icke-exklusiv och
        överlåtbar rätt att på obegränsad tid använda fotografiet för analysandamål. Denna punkt ska fortsätta
        att gälla även för det fall att Avtalet sägs upp eller på annat sätt upphör att gälla.
      </p>
      <h2 className="if heading">4. Datatrafik</h2>
      <p className="if text body">
        För att använda Tjänsten krävs att du har uppkoppling till internet. Du ansvarar för samtliga
        kostnader hänförlig till denna uppkoppling, inbegripet eventuella kostnader för datatrafik och
        roaming.
      </p>
      <h2 className="if heading">5. Ansvar och ansvarsbegränsning</h2>
      <p className="if text body">
        Tjänsteleverantörens ambition är att Tjänsten ska finnas tillgänglig dygnet runt. Tjänsten kan dock
        vara otillgänglig t.ex. till följd av serviceavbrott eller tekniska problem. Fotografier och annat
        material som du har laddat upp i Tjänsten kan av oförutsedd orsak raderas och försvinna för gott. Det
        är ditt ansvar att ha säkerhetskopior på allt material som du laddar upp i Tjänsten.
        Tjänsteleverantören är inte ansvarig för skada eller förlust hänförlig till Tjänsten, oavsett om den
        är direkt eller indirekt.
      </p>
      <h2 className="if heading">6. Tvist</h2>
      <p className="if text body">
        En tvist mellan dig och Tjänsteleverantören med anledning av de förhållanden som regleras av Avtalet
        ska avgöras av svensk domstol och med tillämpning av svensk rätt, dock utan tillämpning av svenska
        lagvalsregler. Tjänsteleverantören får även väcka talan vid domstol i ett annat land där du har din
        hemvist eller där du har tillgångar.
      </p>
      <h2 className="if heading">7. Övrigt</h2>
      <p className="if text body">
        7.1 Tjänsteleverantören förbehåller sig rätten att när som helst säga upp Avtalet eller upphöra med
        att tillhandahålla Tjänsten.
      </p>
      <p className="if text body">
        7.2 Sådana bestämmelser i Avtalet som till sin natur är ägnade att vara tillämpliga även efter
        uppsägning, ska fortsätta att vara tillämpliga.
      </p>
      <p className="if text body">
        7.3 Om något eller några av de villkor som dessa användarvillkor bildar konstateras vara ogiltiga
        eller inte kunna verkställas, tolkas övriga villkor i enlighet med tillämplig lag att så exakt som
        möjligt motsvara det ursprungliga syftet med dessa villkor och till övriga delar kvarstår villkoren
        till fullo i kraft.
      </p>
      <hr />
      <p className="if text disclaimer">
        Dessa villkor upprättades den 8 januari 2021. Audi Försäkring är en del av If Skadeförsäkring AB
        (publ), org.nr 516401-8102, med adress 106 80 Stockholm. If Skadeförsäkring AB (publ) är ett
        försäkringsbolag och står under tillsyn av Finansinspektionen (Finansinspektionen, Box 7821, 103 97
        Stockholm, 08-408 980 00,
        <a href="mailto:finansinspektionen@fi.se">finansinspektionen@fi.se</a>, fi.se). If står även under
        Konsumentverkets tillsyn vad gäller marknadsföring och reklam (Konsumentverket, Box 48, 651 02
        Karlstad, 0771-42 33 00,
        <a href="mailto:konsumentverket@konsumentverket.se">konsumentverket@konsumentverket.se</a>,
        konsumentverket.se).
      </p>
    </div>
  );
}
