import { SagaIterator, EventChannel } from "redux-saga";
import { race, call, put, take, takeLatest, cancelled } from "redux-saga/effects";
import * as Actions from "../../actions";
import { Action } from "redux-actions";
import { Backend } from "../../backend";
import { FileWithTag, ISubmitIntakeActionArguments } from "../../intakeModels";
import { aiEvent } from "../../infrastructure/applicationInsights";
import { createUploadFileChannel, UploadFileEvent } from "./createFileUploadChannel";

function* submitFile(file: FileWithTag, recordId) {
  aiEvent(`Intake: Submit file ${file.tag} start`, {
    tag: file.tag,
    name: file.name,
    size: file.size.toString(),
  });

  let start = new Date();
  let url = Backend.URL.Intake.SubmitFile(recordId, file.tag, null);
  const channel: EventChannel<UploadFileEvent> = yield call(createUploadFileChannel, url, file);

  try {
    while (true) {
      const event: UploadFileEvent = yield take(channel);
      if (event.err) {
        throw event.err;
      } else if (event.success) {
        let end = new Date();
        let took = (end.getTime() - start.getTime()) / 1000;

        yield put(Actions.submitIntakeFilesProgress());
        aiEvent(`Intake: Submit file ${file.tag} finish`, {
          start: JSON.stringify(start),
          end: JSON.stringify(end),
          took: JSON.stringify(took),
          tag: file.tag,
          name: file.name,
          size: file.size.toString(),
        });
      } else if (event.progress) {
        yield put(Actions.submitIntakeFileUploadProgress(event.progress));
      }
    }
  } finally {
    channel.close();
  }
}

function* submitting(payload: ISubmitIntakeActionArguments) {
  try {
    try {
      aiEvent(`Intake: Submit start`);
      let alreadyUploaded = 0;
      for (const file of payload.files) {
        if (payload.currentState.filesUploaded > alreadyUploaded) {
          alreadyUploaded++;

          aiEvent(`Intake: Submit file ${file.tag} skip`, {
            tag: file.tag,
            name: file.name,
            size: file.size.toString(),
          });
          continue;
        }

        yield race({
          task: call(submitFile, file, payload.recordId),
          cancel: take(Actions.submitIntakeCancel),
        });
      }

      aiEvent(`Intake: Submit info`);
      yield put(Actions.submitIntakeInfo());
      yield* Backend.Intake.Submit(payload.data, payload.recordId);

      aiEvent(`Intake: Submit info OK`);
      yield put(Actions.submitIntakeFinished());
    } finally {
      if (yield cancelled()) {
        aiEvent(`Intake: Submit cancel`);
        yield put(Actions.submitIntakeCancel());
      }
    }
  } catch (err) {
    aiEvent(`Intake: Submit failed`);
    yield put(Actions.submitIntakeError(err));
  }
}

function* submitIntake({ payload }: Action<ISubmitIntakeActionArguments>) {
  yield race({
    task: call(submitting, payload),
    cancel: take(Actions.submitIntakeCancel),
  });
}
export default function* submitIntakeSaga(): SagaIterator {
  yield takeLatest(Actions.submitIntake, submitIntake);
}
