import "./phoneInput.scss";
import "./IntlTelInput/intlTelInput.scss";
import React, { useState } from "react";
import IntlTelInput from "./IntlTelInput";
import AllCountries from "./IntlTelInput/components/AllCountries";

export const PhoneInput: React.FC<{
  id;
  name;
  className;
  defaultCountry;
  onChange(number: string, chosenCountryCode: string);
  value;
  tabIndex?;
  disableSuggestions?;
}> = (props) => {
  const countryCodeToDialCode = (countryCode: string) =>
    AllCountries.getCountries().filter((c) => c.iso2 === countryCode.toLowerCase())[0].dialCode;

  const [number, setNumber] = useState(props.value);
  const [chosenCountryCode, setChosenChosenCountryCode] = useState(props.defaultCountry);
  const [dialCode, setDialCode] = useState(countryCodeToDialCode(props.defaultCountry));

  const handleChange = (value: string, dCode: string) => {
    let numberBase: string = value;

    // Remove zero and dialing code from the beginning
    numberBase = numberBase.replace(`+${dCode}`, "").replace(/^0/, "");

    setNumber(numberBase);
    setDialCode(dCode);

    props.onChange(`+${dCode}${numberBase}`.replaceAll("-", ""), chosenCountryCode);
  };

  return (
    <IntlTelInput
      value={number}
      fieldId={props.id}
      fieldName={props.name}
      containerClassName={props.className + " PhoneInput intl-tel-input"}
      inputClassName={props.className + " PhoneInputInput form-control"}
      separateDialCode
      format={false}
      preferredCountries={["SE", "NO", "FI", "DK"]}
      autoComplete={props.disableSuggestions ? "off" : "tel"}
      countrySelectProps={{ role: "listbox", tabIndex: 0 }}
      defaultCountry={props.defaultCountry}
      onPhoneNumberChange={(_: any, number: string) => handleChange(number, dialCode)}
      onSelectFlag={(_, country) => {
        handleChange(number, country.dialCode);
        setChosenChosenCountryCode(country.iso2);
      }}
    />
  );
};
