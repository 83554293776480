import { useEffect } from "react";
import { IUserInfo } from "../backend-models";
import { useSelector } from "react-redux";
import { AppState } from "../models";
import { useHistory } from "react-router-dom";
import Loader from "./shared/loader";
import { useAuth } from "auth/authContext";

const InboxRedirect = () => {
  const history = useHistory();
  const { isAdministrator } = useAuth();
  const userInfo: IUserInfo = useSelector((state: AppState) => state.userInfo);

  const showWorkshop = isAdministrator || userInfo?.workshopObjectList?.length > 0;
  const showGlass = isAdministrator || userInfo?.glassWorkshops?.length > 0;

  useEffect(() => {
    if (userInfo?.isLoading === false) {
      if (!showWorkshop && showGlass) {
        history.replace("/glass-inbox");
      } else {
        history.replace("/inbox");
      }
    }
  });
  return (
    <div className="padded-block">
      <Loader />
    </div>
  );
};

export default InboxRedirect;
