import * as Actions from "../actions";
import reducer from "./reducer";
import { INewInspection } from "../models";

export default reducer<INewInspection>(
  { isLoading: false, saved: false, loadError: undefined },
  (on) => {
    on(Actions.newInspection, (_) => {
      return { isLoading: true, saved: false, loadError: undefined };
    });
    on(Actions.newInspectionSuccess, (_, action) => {
      return { isLoading: false, saved: true, loadError: undefined };
    });
    on(Actions.newInspectionReset, (_, action) => {
      return { isLoading: false, saved: false, loadError: undefined };
    });

    on(Actions.newInspectionError, (_, action) => {
      return { isLoading: false, saved: false, loadError: action.payload.message };
    });
  }
);
