import { SagaIterator } from "redux-saga";
import { Action } from "redux-actions";
import { all, put, throttle } from "redux-saga/effects";
import * as Actions from "actions";
import { processInspectionsResponse } from "models";
import { Backend } from "backend";

function* searchRecords(action: Action<string>) {
  try {
    const result = yield* Backend.Record.SearchRecords(action.payload);
    yield put(Actions.searchRecordsSuccess(processInspectionsResponse(result)));
  } catch (err) {
    yield all([put(Actions.searchRecordsError(err))]);
  }
}
export default function* searchRecordsSaga(): SagaIterator {
  yield throttle(500, Actions.searchRecords, searchRecords);
}
