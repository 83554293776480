import React from "react";
import { FormattedMessage } from "react-intl";

export const ConsentCheckbox: React.FC<{ consent; setConsent; onTermsOfServiceClick }> = ({
  consent,
  setConsent,
  onTermsOfServiceClick,
}) => {
  return (
    <div className="if input-wrapper field-consent-checkbox" data-cy="consentCheckbox">
      <fieldset className="if">
        <div className="if selection-controls [horizontal]">
          <input
            id="consent-checkbox"
            name="consent-checkbox"
            type="checkbox"
            checked={consent}
            onChange={(e) => setConsent(e.target.checked)}
            className="if selection-control"
            autoFocus={true}
          />
          <label htmlFor="consent-checkbox">
            <span>
              <FormattedMessage id="intake.LandingStep.consentCheckbox.text" />
              <a
                href="#"
                className="if link-button"
                onClick={(e) => {
                  e.preventDefault();
                  onTermsOfServiceClick();
                }}
                data-cy="tos-link"
              >
                <FormattedMessage id="intake.LandingStep.consent.link.text" />
              </a>
            </span>
          </label>
        </div>
      </fieldset>
    </div>
  );
};
