import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import * as Actions from "../../actions";
import { Action } from "redux-actions";
import { Backend } from "../../backend";
import { tryUsePreloaded } from "../api";
import { perfMetrics } from "../../utility";

let firstRequest = true;

function* fetchIntakeInfo(action: Action<{ id, customer? }>) {
  try {
    let result = null;

    if (firstRequest) {
      firstRequest = false;
      try {
        let id = action.payload.id.replace(/-/g, "");
        result = yield call(tryUsePreloaded, Backend.URL.Intake.GetIntakeInformation(id));
      } catch {}
    }

    if (!result) result = yield* Backend.Intake.GetIntakeInformation(action.payload.id);
    perfMetrics.intakeInfo = perfMetrics.intakeInfo ?? new Date();
    if(action.payload.customer)
    {
      result.customer = action.payload.customer;
    }
    yield put(Actions.fetchIntakeInfoSuccess(result));
  } catch (err) {
    yield put(Actions.fetchIntakeInfoError(err));
  }
}
export default function* fetchIntakeInfoSaga(): SagaIterator {
  yield takeLatest(Actions.fetchIntakeInfo, fetchIntakeInfo);
}
