import * as React from "react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { IIntakeData, IPhotoSet, IPhotoStepProps } from "../../../intakeModels";
import { FormattedMessage, useIntl } from "react-intl";
import "./photoStep.scss";
import * as Actions from "../../../actions";
import Loader from "../../shared/loader";
import { cachedCreateObjectURL, fileHash } from "../../../intakeUtility";
import { aiEvent } from "../../../infrastructure/applicationInsights";
import { useDispatch } from "react-redux";

interface IPhotoStepState {
  photoset: IPhotoSet;
  showError: boolean;
  errorType: string;
  hashing: boolean;
}

export const PhotoStep: React.FC<IPhotoStepProps> = (props) => {
  const intl = useIntl();
  let fileInput = useRef<HTMLInputElement>(null);
  let [state, setState] = useState<IPhotoStepState>({
    photoset: props.wizard.data.photos[props.photokey] || {
      files: [],
      key: props.photokey,
    },
    showError: false,
    errorType: null,
    hashing: false,
  });
  const [imageLoading, setImageLoading] = useState(true);
  const [deleteConfirm, setdeleteConfirm] = useState(undefined as File);
  const refConfirmButton = useRef<HTMLButtonElement>(undefined);
  const refCancelButton = useRef<HTMLButtonElement>(undefined);
  let dispatch = useDispatch();

  const maxFileSize = 32 * 1024 * 1024;
  const allowedMimeTypes = ["image/jpeg", "image/png"];

  let files = state.photoset.files ?? [];

  function photoIsGloballyUnique(newPhoto: File, ignoreKey = null) {
    return Object.entries(props.wizard.data.photos).every(([key, set]) => {
      if (!set.files.length) return true;

      if (ignoreKey && ignoreKey === key) return true;
      return !filesEqual(set.files[0], newPhoto);
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const valid = {
    size: files.every((f) => f.size < maxFileSize),
    type: files.every((f) => allowedMimeTypes.includes(f.type)),
    unique: !files.length || files.every((f) => photoIsGloballyUnique(f, state.photoset.key)),
  };

  useEffect(() => {
    const havePhotos = Boolean(state.photoset.files.length);
    const isOptional = props.photokey.includes("extra");
    const isValid = (havePhotos || isOptional) && valid.size && valid.type && valid.unique;

    function save(data: IIntakeData) {
      let copy = { ...data };
      copy.photos[props.photokey] = state.photoset;
      return copy;
    }
    if (props.wizard.nextClicked) {
      if (isValid) {
        props.goStep(1, save);
      } else {
        setState((state) => ({ ...state, showError: true }));
        props.cancelNextStep();
      }
    }
    if (props.wizard.prevClicked) props.goStep(-1, save);
  }, [valid, props, state.photoset]);

  useEffect(() => {
    //if modal isn't focused, focus it
    if (deleteConfirm && refConfirmButton.current && refCancelButton.current) {
      if (
        document.activeElement !== refConfirmButton.current &&
        document.activeElement !== refCancelButton.current
      ) {
        refCancelButton.current.focus();
      }
    }
    //disable user tabbing out of the modal
    let listener = (e: KeyboardEvent) => {
      if (refConfirmButton.current && refCancelButton.current && (e.code === "Tab" || e.keyCode === 9)) {
        if (e.target === refConfirmButton.current && !e.shiftKey) {
          e.preventDefault();
          refCancelButton.current.focus();
        } else if (e.target === refCancelButton.current && e.shiftKey) {
          e.preventDefault();
          refConfirmButton.current.focus();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  });

  function filesEqual(a: File, b: File) {
    if ("hash" in a && "hash" in b) {
      return (a as any).hash === (b as any).hash;
    }
    return a.name === b.name && a.size === b.size;
  }

  function filesChosen(e: ChangeEvent<HTMLInputElement>) {
    setState({ ...state, showError: false });
    let userFiles = e.target.files;
    let newState = { ...state };
    Array.from(userFiles).forEach((f) => {
      if (!allowedMimeTypes.includes(f.type)) {
        newState.showError = true;
      }
    });

    if (userFiles && userFiles[0]) {
      let copy = [...state.photoset.files];
      setState({ ...newState, hashing: true });
      fileHash(userFiles[0]).then((_) => {
        if (!photoIsGloballyUnique(userFiles[0], state.photoset.key)) {
          newState.showError = true;
          newState.errorType = "duplicate";
        } else {
          //check for duplicates
          Array.from(userFiles).forEach((f) => {
            if (copy.findIndex((x) => filesEqual(x, f)) === -1) {
              copy.push(f);
            }
          });
        }

        setState({
          ...newState,
          photoset: { ...state.photoset, files: copy },
        });
        if (fileInput.current) {
          fileInput.current.value = "";
        }
      });
    }
  }

  function removeFile(file: File, e: React.MouseEvent<HTMLButtonElement>) {
    aiEvent(`Intake: ${props.name} ${props.photokey} delete photo`);
    e.preventDefault();
    setdeleteConfirm(undefined);
    let copy = [...files];
    copy.splice(files.indexOf(file), 1);
    setState({
      ...state,
      photoset: {
        ...state.photoset,
        files: copy,
      },
      showError: false,
    });
    dispatch(Actions.submitIntakeFilesReset());
  }

  const bytesToMegabytes = (b) => (Math.round((b / 1024 / 1024) * 10) / 10).toFixed(1);

  const getValidationMessage = (showError: boolean, messageId: string, valuesData?: any) => {
    if (!state.showError || !showError) {
      return null;
    }

    return (
      <div
        data-cy="photoValidationError"
        className="if input-error icon ui warning validation-error-container"
      >
        <FormattedMessage id={messageId} values={valuesData} />
      </div>
    );
  };

  const hasImage = Boolean(files.length);
  const maxPhotos = props.photokey.includes("extra") ? 2 : 1;

  const renderImageFiles = files.map((file, index) => (
    <div className="image" key={file.name}>
      <div className="image-container">
        <img
          data-cy="photoPreview"
          data-cy-extra={`photoPreview-${index}`}
          src={cachedCreateObjectURL(file)}
          className="preview"
          alt=""
        />
      </div>
      <div className="image-subtitle ">
        {state.hashing && <Loader />}
        {hasImage && (
          <>
            <span className="if file-name ui icon symbol image">{file.name}</span>
            <span className="if file-feedback">{bytesToMegabytes(file.size)} MB</span>
            <button
              type="button"
              className="if file-action delete icon ui trashcan blue"
              data-cy="removePhoto"
              onClick={() => setdeleteConfirm(file)}
              tabIndex={deleteConfirm ? -1 : 0}
            />
          </>
        )}
      </div>
    </div>
  ));

  return (
    <div className="photo-upload">
      <h1 className="if header-text-with-back-button" data-cy={"photoHeader-" + props.photokey}>
        <FormattedMessage id={"intake.PhotoStep.header." + props.photokey} />
      </h1>
      <div className="if input-wrapper photo-upload-container">
        <p className="if basic-text">
          <FormattedMessage id={"intake.PhotoStep.text." + props.photokey} />
        </p>
        <div className="card-container">
          <div className="image">
            {!props.photokey.includes("extra") && !hasImage && (
              <>
                {imageLoading && (
                  <div className="photo-step-image-loader">
                    <Loader />
                  </div>
                )}
                <div className="image-container" style={{ display: "visible" }}>
                  <img
                    className="preview placeholder"
                    src={`${props.wizard.imagePath}/${props.photokey}.jpg`}
                    alt={intl.formatMessage({ id: "intake.alt.imageIllustration." + props.photokey })}
                    onLoad={() => setImageLoading(false)}
                    data-cy="placeholderPhoto"
                  />
                </div>
              </>
            )}
          </div>
          {renderImageFiles}
        </div>

        {files.length < maxPhotos && !state.hashing && (
          <div className="if input-wrapper">
            <input
              data-cy="photoInput"
              multiple={false}
              data-size="large"
              type="file"
              className="if"
              accept={allowedMimeTypes.join(",")}
              id="photo-upload"
              name="photo-upload"
              onChange={filesChosen}
              ref={fileInput}
              autoFocus={true}
            />
            <label role="button" htmlFor="photo-upload" className="if button primary take-photo-btn">
              <span className="if icon ui camera white" />
              <FormattedMessage id="intake.PhotoStep.add-button" />
            </label>
          </div>
        )}
      </div>
      {getValidationMessage(!files.length && !state.errorType, "intake.PhotoStep.validationMessage")}
      {getValidationMessage(
        !files.length && state.errorType === "duplicate",
        "intake.PhotoStep.validationMessage.duplicatePhoto"
      )}
      {getValidationMessage(!valid.size || state.errorType === "over_max_size", "intake.PhotoStep.validationMessage.tooLargePhoto", {
        fileSize: `${bytesToMegabytes(maxFileSize).replace(".0", "")} MB`,
      })}
      {getValidationMessage(!valid.type, "intake.PhotoStep.validationMessage.wrongFileType")}
      {getValidationMessage(!valid.unique, "intake.PhotoStep.validationMessage.duplicatePhoto")}
      {deleteConfirm && (
        <div className="delete-modal">
          <div className="window">
            <h3>
              <FormattedMessage id="intake.PhotoStep.deleteQuestion" />
            </h3>
            <button
              data-cy="cancelButton"
              className="if button secondary"
              onClick={() => setdeleteConfirm(undefined)}
              ref={refCancelButton}
            >
              <FormattedMessage id="intake.PhotoStep.cancelButton" />
            </button>
            <button
              data-cy="confirmButton"
              className="if button primary"
              onClick={(e) => removeFile(deleteConfirm, e)}
              ref={refConfirmButton}
            >
              <FormattedMessage id="intake.PhotoStep.deleteButton" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
