// noinspection DuplicatedCode

import { spawn } from "redux-saga/effects";

import addCommentSaga, { addGlassCommentSaga } from "./addCommentSaga";
import addRecordSaga from "./addRecordSaga";
import fetchIntakeInfoSaga from "./intake/fetchIntakeInfoSaga";
import fetchPhotosSaga from "./fetchPhotosSaga";
import fetchRecordSaga from "./fetchRecordSaga";
import fetchRecordsSaga from "./fetchRecordsSaga";
import fetchWorkshopsSaga from "./fetchWorkshopsSaga";
import newInspectionSaga from "./newInspectionSaga";
import notificationSaga from "./notificationSaga";
import searchRecordsSaga from "./searchRecordsSaga";
import updatePhotoSaga from "./updatePhotoSaga";
import updateRecordSaga from "./updateRecordSaga";
import submitIntakeSaga from "./intake/submitIntakeSaga";
import sendIntakeLinkReminderSaga from "./sendIntakeLinkReminderSaga";
import fetchUserInfoSaga from "./fetchUserInfoSaga";
import purgeRecordSaga from "../admin/purgeRecordSaga";
import purgeTestRecordsSaga from "../admin/purgeTestRecordsSaga";
import fetchSmsTemplateSaga from "../admin/fetchSmsTemplateSaga";
import submitIntakeFeedbackSaga from "./intake/submitIntakeFeedbackSaga";
import changeRecordStatusSaga from "./changeRecordStatusSaga";
import fetchAllRecordsSaga from "../admin/fetchAllRecordsSaga";
import { addNotificationSaga, deleteNotificationSaga } from "../admin/modifyNotificationsSaga";
import { workshopFeedbackSendSaga } from "./workshopFeedbackSendSaga";
import fetchFeedbackSaga from "../admin/fetchFeedbackSaga";
import { recordSendToCabasSaga } from "./recordSendToCabasSaga";
import fetchGlassInspectionsSaga from "./fetchGlassInspectionsSaga";
import fetchGlassInspectionSaga from "./fetchGlassInspectionSaga";
import { workshopToggleServiceSaga } from "./workshopToggleServiceSaga";
import { sendNotificationEmailsSaga } from "../admin/sendNotificationEmailsSaga";
import { changeGlassInspectionStatusSaga } from "./changeGlassInspectionStatusSaga";

export const rootSaga = function* root() {
  yield spawn(fetchWorkshopsSaga);
  yield spawn(fetchRecordSaga);
  yield spawn(fetchRecordsSaga);

  yield spawn(fetchGlassInspectionsSaga);
  yield spawn(fetchGlassInspectionSaga);
  yield spawn(changeGlassInspectionStatusSaga);
  yield spawn(addGlassCommentSaga);

  yield spawn(fetchPhotosSaga);
  yield spawn(updatePhotoSaga);
  yield spawn(addRecordSaga);
  yield spawn(updateRecordSaga);
  yield spawn(addCommentSaga);
  yield spawn(searchRecordsSaga);
  yield spawn(notificationSaga);
  yield spawn(changeRecordStatusSaga);
  yield spawn(newInspectionSaga);
  yield spawn(fetchIntakeInfoSaga);
  yield spawn(submitIntakeSaga);
  yield spawn(submitIntakeFeedbackSaga);
  yield spawn(sendIntakeLinkReminderSaga);
  yield spawn(fetchUserInfoSaga);

  yield spawn(purgeRecordSaga);
  yield spawn(purgeTestRecordsSaga);
  yield spawn(fetchSmsTemplateSaga);
  yield spawn(fetchAllRecordsSaga);
  yield spawn(addNotificationSaga);
  yield spawn(deleteNotificationSaga);
  yield spawn(workshopFeedbackSendSaga);
  yield spawn(fetchFeedbackSaga);
  yield spawn(sendNotificationEmailsSaga);

  yield spawn(recordSendToCabasSaga);
  yield spawn(workshopToggleServiceSaga);
};
