import { createAction } from "redux-actions";
import { IError } from "../models";
import { IAdminSmsTemplateRequest, IFeedback, IInfoNotification } from "../backend-models";

export const fetchAllRecords = createAction("FETCH_ALL_RECORDS");

export const purgeRecord = createAction<string>("PURGE_RECORD");
export const purgeRecordSuccess = createAction("PURGE_RECORD_SUCCESS");
export const purgeRecordError = createAction<IError>("PURGE_RECORD_ERROR");

export const addNotification = createAction<{ notification: IInfoNotification; reset() }>(
  "ADD_INFO_NOTIFICATION"
);
export const deleteNotification = createAction<IInfoNotification>("DELETE_NOTIFICATION");
export const modifyNotificationsSuccess = createAction<IInfoNotification[]>("MODIFY_NOTIFICATIONS_SUCCESS");
export const modifyNotificationsError = createAction<IError>("MODIFY_NOTIFICATIONS_ERROR");

export const purgeTestRecords = createAction("PURGE_TEST_RECORDS");
export const purgeTestRecordsSuccess = createAction("PURGE_TEST_RECORDS_SUCCESS");
export const purgeTestRecordsError = createAction<IError>("PURGE_TEST_RECORDS_ERROR");

export const fetchSmsTemplate = createAction<IAdminSmsTemplateRequest>("FETCH_SMS_TEMPLATE");
export const fetchSmsTemplateSuccess = createAction<string>("FETCH_SMS_TEMPLATE_SUCCESS");
export const fetchSmsTemplateError = createAction<IError>("FETCH_SMS_TEMPLATE_ERROR");

export const fetchFeedback = createAction("FETCH_FEEDBACK");
export const fetchFeedbackSuccess = createAction<IFeedback[]>("FETCH_FEEDBACK_SUCCESS");
export const fetchFeedbackError = createAction<IError>("FETCH_FEEDBACK_ERROR");

export const sendNotificationEmails = createAction("SEND_NOTIFICATION_EMAILS");
export const sendNotificationEmailsSuccess = createAction("SEND_NOTIFICATION_EMAILS_SUCCESS");
export const sendNotificationEmailsError = createAction<IError>("SEND_NOTIFICATION_EMAILS_ERROR");
