import React, { useState } from "react";
import "./workshopFeedback.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../actions";
import { AppState, IInspectionWithWorkshop } from "../models";
import { store } from "../store";

export const WorkshopFeedback: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const workshopFeedback = useSelector((app: AppState) => app.workshopFeedback);

  const [text, setText] = useState("");
  const sendEnabled = text.trim().length > 3;

  const infoLines = intl.formatMessage({ id: "feedback.info" }).split("\\n");

  function close() {
    dispatch(Actions.workshopFeedbackClose());
  }

  function send() {
    //deep copy
    const appState = JSON.parse(JSON.stringify(store.getState())) as AppState;
    (window as any).store = store;

    const url = document.URL;
    const timeUTC = new Date().toJSON();
    const timeLocal = new Date().toLocaleTimeString();
    const userAgent = window.navigator.userAgent;

    delete appState.admin;
    //simplify records, since azure table storage has size limits
    appState.records.records = appState.records.records.map((r) => {
      return {
        recordId: r.recordId,
        status: r.status,
        decision: r.decision,
      } as IInspectionWithWorkshop;
    });

    dispatch(
      Actions.workshopFeedbackSend({
        text,
        metadata: {
          url,
          timeUTC,
          timeLocal,
          userAgent,
          locale: intl.locale,
          width: window.screen.width,
          height: window.screen.height,
        },
        appState,
      })
    );
    setText("");
    close();
  }

  if (!workshopFeedback.isOpen) return null;
  return (
    <div className="workshop-feedback">
      <div className="window" data-cy="workshopFeedbackWindow">
        <div className="close-button" onClick={close}>
          <div className="if icon ui close" />
        </div>
        <h3>
          <FormattedMessage id="feedback.title" />
        </h3>
        {infoLines.map((text, i) => (
          <p key={i}>{text}</p>
        ))}
        <div className="if input-wrapper">
          <textarea className="if textarea" value={text} onChange={(e) => setText(e.target.value)} />
          <label className="if">
            <FormattedMessage id="feedback.input" />
          </label>
        </div>
        <div className="buttons">
          <button className="if button secondary" onClick={close}>
            <FormattedMessage id="feedback.cancel" />
          </button>
          <button className="if button primary" onClick={send} disabled={!sendEnabled} data-cy="send">
            <FormattedMessage id="feedback.send" />
          </button>
        </div>
      </div>
    </div>
  );
};
