import { IHasLoadingAndError } from "./models";

import {
  Brand,
  ICustomer,
  IInspectionFeedback,
  IInspectionIntakeRequest,
  IIntakeInformation,
  WorkshopCountryCode,
} from "./backend-models";

export type DataSetter = (previousData: IIntakeData) => IIntakeData;

export type EditStep = (stepName: string, photoKey?: string) => void;

export interface IWizardStepProps {
  cancelNextStep();
  // save progress and proceed/go back
  goStep: (step: number, mutateData?: DataSetter) => void;
  editStep?: EditStep;
  wizard: IWizard;
  name: string;
  brand?: Brand;
}

export interface IPhotoStepProps extends IWizardStepProps {
  photokey: PhotoKey;
}

export interface IIntakeInformationState extends IIntakeInformation {
  loadStatus?: number;
}

export type PhotoKey =
  | "back"
  | "distance"
  | "closeup"
  | "diagonal-front"
  | "diagonal-back"
  | "anon-back"
  | "anon-distance"
  | "anon-closeup"
  | "anon-diagonal-front"
  | "anon-diagonal-back"
  | "extra"
  | "anon-extra";

export type ExtraPhotoKey =
  | "extra-1"
  | "anon-extra-1"
  | "extra-2"
  | "anon-extra-2";

export interface IPhotoSet {
  key: string;
  files: File[];
}

export interface IPhotoSetMeta {
  key: string;
  files: {
    name: string;
    lastModified: number;
    size: number;
    type: string;
  }[];
}

export interface IIntakeData {
  customer: ICustomer;
  mileage: number;
  claimDate: Date;
  description: string;
  photos: { [key in PhotoKey]?: IPhotoSet };
  termsOfServiceAccepted: boolean;
}

export interface FileWithTag extends File {
  tag: string;
}

export interface IWizard {
  nextClicked: boolean;
  prevClicked: boolean;
  stepIndex: number;
  data: IIntakeData;
  photoStepKeys: PhotoKey[];
  locale: string;
  brand: Brand;
  isEditing: boolean;
  imagePath: string;
  setLocale: (value: string) => void;
  workshopCountryCode: WorkshopCountryCode;
}

export enum IntakeSendingState {
  NotStarted,
  SendingFiles,
  SendingInfo,
  Finished,
}

export interface IIntakeSubmission extends IHasLoadingAndError {
  data: IInspectionIntakeRequest;
  sendingState: IntakeSendingState;
  filesUploaded: number;
  filesTotal: number;
  uploadProgres: number;
  feedback: IInspectionFeedback;
}

export interface ISubmitIntakeActionArguments {
  recordId: string;
  data: IInspectionIntakeRequest;
  files: FileWithTag[];
  currentState: IIntakeSubmission;
}
