import * as React from "react";
import Loader from "./loader";
import { IHasLoadingAndError, IInspectionStateType } from "../../models";

type IProps = {
  target: IHasLoadingAndError | boolean;
  loading?: boolean;
  error?: string;
  test?: any[];
};
export const LoadableRecords: React.FC<{ records: IInspectionStateType }> = ({ records, children }) => {
  let test = false;
  if ("records" in records) test = records.records && !!records.records.length;
  if ("glassInspections" in records) test = records.glassInspections && !!records.glassInspections.length;
  return (
    <Loadable target={records} test={[records && test && !records.isLoading]}>
      {children}
    </Loadable>
  );
};
const Loadable: React.FC<IProps> = (props) => {
  let loading = true;
  let error = "";
  let test = true;
  if (props.target !== true) {
    if (props.target) {
      loading = props.target.isLoading;
      error = props.target.loadError;
    }
  }
  if (props.test !== undefined) {
    for (let i = 0; i < props.test.length; i++) {
      if (!props.test[i]) {
        test = false;
      }
    }
  }
  return (
    <>
      {loading ? <Loader /> : <></>}
      {error ? <span className="if notification ">{error}</span> : <></>}
      {test && props.children}
    </>
  );
};
export default Loadable;
