import * as Actions from "../actions";
import reducer from "./reducer";
import { IIntakeInformation } from "../backend-models";

export default reducer<IIntakeInformation>({} as IIntakeInformation, (on) => {
  on(Actions.fetchIntakeInfo, (_) => {
    return {
      ..._,
      isLoading: true,
    };
  });

  on(Actions.fetchIntakeInfoSuccess, (_, action) => {
    return {
      ..._,
      ...action.payload,
      isLoading: false,
    };
  });

  on(Actions.fetchIntakeInfoError, (_, action) => {
    return {
      ..._,
      isLoading: false,
      loadError: `Failed to load intake information: ${action.payload.message}`,
      loadStatus: action.payload.status,
    };
  });
});
