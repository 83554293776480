import "./statsPage.scss";
import * as React from "react";
import { withAITracking, reactPlugin } from "../../infrastructure/applicationInsights";
import { useDispatch, useSelector } from "react-redux";
import { AppState, IRecords } from "../../models";
import { IRecord, Status, WorkshopDecision } from "../../backend-models";
import { useHistory } from "react-router-dom";
import { dispatchIfOlderThan, formatWorkshopName } from "../../utility";
import { fetchRecords } from "../../actions";
import { useAuth } from "auth/authContext";

const StatsRow: React.FC<{ name; value? }> = (props) => {
  let renderValue = props.value;
  let percent = "";
  if (typeof renderValue == "object" && "percent" in renderValue) {
    renderValue = props.value.value;
    percent = props.value.percent;
  }
  return (
    <>
      <span className="header">{props.name}</span>
      <span className="value">
        {renderValue}
        {props.children}
      </span>
      <span className="percent">{percent}</span>
    </>
  );
};
const StatsTable: React.FC<{ data }> = (props) => {
  return (
    <span className="stats-table">
      {Object.keys(props.data).map((key) => (
        <StatsRow key={key} name={key} value={props.data[key]} />
      ))}
    </span>
  );
};
const StatsPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAdministrator } = useAuth();
  if (!isAdministrator) history.push("/");

  const _recordsState: IRecords = useSelector((state: AppState) => state.records);
  const lastLoaded = _recordsState?.lastLoaded;

  React.useEffect(() => {
    dispatchIfOlderThan(dispatch, fetchRecords, lastLoaded);
  }, [dispatch, lastLoaded]);

  const testWorkshops = "PHIN,VOLVIA,NO,FI,DK".split(",").map((w) => w + "TEST");
  const allRecords = _recordsState.records;
  const records = allRecords.filter((record) => testWorkshops.indexOf(record.workshopId) === -1);

  let byWorkshop = {};

  records.forEach((record) => {
    let workshopList = byWorkshop[record.workshopId] ?? [];
    workshopList.push(record);
    byWorkshop[record.workshopId] = workshopList;
  });

  function status(status: Status, all: IRecord[]) {
    return all.filter((r) => r.status === status);
  }
  function percent(part: IRecord[], all: IRecord[]) {
    let calc = part.length / all.length;
    if (isNaN(calc)) calc = 0;
    return {
      value: part.length,
      percent: <>{(calc * 100).toFixed(0)}%</>,
    };
  }
  function renderWorkshop(records, workshopId, workshopFullName) {
    const inProgress = status(Status.InProgress, records);
    const newRecords = status(Status.New, records);
    const total = records.length;
    return (
      <React.Fragment key={workshopId}>
        <h4>{workshopFullName}</h4>
        <StatsTable
          data={{
            Total: total,
            Inbox: percent(status(Status.New, records), records),
            "    Old": percent(
              newRecords.filter((r) => r.businessDaysOld > 4),
              newRecords
            ),
            "On Hold": percent(status(Status.OnHold, records), records),
            "In Progress": percent(status(Status.InProgress, records), records),
            "    Repair": percent(
              inProgress.filter((r) => r.decision === WorkshopDecision.Repair),
              inProgress
            ),
            "    Pre-inspection": percent(
              inProgress.filter((r) => r.decision === WorkshopDecision.PreInspection),
              inProgress
            ),
            Open: percent(status(Status.Open, records), records),
            Archive: percent(status(Status.Closed, records), records),
          }}
        />
      </React.Fragment>
    );
  }
  return (
    <div className="padded-block stats-page">
      <h1>Statistics</h1>
      {renderWorkshop(records, "all", "All")}
      {Object.keys(byWorkshop).map((workshopId) => {
        let records = byWorkshop[workshopId];
        return renderWorkshop(records, workshopId, formatWorkshopName(records[0]));
      })}
    </div>
  );
};

export default withAITracking(reactPlugin, StatsPage);
