import "./inputField.scss";

import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IIntakeFieldProps } from "./inputField";
import { PhoneInput } from "../../shared/phoneInput";

interface IPhoneInputProps extends IIntakeFieldProps {
  workshopCountryCode: string;
}

const PhoneInputField: React.FC<IPhoneInputProps> = (props) => {
  const intl = useIntl();
  let name = props.name;
  let classes = props.customClasses ?? "if input-field normal-input is-required ";
  if (!props.isValid) classes += " is-invalid";

  return (
    <div className="if input-wrapper">
      <PhoneInput
        onChange={props.onChange}
        value={props.value ?? ""}
        id={`input-${name}`}
        name={name}
        className={classes}
        {...props.inputProps}
        defaultCountry={props.workshopCountryCode}
      />
      <label className="if" htmlFor={`input-${name}`}>
        {intl.formatMessage({
          id: props.intlPrefix + name + ".header",
        })}
      </label>
      <span className="if input-error validation-message" aria-live="polite">
        <FormattedMessage
          id={props.intlPrefix + name + ".validationMessage"}
          values={{ fieldLength: props.value?.length - props.fieldMaxLength }}
        />
      </span>
    </div>
  );
};
export default PhoneInputField;
