import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "../admin/actions";
import { Backend } from "../backend";
import { Action } from "redux-actions";
import { IAdminSmsTemplateRequest } from "../backend-models";

function* fetchSmsTemplate(action: Action<IAdminSmsTemplateRequest>) {
  try {
    const result = yield* Backend.Admin.SmsTemplate(action.payload);
    yield put(Actions.fetchSmsTemplateSuccess(result));
  } catch (err) {
    yield put(Actions.fetchSmsTemplateError(err));
  }
}
export default function* fetchSmsTemplateSaga(): SagaIterator {
  yield takeLatest(Actions.fetchSmsTemplate, fetchSmsTemplate);
}
