import { SagaIterator } from "redux-saga";
import { put, throttle } from "redux-saga/effects";
import * as Actions from "../actions";
import { showError } from "./common";
import { Action } from "redux-actions";
import { Backend } from "../backend";
import { IInspection, IsRecord } from "../models";

function* fetchPhotos({ payload: record }: Action<IInspection>) {
  try {
    const photos = yield* Backend.Photo.List(record.recordId + (IsRecord(record) ? "" : "?glass=true"));
    yield put(Actions.fetchPhotosSuccess(photos));
  } catch (err) {
    yield showError(err);
    yield put(Actions.fetchPhotosError({ ...err, recordId: record.recordId }));
  }
}
export default function* fetchPhotosSaga(): SagaIterator {
  yield throttle(1000, Actions.fetchPhotos, fetchPhotos);
}
