import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import * as Actions from "../admin/actions";
import { Backend } from "../backend";
import { Action } from "redux-actions";
import { IInfoNotification } from "../backend-models";

export function* addNotificationSaga(): SagaIterator {
  yield takeLatest(Actions.addNotification, function* (action: Action<{notification: IInfoNotification, reset()}>) {
    try {
      const result = yield* Backend.Admin.NotificationAdd(action.payload.notification);
      yield put(Actions.modifyNotificationsSuccess(result));
      yield call(action.payload.reset);
    } catch (err) {
      yield put(Actions.modifyNotificationsError(err));
    }
  });
}
export function* deleteNotificationSaga(): SagaIterator {
  yield takeLatest(Actions.deleteNotification, function* (action: Action<IInfoNotification>) {
    try {
      const result = yield* Backend.Admin.NotificationDelete(action.payload);
      yield put(Actions.modifyNotificationsSuccess(result));
    } catch (err) {
      yield put(Actions.modifyNotificationsError(err));
    }
  });
}
