import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "../actions";
import { showError } from "./common";
import { Action } from "redux-actions";
import { Backend } from "../backend";
import { IDocument } from "../backend-models";

function* updatePhoto(params: Action<IDocument>) {
  try {
    const result = yield* Backend.Photo.Update(params.payload);
    yield put(Actions.updatePhotoSuccess(result));
  } catch (err) {
    yield showError(err);
  }
}
export default function* updatePhotoSaga(): SagaIterator {
  yield takeLatest(Actions.updatePhoto, updatePhoto);
}
