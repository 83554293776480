import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "../actions";
import { NotificationType, processInspectionResponse } from "../models";
import { showError } from "./common";
import { Backend } from "../backend";
import { IRecord } from "../backend-models";
import { IntlShape } from "react-intl";
import { aiEvent } from "../infrastructure/applicationInsights";

function* sendIntakeLinkReminder(action: Action<{ record: IRecord; intl: IntlShape }>) {
  let record = action.payload.record;
  try {
    let newRecord = yield* Backend.Record.SendIntakeSmsReminder(record.recordId);
    aiEvent("Send reminder", {recordId: record.recordId});
    yield put(
      Actions.showNotification({
        message: action.payload.intl.formatMessage(
          { id: "action.sendIntakeLinkReminder.success" },
          { number: record.customer.phoneNumber }
        ),
        type: NotificationType.Success,
        time: new Date(),
      })
    );

    yield put(Actions.sendIntakeLinkReminderSuccess(processInspectionResponse(newRecord)));
  } catch (err) {
    yield showError(err);
    yield put(Actions.sendIntakeLinkReminderError({ ...err, recordId: record.recordId }));
  }
}

export default function* sendIntakeLinkReminderSaga(): SagaIterator {
  yield takeLatest(Actions.sendIntakeLinkReminder, sendIntakeLinkReminder);
}
