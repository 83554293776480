import * as Actions from "actions";
import * as AdminActions from "admin/actions";
import reducer from "../reducer";
import { IRecords } from "models";

export default reducer<IRecords>({ isLoading: false, records: [], loadError: undefined, workshopFilter: {} }, (on) => {
  on(Actions.fetchRecords, (_) => {
    return { ..._, isLoading: true };
  });
  on(AdminActions.fetchAllRecords, (_) => {
    return { ..._, isLoading: true };
  });

  on(Actions.fetchRecordsSuccess, (_, action) => {
    let newRecords = {
      ..._,
      records: action.payload.inspections,
      isLoading: false,
      loadError: undefined,
      everLoaded: true,
      lastLoaded: new Date(),
      workshopFilter: _.workshopFilter,
    };
    console.debug("fetch body, oldest inspection: " + action.payload.inspections.map((x) => x.dateCreated).sort()[0]);
    _.records?.forEach((record) => {
      let found = newRecords.records?.find((r) => r.recordId === record.recordId);
      if (!found) {
        newRecords.records?.push(record);
      }
    });
    return newRecords;
  });

  on(Actions.fetchRecordsError, (_, action) => {
    return {
      ..._,
      isLoading: false,
      loadError: `Failed to load records: ${action.payload.message}`,
    };
  });
});
