import React, { useEffect } from "react";
import "./workshopFeedback.scss";
import { AppState, NotificationType } from "../../models";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../actions";
import * as WorkshopActions from "../../actions";

export const WorkshopFeedback: React.FC = () => {
  const dispatch = useDispatch();
  const adminState = useSelector((state: AppState) => state.admin);

  useEffect(() => {
    dispatch(Actions.fetchFeedback());
  }, [dispatch]);

  let feedbacks = adminState.workshopFeedback.feedback.sort((a, b) => b.timestamp.localeCompare(a.timestamp));

  return (
    <div className="padded-block admin-workshop-feedback">
      <h1>Feedback ({feedbacks.length})</h1>

      {feedbacks.map((feedback, i) => (
        <div className="feedback" key={i}>
          <div className="info">
            <a
              href="#"
              onClick={(e) => {
                dispatch(
                  WorkshopActions.showNotification({
                    message: "Feedback app state logged to console",
                    time: new Date(),
                    type: NotificationType.Success,
                  })
                );
                e.preventDefault();
              }}
            >
              <span className="if icon ui preview" />
            </a>
            &ensp; {feedback.metadata.timeUTC.substr(0, 19)}
            &ensp; {feedback.metadata.locale}
            &ensp; {feedback.userEmail}
            <br />
            <a href={feedback.metadata.url}>{feedback.metadata.url}</a>
          </div>
          <div className="text">{feedback.text}</div>
        </div>
      ))}
    </div>
  );
};
