import * as React from "react";
import { withAITracking, reactPlugin } from "../infrastructure/applicationInsights";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../models";
import ViewHeader from "./shared/viewHeader";
import * as Actions from "../actions";
import { DateRangePicker } from "./intake/fields/DateRangePicker/dateRangePicker";
import { useState } from "react";
import { formatWorkshopName } from "../utility";

const SettingsPage: React.FC = () => {
  return (
    <div className="padded-block">
      <ViewHeader>
        <FormattedMessage id="page.settings" />
      </ViewHeader>

      {window.ClientEnvironment.flags.disablingOfPhotoInspectionService && <CustomerServiceToggle />}
    </div>
  );
};

function CustomerServiceToggle(): JSX.Element {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(Actions.fetchWorkshops());
  }, [dispatch]);

  const [filter, setFilter] = useState("");

  const workshopsState = useSelector((state: AppState) => state.workshops);

  let workshops = workshopsState?.workshops ?? [];

  // finnish workshops can't be disabled
  workshops = workshops.filter((w) => !w.id.startsWith("FI"));
  // only show workshops that have the service defined
  workshops = workshops.filter((w) => w.hasCustomerServiceDefined);

  const totalCount = workshops.length;

  workshops = workshops.filter((w) => (w.name + w.city + w.id).toLowerCase().includes(filter.toLowerCase()));

  return (
    <div className="if table-container">
      <FormattedMessage id="field.summerWorkshopInstructions" />
      <br />
      <br />
      <div className="if input-wrapper">
        <input
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="if input-field"
          placeholder="Search"
        />
      </div>
      <table className="if table record-table" data-cy="tableView">
        <thead className="if">
          <tr>
            <th className="if">
              <FormattedMessage id="field.workshopName" />
            </th>
            <th className="if">
              <FormattedMessage id="field.availability" />
            </th>
          </tr>
        </thead>
        <tbody className="if">
          {workshops.map((workshop, i) => (
            <tr key={workshop.id + i.toString()}>
              <td>
                <span title={workshop.id}>
                  {formatWorkshopName({
                    workshopName: workshop.name,
                    workshopCity: workshop.city,
                  })}
                </span>
              </td>
              <td>
                {workshop.hasCustomerServiceDefined ? (
                  <DateRangePicker
                    startDate={workshop.serviceUnavailabilityStartDate}
                    endDate={workshop.serviceUnavailabilityEndDate}
                    workshopId={workshop.id}
                    isInputDisabled={!workshop.hasCustomerServiceDefined}
                  />
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}

          <tr>
            <td>
              {workshops.length} / {totalCount}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default withAITracking(reactPlugin, SettingsPage);
