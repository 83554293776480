import React from "react";
import MarkdownView from "react-showdown";

export function TermsOfServiceSkoda() {
  // language=Markdown
  let tosMarkdown = `## Användarvillkor för ŠKODA Försäkrings fotobesiktningstjänst

Vi på ŠKODA Försäkring är glada över att du har valt att använda ŠKODA Försäkrings fotobesiktningstjänst (”Tjänsten”). För att använda Tjänsten behöver du först godkänna dessa användarvillkor. Användarvillkoren utgör ett avtal (”Avtalet”) mellan dig och ŠKODA Försäkring, org.nr 516401-8102 (”Tjänsteleverantören”).


1. Tjänsten

  1.1	Genom Tjänsten får du som råkat ut för en skada på ditt fordon möjlighet att överföra fotografier på fordonet till den verkstad som du använder dig av förutsatt att verkstaden är ansluten till Tjänsten och accepterat att du överför fotografier genom Tjänsten.

  1.2	Tjänsten levereras ”i befintligt skick” och i det utförande som den har vid respektive tidpunkt.

2. Hantering av personuppgifter

 2.1 Genom att använda Tjänsten får Tjänsteleverantören tillgång till de uppgifter som du anger eller laddar upp i Tjänsten, t.ex. namn, telefonnummer och registreringsnummer på det fordon som ska bli föremål för fotobesiktning. Tjänsteleverantören överför dessa uppgifter till aktuell verkstad på uppdrag av dig och sparar uppgifterna så länge som det är nödvändigt för att dokumentera uppdragets utförande. Om Tjänsten används inom ramen för ett skadeärende hos Tjänsteleverantören sparas uppgifterna i skadeärendet. 

 2.2 De fotografier som du laddar upp i Tjänsten kan komma att användas för analysändamål, t.ex. för analys av fordonets skador.

 2.3 Du kan läsa mer om Tjänsteleverantörens hantering av personuppgifter och dina rättigheter på www.if.se/om-webbplatsen/hantering-av-personuppgifter. 

3. Immateriella rättigheter och villkor för att använda Tjänsten

  3.1 Samtliga immateriella rättigheter till Tjänsten, inbegripet det material som visas eller finns i Tjänsten, innehas av Tjänsteleverantören eller av leverantörer till Tjänsteleverantören avseende Tjänsten eller annan tredje part. Under förutsättning att du godkänner och uppfyller villkoren i Avtalet ges du härmed en personlig, icke-exklusiv och icke-överlåtbar rätt att använda de tjänster och funktioner som tillhandahålls i Tjänsten. Denna rätt upphör automatiskt om Tjänsteleverantören inaktiverar Tjänsten eller om Avtalet upphör att gälla genom uppsägning eller på annat sätt.

  3.2	Du har inte rätt att:

    (a)	kopiera eller använda Tjänsten för andra ändamål än vad den är avsedd för;

    (b)	sälja, distribuera eller på annat sätt överföra Tjänsten till tredje part;

    (c)	skapa bearbetningar av Tjänsten;

    (d)	använda Tjänsten för att överföra olagliga eller kränkande uppgifter eller sådana uppgifter som utgör en olaglig kopia av tredje parts uppgifter;

    (e)	tillåta tredje part att göra något av ovan nämnda åtgärder.

  3.3	Genom att ladda upp ett fotografi i Tjänsten ger du Tjänsteleverantören en icke-exklusiv och överlåtbar rätt att på obegränsad tid använda fotografiet för analysandamål. Denna punkt ska fortsätta att gälla även för det fall att Avtalet sägs upp eller på annat sätt upphör att gälla.

4.	Datatrafik

  För att använda Tjänsten krävs att du har uppkoppling till internet. Du ansvarar för samtliga kostnader hänförlig till denna uppkoppling, inbegripet eventuella kostnader för datatrafik och roaming.

5.	Ansvar och ansvarsbegränsning

  Tjänsteleverantörens ambition är att Tjänsten ska finnas tillgänglig dygnet runt. Tjänsten kan dock vara otillgänglig t.ex. till följd av serviceavbrott eller tekniska problem. Fotografier och annat material som du har laddat upp i Tjänsten kan av oförutsedd orsak raderas och försvinna för gott. Det är ditt ansvar att ha säkerhetskopior på allt material som du laddar upp i Tjänsten. Tjänsteleverantören är inte ansvarig för skada eller förlust hänförlig till Tjänsten, oavsett om den är direkt eller indirekt. 

6.	Tvist

  En tvist mellan dig och Tjänsteleverantören med anledning av de förhållanden som regleras av Avtalet ska avgöras av svensk domstol och med tillämpning av svensk rätt, dock utan tillämpning av svenska lagvalsregler. Tjänsteleverantören får även väcka talan vid domstol i ett annat land där du har din hemvist eller där du har tillgångar.

7.	Övrigt

  7.1	Tjänsteleverantören förbehåller sig rätten att när som helst säga upp Avtalet eller upphöra med att tillhandahålla Tjänsten.

  7.2	Sådana bestämmelser i Avtalet som till sin natur är ägnade att vara tillämpliga även efter uppsägning, ska fortsätta att vara tillämpliga.

  7.3	Om något eller några av de villkor som dessa användarvillkor bildar konstateras vara ogiltiga eller inte kunna verkställas, tolkas övriga villkor i enlighet med tillämplig lag att så exakt som möjligt motsvara det ursprungliga syftet med dessa villkor och till övriga delar kvarstår villkoren till fullo i kraft.

_______________________________
<small>Dessa villkor upprättades den 8 januari 2021.</small>

<small>ŠKODA Försäkring är en del av If Skadeförsäkring AB (publ), org.nr 516401-8102, med adress 106 80 Stockholm. If Skadeförsäkring AB (publ) är ett försäkringsbolag och står under tillsyn av Finansinspektionen (Finansinspektionen, Box 7821, 103 97 Stockholm, 08-408 980 00, finansinspektionen@fi.se, fi.se). If står även under Konsumentverkets tillsyn vad gäller marknadsföring och reklam (Konsumentverket, Box 48, 651 02 Karlstad, 0771-42 33 00, konsumentverket@konsumentverket.se, konsumentverket.se).</small>`;
  return (
    <div className="if consent-container">
      <MarkdownView markdown={tosMarkdown} />
    </div>
  );
}
