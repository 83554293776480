import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "../actions";
import { NotificationType, processGlassInspectionResponse } from "../models";
import { showError } from "./common";
import { Backend } from "../backend";
import { IChangeGlassStatusRequest, Status } from "../backend-models";
import { FormattedMessage } from "react-intl";
import React from "react";
import { formatGlassDecision, formatStatus, Translation } from "../utility";

const notifyAboutDecisionChange = false;

function* changeGlassInspectionStatus(action: Action<IChangeGlassStatusRequest>) {
  try {
    const result = yield* Backend.Glass.ChangeStatus(action.payload);

    function* writeMessage(message: Translation) {
      yield put(
        Actions.showNotification({
          message,
          time: new Date(),
          type: NotificationType.Success,
        })
      );
    }

    if (action.payload.status !== null && action.payload.status !== undefined) {
      yield* writeMessage(
        <FormattedMessage
          id={
            action.payload.status !== Status.Deleted ? "page.statusChanged.message" : "action.delete.success"
          }
          values={{ status: formatStatus(action.payload.status) }}
        />
      );
    }
    if (
      notifyAboutDecisionChange &&
      action.payload.decision !== null &&
      action.payload.decision !== undefined
    ) {
      yield* writeMessage(
        <FormattedMessage
          id="page.glassDecisionChanged.message"
          values={{ decision: formatGlassDecision(action.payload.decision) }}
        />
      );
    }

    yield put(Actions.updateGlassInspectionSuccess(processGlassInspectionResponse(result)));
  } catch (err) {
    yield showError(err);
    yield put(Actions.updateGlassInspectionError({ ...err, recordId: action.payload.inspectionId }));
  }
}

export function* changeGlassInspectionStatusSaga(): SagaIterator {
  yield takeLatest(Actions.changeGlassInspectionStatus, changeGlassInspectionStatus);
}
