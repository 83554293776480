import * as React from "react";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { fetchGlassInspections } from "actions";
import { AppContext, IGlassInspectionColumns } from "models";
import { useStateSelector } from "store";
import { dispatchIfNeeded, filterGlassType, filterStatusGlass } from "utility";
import { reactPlugin, withAITracking } from "infrastructure/applicationInsights";

import TableView from "../shared/tableView";
import { LoadableRecords } from "../shared/loadable";
import ViewHeader from "../shared/viewHeader";
import { PhotoSource, Status } from "../../backend-models";

const _GlassArchive: React.FC = () => {
  const appContext = useContext(AppContext);
  const customerPhotos = appContext.customerPhotos;
  const dispatch = useDispatch();

  const glassInspections = useStateSelector((state) => state.glassInspections);

  useEffect(() => {
    dispatchIfNeeded(dispatch, fetchGlassInspections, glassInspections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let filtered = glassInspections.glassInspections;

  if (customerPhotos) {
    filtered = filterGlassType(filterStatusGlass(glassInspections, Status.Closed), PhotoSource.Customer);
  } else {
    filtered = filterGlassType(filtered, PhotoSource.Unknown, PhotoSource.Workshop);
  }

  const columns: IGlassInspectionColumns = customerPhotos
    ? [
        "customer.name",
        "customer.registrationNumber",
        "customer.phoneNumber",
        "workshopName",
        "dateCreated",
        "workshopDecision",
      ]
    : ["customer.registrationNumber", "workshopName", "dateCreated"];

  return (
    <div className="padded-block" data-cy="glass-list-page">
      <ViewHeader>
        <FormattedMessage id="page.archive" />
      </ViewHeader>
      <div className="if tabs">
        <ul className="if">
          <li className="if" onClick={() => appContext.mutate((context) => ({ ...context, customerPhotos: true }))}>
            <a className="if" aria-selected={customerPhotos ? "true" : null}>
              <FormattedMessage id="glass.customerPhotos" />
            </a>
          </li>
          <li className="if" onClick={() => appContext.mutate((context) => ({ ...context, customerPhotos: false }))}>
            <a className="if" aria-selected={customerPhotos ? null : "true"}>
              <FormattedMessage id="glass.workshopPhotos" />
            </a>
          </li>
        </ul>
      </div>
      {!glassInspections?.isLoading && glassInspections?.glassInspections?.length === 0 ? (
        <div className="if no-items">
          <FormattedMessage id="app.noItemsInArchive" />
        </div>
      ) : (
        <>
          <LoadableRecords records={glassInspections}>
            <TableView
              isGlass={true}
              columns={columns}
              records={filtered}
              route="/glass/"
              altRoute="{intake}"
              defaultSortColumn="dateCreated"
              showActionColumn={false}
              pageBase="/glass-archive"
            >
              <FormattedMessage id="app.noItemsInArchive" />
            </TableView>
          </LoadableRecords>
        </>
      )}
    </div>
  );
};

export const GlassArchive = withAITracking(reactPlugin, _GlassArchive);
