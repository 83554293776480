export function TermsOfServiceIfFi() {
  return (
    <div className="if consent-container">
      <h1 className="if heading">Käyttöehdot digitaaliselle vahinkotarkastukselle</h1>

      <p className="if text body">
        Me Ifillä olemme tyytyväisiä siitä, että aiot hyödyntää Ifin digitaalista vahinkotarkastusta
        (”Palvelu”). Voidaksesi käyttää Palvelua, sinun tulee ensin hyväksyä nämä käyttöehdot. Käyttöehdot
        muodostavat sopimuksen (”Sopimus”) sinun ja If Vahinkovakuutus Oyj, Suomen sivuliike, FI-1602149-8
        (Palveluntarjoaja) välille. Jos et hyväksy näitä käyttöehtoja, älä käytä Palvelua.
      </p>
      <h2 className="if heading">1. Palvelu</h2>
      <p className="if text body">
        1.1 Jos ajoneuvosi on vahingoittunut, sinulla on Palvelun avulla mahdollisuus siirtää valokuvia
        ajoneuvostasi käyttämääsi korjaamoon. Tämä edellyttää, että korjaamo on ottanut Palvelun käyttöön, ja
        että hyväksyt valokuvien siirron Palvelun kautta.
      </p>
      <p className="if text body">
        1.2 Palvelun kautta lähettämiesi valokuvien avulla pyritään arvioimaan ajoneuvolle aiheutuneet
        vahingot. Ifillä on kuitenkin aina oikeus tehdä tai teettää vahingoittuneelle ajoneuvolle paikan
        päällä tehtävä tarkastus.
      </p>
      <p className="if text body">
        1.3 Palvelu toimitetaan ”sellaisena kuin se on”. Saat Palvelun käyttöösi sellaisenaan kuin se
        kulloinkin on.
      </p>
      <h2 className="if heading">2. Henkilötietojen käsittely</h2>
      <p className="if text body">
        2.1 Palveluntarjoaja pääsee Palvelun avulla käyttämään niitä tietoja, jotka syötät tai lataat
        Palveluun. Näitä tietoja ovat esim. Palvelun käyttäjän nimi, puhelinnumero sekä digitaalisen
        vahinkotarkastuksen kohteena olevan ajoneuvon rekisterinumero. Palveluntarjoaja siirtää nämä tiedot
        puolestasi käyttämääsi korjaamoon ja tallentaa tiedot niin kauan kuin se on tarpeellista toimeksiannon
        suorittamiseksi.
      </p>
      <p className="if text body">
        2.2 Palveluun lataamiasi valokuvia voidaan käyttää analyysitarkoituksiin, kuten ajoneuvovaurioiden
        analysointiin.
      </p>
      <p className="if text body">
        2.3 Käsittelemme asiakkaidemme henkilötietoja noudattaen kulloinkin voimassaolevaa vakuutus- ja
        tietosuojalainsäädäntöä. Lisätietoja henkilötietojen käsittelystä löytyy osoitteesta:
        <a href="https://www.if.fi/henkilotietojen-kasittely">https://www.if.fi/henkilotietojen-kasittely</a>.
      </p>
      <h2 className="if heading">3. Immateriaalioikeudet eli aineettomat oikeudet</h2>
      <p className="if text body">
        3.1 Kaikki Palvelun immateriaalioikeudet, mukaan lukien Palveluun syötetty ja siellä näkyvä
        materiaali, kuuluvat Palveluntarjoajalle, joka voi luovuttaa ne kolmannelle osapuolelle. Jos hyväksyt
        Sopimuksen ehdot ja noudatat niitä, saat henkilökohtaisen oikeuden käyttää Palvelua. Tämä oikeus ei
        ole yksinoikeus, eikä se ole siirrettävissä. Oikeus käyttää Palvelua päättyy automaattisesti, jos
        Palveluntarjoaja lopettaa Palvelun tarjoamisen tai jos Sopimuksen voimassaolo päättyy irtisanomisen
        tai muun syyn vuoksi.
      </p>
      <p className="if text body">3.2 Sinulla ei ole oikeutta:</p>
      <p className="if text body">
        (a) kopioida tai käyttää Palvelua muuhun tarkoituksen kuin mihin se on tarkoitettu;
      </p>
      <p className="if text body">
        (b) myydä, jakaa tai muulla tavoin siirtää Palvelua kolmannelle osapuolelle;
      </p>
      <p className="if text body">(c) muokata Palvelua millään tavalla;</p>
      <p className="if text body">
        (d) käyttää Palvelua laittomien tai loukkaavien tietojen välittämiseen, tai sellaisten tietojen
        välittämiseen, jotka muodostavat laittoman kopion kolmannen tahon tiedoista.
      </p>
      <p className="if text body">
        (e) sallia kolmansien osapuolten toteuttaa mitä tahansa edellä mainituista toimenpiteistä.
      </p>
      <p className="if text body">
        3.3 Lataamalla valokuvan Palveluun annat Palveluntarjoajalle ei-yksinomaisen ja siirrettävän oikeuden
        käyttää valokuvaa analysointitarkoituksiin rajoittamattomaksi ajaksi. Tätä kohtaa sovelletaan, vaikka
        Sopimus irtisanotaan tai se lakkaa muutoin olemasta voimassa.
      </p>
      <h2 className="if heading">4. Dataliikenne</h2>
      <p className="if text body">
        Palvelun käyttäminen edellyttää Internet-yhteyttä. Olet vastuussa kaikista tähän liittyvistä
        kustannuksista, mukaan lukien dataliikenteen kustannukset ja roaming-kustannukset.
      </p>
      <h2 className="if heading">5. Vastuu ja vastuunrajoitukset</h2>
      <p className="if text body">
        Palveluntarjoajan tavoitteena on, että Palvelu on käytettävissä ympäri vuorokauden. Palvelu voi
        kuitenkin olla poissa käytöstä esim. palvelun keskeytysten tai teknisten ongelmien vuoksi. Palveluun
        lataamasi valokuvat ja muu materiaali voivat ennalta arvaamattomista syistä poistua ja kadota
        lopullisesti. Sinun vastuullasi on ottaa varmuuskopiot kaikesta Palveluun lataamastasi materiaalista.
        Palveluntarjoaja ei ole vastuussa Palveluun liittyvistä vahingoista tai menetyksistä riippumatta
        siitä, onko kyse välittömästä vai välillisestä vahingosta.
      </p>
      <h2 className="if heading">6. Riitojen ratkaisu</h2>
      <p className="if text body">
        Sinun ja Palveluntarjoajan välinen riita, joka liittyy tähän sopimukseen, ratkaistaan Suomen
        tuomioistuimessa ja soveltamalla Suomen lakia, mutta ilman Suomen lainvalintasääntöjen soveltamista.
        Palveluntarjoaja voi nostaa kanteen myös toisessa maassa, jossa sinulla on kotipaikka tai jossa
        sinulla on omaisuutta.
      </p>
      <h2 className="if heading">7. Muita ehtoja</h2>
      <p className="if text body">
        7.1 Palveluntarjoaja pidättää oikeuden irtisanoa Sopimuksen milloin tahansa tai lopettaa Palvelun
        tarjoamisen.
      </p>
      <p className="if text body">
        7.2 Sellaiset Sopimuksen ehdot, jotka ovat sovellettavissa myös Sopimuksen irtisanomisen jälkeen, ovat
        edelleen voimassa myös Sopimuksen päättymisen jälkeen.
      </p>
      <p className="if text body">
        7.3 Jos jokin tai jotkin Sopimuksen ehdoista todetaan pätemättömiksi tai soveltumattomiksi, muita
        ehtoja on tulkittava sovellettavan lain mukaisesti vastaamaan mahdollisimman tarkasti Sopimuksen
        alkuperäistä tarkoitusta. Tällöinkin Sopimuksen muut ehdot ovat edelleen voimassa.
      </p>
      <hr />
      <p className="if text disclaimer" style={{ textAlign: "left" }}>
        Nämä ehdot on vahvistettu 3. maaliskuuta 2021.
        <br />
        If Vahinkovakuutus Oyj, Suomen sivuliike Rekisteröity kotipaikka Tukholma (Y-tunnus 1602149-8)
      </p>
    </div>
  );
}
