import React from "react";
import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "../actions";
import { showError } from "./common";
import { Action } from "redux-actions";
import { Backend } from "../backend";
import { IFeedback } from "../backend-models";
import { NotificationType } from "../models";
import { FormattedMessage } from "react-intl";

function* workshopFeedbackSend(params: Action<IFeedback>) {
  try {
    const result = yield* Backend.App.Feedback(params.payload);
    yield put(Actions.workshopFeedbackSendSuccess(result));
    yield put(
      Actions.showNotification({
        message: <FormattedMessage id="feedback.success" />,
        time: new Date(),
        type: NotificationType.Success,
      })
    );
  } catch (err) {
    yield put(Actions.workshopFeedbackSendError(err));
    yield showError(err);
  }
}
export function* workshopFeedbackSendSaga(): SagaIterator {
  yield takeLatest(Actions.workshopFeedbackSend, workshopFeedbackSend);
}
