import * as React from "react";

const ViewHeader: React.FC = ({ children }) => {
  return (
    <h1 className="if heading" data-cy="header">
      {children}
    </h1>
  );
};

export default ViewHeader;
