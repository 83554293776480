import "./contactInformationStep.scss";

import * as React from "react";
import { ChangeEvent, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import validator from "validator";

import { DataSetter, IWizardStepProps } from "../../../intakeModels";
import { AppState } from "../../../models";
import { validateRegistrationNumber } from "../../../validation";
import InputField from "../fields/inputField";
import PhoneInputField from "../fields/phoneInputField";
import { daysToMilliseconds, deepGet, deepSet } from "../../../utility";
import { Brand, IIntakeInformation } from "../../../backend-models";
import { shouldShowClaimDate } from "../../../intakeUtility";
import dayjs from "dayjs";

const ContactInformationStep: React.FC<IWizardStepProps> = (props) => {
  const intakeInfo: IIntakeInformation = useSelector((state: AppState) => state.intake);

  let [state, setState] = React.useState({
    fields: props.wizard.data,
    chosenCountryCode: intakeInfo.workshopCountryCode,
    validate: false,
  });

  let fields = state.fields;

  let showClaimDate = shouldShowClaimDate(intakeInfo);
  let claimDateRequired =
    showClaimDate && (intakeInfo?.brand === Brand.Volvia || intakeInfo?.brand === Brand.Renault);

  const validators = useMemo(
    () => ({
      "customer.name": (name) => name && !(name.length < 3 || name.length > 200),
      "customer.phoneNumber": (num) => isValidPhoneNumber(num ?? ""),
      "customer.email": validator.isEmail,
      "customer.registrationNumber": (regNumber) => regNumber && validateRegistrationNumber(regNumber),
      mileage: (mileage) => mileage && !(mileage.length < 0 || mileage.length > 9) && !isNaN(mileage),
      claimDate: (date) => {
        if (!showClaimDate) {
          return true;
        }
        if (date == null) return !claimDateRequired;
        if (!isNaN(date.getTime())) {
          let now = new Date();
          now.setHours(13); //date arg has hours=12
          let inFuture = date > now;
          let twoYearsAgo = new Date(now.getTime() - daysToMilliseconds(730));
          let moreThanTwoYearsAgo = date < twoYearsAgo;
          return !inFuture && !moreThanTwoYearsAgo;
        }
        return false;
      },
    }),
    [showClaimDate, claimDateRequired]
  );

  React.useEffect(() => {
    const formatNumber = (number) => {
      if (window.intlTelInputUtils) {
        let format = window.intlTelInputUtils.numberFormat.E164;
        number = window.intlTelInputUtils.formatNumber(number, state.chosenCountryCode, format);
      }

      return number;
    };
    const save: DataSetter = (data) => ({
      ...data,
      customer: {
        ...fields.customer,
        phoneNumber: formatNumber(fields.customer.phoneNumber),
      },
      claimDate: fields.claimDate,
      mileage: fields.mileage,
    });

    function formIsValid() {
      return [
        "customer.name",
        "customer.phoneNumber",
        "customer.email",
        "customer.registrationNumber",
        "mileage",
        "claimDate",
      ].every((x) => validators[x](deepGet(state.fields, x)));
    }
    if (props.wizard.nextClicked) {
      if (!formIsValid()) {
        setState({ ...state, validate: true });
        props.cancelNextStep();
      } else {
        props.goStep(1, save);
      }
    }
    if (props.wizard.prevClicked) {
      props.goStep(-1, save);
    }
  }, [fields, props, state, validators]);

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    let newState = { ...state, fields: { ...state.fields } };
    deepSet(newState.fields, name, value);
    setState(newState);
  }

  function onPhoneChange(number: string, chosenCountryCode: string) {
    let newState = { ...state };
    newState.fields.customer.phoneNumber = number ?? "";
    newState.chosenCountryCode = chosenCountryCode;
    setState(newState);
  }
  function onMileageChange(e: ChangeEvent<HTMLInputElement>) {
    setState({ ...state, fields: { ...fields, mileage: Number(e.target.value) } });
  }

  function onEmailChange(e: ChangeEvent<HTMLInputElement>) {
    let newState = { ...state };
    newState.fields.customer.email = e.target.value.trim();
    setState(newState);
  }

  function propsFor(name, customOnChange = null) {
    return {
      onChange: customOnChange ?? onChange,
      name,
      value: deepGet(fields, name),
      isValid: state.validate ? validators[name](deepGet(fields, name)) : true,
      intlPrefix: "intake.ContactInformationStep.",
    };
  }

  function setClaimDate(dateText: string) {
    let date = new Date(dateText);
    if (isNaN(date.getTime())) {
      date = null;
    } else {
      date.setHours(12);
    }
    return setState({
      ...state,
      fields: {
        ...fields,
        claimDate: date,
      },
    });
  }
  function isoDate(date: Date) {
    try {
      return date?.toISOString().substring(0, 10);
    } catch (e) {
      if (e instanceof RangeError) {
        //ignored, invalid date
      } else {
        throw e;
      }
    }
    return null;
  }
  function claimDateClasses() {
    let classes = "if input-field date";
    if (state.validate) {
      if (!validators.claimDate(fields.claimDate)) {
        classes += " is-invalid";
      }
    }
    return classes;
  }

  return (
    <>
      <h1 className="if header-text" data-cy="contactInformationHeader">
        <FormattedMessage id="intake.ContactInformationStep.header" />
      </h1>
      <p className="if basic-text">
        <FormattedMessage id="intake.ContactInformationStep.text" />
      </p>
      <p className="if text meta">
        <FormattedMessage id="intake.ContactInformationStep.helpText" />
      </p>
      <form>
        <InputField {...propsFor("customer.name")} autoComplete="name" />
        <PhoneInputField
          {...propsFor("customer.phoneNumber", onPhoneChange)}
          autoComplete="tel"
          workshopCountryCode={intakeInfo.workshopCountryCode}
        />
        <InputField {...propsFor("customer.email", onEmailChange)} autoComplete="email" />
        <InputField
          {...propsFor("customer.registrationNumber")}
          customClasses="smaller-input"
          autoComplete="off"
        />
        <InputField
          {...propsFor("mileage", onMileageChange)}
          customClasses="smaller-input"
          inputProps={{
            max: 100_000_000,
            min: 0,
            type: "number",
          }}
          autoComplete="off"
        />
        {showClaimDate && (
          <div className="if input-wrapper">
            <input
              type="date"
              className={claimDateClasses()}
              name="input-claimDate"
              onChange={(e) => setClaimDate(e.target.value)}
              value={isoDate(fields.claimDate) ?? ""}
              id="input-claimDate"
              min={dayjs().subtract(730, "day").format("YYYY-MM-DD")}
              max={dayjs().format("YYYY-MM-DD")}
              autoComplete="off"
              required={claimDateRequired}
            />
            <label className="if" htmlFor="input-claimDate">
              <FormattedMessage id="intake.ContactInformationStep.claimDate.header" />
            </label>
            {(state.fields.claimDate != null || claimDateRequired) && (
              <span className="if input-error validation-message">
                <FormattedMessage id="intake.ContactInformationStep.claimDate.validationMessage" />
              </span>
            )}
          </div>
        )}
      </form>
    </>
  );
};
export default ContactInformationStep;
