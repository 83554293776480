import { isValidYyMmDd } from "utility";

export function validateRegistrationNumber(regNumber: string) {
  return /^([a-zA-Z0-9äöåøæÄÖÅØÆ -]{3,9})$/.test(regNumber);
}

export function validateClaimNumber(claimNumber: string): boolean {
  if (claimNumber == null) return false;

  claimNumber = claimNumber.trim();

  if (!claimNumber) return false;

  const currentYear = new Date().getFullYear().toString().substr(2);
  const lastYear = (new Date().getFullYear() - 1).toString().substr(2);

  function ValidYear(year: string) {
    return year === currentYear || year === lastYear;
  }

  function ValidDate(date: string) {
    return isValidYyMmDd(date) && ValidYear(date.substr(0, 2));
  }

  //Fenix: 123456789
  function ValidFenix(number: string) {
    if (number.length !== 9) return false;

    if (!ValidYear(number.substr(0, 2))) return false;

    return /^\d{9}$/.test(number);
  }

  //Waypoint: 12.1234, 12.001234
  //[year].[claim]
  function ValidWaypoint(number: string) {
    if (number.length < 4) return false;

    if (!ValidYear(number.substr(0, 2))) return false;

    return /^\d{2}\.\d{1,7}$/.test(number);
  }

  //PXA651-171001, PAJ001190502
  //[reg]-[yyMMdd]
  //aka Finesse
  function ValidSkrot(number: string) {
    if (number.length < 10) return false;

    number = number.replace("-", "");
    const datePart = number.substr(number.length - 6);
    const regNumber = number.substr(0, number.length - 6);

    if (!ValidYear(datePart.substr(0, 2))) return false;

    return ValidDate(datePart) && validateRegistrationNumber(regNumber);
  }

  // VTA1234567PRI, VTA7654321VOL
  // VTA[0-9 x7][PRI/COM/IND/VOL]
  function ValidFinnish(number: string) {
    return /^VTA\d{7}(PRI|COM|IND|VOL)?$/.test(number);
  }

  // XX12345, AB67890
  // 2 capital lettes (A-Z) and 5 numbers
  function ValidNorwegian(number: string) {
    if (number.length !== 7) return false;

    return /^([A-Z]{2}[0-9]{5}|[A-Z]{1}[0-9]{6})$/.test(number);
  }

  // 581234567, 123456-A-1234, 123456A1234
  // "58" + 7 numbers or 6 numbers + "A" + 4 numbers (each part separated with "-" or not)
  function ValidDanish(number: string) {
    if (number.length < 9) return false;

    if (/^58\d{7}$/.test(number)) return true;
    if (/^\d{6}-A-\d{4}$/.test(number)) return true;
    if (/^\d{6}A\d{4}$/.test(number)) return true;

    return false;
  }
  //UFO01A/19/190817, CWS143/71/180327
  //[reg]/[01-99]/[yyMMdd]
  //[reg]/[01-99]/[CDW3]
  //aka VSG, IMF
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function ValidWarrantyOrImfClaimformat(number) {
    const parts = number.split("/");

    if (parts.length !== 3) return false;

    if (!validateRegistrationNumber(parts[0])) return false;

    if (parts[2].toUpperCase() !== "CDW3" && !ValidDate(parts[2])) return false;

    const caseNumber = parseInt(parts[1]);
    if (isNaN(caseNumber)) return false;

    return caseNumber >= 1 && caseNumber <= 99;
  }

  return (
    ValidWaypoint(claimNumber) ||
    ValidFenix(claimNumber) ||
    ValidSkrot(claimNumber) ||
    ValidFinnish(claimNumber) ||
    ValidNorwegian(claimNumber) ||
    ValidDanish(claimNumber) ||
    ValidWarrantyOrImfClaimformat(claimNumber)
  );
}
