import React, { useState } from "react";
import { IGlassInspection, IRecord } from "../../backend-models";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { addComment, addGlassComment } from "../../actions";
import { formatDateTime } from "../../utility";

export const RecordComments: React.FC<{ record: IRecord | IGlassInspection; isGlass?: boolean }> = ({
  record,
  isGlass = false,
}) => {
  let comments = record.comments;
  let intl = useIntl();
  const [commentText, setCommentText] = useState("");
  let isCommentTooLong = commentText.length > 500;

  const dispatch = useDispatch();

  function AddComment() {
    dispatch(
      (isGlass ? addGlassComment : addComment)({
        recordId: record.recordId,
        comment: commentText,
      })
    );
    setCommentText("");
  }

  function isCommentEmpty() {
    if (!!commentText) {
      return commentText.trim() === "";
    }
    return true;
  }

  return (
    <section className="comments field-container">
      <h3 className="if heading">
        <FormattedMessage id="field.comments" />
      </h3>
      {comments &&
        comments.map((comment, i) => (
          <div className="if comment" key={i}>
            <div className="comment-header">
              <span className="author">{comment.author}</span>
              <span className="date">{formatDateTime(comment.date)}</span>
            </div>
            <div className="text">{comment.text}</div>
          </div>
        ))}

      <div className="if input-wrapper">
        <textarea
          className={"if textarea" + (isCommentTooLong ? " is-invalid" : "")}
          name="caseComment"
          placeholder={intl.formatMessage({ id: "comment.placeholder" })}
          value={commentText}
          onChange={(e) => {
            setCommentText(e.target.value);
          }}
        />
        <span className="if input-error validation-message">
          <FormattedMessage id="comment.tooLong" />
        </span>
      </div>
      <button
        className="if button secondary"
        onClick={AddComment}
        disabled={isCommentEmpty() || isCommentTooLong}
      >
        <FormattedMessage id="comment.buttonAdd" />
      </button>
    </section>
  );
};
