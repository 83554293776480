import "./intakeLinkDecoder.scss";
import React, { useState } from "react";
import { decodeIntakeUrlData } from "../../intakeUtility";
import { useHistory } from "react-router-dom";
import { Brand, WorkshopCountryCode } from "../../backend-models";

export const TestIntakeLinkDecoder: React.FC = () => {
  const history = useHistory();
  let [state, setState] = useState({
    intakeUrlToDecode: "",
  });

  let decodedIntakeData: { id; brand; countryCode } = null;
  if (state.intakeUrlToDecode) {
    let intakeUrlData: any = state.intakeUrlToDecode.match("([a-zA-Z0-9_-]{23})");
    if (intakeUrlData) {
      decodedIntakeData = decodeIntakeUrlData(intakeUrlData[1]);
    }
  }
  let fullIntakeLink = "/intake/";
  if (decodedIntakeData) {
    fullIntakeLink += `${decodedIntakeData.id}/${Brand[decodedIntakeData.brand]}?lang=${
      WorkshopCountryCode[decodedIntakeData.countryCode]
    }`;
  }
  return (
    <>
      <h2>Decode intake link</h2>
      <input
        type="text"
        className="if input-field"
        value={state.intakeUrlToDecode}
        onChange={(e) => setState({ ...state, intakeUrlToDecode: e.target.value })}
        style={{ width: "100%", maxWidth: "100%" }}
      />
      <br />
      {decodedIntakeData && (
        <>
          <br />
          <span className="intake-link-decoder-results">
            <a
              style={{ gridColumn: "1/3" }}
              href={"/details/" + decodedIntakeData.id}
              onClick={(e) => {
                e.preventDefault();
                history.push("/details/" + decodedIntakeData.id);
              }}
            >
              Details
            </a>
            <a
              style={{ gridColumn: "1/3" }}
              href={fullIntakeLink}
              onClick={(e) => {
                e.preventDefault();
                history.push(fullIntakeLink);
              }}
            >
              {fullIntakeLink}
            </a>
            <b>ID</b>
            <span>{decodedIntakeData.id}</span>
            <b>Brand</b>
            <span>{Brand[decodedIntakeData.brand]}</span>
            <b>Country</b>
            <span>{WorkshopCountryCode[decodedIntakeData.countryCode]}</span>
          </span>
        </>
      )}
      <br />
    </>
  );
};
