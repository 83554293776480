export function TermsOfServiceIfNo() {
  return (
    <div className="if consent-container">
      <h1 className="if heading">Brukervilkår for Ifs fotobesiktelsestjeneste</h1>

      <p className="if text body">
        Vi i If er glade for at du har valgt å bruke Ifs tjeneste for fotobesiktelse (“Tjenesten”). For å
        bruke Tjenesten må du først godta disse brukervilkårene. Brukervilkårene utgjør en avtale («Avtalen")
        mellom deg og If Skadeförsäkring AB (publ), organisasjonsnummer 516401-8102 ("Tjenesteleverandøren").
      </p>
      <h2 className="if heading">1. Tjenesten</h2>
      <p className="if text body">
        1.1 Ved å benytte Tjenesten har du som har fått skade på kjøretøyet ditt mulighet til å overføre
        bilder av kjøretøyet til verkstedet du bruker, forutsatt at verkstedet er koblet til Tjenesten og
        godtar at du overfører bilder gjennom Tjenesten.
      </p>
      <p className="if text body">
        1.2 Tjenesten leveres i den stand og i den versjon den har på det tidspunktet du benytter Tjenesten.
      </p>
      <h2 className="if heading">2. Behandling av personopplysninger</h2>
      <p className="if text body">
        2.1 Ved å bruke Tjenesten får Tjenesteleverandøren tilgang til informasjonen du legger inn eller
        laster opp i Tjenesten, f.eks. navn, telefonnummer og registreringsnummer på kjøretøyet som skal
        fotograferes. Tjenesteleverandøren overfører denne informasjonen til det aktuelle verkstedet på dine
        vegne og lagrer informasjonen så lenge det er nødvendig for å dokumentere gjennomføringen av
        oppdraget. Dersom Tjenesten benyttes i forbindelse med et skadeoppgjør hos Tjenesteleverandøren,
        lagres informasjonen i den aktuelle skadesaken.
      </p>
      <p className="if text body">
        2.2 Du kan lese mer om Tjenesteleverandørens behandling av personopplysninger og dine rettigheter på
        <a href="https://www.if.no/behandling-av-personopplysninger">
          www.if.no/behandling-av-personopplysninger
        </a>
        .
      </p>
      <h2 className="if heading">3. Immaterielle rettigheter og vilkår for bruk av Tjenesten</h2>
      <p className="if text body">
        3.1 Alle immaterielle rettigheter i Tjenesten, inkludert materialet som vises eller eksisterer i
        Tjenesten, tilhører Tjenesteleverandøren eller en av dens underleverandører. Forutsatt at du godtar og
        oppfyller vilkårene i Avtalen, får du herved en personlig, ikke-eksklusiv og ikke-overførbar rett til
        å bruke Tjenesten og funksjonene som tilbys der. Denne retten opphører automatisk hvis
        Tjenesteleverandøren deaktiverer Tjenesten eller hvis Avtalen opphører å gjelde ved oppsigelse eller
        på annen måte.
      </p>
      <p className="if text body">3.2 Du har ikke rett til å:</p>
      <p className="if text body">
        (a) kopiere eller bruke Tjenesten til andre formål enn beskrevet i Avtalen;
      </p>
      <p className="if text body">
        (b) selge, distribuere eller på annen måte overføre Tjenesten til tredjepart;
      </p>
      <p className="if text body">(c) lage tilpasninger av Tjenesten;</p>
      <p className="if text body">
        (d) bruke Tjenesten til å overføre ulovlige eller krenkende data eller slike data som utgjør en
        ulovlig kopi av tredjepartsdata;
      </p>
      <p className="if text body">(e) tillate tredjeparter å treffe noen av de ovennevnte tiltakene.</p>
      <p className="if text body">
        3.3 Ved å laste opp et fotografi i Tjenesten, gir du Tjenesteleverandøren en ikke-eksklusiv og
        overførbar rett til å bruke fotografiet til analyseformål på ubestemt tid. Eventuelle
        personopplysninger som knytter seg til et fotografi skal ved slik bruk anonymiseres (slik som navn og
        adresse på bileier, registreringsnummer), slik at fotografiet ikke lenger kan knyttes til en person.
        Denne klausulen skal fortsette å gjelde også for det tilfelle Avtalen blir sagt opp eller på annen
        måte opphører.
      </p>
      <h2 className="if heading">4. Datatrafikk</h2>
      <p className="if text body">
        For å bruke Tjenesten må du ha en internettforbindelse. Du er ansvarlig for alle kostnader knyttet til
        denne forbindelsen, inkludert kostnader for datatrafikk og roaming.
      </p>
      <h2 className="if heading">5. Ansvar og ansvarsbegrensning</h2>
      <p className="if text body">
        Tjenesteleverandørens ambisjon er at Tjenesten skal være tilgjengelig døgnet rundt. Det kan imidlertid
        oppstå tjenesteavbrudd eller tekniske problemer. Fotografier og annet materiale du har lastet opp i
        Tjenesten kan av uforutsette grunner slettes og forsvinne for godt. Det er ditt ansvar å ha
        sikkerhetskopier av alt materiale du laster opp til Tjenesten. Tjenesteleverandøren er ikke ansvarlig
        for skade eller tap som kan tilskrives Tjenesten, uavhengig av om den er direkte eller indirekte.
      </p>
      <h2 className="if heading">6. Tvist</h2>
      <p className="if text body">
        Dersom det oppstår en tvist mellom deg og Tjenesteleverandøren på grunn av omstendigheter regulert i
        Avtalen skal tvisten avgjøres ved en norsk domstol og med anvendelse av norsk rett.
      </p>
      <h2 className="if heading">7. Annet</h2>
      <p className="if text body">
        7.1 Tjenesteleverandøren forbeholder seg retten til å si opp Avtalen når som helst eller å slutte å
        levere Tjenesten.
      </p>
      <p className="if text body">
        7.2 Bestemmelser i avtalen som etter sin art er egnet til å gjelde også etter avtalens opphør, skal
        fortsatte være gjeldende etter opphør.
      </p>
      <p className="if text body">
        7.3 Hvis det er vilkår eller betingelser i Avtalen som blir ansett ugyldige eller som ikke kan
        håndheves, skal de øvrige bestemmelsene fortsette å gjelde eller tolkes i samsvar med gjeldende lov
        for å tilsvare så presist som mulig det opprinnelige formålet med disse bestemmelsene.
      </p>
      <hr />
      <p className="if text disclaimer" style={{ textAlign: "left" }}>
        Disse vilkårene ble opprettet 11. februar 2021.
        <br />
        If Skadeforsikring NUF
        <br />
        Foretaksregisteret, org.nr: 981 290 666
        <br />
        Postboks 240, 1326 Lysaker
        <br />
        Besøksadresse: Drammensveien 264, 0283 Oslo
        <br />
        Telefon: 21 49 24 00
        <br />
        <a href="https://www.if.no">www.if.no</a>
        <br />
        <br />
        <br />
        Filial av:
        <br />
        If Skadeförsäkring AB (publ)
        <br />
        Hovedkontor: S-106 80 Stockholm
        <br />
        Bolagsverket, reg.nr.: 516401-8102
      </p>
    </div>
  );
}
