//GENERATED AUTOMATICALLY, DO NOT EDIT
//generate-translations.js

const translations = {
  "id": "nb",
  "app.error": "Feil",
  "app.notFound": "Side ikke funnet",
  "app.redirecting": "Omdirigerer",
  "app.na": "N/A",
  "app.noItemsOnHold": "Ingen oppgaver er pauset",
  "app.noItemsInProgress": "Det er ingen pågående oppgaver",
  "app.noItemsInInbox": "Det er ingen nye oppgaver",
  "app.noItemsInArchive": "Det er ingen arkivert oppgaver",
  "app.noItemsInOpen": "Det er ingen åpnet oppgaver",
  "app.recordNotFound": "Posten ble ikke funnet",
  "app.forbiddenRecord": "Du har ikke tillatelse til å åpne dette ærend: {recordId}",
  "app.adblock": "Hvis du opplever problemer, kan du prøve å slå av annonseblokkering.",
  "app.loggedOut": "Du har blitt logget ut",
  "app.workshopVacation": "Ferieendringer lagret",
  "action.logIn": "Logg inn",
  "action.logOut": "Logg ut",
  "action.action": "Behandle",
  "action.close": "Lukk",
  "action.OnHold": "Pause",
  "action.moveTo": "Flytte til",
  "action.delete": "Slette",
  "action.delete.success": "Saken er slettet",
  "action.save": "Lagre",
  "action.clear": "Rense",
  "table.of": "av",
  "table.old": "dager gammel",
  "page.inbox": "Innboks",
  "page.inProgress": "Pågående",
  "page.onHold": "Pauset",
  "page.archive": "Arkiv",
  "page.search": "Søk",
  "page.new": "Send fotobesiktigelse",
  "page.open": "Åpne",
  "page.details": "Detaljer",
  "page.statusChanged.message": "Saken er flyttet til {status}",
  "page.settings": "Ferie instillinger",
  "field.photoSource": "Kilde",
  "field.recordId": "ID-nummer",
  "field.claimNumber": "Skadenummer",
  "field.customer.name": "Navn",
  "field.customer.registrationNumber": "Reg.nummer",
  "field.customer.phoneNumber": "Telefonnummer",
  "field.workshopName": "Verksted",
  "field.workshopId": "Verksted ID",
  "field.customer.email": "E-post",
  "field.mileage": "Kilometerstand",
  "field.description": "Beskrivelse",
  "field.status": "Status",
  "field.decision": "Vurdering",
  "field.decidedBy": "Vurdering gjort av",
  "field.dateDecided": "Vurdering ble tatt den",
  "field.createdBy": "Sendt av",
  "field.dateCreated": "Sendt den",
  "field.glass.dateCreated": "Opprettet den",
  "field.dateOfIntake": "Opprettet den",
  "field.comments": "Kommentarer",
  "field.intakeLink": "Intake",
  "field.claimDate": "Skadedato",
  "field.city": "By",
  "field.availability": "Fotobesiktigelse",
  "field.summerWorkshopInstructions": "Kommer dere til å holde stengt i sommerferien? Da er det viktig at dere markerer fotobesiktelse som «stengt» den aktuelle perioden, gjerne en uke i forkant, slik at kunder ikke får invitasjon til fotobesiktigelse i ferien deres.",
  "field.workshopDecision": "Vurdering",
  "link.back": "Tilbake",
  "link.edit": "Rediger",
  "link.createNewInspection": "Send fotobesiktigelse",
  "newInspection.claimNumber": "Skadenummer (valgfritt)",
  "newInspection.customerPhoneNumber": "Kundens telefonnummer",
  "newInspection.sendLinkToCustomer": "Send lenke til kunden",
  "newInspection.sent": "Lenken er sendt",
  "newInspection.registrationNumber": "Registreringsnummer",
  "newInspection.workshop": "Verksted",
  "newInspection.brand": "Forsikring",
  "newInspection.registrationNumber.validationMessage": "Registreringsnummer er ikke gyldig",
  "newInspection.claimNumber.validationMessage": "Skadenummer er ikke gyldig",
  "newInspection.phone.validationMessage": "Telefonnummer er ikke gyldig",
  "newInspection.workshop.validationMessage": "Verksted kan ikke være tomt",
  "newInspection.brand.validationMessage": "Brennmerke kan ikke være tomt",
  "newInspection.select.workshop": "Velg verksted",
  "newInspection.select.brand": "Velg forsikring",
  "newInspection.customerName": "Kundens navn",
  "newInspection.customerName.validationMessage": "Kundens navn er ikke gyldig",
  "decision.Undecided": "-",
  "decision.Repair": "Reparasjon",
  "decision.PreInspection": "Førbesiktigelse",
  "glassDecision.NotAvailable": "-",
  "glassDecision.Repair": "Reparasjon",
  "glassDecision.Replace": "Endring",
  "status.Open": "Åpen",
  "status.New": "Ny",
  "status.InProgress": "Pågår",
  "status.OnHold": "Pauset",
  "status.Closed": "Avsluttet",
  "status.Deleted": "Slettet",
  "photos.count": "Bilde {current} av {total}",
  "photos.save": "Lagre bilde",
  "photos.saveAll": "Lagre alle bilder",
  "photos.saveAllPreparing": "Forbereder",
  "photos.saveAllDownloaded": "Nedlastet",
  "photos.photosProcessing": "Bilder behandles, kom tilbake senere",
  "photos.noPhotos": "Ingen bilder har blitt sendt inn ennå",
  "comment.placeholder": "Skriv din kommentar her",
  "comment.buttonAdd": "Legg til",
  "comment.tooLong": "Kommentaren er for long (maks 500 tegn)",
  "search.placeHolder": "Søk",
  "action.sendIntakeLinkReminder": "Send SMS-påminnelse",
  "action.sendIntakeLinkReminder.success": "Påminnelse er sendt",
  "action.sendIntakeLinkReminder.hasBeenSent": "Påminnelse er sendt",
  "workshop.ui.page.title": "Verkstedportal",
  "feedback.title": "Gi tilbakemelding",
  "feedback.info": "Vi har alltid som mål å forbedre tjenestene våre. Fortell oss hva du synes om fotoinspeksjonen, og om det er noe som kan gjøre det bedre for deg.\\nVær oppmerksom på at hvis du opplever systemfeil, kan du kontakte systemansvarlig i stedet for å fylle ut dette skjemaet.",
  "feedback.input": "Kommentar",
  "feedback.cancel": "Avbryt",
  "feedback.send": "Send tilbakemeldning",
  "feedback.success": "Takk for tilbakemeldingen!",
  "inactive.user.disabled.text": "Kontoen din er deaktivert, gjør det for inaktivitet. Ta kontakt",
  "inactive.user.contact.email.address.text": "service",
  "intake.ui.page.title": "Send skadebilde",
  "intake.ui.page.title.tabHeader": "Fotobesiktigelse - Send skadebilde",
  "intake.LandingStep.header": "Fotoinspeksjon",
  "intake.LandingStep.madeBy": "En tjeneste skapt av",
  "intake.LandingStep.text": "Her kan du sende bilder av skaden, istedenfor å avtale time til takst. Det tar bare noen minutter. Bildene sendes til verkstedet. Verkstedet vil kontakte deg med informasjon om neste steg.",
  "intake.LandingStep.text2": "Vi behandler personopplysninger i overensstemmelse med gjeldende lovgivning om forsikring og databeskyttelse.",
  "intake.LandingStep.link.text": "Les mer om behandling av personopplysninger på vår hjemmeside.",
  "intake.LandingStep.consentCheckbox.text": "Jeg har lest og godkjenner",
  "intake.LandingStep.consent.link.text": "brukervilkår",
  "intake.LandingStep.privacyPolicy.text": "Les mer om vår håndtering av personlig informasjon og dine rettigheter ved å",
  "intake.LandingStep.privacyPolicy.link.text": "klikke her",
  "intake.LandingStep.accessibilityStatement.link.text": "Tilgjengelighetserklæring",
  "intake.alt.imageCar": "Bil",
  "intake.alt.imageSummary.back": "Bilde av bilen tatt bakfra",
  "intake.alt.imageSummary.distance": "Bilde av bilen tatt på avstand",
  "intake.alt.imageSummary.closeup": "Bildet av bilen tatt i nærbilde",
  "intake.alt.imageSummary.diagonal-front": "Bildet av bilen tatt fra en vinkel",
  "intake.alt.imageSummary.diagonal-back": "Bildet av bilen tatt fra en annen vinkel",
  "intake.alt.back": "Tilbake",
  "intake.alt.imageIllustration.back": "Illustrasjon av en bil fra bakfra",
  "intake.alt.imageIllustration.distance": "Illustrasjon av en bil på avstand",
  "intake.alt.imageIllustration.closeup": "Illustrasjon av en bil på nært hold",
  "intake.alt.imageIllustration.diagonal-front": "Illustrasjon av en bil fra en vinkel",
  "intake.alt.imageIllustration.diagonal-back": "Illustrasjon av en bil fra en annen vinkel",
  "intake.wizard.buttonStart": "Start fotoinspeksjon",
  "intake.wizard.buttonNext": "Neste",
  "intake.wizard.buttonSubmit": "Send til verksted",
  "intake.wizard.buttonBack": "Tilbake",
  "intake.wizard.sendingInformation": "Din informasjon sendes til verkstedet...",
  "intake.wizard.sendingFile": "Laster opp bilder: {current} av {total}",
  "intake.wizard.buttonSave": "Lagre",
  "intake.ContactInformationStep.header": "Din informasjon",
  "intake.ContactInformationStep.text": "Vi trenger litt informasjon for å komme i kontakt med deg og for å bestille tid hos verksted.",
  "intake.ContactInformationStep.helpText": "Felt markert med * er obligatoriske",
  "intake.ContactInformationStep.customer.name.header": "Navn",
  "intake.ContactInformationStep.customer.name.validationMessage": "Navn kan ikke være tomt",
  "intake.ContactInformationStep.customer.phoneNumber.header": "Telefonnummer",
  "intake.ContactInformationStep.customer.phoneNumber.validationMessage": "Telefonnummer er ikke gyldig",
  "intake.ContactInformationStep.customer.email.header": "E-post",
  "intake.ContactInformationStep.customer.email.validationMessage": "E-postadressen er ikke gyldig",
  "intake.ContactInformationStep.customer.registrationNumber.header": "Registreringsnummer",
  "intake.ContactInformationStep.customer.registrationNumber.validationMessage": "Registreringsnummer er ikke gyldig",
  "intake.ContactInformationStep.mileage.header": "Bilens kilometerstand",
  "intake.ContactInformationStep.mileage.validationMessage": "Oppgitt kilometerstand er ikke gyldig",
  "intake.ContactInformationStep.claimDate.header": "Skadedato",
  "intake.ContactInformationStep.claimDate.validationMessage": "Skadedato er ikke gyldig",
  "intake.DescriptionStep.header": "Beskrivelse",
  "intake.DescriptionStep.help": "Gi en kort beskrivelse av skaden og hvor den er på bilen",
  "intake.DescriptionStep.description.header": "Beskrivelse",
  "intake.DescriptionStep.description.validationMessage": "Maks 200 tegn ({fieldLength} for mange)",
  "intake.DescriptionStep.description.statusMessage": "Maks 200 tegn ({descriptionLength} igjen)",
  "intake.DescriptionStep.placeholder": "Kort beskrivelse",
  "intake.PhotoStep.header": "Bilde",
  "intake.PhotoStep.header.back": "Bilens bakside",
  "intake.PhotoStep.text.back": "Ta et bilde av bilens bakside slik at både registreringsnummer og bilmodell er synlig",
  "intake.PhotoStep.header.distance": "Bilde av skaden fra avstand",
  "intake.PhotoStep.text.distance": "Ta et bilde av skaden fra ca 3 meters avstand",
  "intake.PhotoStep.header.closeup": "Nærbilde av skaden",
  "intake.PhotoStep.text.closeup": "Ta et nærbilde av skaden rett på",
  "intake.PhotoStep.header.diagonal-front": "Nærbilde av skaden fra den ene siden",
  "intake.PhotoStep.text.diagonal-front": "Ta et nærbilde av skaden vinklet fra den ene siden",
  "intake.PhotoStep.header.diagonal-back": "Nærbilde av skaden fra den andre siden",
  "intake.PhotoStep.text.diagonal-back": "Ta et nærbilde av skaden vinklet fra den andre siden",
  "intake.PhotoStep.header.extra": "Tilleggsbilder (valgfritt)",
  "intake.PhotoStep.text.extra": "Ta opptil 2 ekstra bilder av skaden. Du kan også hoppe over dette og gå direkte til neste steg.",
  "intake.PhotoStep.add-button": "Ta eller last opp bilde",
  "intake.PhotoStep.deleteQuestion": "Slette bilde?",
  "intake.PhotoStep.cancelButton": "Avbryt",
  "intake.PhotoStep.deleteButton": "Slette",
  "intake.PhotoStep.validationMessage": "Du må legge til et bilde for å gå videre",
  "intake.PhotoStep.validationMessage.tooLargePhoto": "Bildet som er vedlagt er for stort. Maksimal filstørrelse er {fileSize}.",
  "intake.PhotoStep.validationMessage.wrongFileType": "Bildet som er vedlagt har feil filtype. Bare PNG eller JPG er tillatt.",
  "intake.PhotoStep.validationMessage.duplicatePhoto": "Dette bildet er allerede benyttet, vennligst forsøk igjen med et nytt bilde.",
  "intake.SummaryStep.header": "Sammendrag",
  "intake.SummaryStep.text": "Nesten ferdig! Her kan du gå gjennom sammendraget av informasjonen og bildene som vil bli sendt til verkstedet.",
  "intake.SummaryStep.error": "Noe gikk galt. Vennligst sjekk nettverkstilkoblingen.",
  "intake.SummaryStep.photos.header": "Bilder",
  "intake.SummaryStep.description.missing": "Ingen beskrivelse er lagt til",
  "intake.SummaryStep.information.header": "Din informasjon",
  "intake.SummaryStep.customer.name.header": "Navn",
  "intake.SummaryStep.customer.phoneNumber.header": "Telefonnum.",
  "intake.SummaryStep.customer.email.header": "E-post",
  "intake.SummaryStep.customer.registrationNumber.header": "Reg. num.",
  "intake.SummaryStep.mileage.header": "Kilometerstand",
  "intake.SummaryStep.claimDate.header": "Skadedato",
  "intake.ConfirmationStep.header": "Takk!",
  "intake.ConfirmationStep.textIf": "Bildene dine er sendt til verkstedet. {workShopName} kommer til å kontakte deg for å avtale time hos verksted.",
  "intake.ConfirmationStep.textWhiteLabel": "Bildene dine er sendt til verkstedet. {workShopName} kommer til å kontakte deg i løpet av {slaDays} arbeidsdager for å avtale time hos verksted.",
  "intake.ConfirmationStep.feedbackHeader": "Hva syntes du om fotobesiktigelsen?",
  "intake.ConfirmationStep.feedbackText.header": "Kommentar (valgfritt)",
  "intake.ConfirmationStep.feedbackText.placeholder": "Skriv en kommentar her hvis du vil fortelle oss hvordan du opplevde fotobesiktigelsen",
  "intake.ConfirmationStep.feedbackButton": "Send",
  "intake.ConfirmationStep.feedbackConfirmation": "Takk for tilbakemeldingen!",
  "intake.invalid.header": "Oops, beklager!",
  "intake.invalid.expiredOrDone": "Denne lenken er ikke lenger gyldig",
  "intake.invalid.error": "Noe gikk galt, vennligst forsøk igjen.",
  "intake.invalid.text": "Vi finner ikke siden du forsøker å nå. Det er mulig den er flyttet eller tatt den bort.",
  "intake.VolviaLandingStep.header": "Fotoinspeksjon",
  "intake.VolviaLandingStep.text": "Spar tid og penger. Ta bilder av skaden for å få en digital vurdering og bestilling fra verkstedet innen 3 dager.",
  "intake.VolviaLandingStep.link.text": "Administrasjon av personopplysninger",
  "intake.InstructionStep.header": "This is how it works",
  "intake.InstructionStep.text": "We will first ask you to enter your contact details, some information about your car and also about the damage.",
  "intake.InstructionStep.text2": "Then you will be asked to take or upload 5 photos of the damage to help the workshop with the assessment. The photos you need are one on the car from behind, one where the whole damage is visible and three close ups of the damage from different angles. See examples below.",
  "confirm.delete.title": "Slette sak",
  "confirm.delete.text": "Dette er en link som er sendt kunde, hvor vi ber om bilder av skaden. Hvis du sletter saken, vil linken ikke virke og kunden får ikke sendt inn bilder.",
  "confirm.delete.cancel": "Avbryt",
  "confirm.delete.yes": "Slett",
  "curityMigrationBannerText": "Vi har gjort en teknisk oppdatering av Photo Inspection-appen. For å logge på, vennligst endre passordet ditt via e-posten som er sendt til deg. Etter det kan du logge på som vanlig.",
  "section.bodyInspections": "Karosseri",
  "section.glassInspections": "Glass",
  "section.admin": "Admin panel",
  "glass.customerPhotos": "Kundebilder",
  "glass.inbox": "Innboks",
  "glass.workshopPhotos": "Verkstedbilder",
  "photoSource.Customer": "Kundebilder",
  "photoSource.Workshop": "Verkstedbilder"
};
export default translations;