import React from "react";

export const TestOther: React.FC = () => {
  return (
    <>
      <h2>Other</h2>
      <pre>
        localStorage language = {localStorage["if.photo.inspection.selectedLanguage"]}
        <br />
        cookie = {document.cookie ? document.cookie.substr(0, 30) + "..." : "empty"}
      </pre>
    </>
  );
};
