import "./intakeLinkDecoder.scss";
import React, { useRef } from "react";
import { Constants } from "../../backend-models";

export const Impersonate: React.FC = () => {
  const input = useRef<HTMLInputElement>();

  return (

    <div>
      <h2>Impersonate</h2>
      <input type="text" className="if input-field" ref={input} />
      <br />
      <br />
      <button className="if button primary" onClick={() => {
        document.cookie = Constants.ImpersonationCookie + "=" + input.current.value;
        document.location.href = '/';
      }}>Set impersonated user</button>
    </div>
  );
};
