import "./login.scss";

import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { withPathAndAITracking } from "../infrastructure/applicationInsights";
import { useAuth } from "../auth/authContext";

export const Login = withPathAndAITracking("/login", () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  let [redirectError] = useState(null);

  let [adVisible, setAdVisible] = useState(true);

  useEffect(() => {
    let mounted = true;

    fetch("/api/health/warmup?&ad_url=&banner_id=&bannerid=")
      .then(() => {
        if (mounted) setAdVisible(true);
      })
      .catch((err: DOMException) => {
        if (mounted) setAdVisible(false);
      });

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div className="padded-block login-page">
      {isAuthenticated && (location?.pathname === "/login" || location?.pathname === "/") && (
        <Redirect to={"/inbox-redirect"} />
      )}
      <h1 className="if heading largest center" data-cy="notFoundHeader">
        <FormattedMessage id="app.redirecting" tagName="span" />
        ...
      </h1>
      {!adVisible && (
        <span>
          <br />
          <FormattedMessage id="app.adblock" />
        </span>
      )}
      <br />
      {redirectError}
    </div>
  );
});
