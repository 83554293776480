import "./inspectionWizard.scss";

import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";

import * as Actions from "../../actions";
import { Brand, ICustomer, IInspectionIntakeRequest, WorkshopCountryCode } from "../../backend-models";
import {
  DataSetter,
  FileWithTag,
  IntakeSendingState,
  IPhotoSet,
  IPhotoSetMeta,
  IPhotoStepProps,
  IWizard,
  IWizardStepProps,
  PhotoKey,
} from "../../intakeModels";
import { AppState } from "../../models";
import Confirmation from "./confirmation";
import InspectionWizardButtons from "./inspectionWizardButtons";
import ProgressBar from "./progressBar";
import ContactInformationStep from "./steps/contactInformationStep";
import InstructionStep from "./steps/instructionStep";
import DescriptionStep from "./steps/descriptionStep";
import { LandingStep } from "./steps/landingStep";
import { PhotoStep } from "./steps/photoStep";
import SummaryStep from "./steps/summaryStep";
import { VolviaLandingStep } from "./steps/volvia/volviaLandingStep";
import { preloadImage, testableNumber, testFlagSet, testPhotos } from "../../intakeUtility";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { aiEvent, appInsights, reactPlugin, withAITracking } from "../../infrastructure/applicationInsights";
import ErrorStep from "./steps/errorStep";
import { ITelemetryItem } from "@microsoft/applicationinsights-core-js";
import { UAParser } from "ua-parser-js";
import { useEffect } from "react";

function AIComponent<P>(Component: React.ComponentType<P>): React.ComponentClass<P> {
  let ComponentWithAI = withAITracking(reactPlugin, Component);
  return class extends React.Component<P> {
    public componentDidMount() {
      let props = this.props as any as IPhotoStepProps;
      let event = `Intake: ${props.wizard.isEditing ? "Edit " : ""}${props.name}`;
      if ("photokey" in props) {
        event += ` ${props.photokey}`;
      }
      aiEvent(event);
    }
    public render() {
      return <ComponentWithAI {...this.props} />;
    }
  };
}

const PhotoStepAI = AIComponent(PhotoStep);
const LandingStepAI = AIComponent(LandingStep);
const VolviaLandingStepAI = AIComponent(VolviaLandingStep);
const InstructionStepAI = AIComponent(InstructionStep);
const ContactInformationStepAI = AIComponent(ContactInformationStep);
const DescriptionStepAI = AIComponent(DescriptionStep);
const SummaryStepAI = AIComponent(SummaryStep);

const telemetryData: Partial<{
  step: number;
  sendingState: string;
  filesUploaded: number;
  stepName: string;
  id: string;
  registrationNumber: string;
}> = {};

appInsights?.addTelemetryInitializer((item: ITelemetryItem) => {
  if (!item) return;
  item.data = { ...(item.data ?? {}), ...telemetryData };
  console.debug("telemetry props", item.data);
});

// next step flow:
//  [nextClicked] is set
//  current step chooses to either block by calling cancelNextStep()
//  or save their stuff to IIntakeData and proceed to next step by passing a DataSetter to advanceStep()
const InspectionWizard: React.FC<{
  recordId: string;
  locale: string;
  setLocale(locale: string);
  customerInformation: ICustomer;
  brand: Brand;
  workshopCountryCode: WorkshopCountryCode;
}> = (props) => {
  let dispatch = useDispatch();
  const intl = useIntl();

  let submission = useSelector((state: AppState) => state.intakeSubmission);

  if (testFlagSet("submitted")) {
    submission.sendingState = IntakeSendingState.Finished;
  }

  const photoStepKeys: PhotoKey[] = [
    "back",
    "distance",
    "closeup",
    "diagonal-front",
    "diagonal-back",
    "extra",
  ];
  const testPrefill = testFlagSet("prefill");

  const [state, setState] = React.useState<IWizard>({
    locale: props.locale,
    stepIndex: testableNumber(0, "step"),
    nextClicked: false,
    prevClicked: false,
    photoStepKeys: photoStepKeys,
    data: {
      customer: {
        email: props.customerInformation?.email ?? "",
        name: props.customerInformation?.name ?? "",
        phoneNumber:
          props.customerInformation?.phoneNumber && isValidPhoneNumber(props.customerInformation?.phoneNumber)
            ? props.customerInformation?.phoneNumber
            : "",
        registrationNumber: props.customerInformation?.registrationNumber ?? "",
      },
      mileage: testableNumber(testPrefill ? 123 : null, "mileage"),
      claimDate: null,
      photos: testPrefill && !testFlagSet("noPhotos") ? testPhotos(photoStepKeys) : {},
      description: testPrefill ? "description" : "",
      termsOfServiceAccepted: testPrefill,
    },
    brand: props.brand,
    isEditing: false,
    imagePath: props.brand === Brand.Volvia ? "/img/volvia" : "/img/if",
    setLocale: changeIntakeLocale,
    workshopCountryCode: props.workshopCountryCode,
  });

  function changeIntakeLocale(locale: string) {
    setState({ ...state, locale });
    props.setLocale(locale);
  }

  let { data, stepIndex } = state;

  let steps = populateBrandSteps();

  function stepNameToIndex(name: string, photoKey?: string) {
    if (name === "PhotoStep") {
      return steps.findIndex((step) => step.props.name === name && step.props.photokey === photoKey);
    }
    return steps.findIndex((step) => step.props.name === name);
  }

  function cancelNextStep() {
    setState({ ...state, nextClicked: false });
  }

  function goStep(stepOffset: number, mutateData: DataSetter = (_) => _) {
    let stepIndex = state.stepIndex + stepOffset;
    if (state.isEditing && state.nextClicked) {
      stepIndex = stepNameToIndex("SummaryStep");
    }
    setState({
      ...state,
      prevClicked: false,
      nextClicked: false,
      data: mutateData(data),
      stepIndex,
      isEditing: false,
    });

    document.scrollingElement.scrollTop = 0;
  }

  function editStep(stepName: string, photoKey?: string) {
    setState({
      ...state,
      isEditing: true,
      prevClicked: false,
      nextClicked: false,
      stepIndex: stepNameToIndex(stepName, photoKey),
    });
  }

  let currentStep = steps[stepIndex];
  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (currentStep) {
      document.title = `${intl.formatMessage({
        id: "intake.ui.page.title",
      })} - ${intl.formatMessage({ id: `intake.${currentStep.props.name}.header` })}`;
    }
  }, [currentStep, intl]);

  function prepareFileMeta(file: File) {
    return {
      name: file.name,
      lastModified: file.lastModified,
      size: file.size,
      type: file.type,
    };
  }
  function prepareData(): IInspectionIntakeRequest {
    let photosMeta = {};
    for (let photosKey in data.photos) {
      let step: IPhotoSet = data.photos[photosKey];
      let stepMeta = { key: step.key } as IPhotoSetMeta;
      stepMeta.files = step.files.map(prepareFileMeta);

      photosMeta[photosKey] = stepMeta;
    }
    return {
      customer: data.customer,
      description: data.description,
      mileage: data.mileage,
      claimDate: data.claimDate ? data.claimDate.toISOString() : null,
      termsOfServiceAccepted: data.termsOfServiceAccepted,
    };
  }

  function populateBrandSteps() {
    let stepProps = {
      goStep,
      cancelNextStep,
      wizard: state,
    } as IWizardStepProps;

    let steps = [];

    if (state.brand === Brand.Volvia) {
      steps.push(<VolviaLandingStepAI {...stepProps} name="VolviaLandingStep" />);
    } else {
      steps.push(<LandingStepAI {...stepProps} name="LandingStep" brand={state.brand} />);
    }

    let ua = window.ClientEnvironment.overrideUserAgent ?? window.navigator.userAgent;
    let isMobile = new UAParser(ua).getDevice().type === "mobile";
    if (!isMobile) {
      steps.push(<InstructionStepAI {...stepProps} name="InstructionStep" />);
    }

    steps.push(
      <ContactInformationStepAI {...stepProps} name="ContactInformationStep" />,
      <DescriptionStepAI {...stepProps} name="DescriptionStep" />,
      ...state.photoStepKeys.map((stepkey) => (
        <PhotoStepAI {...stepProps} photokey={stepkey} key={stepkey} name="PhotoStep" />
      )),
      <SummaryStepAI {...stepProps} name="SummaryStep" editStep={editStep} />
    );
    return steps;
  }

  const submitInspection = () => {
    appInsights.trackTrace({ message: "Clicked submit" });
    let files: FileWithTag[] = [];

    //order is important, photoStepKeys are in the right order
    photoStepKeys.forEach((photosKey) =>
      (data.photos[photosKey] as IPhotoSet).files.forEach((f, i) => {
        let withTag = f as FileWithTag;
        const extraCount = files.filter((f) => f.tag.includes("extra")).length;
        withTag.tag = photosKey.includes("extra") ? `${photosKey}-${extraCount + 1}` : photosKey;
        files.push(withTag);
      })
    );

    dispatch(
      Actions.submitIntake({
        data: prepareData(),
        recordId: props.recordId,
        files,
        currentState: submission,
      })
    );
  };

  useEffect(() => {
    telemetryData.step = stepIndex;
    telemetryData.sendingState = IntakeSendingState[submission.sendingState];
    telemetryData.filesUploaded = submission?.filesUploaded;
    telemetryData.stepName = currentStep?.props?.name;
    telemetryData.id = props?.recordId;
    telemetryData.registrationNumber = data?.customer?.registrationNumber;
  }, [stepIndex, submission, currentStep, props, data]);

  if (submission.sendingState === IntakeSendingState.Finished) {
    return (
      <div className="wizard-container">
        <Confirmation recordId={props.recordId} />
      </div>
    );
  }
  state.photoStepKeys.map((k) => `${state.imagePath}/${k}.jpg`).map(preloadImage);

  return (
    <>
      <ProgressBar currentStep={stepIndex} total={steps.length - 1} />
      <div className={"step-container " + currentStep.props.name}>
        {stepIndex > 1 && !state.isEditing && (
          <a
            href="#"
            className="if back-to-link"
            onClick={(e) => {
              e.preventDefault();
              setState({ ...state, prevClicked: true });
            }}
            data-cy="back-link"
          >
            <FormattedMessage id="intake.alt.back" />
          </a>
        )}

        <AppInsightsErrorBoundary
          appInsights={reactPlugin}
          onError={() => <ErrorStep wizard={state} goStep={goStep} />}
          key={state.stepIndex}
        >
          {currentStep}
        </AppInsightsErrorBoundary>
        <InspectionWizardButtons
          currentStep={stepIndex}
          totalSteps={steps.length}
          onPrev={() => {
            appInsights.trackTrace({ message: "Clicked back" });
            setState({ ...state, prevClicked: true });
          }}
          onNext={() => {
            appInsights.trackTrace({ message: "Clicked next" });
            setState({ ...state, nextClicked: true });
          }}
          onSubmit={submitInspection}
          onCancelSubmit={() => dispatch(Actions.submitIntakeCancel())}
          isEditing={state.isEditing}
        />
      </div>
    </>
  );
};
export default InspectionWizard;
