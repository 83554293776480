import React from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { useDispatch, useSelector } from "react-redux";
import { reactPlugin, withAITracking } from "infrastructure/applicationInsights";
import { AppState, IGlassInspectionWithWorkshop } from "models";
import * as Actions from "actions";
import { dispatchIfNeeded } from "utility";
import { PhotoSource } from "backend-models";

import Loader from "../shared/loader";
import { GlassDetailsWorkshop } from "./glassDetailsWorkshop";
import { GlassDetailsCustomer } from "./glassDetailsCustomer";

interface DetailsParams {
  id: string;
}

const _GlassDetailsPage: React.FC<{ history }> = (props) => {
  let { id } = useParams<DetailsParams>();
  const dispatch = useDispatch();

  let record: IGlassInspectionWithWorkshop = { isLoading: true } as IGlassInspectionWithWorkshop;
  const records = useSelector((state: AppState) => state.glassInspections);

  React.useEffect(() => {
    dispatchIfNeeded(dispatch, Actions.fetchGlassInspections, records);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    dispatch(Actions.fetchGlassInspection(id));
  }, [dispatch, id]);

  if (records && records.glassInspections) {
    let found = records.glassInspections.filter((r) => r.recordId === id)[0];
    if (found) {
      record = found;
    }
  }

  if (records.isLoading || record.isLoading)
    return (
      <div className="if padded-block darkBeige detail-view">
        <Loader />
      </div>
    );

  if (records.exists === false) {
    return (
      <div className="if padded-block darkBeige detail-view">
        <div className="if notification error">
          <FormattedMessage id="app.recordNotFound" />
        </div>
      </div>
    );
  }

  const isCustomer = record?.photoSource === PhotoSource.Customer;

  const DetailsView = isCustomer ? GlassDetailsCustomer : GlassDetailsWorkshop;

  return <DetailsView inspection={record} />;
};

export const GlassDetailsPage = withAITracking(reactPlugin, _GlassDetailsPage);
