
import dayjs from "dayjs";
import { shouldPolyfill } from "@formatjs/intl-locale/should-polyfill";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/fi";
import "dayjs/locale/sv";
import "dayjs/locale/nb";
import "dayjs/locale/da";
import "dayjs/locale/en-gb";

dayjs.extend(localizedFormat);

//polyfills for IE11 per https://formatjs.io/docs/guides/runtime-requirements'

if (shouldPolyfill()) {
  require("@formatjs/intl-locale/polyfill");
}
