import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { fetchGlassInspections } from "actions";
import { IGlassInspectionColumns } from "models";
import { useStateSelector } from "store";
import { dispatchIfNeeded, filterGlassType, filterStatusGlass } from "utility";
import { reactPlugin, withAITracking } from "infrastructure/applicationInsights";

import TableView from "../shared/tableView";
import { LoadableRecords } from "../shared/loadable";
import ViewHeader from "../shared/viewHeader";
import { PhotoSource, Status } from "../../backend-models";

const _GlassInProgress: React.FC = () => {
  const dispatch = useDispatch();

  const glassInspections = useStateSelector((state) => state.glassInspections);

  useEffect(() => {
    dispatchIfNeeded(dispatch, fetchGlassInspections, glassInspections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let filtered = filterGlassType(filterStatusGlass(glassInspections, Status.InProgress), PhotoSource.Customer);

  const columns: IGlassInspectionColumns = [
    "customer.name",
    "customer.registrationNumber",
    "customer.phoneNumber",
    "workshopName",
    "workshopDecision",
    "dateCreated",
  ];

  return (
    <div className="padded-block" data-cy="glass-list-page">
      <ViewHeader>
        <FormattedMessage id="page.inProgress" />
        <div className="subtitle">
          <FormattedMessage id="glass.customerPhotos" />
        </div>
      </ViewHeader>
      {!glassInspections?.isLoading && glassInspections?.glassInspections?.length === 0 ? (
        <div className="if no-items">
          <FormattedMessage id="app.noItemsInProgress" />
        </div>
      ) : (
        <>
          <LoadableRecords records={glassInspections}>
            <TableView
              isGlass={true}
              columns={columns}
              records={filtered}
              route="/glass/"
              altRoute="{intake}"
              defaultSortColumn="dateCreated"
              showActionColumn={true}
              pageBase="/glass-inProgress"
            >
              <FormattedMessage id="app.noItemsInProgress" />
            </TableView>
          </LoadableRecords>
        </>
      )}
    </div>
  );
};

export const GlassInProgress = withAITracking(reactPlugin, _GlassInProgress);
