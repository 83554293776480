import messages_id from "./id";
import messages_en from "./en";
import messages_sv from "./sv";
import messages_da from "./da";
import messages_nb from "./nb";
import messages_fi from "./fi";

export const messages = {
  id: messages_id,
  en: messages_en,
  sv: messages_sv,
  da: messages_da,
  nb: messages_nb,
  fi: messages_fi,
};