import React, { useEffect } from "react";
import { withPathAndAITracking } from "../infrastructure/applicationInsights";
import { FormattedMessage } from "react-intl";
import { useAuth } from "../auth/authContext";

export const Relogin = withPathAndAITracking("/relogin", () => {
  const { setLoggedOut } = useAuth();

  useEffect(() => {
    setLoggedOut();
  }, [setLoggedOut]);

  return (
    <div className="if container padded-block">
      <br />
      <h1 className="if heading largest center" data-cy="notFoundHeader">
        <FormattedMessage id="app.redirecting" tagName="span" />
        ...
      </h1>
    </div>
  );
});
