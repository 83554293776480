import * as React from "react";
import { reactPlugin, withAITracking } from "../infrastructure/applicationInsights";
import { FormattedMessage, useIntl } from "react-intl";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Actions from "../actions";
import { AppState } from "../models";
import { validateClaimNumber, validateRegistrationNumber } from "../validation";
import Loader from "./shared/loader";
import ViewHeader from "./shared/viewHeader";
import { deepSet, formatBrandName, mapToCountry, sortWorkshops } from "../utility";
import { Brand, INewInspectionRequest, IWorkshop, WorkshopCountryCode } from "../backend-models";
import { PhoneInput } from "./shared/phoneInput";
import classNames from "classnames";
import { useStateSelector } from "../store";

const New: React.FC = () => {
  const userInfo = useStateSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const history = useHistory();
  let intl = useIntl();
  let [validate, setValidate] = React.useState(false);

  const newInspection = useSelector((state: AppState) => state.newInspection);
  const workshops = useSelector((state: AppState) => state.workshops);

  function sendNewInspection() {
    if (!formIsValid()) {
      setValidate(true);
    } else {
      let record: INewInspectionRequest = {
        ...state,
        workshopId: state.workshop.id,
        brand: Brand[state.brand],
        sendSms: true,
        createdBy: userInfo.name,
      };

      dispatch(Actions.newInspection(record));
    }
  }

  React.useEffect(() => {
    dispatch(Actions.fetchWorkshops());
  }, [dispatch]);

  let [state, setState] = React.useState({
    customer: {
      registrationNumber: "",
      phoneNumber: "",
      name: "",
      email: "",
    },
    claimNumber: "",
    workshop: null as IWorkshop,
    brand: "",
  });

  function disabledWorkshop() {
    return workshops.workshops.length <= 1;
  }

  function validRegistrationNumber() {
    return validateRegistrationNumber(state.customer.registrationNumber);
  }

  function validPhoneNumber() {
    return isValidPhoneNumber(state.customer.phoneNumber);
  }

  function validWorkshop() {
    return state.workshop != null || disabledWorkshop();
  }

  function validBrand() {
    return state.brand != null && state.brand.trim() !== "";
  }

  function validName() {
    let name = state.customer.name;
    return name != null && name.trim() !== "" && name.length > 2 && name.length <= 200;
  }

  function validClaimNumber() {
    return validateClaimNumber(state.claimNumber) || state.claimNumber === "";
  }

  function setNewState(prop, val) {
    setState(deepSet({ ...state }, prop, val));
  }

  function formIsValid(): boolean {
    if (newInspection.isLoading) return false;

    return (
      validRegistrationNumber() &&
      validPhoneNumber() &&
      validClaimNumber() &&
      validWorkshop() &&
      validBrand() &&
      (window.ClientEnvironment.flags.prefillIntake ? validName() : true)
    );
  }

  React.useEffect(() => {
    if (newInspection.saved) {
      dispatch(Actions.newInspectionReset());
      history.push("/inbox");
    }
  }, [newInspection.saved, history, dispatch]);

  React.useEffect(() => {
    if (workshops?.workshops != null) {
      if (workshops.workshops.length === 1) {
        let workshop = workshops.workshops[0];
        let brand = "";
        let enabled = workshop.brands.filter((b) => isBrandEnabled(workshop.countryCode, b));
        if (enabled.length === 1) {
          brand = Brand[enabled[0]];
        }
        setState((state) => ({ ...state, workshop, brand }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workshops]);

  let enabledBrands = window.ClientEnvironment.enabledBrands;
  function isCountryEnabled(country) {
    return Boolean(enabledBrands[country]);
  }
  function isBrandEnabled(country, brand: Brand) {
    let countryBrands = enabledBrands[country];
    return Boolean(countryBrands.find((b) => b === Brand[brand]));
  }

  let hasWhitelabel = false;
  let selectedWorkshopEnabledBrands = state.workshop?.brands?.filter((b) => {
    let enabled = isBrandEnabled(state.workshop?.countryCode, b);
    if (!enabled) {
      console.log(
        `Hiding brand ${state.workshop?.id}/${state.workshop?.countryCode}/${Brand[b]} - disabled by config`
      );
    } else if (b === Brand.NotBranded) {
      hasWhitelabel = true;
      return false;
    }
    return enabled;
  });
  if (hasWhitelabel) {
    selectedWorkshopEnabledBrands.push(Brand.NotBranded);
  }

  React.useEffect(() => {
    if (selectedWorkshopEnabledBrands?.length === 1) {
      setState((state) => ({ ...state, brand: Brand[selectedWorkshopEnabledBrands[0]] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.workshop]);

  let defaultCountry = "SE";

  let dropdownStyles = "if dropdown";
  if (workshops?.workshops?.length > 100) {
    dropdownStyles = "lightweight-dropdown";
  }

  if (workshops.workshops?.[0]?.countryCode) {
    defaultCountry = workshops.workshops[0].countryCode;
  } else {
    defaultCountry = WorkshopCountryCode[mapToCountry(userInfo.preferredLanguageCode)];
  }

  let content;

  if (newInspection.saved) {
    content = (
      <div>
        <FormattedMessage id="newInspection.sent" />
      </div>
    );
  } else if (
    !workshops ||
    !workshops.workshops ||
    !workshops.workshops.length ||
    workshops.isLoading ||
    userInfo.isLoading
  ) {
    content = <Loader />;
  } else {
    content = (
      <div>
        <div className="if input-wrapper">
          <input
            placeholder="ABC123"
            id="input-customer.registrationNumber"
            name="input-customer.registrationNumber"
            type="text"
            data-size="small"
            className={`if input-field ${validate && !validRegistrationNumber() ? "is-invalid" : ""}`}
            value={state.customer.registrationNumber}
            onChange={(e) => setNewState("customer.registrationNumber", e.target.value)}
          />
          <label className="if" htmlFor="input-customer.registrationNumber">
            <FormattedMessage id="newInspection.registrationNumber" />
          </label>
          <span className="if input-error validation-message">
            <FormattedMessage id="newInspection.registrationNumber.validationMessage" />
          </span>
        </div>
        <div className="if input-wrapper">
          <input
            placeholder="0123456789"
            id="input-claimNumber"
            name="input-claimNumber"
            type="text"
            data-size="small"
            className={`if input-field ${validate && !validClaimNumber() ? "is-invalid" : ""}`}
            value={state.claimNumber}
            onChange={(e) => setNewState("claimNumber", e.target.value.toUpperCase())}
          />
          <label className="if" htmlFor="input-claimNumber">
            <FormattedMessage id="newInspection.claimNumber" />
          </label>
          <span className="if input-error validation-message">
            <FormattedMessage id="newInspection.claimNumber.validationMessage" />
          </span>
        </div>
        {window.ClientEnvironment.flags.prefillIntake && (
          <div className="if input-wrapper">
            <input
              placeholder="First Last"
              id="input-customer.name"
              name="input-customer.name"
              type="text"
              className={`if input-field ${validate && !validName() ? "is-invalid" : ""}`}
              data-size="small"
              value={state.customer.name}
              onChange={(e) => setNewState("customer.name", e.target.value)}
            />
            <label className="if" htmlFor="input-customer.name">
              <FormattedMessage id="newInspection.customerName" />
            </label>
            <span className="if input-error validation-message">
              <FormattedMessage id="newInspection.customerName.validationMessage" />
            </span>
          </div>
        )}
        <div className="if input-wrapper">
          <PhoneInput
            id="input-customer.phoneNumber"
            name="input-customer.phoneNumber"
            className={`if input-field ${validate && !validPhoneNumber() ? "is-invalid" : ""}`}
            value={state.customer.phoneNumber}
            onChange={(e) => setNewState("customer.phoneNumber", e)}
            defaultCountry={defaultCountry}
            disableSuggestions={true}
          />
          <label className="if" htmlFor="input-customer.phoneNumber">
            <FormattedMessage id="newInspection.customerPhoneNumber" />
          </label>
          <span className="if input-error validation-message">
            <FormattedMessage id="newInspection.phone.validationMessage" />
          </span>
        </div>
        <div className="if input-wrapper">
          <select
            name="select-workshop"
            disabled={disabledWorkshop()}
            data-cy="workshop"
            className={classNames(dropdownStyles, { "is-invalid": validate && !validWorkshop() })}
            value={state.workshop?.id}
            onChange={(e) => {
              const selectedWorkshop = workshops.workshops.find((w) => w.id === e.target.value);
              setState({
                ...state,
                workshop: selectedWorkshop,
                brand: selectedWorkshop?.brands.length > 1 ? "" : Brand[selectedWorkshop?.brands[0]],
              });
            }}
          >
            <option value="">{intl.formatMessage({ id: "newInspection.select.workshop" })}</option>
            {sortWorkshops(workshops.workshops)
              .filter((w) => isCountryEnabled(w.countryCode))
              .map((w) => (
                <option key={w.id + w.name} value={w.id}>
                  {w.name + " (" + w.city + ") (" + w.id + ")"}
                </option>
              ))}
          </select>
          <label className="if input-label" htmlFor="select-workshop">
            <FormattedMessage id="newInspection.workshop" />
          </label>
          <span className="if input-error validation-message">
            <FormattedMessage id="newInspection.workshop.validationMessage" />
          </span>
        </div>
        <div className="if input-wrapper">
          <select
            disabled={!state.workshop || selectedWorkshopEnabledBrands?.length <= 1}
            data-cy="brand"
            name="select-brand"
            className={`if dropdown ${validate && !validBrand() ? "is-invalid" : ""}`}
            value={state.brand}
            onChange={(e) => setState({ ...state, brand: e.target.value })}
          >
            <option value="">{intl.formatMessage({ id: "newInspection.select.brand" })}</option>
            {selectedWorkshopEnabledBrands?.map((b: Brand) => (
              <option key={b} value={Brand[b]}>
                {formatBrandName(b, intl)}
              </option>
            ))}
          </select>
          <label className="if input-label" htmlFor="select-brand">
            <FormattedMessage id="newInspection.brand" />
          </label>
          <span className="if input-error validation-message">
            <FormattedMessage id="newInspection.brand.validationMessage" />
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="padded-block">
      <ViewHeader>
        <FormattedMessage id="page.new" />
      </ViewHeader>
      <br />
      {content}
      {newInspection.isLoading ? <Loader /> : null}
      <br />
      <button data-cy="newInspectionButton" className={"if button primary "} onClick={sendNewInspection}>
        <div className="if icon ui outgoing-call white" />
        <FormattedMessage id="newInspection.sendLinkToCustomer" />
      </button>
    </div>
  );
};

export default withAITracking(reactPlugin, New);
