import { SagaIterator } from "redux-saga";
import { all, call, put, takeLeading } from "redux-saga/effects";
import * as Actions from "../actions";
import { processInspectionsResponse } from "../models";
import { showError } from "./common";
import { Backend } from "../backend";
import { tryUsePreloaded } from "./api";
import { perfMetrics } from "../utility";
import { IBodyInspectionsResponse } from "../backend-models";

let firstRequest = true;

function* fetchRecords() {
  try {
    let result: IBodyInspectionsResponse = null;

    if (firstRequest) {
      firstRequest = false;
      try {
        result = yield call(tryUsePreloaded, Backend.URL.Record.GetRecords());
      } catch {}
    }

    if (!result) result = yield* Backend.Record.GetRecords();

    perfMetrics.records = perfMetrics.records ?? new Date();

    yield put(Actions.fetchRecordsSuccess(processInspectionsResponse(result)));
  } catch (err) {
    yield showError({ message: "Could not fetch inspections: " + err.message });
    yield all([put(Actions.fetchRecordsError(err))]);
  }
}
export default function* fetchRecordsSaga(): SagaIterator {
  yield takeLeading(Actions.fetchRecords, fetchRecords);
}
