import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { reactPlugin, withAITracking } from "infrastructure/applicationInsights";
import { getPossibleStatusChanges, IGlassInspectionWithWorkshop, IStatusChange } from "models";
import { Brand, GlassDecision, Status } from "backend-models";

import PhotoViewer from "../shared/photoViewer";
import { GlassInfo } from "../shared/recordInfo";
import Loader from "../shared/loader";
import Tag from "../shared/tag";
import * as Actions from "../../actions";
import { purgeRecord } from "../../admin/actions";
import { useAuth } from "../../auth/authContext";
import { useDispatch } from "react-redux";
import { useComponentVisible } from "../shared/componentVisibleHelper";
import { useConfirm } from "../shared/useConfirm";
import { RecordComments } from "../shared/recordComments";

const DecisionSwitch: React.FC<{
  isLoading;
  decision: GlassDecision;
  setDecision: (decision: GlassDecision) => void;
}> = ({ isLoading, decision, setDecision }) => {
  return (
    <div className="if segmented-control" role="radiogroup">
      {isLoading ? (
        <>
          <input
            type="radio"
            id="segment-loading"
            name="segment-loading"
            checked={false}
            onChange={() => {}}
          />
          <label htmlFor="segment-loading" className="segment-loading">
            <Loader />
            &nbsp;
          </label>
        </>
      ) : (
        <>
          <input
            type="radio"
            id="segment-pre-inspection"
            name="segment-decision"
            checked={decision === GlassDecision.Replace}
            onChange={() => {}}
          />
          <label htmlFor="segment-pre-inspection" onClick={() => setDecision(GlassDecision.Replace)}>
            <FormattedMessage id="glassDecision.Replace" />
          </label>

          <input
            type="radio"
            id="segment-repair"
            name="segment-decision"
            checked={decision === GlassDecision.Repair}
            onChange={() => {}}
          />
          <label htmlFor="segment-repair" onClick={() => setDecision(GlassDecision.Repair)}>
            <FormattedMessage id="glassDecision.Repair" />
          </label>
        </>
      )}
    </div>
  );
};

const _GlassDetailsCustomer: React.FC<{ inspection: IGlassInspectionWithWorkshop }> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const record = props.inspection;
  const { isAdministrator } = useAuth();
  const { confirmPopUp, setConfirmPopUp } = useConfirm();
  const {
    ref: refActionMenu,
    isComponentVisible: actionMenuOpen,
    setIsComponentVisible: setActionMenuOpen,
  } = useComponentVisible(false);

  let possibleStatusChanges: IStatusChange[] = [];

  if (record) {
    possibleStatusChanges = getPossibleStatusChanges(record, intl);
  }

  function changeStatus(status: Status) {
    setActionMenuOpen(false);
    dispatch(
      Actions.changeGlassInspectionStatus({
        inspectionId: record.recordId,
        status: status,
        decision: null,
      })
    );
  }

  function changeDecision(decision: GlassDecision) {
    console.log("changeDecision");
    if (record.workshopDecision === decision) {
      decision = GlassDecision.NotAvailable;
    }
    dispatch(
      Actions.changeGlassInspectionStatus({
        inspectionId: record.recordId,
        status: null,
        decision: decision,
      })
    );
  }

  return (
    <>
      {confirmPopUp}
      <div className="if padded-block darkBeige detail-view">
        <div className="detail-row">
          <div className="detail-column info-detail-column">
            <section>
              <button className="if back-to-link button tertiary" onClick={history.goBack}>
                <FormattedMessage id="link.back" />
              </button>
              <div className="header-container">
                <div className="header-heading-container">
                  <h1 className="if heading">{record.customer?.registrationNumber}</h1>
                  <Tag status={record.status} />
                </div>
                {![Brand.NotBranded, Brand.BMW].includes(record.brand) && (
                  <img
                    className="header-brand-logo"
                    src={`/img/${Brand[record.brand]}/logo.svg`}
                    alt={Brand[record.brand]}
                  />
                )}
              </div>
              <div className="status-and-decision">
                {record.status !== Status.Open && (
                  <DecisionSwitch
                    setDecision={changeDecision}
                    decision={record.workshopDecision}
                    isLoading={record.isStatusLoading}
                  />
                )}

                <div className="if status-menu" ref={refActionMenu}>
                  <button
                    className="if button icon ui more-vertical"
                    onClick={() => setActionMenuOpen(!actionMenuOpen)}
                  />
                  <nav className={"if menu " + (actionMenuOpen ? "is-open" : "")}>
                    <ul className="if">
                      {!(record.status === Status.Open) && (
                        <li>
                          <div className="text menu-item-text">
                            <FormattedMessage id="action.moveTo" />
                          </div>
                        </li>
                      )}
                      {possibleStatusChanges.map((change) => {
                        return (
                          <li key={change.status}>
                            <button
                              className="if"
                              onClick={() => {
                                setActionMenuOpen(false);
                                if (change.status === Status.Deleted) {
                                  setConfirmPopUp({
                                    title: intl.formatMessage({ id: "confirm.delete.title" }),
                                    text: intl.formatMessage({ id: "confirm.delete.text" }),
                                    confirmAction: () => {
                                      changeStatus(change.status);
                                    },
                                  });
                                } else {
                                  changeStatus(change.status);
                                }
                              }}
                            >
                              {change.status !== Status.Deleted ? (
                                <span className="if icon ui corner-left-round menu-icon" />
                              ) : null}
                              {change.text}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </nav>
                </div>
              </div>
              {isAdministrator && (
                <div className="if actions admin">
                  {getPossibleStatusChanges(record, intl, true).map((change) => (
                    <button
                      className="if button primary status-button"
                      onClick={() => {
                        changeStatus(change.status);
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      }}
                      key={change.status}
                    >
                      {change.text}
                    </button>
                  ))}
                  <br />
                  <button
                    className="if button primary delete status-button"
                    onClick={() => dispatch(purgeRecord(record.recordId))}
                    data-cy="purgeRecord"
                  >
                    Delete
                  </button>
                </div>
              )}
            </section>
            <GlassInfo glassInspection={record} key={record.recordId} />
            <RecordComments record={record} isGlass={true} />
          </div>
          <div className="detail-column photo-detail-column">
            <PhotoViewer record={record} />
          </div>
        </div>
      </div>
    </>
  );
};

export const GlassDetailsCustomer = withAITracking(reactPlugin, _GlassDetailsCustomer);
