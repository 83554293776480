import React from "react";
import {
  IGlassInspectionWithWorkshop,
  IInspection,
  IInspectionWithWorkshop,
  InspectionProperty,
  toDisplay,
} from "../../models";
import { formatDateTime, Translation } from "../../utility";
import { FormattedMessage, useIntl } from "react-intl";
import "./recordInfo.scss";
import { PhotoSource } from "../../backend-models";

interface RecordValueProps {
  record: IInspection;
  field: InspectionProperty;
  value?: Translation;
  showEmpty?: boolean;
  fullWidth?: boolean;
  translate?: boolean;
  isGlass?: boolean;
}

const RecordValue: React.FC<RecordValueProps> = ({
  record,
  field,
  value,
  showEmpty,
  fullWidth,
  translate,
  isGlass,
}) => {
  value = value ?? toDisplay(record, field, showEmpty ? "-" : "");

  return (
    <div className={`if col-${fullWidth ? 12 : 6}--smlr`}>
      <label className="if">
        <FormattedMessage id={"field." + (isGlass ? "glass." : "") + field} />
      </label>
      <br />
      <span data-cy={field} className="record-info-value" translate={translate ? "yes" : "no"}>
        {value}
      </span>
    </div>
  );
};

const Row = ({ children }) => <div className="if row">{children}</div>;
const Section = ({ children }) => <div className="field-container">{children}</div>;
type ValueProps = Omit<RecordValueProps, "record">;

export const RecordInfo: React.FC<{ record: IInspectionWithWorkshop }> = (props) => {
  let record = props.record;
  let intl = useIntl();
  let Value = (props: ValueProps) => <RecordValue record={record} {...props} />;

  return (
    <div className="if across grid record-info-grid">
      <Section>
        <Row>
          <Value field="customer.name" />
          <Value field="customer.phoneNumber" />
        </Row>
        <Row>
          <Value field="customer.email" fullWidth={true} />
        </Row>
      </Section>
      <Section>
        <Row>
          <Value field="claimNumber" value={record.claimNumber || record.externalId} />
          <Value field="claimDate" />
        </Row>
        <Row>
          <Value field="mileage" />
        </Row>
        <Row>
          <Value field="description" fullWidth={true} showEmpty={true} translate={true} />
        </Row>
      </Section>
      <Section>
        <Row>
          <Value field="decision" showEmpty={true} translate={true} />
          <Value field="dateDecided" showEmpty={true} />
        </Row>
        <Row>
          <Value field="decidedBy" />
        </Row>
        <Row>
          <Value
            field="dateCreated"
            value={formatDateTime(record.intakeReminderSmsSentDate || record.intakeSmsSentDate, intl)}
          />
          <Value field="createdBy" />
        </Row>

        <Row>
          <Value field="dateOfIntake" />
          <Value field="workshopName" />
        </Row>
      </Section>
    </div>
  );
};

export const GlassInfo: React.FC<{ glassInspection: IGlassInspectionWithWorkshop }> = (props) => {
  let record = props.glassInspection;
  let Value = (props: ValueProps) => <RecordValue record={record} {...props} />;

  const isCustomer = record?.photoSource === PhotoSource.Customer;

  return isCustomer ? (
    <div className="if across grid record-info-grid">
      <Section>
        <Row>
          <Value field="customer.name" />
          <Value field="customer.phoneNumber" />
        </Row>
        <Row>
          <Value field="customer.email" fullWidth={true} />
        </Row>
        <Row>
          <Value field="claimNumber" />
          <Value field="claimDate" />
        </Row>
        <Row>
          <Value field="description" fullWidth={true} />
        </Row>
        <Row>
          <Value field="workshopDecision" showEmpty={true} translate={true} />
          <Value field="dateDecided" />
          <Value field="decidedBy" />
          <Value field="photoSource" />
        </Row>
        <Row>
          <Value field="dateCreated" isGlass={true} />
          <Value field="workshopName" />
        </Row>
      </Section>
    </div>
  ) : (
    <div className="if across grid record-info-grid">
      <Section>
        <Row>
          <Value field="workshopName" />
          <Value field="photoSource" />
        </Row>
        <Row>
          <Value field="dateCreated" isGlass={true} />
          <Value field="workshopDecision" />
        </Row>
      </Section>
    </div>
  );
};
