import * as Actions from "../admin/actions";
import reducer from "./reducer";
import { ISmsTemplate } from "../models";

export default reducer<ISmsTemplate>({ result: "", isLoading: false } as ISmsTemplate, (on) => {
  on(Actions.fetchSmsTemplate, (_) => {
    return {
      ..._,
      isLoading: true,
      loadError: undefined,
    };
  });

  on(Actions.fetchSmsTemplateSuccess, (_, action) => {
    return {
      ..._,
      isLoading: false,
      result: action.payload,
    };
  });

  on(Actions.fetchSmsTemplateError, (_, action) => {
    return {
      ..._,
      isLoading: false,
      loadError: `Failed to load user information: ${action.payload.message}`,
    };
  });
});
