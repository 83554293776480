import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import * as Actions from "../actions";
import { Backend } from "../backend";
import { tryUsePreloaded } from "./api";
import { history } from "../store";
import { showError } from "./common";
import { setCurrentUser } from "../utility";
import { IUserInfo } from "../backend-models";

let firstRequest = true;
function* fetchUserInfo() {
  try {
    let result: IUserInfo = null;

    if (firstRequest) {
      firstRequest = false;
      try {
        result = yield call(tryUsePreloaded, Backend.URL.UserInfo.GetUserInfo());
      } catch {}
    }
    if (!result) result = yield* Backend.UserInfo.GetUserInfo();

    if (result.isDeleted) yield history.push("/logout");

    setCurrentUser(result.email);

    yield put(Actions.fetchUserInfoSuccess(result));
  } catch (err) {
    yield showError({ message: "Could not load user information: " + err.message });
    yield put(Actions.fetchUserInfoError(err));
  }
}
export default function* fetchUserInfoSaga(): SagaIterator {
  yield takeLatest(Actions.fetchUserInfo, fetchUserInfo);
}
