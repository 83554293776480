import * as Actions from "../../actions";
import reducer from "../reducer";
import { IRecords } from "../../models";
import { recordLoading, recordError, updateRecord } from "./index";
import { updateRecordSuccessReducer } from "./updateRecord";

export default reducer<IRecords>(
  { isLoading: false, records: [], loadError: undefined, workshopFilter: {} },
  (on) => {
    on(Actions.fetchRecord, (_, action) => {
      return recordLoading(_, action.payload);
    });

    on(Actions.fetchRecordSuccess, updateRecordSuccessReducer);

    on(Actions.sendIntakeLinkReminderSuccess, (_, action) => {
      return updateRecord(_, { ...action.payload, isLoading: false });
    });

    on(Actions.fetchRecordError, (_, action) => {
      return { ...recordError(_, action.payload, "Failed to load record"), exists: false };
    });
  }
);
