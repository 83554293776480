import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { checkIE } from "../utility";
import ieWarning from "./shared/ieWarning";
import { InfoNotifications } from "./infoNotifications";
import translations_en from "../translations/en";
import translations_sv from "../translations/sv";
import translations_nb from "../translations/nb";
import translations_fi from "../translations/fi";
import translations_da from "../translations/da";

const Landing: React.FC = () => {
  const history = useHistory();
  let isIE11 = checkIE();
  return (
    <div className="workshop-landing-container centered padded">
      {isIE11 && (
        <>
          <br />
          <br />
          <br />
        </>
      )}
      <InfoNotifications />
      {window.ClientEnvironment.flags.showCurityBanner && (
        <div className="info-notifications" data-cy="info-notifications" style={{ marginTop: "-5em" }}>
          <div className="info-notification if notification" style={{ flexDirection: "column" }}>
            <span className="if heading smallest">Attention / Obs / Huomio</span>
            <p>
              {translations_en.curityMigrationBannerText}
              <br />
              <br />
              {translations_sv.curityMigrationBannerText}
              <br />
              <br />
              {translations_nb.curityMigrationBannerText}
              <br />
              <br />
              {translations_fi.curityMigrationBannerText}
              <br />
              <br />
              {translations_da.curityMigrationBannerText}
            </p>
          </div>
        </div>
      )}
      <div className="landing-logo-container">
        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
        <img src="img/logo-phin.svg" alt="Photo Inspection" />
      </div>
      <h1 className="if header">PHOTO INSPECTION</h1>
      <button
        type="button"
        className="if button primary workshopButton"
        data-cy="button-go-to-workshop"
        onClick={(e) => {
          e.preventDefault();
          history.replace("/login");
        }}
      >
        <FormattedMessage id="field.workshopName" />
        <div className="if icon product vehicles white" />
        <div className="if icon ui drop-right white" />
      </button>
      {isIE11 && ieWarning}
    </div>
  );
};

export default Landing;
