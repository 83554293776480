import React from "react";
import { withAITracking, reactPlugin } from "../infrastructure/applicationInsights";
import { FormattedMessage } from "react-intl";

const Error = () => {
  let urlParams = new URLSearchParams(document.location.search);
  return (
    <div className="if container padded-block">
      <h1 className="if heading largest center" data-cy="notFoundHeader">
        <FormattedMessage id="app.error" />
      </h1>
      <p className="if text lead center">{urlParams.get("message")}</p>
    </div>
  );
};

export default withAITracking(reactPlugin, Error);
