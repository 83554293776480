import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { AppContext, IAppContext, IAppContextData } from "models";

import EnglishTranslations from "../translations/en";
import AuthRoute from "../auth/authRoute";
import Archive from "./archive";
import DetailsPage from "./details";
import Inbox from "./inbox";
import InProgress from "./inProgress";
import { Login } from "./login";
import Logout from "./logout";
import Header from "./navigation/header";
import SideNavigation from "./navigation/sideNavigation";
import New from "./new";
import Error from "./error";
import OnHold from "./onHold";
import OpenCases from "./open";
import Search from "./search";
import { GlassInbox } from "components/glass/glassInbox";
import { GlassInProgress } from "./glass/glassInProgress";
import { GlassArchive } from "./glass/glassArchive";
import { GlassSearch } from "./glass/glassSearch";
import { GlassDetailsPage } from "./glass/glassDetails";
import TestPage from "../admin/components/testPage";
import UnAuthorized from "./unAuthorized";
import Forbidden from "./forbidden";
import { useIntl } from "react-intl";
import { Notifications } from "./shared/notifications";
import StatsPage from "../admin/components/statsPage";
import Feedback from "../admin/components/feedback";
import { WorkshopFeedback as AdminWorkshopFeedback } from "../admin/components/workshopFeedback";
import { aiEvent } from "../infrastructure/applicationInsights";
import { IUserInfo } from "../backend-models";
import { useSelector } from "react-redux";
import { AppState } from "../models";
import EditNotifications from "../admin/components/editNotifications";
import { InfoNotifications } from "./infoNotifications";
import { WorkshopFeedback } from "./workshopFeedback";
import { WorkshopVisibility } from "../admin/components/workshopVisibility";
import InboxRedirect from "./inboxRedirect";
import SettingsPage from "./settings";
import { LoggedOut } from "../page/loggedOut";
import { ComponentWithPath } from "../utility";
import { Relogin } from "../page/relogin";

const WorkshopStartAIEvent: React.FC = () => {
  const userInfo: IUserInfo = useSelector((state: AppState) => state.userInfo);
  React.useEffect(() => {
    if (userInfo?.name) {
      aiEvent("Workshop: Start");
    }
  }, [userInfo?.name]);
  return null;
};

const WorkshopTitleSetter: React.FC<{ pageTranslationId }> = ({ pageTranslationId }) => {
  const userInfo: IUserInfo = useSelector((state: AppState) => state.userInfo);
  const intl = useIntl();
  useEffect(() => {
    let pageName = EnglishTranslations[pageTranslationId];

    if (userInfo?.name && pageName) {
      aiEvent("Workshop: " + pageName);

      document.title =
        intl.formatMessage({ id: "workshop.ui.page.title" }) +
        " - " +
        intl.formatMessage({ id: pageTranslationId });
    }
  }, [intl, pageTranslationId, userInfo?.name]);
  return null;
};

const WorkshopUi: React.FC<{ locale: any; setLocale: any }> = (props) => {
  const location = useLocation() ?? window.location;
  const pathnamePage = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);

  const showSideNavigation = !["unauthorized", LoggedOut.Path, Login.Path].some((path) =>
    ("/" + pathnamePage.toLowerCase()).includes(path)
  );

  let pageTranslationId = "page." + pathnamePage;

  let pathname = document.location.pathname;

  document.documentElement.classList.add("workshop-ui");
  document.documentElement.classList.remove("intake");

  function ComponentRoute(component: ComponentWithPath) {
    return <Route exact path={component.Path} component={component} />;
  }
  function AuthComponentRoute(component: ComponentWithPath) {
    return <AuthRoute path={component.Path} component={component} />;
  }

  const [contextData, setContextData] = useState<IAppContextData>({customerPhotos: true});

  const appContext: IAppContext = {
    ...contextData,
    mutate: setContextData,
  };

  return (
    <AppContext.Provider value={appContext}>
      <WorkshopStartAIEvent />
      <WorkshopTitleSetter pageTranslationId={pageTranslationId} />
      <InfoNotifications showCurityBanner={window.ClientEnvironment.flags.showCurityBanner} />
      <WorkshopFeedback />
      <Header locale={props.locale} setLocale={props.setLocale} />
      <div className="main-container">
        {showSideNavigation && <SideNavigation />}
        <main className="if main color background lighterBeige">
          <Switch>
            {AuthComponentRoute(Login)}
            <Route path="/logout" component={Logout} />

            <Route exact path="/unauthorized" component={UnAuthorized} />
            <Route exact path="/forbidden/:id?" component={Forbidden} />
            <Route exact path="/login-failed" component={Error} />
            {ComponentRoute(LoggedOut)}
            {ComponentRoute(Relogin)}

            <AuthRoute path="/inbox-redirect" exact component={InboxRedirect} />

            <AuthRoute path="/inbox" exact component={Inbox} />
            <AuthRoute path="/open" exact component={OpenCases} />
            <AuthRoute path="/new" exact component={New} />
            <AuthRoute path="/inProgress" exact component={InProgress} />
            <AuthRoute path="/onHold" exact component={OnHold} />
            <AuthRoute path="/archive" exact component={Archive} />
            <AuthRoute path="/details/:id" exact component={DetailsPage} />
            <AuthRoute path="/search" exact component={Search} />

            <AuthRoute path="/glass/:id" exact component={GlassDetailsPage} />
            <AuthRoute path="/glass-inbox" exact component={GlassInbox} />
            <AuthRoute path="/glass-inProgress" exact component={GlassInProgress} />
            <AuthRoute path="/glass-archive" exact component={GlassArchive} />
            <AuthRoute path="/glass-search" exact component={GlassSearch} />

            <AuthRoute path="/test" exact component={TestPage} />
            <AuthRoute path="/stats" exact component={StatsPage} />
            <AuthRoute path="/feedback" exact component={Feedback} />
            <AuthRoute path="/workshop-feedback" exact component={AdminWorkshopFeedback} />
            <AuthRoute path="/workshop-visibility" exact component={WorkshopVisibility} />
            <AuthRoute path="/notifications" exact component={EditNotifications} />

            <AuthRoute path="/settings" exact component={SettingsPage} />

            <Redirect to={"/missing" + pathname} />
          </Switch>
        </main>
      </div>
      <Notifications />
    </AppContext.Provider>
  );
};
export default WorkshopUi;
