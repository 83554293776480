import { buffers, eventChannel, Subscribe, Unsubscribe, END } from "redux-saga";

export interface UploadFileEvent {
  err?: Error;
  progress?: number;
  success?: boolean;
}
export function createUploadFileChannel(url: string, file: File) {
  let subscribe: Subscribe<UploadFileEvent> = (emit) => {
    const xhr = new XMLHttpRequest();
    const onProgress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emit({ progress });
      }
    };
    const onFailure = (e: ProgressEvent) => {
      emit({ err: new Error("Upload failed") });
      emit(END);
    };
    xhr.upload.addEventListener("progress", onProgress);
    xhr.upload.addEventListener("error", onFailure);
    xhr.upload.addEventListener("abort", onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      if (readyState === 4) {
        if (status === 200) {
          emit({ success: true });
          emit(END);
        } else {
          onFailure(null);
        }
      }
    };
    xhr.open("POST", url, true);
    let data = new FormData();
    data.append("file", file, file.name);
    xhr.send(data);
    return (() => {
      xhr.upload.removeEventListener("progress", onProgress);
      xhr.upload.removeEventListener("error", onFailure);
      xhr.upload.removeEventListener("abort", onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    }) as Unsubscribe;
  };
  return eventChannel<UploadFileEvent>(subscribe, buffers.sliding(2));
}
