import * as React from "react";
import {useEffect, useState} from "react";
import "./confirmation.scss";
import {FormattedMessage, useIntl} from "react-intl";
import {AppState} from "../../models";
import {useDispatch, useSelector} from "react-redux";
import {Brand, IIntakeInformation} from "../../backend-models";
import {IIntakeSubmission} from "../../intakeModels";
import * as Actions from "../../actions";
import InputField from "./fields/inputField";

const Confirmation: React.FC<{ recordId }> = ({recordId}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const intakeInfo: IIntakeInformation = useSelector((state: AppState) => state.intake);
  const {feedback}: IIntakeSubmission = useSelector((state: AppState) => state.intakeSubmission);
  const [text, setText] = useState("");

  useEffect(() => {
    document.scrollingElement.scrollTop = 0;
  }, []);

  const showFeedbackText = feedback.positive !== undefined;
  const showFeedbackSent = feedback.text !== undefined;

  function feedbackThumbs(positive: boolean) {
    dispatch(Actions.submitIntakeFeedback({id: recordId, data: {positive}}));
  }

  function feedbackText() {
    dispatch(Actions.submitIntakeFeedback({id: recordId, data: {...feedback, text: text}}));
  }

  return (
    <div>
      <div className="if container ConfirmationStep" style={{marginTop: "2rem"}}>
        <h1 className="if header-text" data-cy="confirmationHeader">
          <FormattedMessage id="intake.ConfirmationStep.header"/>
        </h1>

        <p className="if basic-text" data-cy="confirmationMessage">
          {(intakeInfo?.brand === Brand.Volvia || intakeInfo?.brand === Brand.Renault) ? (
            <FormattedMessage
              id="intake.ConfirmationStep.textWhiteLabel"
              values={{
                workShopName: intakeInfo?.workshopName,
                slaDays: intakeInfo?.serviceLevelAgreement ?? 3,
              }}
            />
          ) : (
            <FormattedMessage
              id="intake.ConfirmationStep.textIf"
              values={{
                workShopName: intakeInfo?.workshopName,
                slaDays: intakeInfo?.serviceLevelAgreement ?? 3,
              }}
            />
          )}
        </p>

        <div className="feedback-container">
          <hr/>
          <h2>
            {showFeedbackSent ? (
              <span data-cy="feedback-confirmation">
                  <FormattedMessage id="intake.ConfirmationStep.feedbackConfirmation"/>
                </span>
            ) : (
              <FormattedMessage id="intake.ConfirmationStep.feedbackHeader" tagName="span"/>
            )}
          </h2>
          {!showFeedbackSent && (
            <div className="thumbs">
              <button onClick={() => feedbackThumbs(true)} data-cy="thumbs-up">
                <img
                  alt="Thumb Up"
                  src={"/img/intake/thumb-up" + (feedback.positive === true ? "-filled.svg" : ".svg")}
                />
              </button>
              <button onClick={() => feedbackThumbs(false)} data-cy="thumbs-down">
                <img
                  alt="Thumb Down"
                  src={"/img/intake/thumb-down" + (feedback.positive === false ? "-filled.svg" : ".svg")}
                />
              </button>
            </div>
          )}
          {showFeedbackText && !showFeedbackSent && (
            <div className="text">
              <InputField
                onChange={(e) => setText(e.target.value)}
                value={text}
                noValidation={true}
                intlPrefix="intake.ConfirmationStep."
                name="feedbackText"
                type="textarea"
                inputProps={{
                  placeholder: intl.formatMessage({
                    id: "intake.ConfirmationStep.feedbackText.placeholder",
                  }),
                }}
                optional={true}
              />
              <button className="if button primary" data-cy="feedbackButton" onClick={() => feedbackText()}>
                <FormattedMessage id="intake.ConfirmationStep.feedbackButton"/>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Confirmation;
