import { FormattedMessage } from "react-intl";

import React from "react";
import "./invalidLink.scss";
import { Brand} from "../../backend-models";
import { IIntakeInformationState } from "../../intakeModels";

const InvalidLink: React.FC<{ brand: Brand; intakeInfo?: IIntakeInformationState}> = (props) => {
  let header = (id) => (
    <h1 className="if heading largest center" data-cy="invalidHeader">
      <FormattedMessage id={id} />
    </h1>
  );
  // 404 ID not found, or 400 invalid guid
  let message = (
    <>
      {header("intake.invalid.header")}
      <p className="if text lead center basic-text">
        <FormattedMessage id="intake.invalid.text" />
      </p>
    </>
  );
  
  if (props.intakeInfo?.expired) {
    message = header("intake.invalid.expiredOrDone");
  }

  if (props.intakeInfo?.loadStatus === 500) {
    message = header("intake.invalid.error");
  }

  let logoPath = (brand: string) => (brand === "if" ? "img/logo-phin.svg" : `img/${brand}/logo.svg`);

  return (
    <div className="invalid-link-container centered padded">
      <div className="invalid-link-logo-container">
        <img src={logoPath(Brand[props.brand].toLowerCase())} alt={`${Brand[props.brand]}`} />
      </div>
      {message}
    </div>
  );
};

export default InvalidLink;
