import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { fetchGlassInspections } from "actions";
import { IGlassInspectionColumns } from "models";
import { useStateSelector } from "store";
import { dispatchIfNeeded } from "utility";
import { withAITracking, reactPlugin } from "infrastructure/applicationInsights";

import TableView from "../shared/tableView";
import { LoadableRecords } from "../shared/loadable";
import ViewHeader from "../shared/viewHeader";
import { Brand, GlassDecision, Status } from "../../backend-models";

const _GlassSearch: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [searchText, setSearchText] = useState("");

  const glassInspections = useStateSelector((state) => state.glassInspections);

  useEffect(() => {
    dispatchIfNeeded(dispatch, fetchGlassInspections, glassInspections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let filtered = glassInspections?.glassInspections ?? [];

  let terms = searchText.toLowerCase().split(" ");

  filtered = filtered.filter((r) => {
    return terms.every((term) => {
      function matches(val) {
        return (val ?? "").toLowerCase().indexOf(term) !== -1;
      }
      return [
        r.workshopCity,
        r.workshopName,
        r.workshopId,
        r.recordId,
        r.customer.name,
        r.customer.email,
        r.customer.phoneNumber,
        r.customer.registrationNumber,
        Status[r.status],
        GlassDecision[r.workshopDecision],
        r.claimNumber,
        Brand[r.brand],
        r.claimNumber,
      ].some(matches);
    });
  });

  const columns: IGlassInspectionColumns = [
    "photoSource",
    "customer.name",
    "customer.registrationNumber",
    "workshopName",
    "status",
    "dateCreated",
  ];

  return (
    <div className="padded-block" data-cy="glass-list-page">
      <ViewHeader>
        <FormattedMessage id="page.search" />
      </ViewHeader>
      <>
        <div className="if input-wrapper">
          <input
            placeholder={intl.formatMessage({ id: "search.placeHolder" })}
            id="search-text"
            type="search"
            value={searchText}
            className="if input-field"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <LoadableRecords records={glassInspections}>
          <TableView
            isGlass={true}
            columns={columns}
            records={filtered}
            route="/glass/"
            altRoute="{intake}"
            defaultSortColumn="dateCreated"
            showActionColumn={false}
            pageBase="/glass-search"
          ></TableView>
        </LoadableRecords>
      </>
    </div>
  );
};

export const GlassSearch = withAITracking(reactPlugin, _GlassSearch);
