import {
  deepGet,
  formatDate,
  formatDateTime,
  formatDecision,
  formatGlassDecision,
  formatPhotoSource,
  formatStatus,
  formatWorkshopName,
  Translation,
} from "./utility";
import { IntlShape } from "react-intl";
import { IIntakeInformationState, IIntakeSubmission } from "./intakeModels";

import {
  Brand,
  IBodyInspectionResponse,
  IBodyInspectionsResponse,
  IClientEnvironment,
  IDocument,
  IFeedback,
  IGlassInspection,
  IGlassInspectionResponse,
  IGlassInspectionsResponse,
  IRecord,
  IUserInfo,
  IWorkshop,
  IWorkshopInfo,
  Status,
} from "./backend-models";
import { createContext } from "react";

export interface IPerformanceMetrics {
  head: Date;
  app: Date;
  records?: Date;
  table?: Date;
  intakeInfo?: Date;
  intakeRender?: Date;
  report();
}

declare global {
  interface Window {
    Cypress: any;
    ClientEnvironment: IClientEnvironment;
    _photoinspection: {
      perfMetrics: IPerformanceMetrics;
    };
    intlTelInputUtils: any;
  }
}

export { IntakeSendingState } from "./intakeModels";

export type DateString = string;
export enum NotificationType {
  Error,
  Success,
}

export interface INotification {
  message: Translation;
  type: NotificationType;
  time: Date;
  fade?: boolean;
  dataCy?: any;
}

export interface IHasLoadingAndError {
  isLoading?: boolean;
  loadError?: string;
  exists?: boolean;
}

export interface IRecordHasLoadingAndError extends IHasLoadingAndError {
  isStatusLoading?: boolean;
}

export type IWorkshopFilter = Record<string, IWorkshopInfo & { show: boolean }>;

export interface IAdminState {
  workshopFeedback: {
    feedback: IFeedback[];
  } & IHasLoadingAndError;
}

export interface IUserClaim {
  claimType: string;
  claimValue: string;
}

export interface AppState {
  records: IRecords;
  glassInspections: IGlassInspections;
  notifications: INotification[];
  searchResults: ISearchResults;
  workshops: IWorkshops;
  newInspection: INewInspection;
  photos: IPhotos;
  intake: IIntakeInformationState;
  intakeSubmission: IIntakeSubmission;
  intakeLinkReminders: IIntakeLinkReminders;
  userInfo: IUserInfo;
  smsTemplate: ISmsTemplate;
  workshopFeedback: IWorkshopFeedback;
  admin: IAdminState;
}

export interface ISmsTemplate extends IHasLoadingAndError {
  result: string;
}

export interface IError {
  message: string;
  recordId?: IRecord["recordId"];
  status?: number;
  redirecting?: boolean;
}

export interface INewInspection extends IHasLoadingAndError {
  saved: boolean;
}

//export type RecordProperty = Join<DeepProps<IRecord>> | keyof IRecord;

export interface IWithWorkshop {
  workshopId: string;
  workshopName: string;
  workshopCity: string;
  workshopCabasId: string;
}

export interface IInspectionWithWorkshop extends IRecord, IWithWorkshop {}
export interface IGlassInspectionWithWorkshop extends IGlassInspection, IWithWorkshop {}

export type InspectionProperty =
  | "customer.name"
  | "customer.phoneNumber"
  | "customer.email"
  | "customer.registrationNumber"
  | keyof IInspectionWithWorkshop
  | keyof IGlassInspection;
export type TableColumn = InspectionProperty | "intakeLink";
export type IRecordColumns = TableColumn[];

export type GlassInspectionColumn = InspectionProperty;
export type IGlassInspectionColumns = GlassInspectionColumn[];

export interface IInspectionWithWorkshopResponse {
  inspections: IInspectionWithWorkshop[];
  workshops: IWorkshopInfo[];
}
export interface IGlassInspectionWithWorkshopResponse {
  inspections: IGlassInspectionWithWorkshop[];
  workshops: IWorkshopInfo[];
}

export interface ISortConfig {
  field: TableColumn;
  ascending: boolean;
  currentPage: number;
}
export interface IStoredSortConfigs {
  [key: string]: ISortConfig;
}

export type IRecordColumnNameMap = {
  [key in keyof IRecord]?: string;
};

export type IInspection = IInspectionWithWorkshop | IGlassInspectionWithWorkshop;

export function IsRecord(maybeRecord: IInspection): maybeRecord is IInspectionWithWorkshop {
  return "noCustomer" in maybeRecord;
}

export interface IInspectionState extends IHasLoadingAndError {
  everLoaded?: boolean;
  lastLoaded?: Date;
  workshopFilter: IWorkshopFilter;
}

export interface IRecords extends IInspectionState {
  records: IInspectionWithWorkshop[];
}

export interface IGlassInspections extends IInspectionState {
  glassInspections: IGlassInspectionWithWorkshop[];
}

export type IInspectionStateType = IRecords | IGlassInspections;

export interface IWorkshops extends IHasLoadingAndError {
  workshops: IWorkshop[];
}

export interface ISearchResults extends IHasLoadingAndError {
  searchResults: IInspectionWithWorkshop[];
}

export interface IPhotos extends IHasLoadingAndError {
  photos: IDocument[];
  recordId: IRecord["recordId"];
  timestamp: number;
}

export interface IIntakeLinkReminders {
  records: IRecord[];
}
export interface IWorkshopFeedback extends IHasLoadingAndError {
  isOpen: boolean;
}

function addWorkshopData<T extends IRecord | IGlassInspection>(inspection: T, workshop: IWorkshopInfo) {
  return {
    ...inspection,
    isLoading: false,
    loadError: undefined,
    workshopName: workshop?.name ?? "Workshop",
    workshopCity: workshop?.city ?? "City",
    workshopCabasId: workshop?.cabasId ?? "",
  } as T & IWithWorkshop;
}

function makeProcessInspection(workshops: IWorkshopInfo[]) {
  return (inspection) => {
    const workshop = workshops.find((w) => w.id === inspection.workshopId);
    return addWorkshopData(inspection, workshop);
  };
}

export function processInspectionsResponse(response: IBodyInspectionsResponse): IInspectionWithWorkshopResponse {
  return {
    inspections: response.inspections.map(makeProcessInspection(response.workshops)),
    workshops: response.workshops,
  };
}
export function processGlassInspectionsResponse(
  response: IGlassInspectionsResponse
): IGlassInspectionWithWorkshopResponse {
  return {
    inspections: response.inspections.map(makeProcessInspection(response.workshops)),
    workshops: response.workshops,
  };
}

export function processInspectionResponse(response: IBodyInspectionResponse): IInspectionWithWorkshop {
  return addWorkshopData(response.inspection, response.workshop);
}

export function processGlassInspectionResponse(response: IGlassInspectionResponse): IGlassInspectionWithWorkshop {
  return addWorkshopData(response.inspection, response.workshop);
}

export function toDisplay(record: IInspection, field: TableColumn, emptyDefault: string = null) {
  let val = deepGet(record, field);
  if (emptyDefault !== null && (val === null || val === undefined)) {
    return emptyDefault;
  }
  switch (field) {
    case "dateCreated":
    case "dateOfIntake":
    case "dateDecided":
      return formatDateTime(val);
    case "claimDate":
      return formatDate(val);
    case "intakeSmsSentDate":
      if (IsRecord(record)) return formatDate(record.intakeReminderSmsSentDate || record.intakeSmsSentDate);
      break;
    case "decision":
      return formatDecision(val);
    case "workshopDecision":
    case "aIDecision":
      return formatGlassDecision(val);
    case "status":
      return formatStatus(val);
    case "photoSource":
      return formatPhotoSource(val);
    case "comments":
      return val.length;
    case "intakeLink":
      return (
        <a href={`/intake/${record.recordId}${record.brand !== Brand.If ? `/${Brand[record.brand]}` : ""}`}>intake</a>
      );
    case "workshopName":
      return formatWorkshopName(record);
    case "customer.email":
      return (
        <a className="if" href={"mailto:" + val}>
          {val}
        </a>
      );
    default:
      return val;
  }
}
export interface IStatusChange {
  status: Status;
  text: Translation;
}
export function getPossibleStatusChanges(record: IInspection, intl: IntlShape, all: boolean = false) {
  const isGlass = !IsRecord(record);
  let changes: IStatusChange[] = [
    {
      status: Status.Open,
      text: formatStatus(Status.Open),
    },
    {
      status: Status.IntakeCompleted,
      text: "Intake Completed",
    },
    {
      status: Status.New,
      text: intl.formatMessage({ id: "page.inbox" }),
    },
    {
      status: Status.InProgress,
      text: intl.formatMessage({ id: "page.inProgress" }),
    },
    {
      status: Status.OnHold,
      text: intl.formatMessage({ id: "page.onHold" }),
    },
    {
      status: Status.Closed,
      text: intl.formatMessage({ id: "page.archive" }),
    },
    null, // Status.SmsSendingError
    {
      status: Status.Deleted,
      text: intl.formatMessage({ id: "action.delete" }),
    },
  ];
  if (!all) {
    delete changes[Status.IntakeCompleted];
    delete changes[Status.Open];
    delete changes[record.status];
  }
  if (record.status === Status.Open) {
    delete changes[Status.New];
    delete changes[Status.OnHold];
    delete changes[Status.InProgress];
    delete changes[Status.Closed];
  }
  if (!all && record.status !== Status.Open) {
    delete changes[Status.Deleted];
  }
  if (isGlass) {
    delete changes[Status.IntakeCompleted];
    delete changes[Status.OnHold];
  }
  return changes.filter((x) => x);
}

export interface IAppContextData {
  lastSection?: string;
  customerPhotos?: boolean;
}
type IAppContextDataMutator = (a: IAppContextData) => IAppContextData;
export interface IAppContext extends IAppContextData {
  mutate(mutator: IAppContextDataMutator): void;
}

export const AppContext = createContext<IAppContext>({
  mutate() {},
});
