import React, { useEffect } from "react";
import { withPathAndAITracking } from "../infrastructure/applicationInsights";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useAuth } from "../auth/authContext";

export const LoggedOut = withPathAndAITracking("/logged-out", () => {
  const history = useHistory();
  const { setLoggedOut } = useAuth();

  useEffect(() => {
    setLoggedOut();
  }, [setLoggedOut]);

  return (
    <div className="if container padded-block">
      <br />
      <h1 className="if heading largest center" data-cy="notFoundHeader">
        <FormattedMessage id="app.loggedOut" />
      </h1>
      <br />
      <p className="if text lead center">
        <button className="if button secondary" onClick={() => history.push("/login")}>
          <span className="if icon ui log-in blue" />
          <span className="if user-name">
            <FormattedMessage id="action.logIn" data-cy="loginLink" />
          </span>
        </button>
      </p>
    </div>
  );
});
