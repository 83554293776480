import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "../actions";
import { IRecord } from "../backend-models";
import { NotificationType, processInspectionResponse } from "../models";
import { showError } from "./common";
import { Backend } from "../backend";

function* addRecord(action: Action<IRecord>) {
  try {
    const result = yield* Backend.Record.Add(action.payload);
    yield put(Actions.addRecordSuccess(processInspectionResponse(result)));
    if (action.payload.workshopId === "PHINTEST") {
      yield put(
        Actions.showNotification({
          message: "Record created with ID " + result.inspection.recordId,
          type: NotificationType.Success,
          time: new Date(),
        })
      );
    }
  } catch (err) {
    yield showError(err);
    yield put(Actions.addRecordError(err));
  }
}

export default function* addRecordSaga(): SagaIterator {
  yield takeLatest(Actions.addRecord, addRecord);
}
