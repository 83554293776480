import React from "react";
import "./infoNotifications.scss";
import { IUserInfo } from "../backend-models";
import { useSelector } from "react-redux";
import { AppState } from "../models";
import { getCurrentNotifications, mapToCountry, useLocalStorage } from "../utility";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

export const InfoNotifications: React.FC<{ showCurityBanner?: boolean }> = (props) => {
  const userInfo: IUserInfo = useSelector((state: AppState) => state.userInfo);
  const [hidden, setHidden] = useLocalStorage("hidden-notifications", []);
  const now = dayjs().toISOString().substr(0, 19);
  if (userInfo?.isLoading === true) return null;

  let notifications = getCurrentNotifications();

  if (props.showCurityBanner) {
    const message = "curityMigrationBannerText";
    notifications.push({
      id: message,
      message,
      start: null,
      end: null,
      country: null,
      translate: true,
    } as any);
  }

  notifications = notifications
    .filter((notification) => {
      //filter empty messages
      if (!notification.message) return false;

      //if user not logged in show everything
      let userCountry = userInfo?.preferredLanguageCode;
      if (userCountry === undefined) return true;

      //country == null means all countries
      if (notification.country === null) return true;

      return notification.country === mapToCountry(userCountry);
    })
    .filter((notification) => hidden.indexOf(notification.id) < 0)
    .filter((notification) => {
      if (notification.start === null) return true;
      return now > notification.start.substr(0, 19);
    })
    .filter((notification) => {
      if (notification.end === null) return true;
      return now < notification.end.substr(0, 19);
    });

  if(document.location.hostname === 'bilfoto.if.se')
  {
    notifications.push({
      message: (<h2>bilfoto.if.se is no longer in use, please use the correct address: <a href='https://photoinspection.if.eu'>photoinspection.if.eu</a></h2>) as any,
      timestamp: (new Date()).toString(),
    });
  }

  return (
    <div className="info-notifications" data-cy="info-notifications">
      {notifications.map((n) => (
        <div className="info-notification if notification" key={n.id}>
          <p>{"translate" in n ? <FormattedMessage id={n.message} /> : n.message}</p>
          <button
            className="if button"
            onClick={() => {
              setHidden([...hidden, n.id]);
            }}
          >
            <i className="if icon ui close brown" />
          </button>
        </div>
      ))}
    </div>
  );
};
