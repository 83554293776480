import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState, ISmsTemplate } from "../../models";
import { Brand } from "../../backend-models";
import { TestLangSelect } from "./langSelect";
import { TestBrandSelect } from "./brandSelect";
import * as Actions from "../actions";

export const TestSmsTemplate: React.FC = () => {
  const template: ISmsTemplate = useSelector((state: AppState) => state.smsTemplate);
  let [state, setState] = useState({
    countryCode: "SE",
    brand: "If",
    template: "PrivateCarPreInspectionCustomerInvite",
    ips: false,
  });

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      Actions.fetchSmsTemplate({
        ...state,
        brand: Brand[state.brand],
      })
    );
  }, [dispatch, state]);

  let header = "";
  let headerSplit = template.result.split("\n\n");
  if(headerSplit.length > 1)
  {
    header = headerSplit[0];
    template.result = headerSplit[1];
  }
  else
  {
    header = template.result;
    template.result = "";
  }

  return (
    <>
      <h2>SMS template</h2>
      <TestLangSelect
        value={state.countryCode}
        onChange={(v) => setState({ ...state, countryCode: v })}
        name="sms"
      />
      <TestBrandSelect value={state.brand} onChange={(v) => setState({ ...state, brand: v })} name="sms" />
      <div className="if input-wrapper">
        <div className="if selection-controls">
          <input
            checked={state.ips}
            type="checkbox"
            id="sms-ips"
            className="if selection-control"
            onChange={(e) => setState({ ...state, ips: e.target.checked })}
          />
          <label htmlFor="sms-ips">IPS</label>
        </div>
      </div>
      <div className="if input-wrapper">
        <select className="if dropdown" name="sms-template" value={state.template} onChange={(v) => setState({ ...state, template: v.target.value })}>
          <option>PrivateCarPreInspectionCustomerInvite</option>
          <option>PrivateCarPreInspectionCustomerInviteReminder</option>
          <option>PrivateCarPreInspectionCustomerConfirmation</option>
        </select>
        <label className="if input-label" htmlFor="sms-template">
          Select template
        </label>
      </div>
      <div className="sms-template">
        <div className="sms-template-header">
          {header}
        </div>
        <div className="sms-template-text">
          {template.loadError || template.result}
        </div>
      </div>
    </>
  );
};
