import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "../actions";
import { processInspectionResponse } from "../models";
import { showError } from "./common";
import { Backend } from "../backend";
import { Action } from "redux-actions";
import { history } from "../store";

function* fetchRecord(action: Action<string>) {
  try {
    const result = yield* Backend.Record.GetRecord(action.payload);
    yield put(Actions.fetchRecordSuccess(processInspectionResponse(result)));
  } catch (err) {
    if(err.status === 403) {
      yield history.push("/forbidden/" + action.payload);
    }
    if (err.status !== 404) {
      yield showError(err);
    }

    yield put(Actions.fetchRecordError({ ...err, recordId: action.payload }));
  }
}
export default function* fetchRecordSaga(): SagaIterator {
  yield takeLatest(Actions.fetchRecord, fetchRecord);
}
