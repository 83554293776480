//GENERATED AUTOMATICALLY, DO NOT EDIT
//generate-translations.js

const translations = {
  "id": "da",
  "app.error": "Fejl",
  "app.notFound": "Siden kan ikke vises",
  "app.redirecting": "Viderestiller",
  "app.na": "N/A",
  "app.noItemsOnHold": "Ingen pausede emner",
  "app.noItemsInProgress": "Ingen emner i gang",
  "app.noItemsInInbox": "Ingen nye emner i indbakken",
  "app.noItemsInArchive": "Ingen emner",
  "app.noItemsInOpen": "Ingen emner",
  "app.recordNotFound": "Registreringen blev ikke fundet",
  "app.forbiddenRecord": "Du har ikke tilladelse til at åbne emnet {recordId}",
  "app.adblock": "Omstiller... Hvis du oplever problemer, så prøv venligst at slå Ad Blocker fra.",
  "app.loggedOut": "Du er blevet logget af",
  "app.workshopVacation": "Ferieændringer gemt",
  "action.logIn": "Log ind",
  "action.logOut": "Log ud",
  "action.action": "Håndtere",
  "action.close": "Arkiver",
  "action.OnHold": "Pause",
  "action.moveTo": "Flytte til",
  "action.delete": "Slet",
  "action.delete.success": "Sagen er slettet",
  "action.save": "Gemme",
  "action.clear": "Fortryd",
  "table.of": "af",
  "table.old": "dage gammel",
  "page.inbox": "Indbakke",
  "page.inProgress": "Under behandling",
  "page.onHold": "På pause",
  "page.archive": "Arkiv",
  "page.search": "Søg",
  "page.new": "Send fotoinspektion",
  "page.open": "Åben",
  "page.details": "Detaljer",
  "page.statusChanged.message": "Sagen er flyttet til {status}",
  "page.settings": "Ferie indstillinger",
  "field.photoSource": "Kilde",
  "field.recordId": "ID-nummer",
  "field.claimNumber": "Skadenummer",
  "field.customer.name": "Navn",
  "field.customer.registrationNumber": "Reg.nr.",
  "field.customer.phoneNumber": "Telefonnummer",
  "field.workshopName": "Værksted",
  "field.workshopId": "Værksted ID",
  "field.customer.email": "E-mail",
  "field.mileage": "Kilometerstand",
  "field.description": "Beskrivelse",
  "field.status": "Status",
  "field.decision": "Beslutning",
  "field.decidedBy": "Beslutning taget af",
  "field.dateDecided": "Beslutning taget den",
  "field.createdBy": "Sendt af",
  "field.dateCreated": "Sendt den",
  "field.glass.dateCreated": "Oprettet den",
  "field.dateOfIntake": "Oprettet den",
  "field.comments": "Kommentarer",
  "field.intakeLink": "Intake",
  "field.claimDate": "Skadedato",
  "field.city": "By",
  "field.availability": "Fotoinspektion",
  "field.summerWorkshopInstructions": "Holder du ferielukket i løbet af sommeren? Så er det vigtigt, at du markerer fotoinspektionen som lukket for den aktuelle periode.\r\n\r\nDet er vigtigt, at du angiver datoen en uge før du lukker, så vi mindsker risikoen for, at fotoinspektioner står ubehandlet",
  "field.workshopDecision": "Beslutning",
  "link.back": "Tilbage",
  "link.edit": "Rediger",
  "link.createNewInspection": "Ny fotoinspektion",
  "newInspection.claimNumber": "Skadenummer (valgfrit)",
  "newInspection.customerPhoneNumber": "Kundens telefonnummer",
  "newInspection.sendLinkToCustomer": "Send link til kunden",
  "newInspection.sent": "Ny fotoinspektion sendt af",
  "newInspection.registrationNumber": "Registreringsnummer",
  "newInspection.workshop": "Værksted",
  "newInspection.brand": "Forsikring",
  "newInspection.registrationNumber.validationMessage": "Registreringsnummeret er ugyldigt",
  "newInspection.claimNumber.validationMessage": "Skadenummeret er ugyldigt",
  "newInspection.phone.validationMessage": "Telefonnummeret er ugyldigt",
  "newInspection.workshop.validationMessage": "Værksted skal udfyldes",
  "newInspection.brand.validationMessage": "Mærke kan ikke være tomt",
  "newInspection.select.workshop": "Vælg værksted",
  "newInspection.select.brand": "Vælg forsikring",
  "newInspection.customerName": "Kundens navn",
  "newInspection.customerName.validationMessage": "Kundens navn er ugyldigt",
  "decision.Undecided": "-",
  "decision.Repair": "Reparation",
  "decision.PreInspection": "For-besigtiget",
  "glassDecision.NotAvailable": "-",
  "glassDecision.Repair": "Reparation",
  "glassDecision.Replace": "Udskiftning",
  "status.Open": "Åben",
  "status.New": "Ny",
  "status.InProgress": "I gang",
  "status.OnHold": "På pause",
  "status.Closed": "Afsluttet",
  "status.Deleted": "Slettet",
  "photos.count": "Billede {current} af {total}",
  "photos.save": "Gem billede",
  "photos.saveAll": "Gem alle billeder",
  "photos.saveAllPreparing": "Forbereder",
  "photos.saveAllDownloaded": "Downloaded",
  "photos.photosProcessing": "Billeder bliver behandlet, kom tilbage senere",
  "photos.noPhotos": "Ingen billeder",
  "comment.placeholder": "Skriv din kommentar her",
  "comment.buttonAdd": "Tilføj",
  "comment.tooLong": "Kommentaren er for lang (max 500 tegn)",
  "search.placeHolder": "Søgeord",
  "action.sendIntakeLinkReminder": "Send SMS-påmindelse",
  "action.sendIntakeLinkReminder.success": "Påmindelse er sendt",
  "action.sendIntakeLinkReminder.hasBeenSent": "Påmindelse er sendt",
  "workshop.ui.page.title": "Værkstedsportalen",
  "feedback.title": "Giv os feedback",
  "feedback.info": "Vi tilstræber altid at forbedre vores tjenester. Fortæl os, hvad du synes om fotoinspektionen, og hvis der er noget, der kan gøre det bedre for dig.\\nBemærk, at hvis du oplever systemfejl, kan du kontakte din systemadministrator i stedet for at udfylde denne formular.",
  "feedback.input": "Kommentar",
  "feedback.cancel": "Annuller",
  "feedback.send": "Send feedback",
  "feedback.success": "Tusinde tak for din feedback!",
  "inactive.user.disabled.text": "Din konto er blevet deaktiveret på grund af inaktivitet. Kontakt venligst",
  "inactive.user.contact.email.address.text": "support",
  "intake.ui.page.title": "Send skadesbilleder",
  "intake.ui.page.title.tabHeader": "Fotoinspektion - Send skadesbilleder",
  "intake.LandingStep.header": "Fotoinspektion",
  "intake.LandingStep.madeBy": "En tjeneste skabt af",
  "intake.LandingStep.text": "Send billeder af skaden til værkstedet, så de kan forberede sig på reparationen. Det tager få minutter. Derefter vil værkstedet kontakte dig.",
  "intake.LandingStep.text2": "Vi behandler dine personoplysnigner i overensstemmelse med gældende lovgivning og  Databeskyttelsesforordningen (GDPR).",
  "intake.LandingStep.link.text": "Her kan du læse mere om vores håndtering af persondata og dine rettigheder",
  "intake.LandingStep.consentCheckbox.text": "Jeg har læst og accepterer",
  "intake.LandingStep.consent.link.text": "brugervilkår",
  "intake.LandingStep.privacyPolicy.text": "Læs mere om vores håndtering af persondata og dine rettigheder",
  "intake.LandingStep.privacyPolicy.link.text": "her",
  "intake.LandingStep.accessibilityStatement.link.text": "Tilgængelighedserklæring",
  "intake.alt.imageCar": "Bil",
  "intake.alt.imageSummary.back": "Billede af bilen taget bagfra",
  "intake.alt.imageSummary.distance": "Billede af bilen taget på afstand",
  "intake.alt.imageSummary.closeup": "Billede af bilen taget i nærbillede",
  "intake.alt.imageSummary.diagonal-front": "Billede af bilen taget fra en vinkel",
  "intake.alt.imageSummary.diagonal-back": "Billede af bilen taget fra en anden vinkel",
  "intake.alt.back": "Tilbage",
  "intake.alt.imageIllustration.back": "Illustration af en bil bagfra",
  "intake.alt.imageIllustration.distance": "Illustration af en bil på afstand",
  "intake.alt.imageIllustration.closeup": "Illustration af en bil tæt på",
  "intake.alt.imageIllustration.diagonal-front": "Illustration af en bil fra en vinkel",
  "intake.alt.imageIllustration.diagonal-back": "Illustration af en bil fra en anden vinkel",
  "intake.wizard.buttonStart": "Start fotoinspektion",
  "intake.wizard.buttonNext": "Næste",
  "intake.wizard.buttonSubmit": "Send fotoinspektion",
  "intake.wizard.buttonBack": "Tilbage",
  "intake.wizard.sendingInformation": "Din information sendes til værkstedet…",
  "intake.wizard.sendingFile": "Uploader dine foto: {current} af {total}",
  "intake.wizard.buttonSave": "Gem",
  "intake.ContactInformationStep.header": "Din information",
  "intake.ContactInformationStep.text": "Vi har brug for noget information til at komme i kontakt med dig for at booke et møde.",
  "intake.ContactInformationStep.helpText": "Felter markeret med * skal udfyldes",
  "intake.ContactInformationStep.customer.name.header": "Navn",
  "intake.ContactInformationStep.customer.name.validationMessage": "Navn kan ikke være tomt",
  "intake.ContactInformationStep.customer.phoneNumber.header": "Telefonnummer",
  "intake.ContactInformationStep.customer.phoneNumber.validationMessage": "Telefonnummer er ikke gyldigt",
  "intake.ContactInformationStep.customer.email.header": "Email",
  "intake.ContactInformationStep.customer.email.validationMessage": "Email adresse er ikke gyldig",
  "intake.ContactInformationStep.customer.registrationNumber.header": "Registreringsnummer",
  "intake.ContactInformationStep.customer.registrationNumber.validationMessage": "Registreringsnummer er ikke gyldigt",
  "intake.ContactInformationStep.mileage.header": "Bilens kilometertal",
  "intake.ContactInformationStep.mileage.validationMessage": "Bilens kilometertal er ikke gyldigt",
  "intake.ContactInformationStep.claimDate.header": "Skadedato",
  "intake.ContactInformationStep.claimDate.validationMessage": "Dato er ikke gyldig",
  "intake.DescriptionStep.header": "Beskrivelse",
  "intake.DescriptionStep.help": "Tilføj kort beskrivelse af skaden og hvor på bilen skaden er placeret.",
  "intake.DescriptionStep.description.header": "Beskrivelse",
  "intake.DescriptionStep.description.validationMessage": "Max 200 tegn ({fieldLength} too many)",
  "intake.DescriptionStep.description.statusMessage": "Max 200 tegn ({descriptionLength} remaining)",
  "intake.DescriptionStep.placeholder": "Eksempel: Skaden er to små buler på bilens højre side ved baghjulet og på bagdøren.",
  "intake.PhotoStep.header": "Billede",
  "intake.PhotoStep.header.back": "Billede af bilen bagfra",
  "intake.PhotoStep.text.back": "Tag et billede af bilen bagfra, hvor nummerpladen og bilenmodel kan ses.",
  "intake.PhotoStep.header.distance": "Billede af skaden fra afstand",
  "intake.PhotoStep.text.distance": "Tag et et billede af skaden fra en afstand ad ca. 3 meter. Forsøg at få hele siden hvorpå skaden sidder med i billedet.",
  "intake.PhotoStep.header.closeup": "Nærbillede af skaden",
  "intake.PhotoStep.text.closeup": "Tag et billede tæt på af skaden set forfra.",
  "intake.PhotoStep.header.diagonal-front": "Nærillede fra en side",
  "intake.PhotoStep.text.diagonal-front": "Tag et nærbillede af skaden fra en lidt skrå vinkel.",
  "intake.PhotoStep.header.diagonal-back": "Nærbillede fra den modsatte side",
  "intake.PhotoStep.text.diagonal-back": "Tag et nærbillede af skaden lidt vinketfra den modsatte retning.",
  "intake.PhotoStep.header.extra": "Yderligere fotos (valgfrit)",
  "intake.PhotoStep.text.extra": "Tag op til 2 ekstra billeder af skaden. Du kan springe dette skridt over.",
  "intake.PhotoStep.add-button": "Tag eller upload billede",
  "intake.PhotoStep.deleteQuestion": "Slet foto?",
  "intake.PhotoStep.cancelButton": "Annuller",
  "intake.PhotoStep.deleteButton": "Slet",
  "intake.PhotoStep.validationMessage": "Du skal tilføje et billede for at fortsætte",
  "intake.PhotoStep.validationMessage.tooLargePhoto": "Det vedlagte billede er for stort. Max fil størrelse er {fileSize}.",
  "intake.PhotoStep.validationMessage.wrongFileType": "Billedet er gemt i et ugyldigt format. Kun PNG og JPG er tilladt.",
  "intake.PhotoStep.validationMessage.duplicatePhoto": "Dette billede er allerede tilføjet. Vælg venligst et andet",
  "intake.SummaryStep.header": "Opsummering",
  "intake.SummaryStep.text": "Næsten færdig! Gennemgå dine billeder og informationen der vil blive sendt til værkstedet.",
  "intake.SummaryStep.error": "Noget gik galt. Kontroller venligst din netværkstilslutning.",
  "intake.SummaryStep.photos.header": "Billeder",
  "intake.SummaryStep.description.missing": "Ingen beskrivelse er tilføjet",
  "intake.SummaryStep.information.header": "Din information",
  "intake.SummaryStep.customer.name.header": "Navn",
  "intake.SummaryStep.customer.phoneNumber.header": "Telefonnum.",
  "intake.SummaryStep.customer.email.header": "Email",
  "intake.SummaryStep.customer.registrationNumber.header": "Reg. num.",
  "intake.SummaryStep.mileage.header": "Kilometertal",
  "intake.SummaryStep.claimDate.header": "Skadedato",
  "intake.ConfirmationStep.header": "Mange tak!",
  "intake.ConfirmationStep.textIf": "Dine billeder er sendt til {workShopName}. De kontakter dig for at aftale det videre forløb.",
  "intake.ConfirmationStep.textWhiteLabel": "Din fotoinspektion er blevet sendt til værkstedet. {workShopName} vil kontakte dig inden for {slaDays} arbejdsdage for at booke et møde med værsktedet.",
  "intake.ConfirmationStep.feedbackHeader": "Hvad syntes du om denne foto besigtigelse?",
  "intake.ConfirmationStep.feedbackText.header": "Kommentar (valgfri)",
  "intake.ConfirmationStep.feedbackText.placeholder": "Tilføj en kommentar, hvis du har lyst til at dele din oplevelse omkring foto besigtigelse med os",
  "intake.ConfirmationStep.feedbackButton": "Send",
  "intake.ConfirmationStep.feedbackConfirmation": "Tusinde tak for din tilbagemelding!",
  "intake.invalid.header": "Ups.. Siden kunne ikke findes",
  "intake.invalid.text": "Undskyld, siden du forsøgte at nå eksisterer ikke. Den er enten blevet flyttet eller URL'et du brugte var forkert.",
  "intake.VolviaLandingStep.header": "Fotobesiktning",
  "intake.VolviaLandingStep.text": "Spar tid og penge. Tag billeder af skaderne for at få en digital vurdering og reservation fra værkstedet inden for 3 dage.",
  "intake.VolviaLandingStep.link.text": "Administration af personlige data",
  "intake.InstructionStep.header": "This is how it works",
  "intake.InstructionStep.text": "We will first ask you to enter your contact details, some information about your car and also about the damage.",
  "intake.InstructionStep.text2": "Then you will be asked to take or upload 5 photos of the damage to help the workshop with the assessment. The photos you need are one on the car from behind, one where the whole damage is visible and three close ups of the damage from different angles. See examples below.",
  "confirm.delete.title": "Slet sagen",
  "confirm.delete.text": "Der er sendt et link til kunden, hvor vi beder om billeder. Hvis du sletter sagen, vil linket ikke længere virke og kunden kan derfor ikke sende fotos.",
  "confirm.delete.cancel": "Fortryd",
  "confirm.delete.yes": "Slet",
  "curityMigrationBannerText": "Vi har lavet en teknisk opdatering til Photo Inspection-appen. For at logge ind skal du ændre din adgangskode via den e-mail, der er blevet sendt til dig. Herefter kan du logge ind som normalt.",
  "section.bodyInspections": "Køretøjer",
  "section.glassInspections": "Glas",
  "section.admin": "Admin panel",
  "glass.customerPhotos": "Kundebilleder",
  "glass.inbox": "Indbakke",
  "glass.workshopPhotos": "Værkstedsbilleder",
  "photoSource.Customer": "Kundebilleder",
  "photoSource.Workshop": "Værkstedsbilleder"
};
export default translations;