import React, { useMemo, useState } from "react";
import { reactPlugin, withAITracking } from "../infrastructure/applicationInsights";
import "./details.scss";
import Tag from "./shared/tag";
import { useHistory, useParams } from "react-router-dom";
import PhotoViewer from "./shared/photoViewer";
import { AppState, getPossibleStatusChanges, IInspectionWithWorkshop, IIntakeLinkReminders, IRecords, IStatusChange } from "../models";
import Loader from "./shared/loader";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../actions";
import { dispatchIfNeeded, filterStatus } from "../utility";
import { RecordInfo } from "./shared/recordInfo";
import { FormattedMessage, useIntl } from "react-intl";
import { Brand, Status, WorkshopDecision } from "../backend-models";
import { purgeRecord } from "../admin/actions";
import { useComponentVisible } from "./shared/componentVisibleHelper";
import { RecordComments } from "./shared/recordComments";
import { useConfirm } from "./shared/useConfirm";
import { useAuth } from "auth/authContext";
import { decodeIntakeUrlData } from "../intakeUtility";

interface DetailsParams {
  id: string;
}

const showUndecided = false;
export const DetailsPage: React.FC<{ history }> = (props) => {
  let { id: paramsId } = useParams<DetailsParams>();
  const { isAdministrator } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const id = decodeIntakeUrlData(paramsId, true)?.id ?? paramsId;

  let [state, setState] = useState({
    originalStatus: null,
    originalId: null,
  });

  const {
    ref: refActionMenu,
    isComponentVisible: actionMenuOpen,
    setIsComponentVisible: setActionMenuOpen,
  } = useComponentVisible(false);

  let record = useMemo(() => ({ isLoading: true } as IInspectionWithWorkshop), []);
  const records: IRecords = useSelector((state: AppState) => state.records);

  const { confirmPopUp, setConfirmPopUp } = useConfirm();

  React.useEffect(() => {
    dispatchIfNeeded(dispatch, Actions.fetchRecords, records);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    dispatch(Actions.fetchRecord(id));
  }, [dispatch, id]);

  if (records && records.records) {
    let found = records.records.filter((r) => r.recordId === id)[0];
    if (found) {
      record = found;
    }
  }

  React.useEffect(() => {
    if (
      record &&
      record.status !== null &&
      state.originalId != null &&
      state.originalId === record.recordId
    ) {
      function showNextInQueue(status: Status, redirect: string) {
        if (state.originalStatus === status && record.status !== status) {
          let newRecords = filterStatus(records, status).filter((r) => r.recordId);
          if (newRecords.length > 0) {
            setState({ originalId: null, originalStatus: null });
            history.replace("/details/" + newRecords[0].recordId);
          } else {
            history.push(redirect);
          }
        }
      }
      showNextInQueue(Status.New, "/");
      showNextInQueue(Status.InProgress, "/inProgress");
      showNextInQueue(Status.OnHold, "/onHold");
    }
  }, [record, records, state, history]);

  let intl = useIntl();

  let possibleStatusChanges: IStatusChange[] = [];

  if (record) {
    possibleStatusChanges = getPossibleStatusChanges(record, intl);
  }

  function changeRecordStatus(status: Status, setOriginalStatus: boolean = true) {
    setActionMenuOpen(false);
    if (setOriginalStatus && state.originalStatus == null) {
      setState({
        ...state,
        originalStatus: record.status,
        originalId: record.recordId,
      });
    }
    dispatch(
      Actions.changeRecordStatus({
        recordId: record.recordId,
        status: status,
        decision: null,
      })
    );
  }

  function changeRecordDecision(decision: WorkshopDecision) {
    if (record.decision === decision) {
      decision = WorkshopDecision.Undecided;
    }
    dispatch(
      Actions.changeRecordStatus({
        recordId: record.recordId,
        status: null,
        decision: decision,
      })
    );
  }

  function dispatchsendIntakeLinkReminder() {
    dispatch(Actions.sendIntakeLinkReminder({ record, intl }));
  }
  let intakeLinkReminders: IIntakeLinkReminders = useSelector((state: AppState) => state.intakeLinkReminders);

  function isSendingIntakeReminder() {
    const rec = intakeLinkReminders?.records?.find((r) => r.recordId === record.recordId);
    return rec && rec.isLoading;
  }

  let sendToCabasEnabled = false;

  if (record.workshopCabasId) {
    if (record?.workshopId.startsWith("SE")) {
      sendToCabasEnabled = true;
    }
  }

  if (records.isLoading || record.isLoading)
    return (
      <div className="if padded-block darkBeige detail-view">
        <Loader />
      </div>
    );

  if (records.exists === false) {
    return (
      <div className="if padded-block darkBeige detail-view">
        <div className="if notification error">
          <FormattedMessage id="app.recordNotFound" />
        </div>
      </div>
    );
  }

  return (
    <>
      {confirmPopUp}
      <div className="if padded-block darkBeige detail-view">
        <div className="detail-row">
          <div className="detail-column info-detail-column">
            <section>
              <button className="if back-to-link button tertiary" onClick={props.history.goBack}>
                <FormattedMessage id="link.back" />
              </button>

              <div className="header-container">
                <div className="header-heading-container">
                  <h1 className="if heading">{record.customer?.registrationNumber}</h1>
                  <Tag status={record.status} />
                </div>
                {![Brand.NotBranded, Brand.BMW].includes(record.brand) && (
                  <img
                    className="header-brand-logo"
                    src={`/img/${Brand[record.brand]}/logo.svg`}
                    alt={Brand[record.brand]}
                  />
                )}
              </div>
              <div className="status-and-decision">
                {record.status !== Status.Open && (
                  <DecisionSwitch
                    setDecision={changeRecordDecision}
                    decision={record.decision}
                    isLoading={record.isStatusLoading}
                  />
                )}

                <div className="if status-menu" ref={refActionMenu}>
                  <button
                    className="if button icon ui more-vertical"
                    onClick={() => setActionMenuOpen(!actionMenuOpen)}
                  />
                  <nav className={"if menu " + (actionMenuOpen ? "is-open" : "")}>
                    <ul className="if">
                      {!(record.status === Status.Open) && (
                        <li>
                          <div className="text menu-item-text">
                            <FormattedMessage id="action.moveTo" />
                          </div>
                        </li>
                      )}
                      {possibleStatusChanges.map((change) => {
                        return (
                          <li key={change.status}>
                            <button
                              className="if"
                              onClick={() => {
                                setActionMenuOpen(false);
                                if (change.status === Status.Deleted) {
                                  setConfirmPopUp({
                                    title: intl.formatMessage({ id: "confirm.delete.title" }),
                                    text: intl.formatMessage({ id: "confirm.delete.text" }),
                                    confirmAction: () => {
                                      changeRecordStatus(change.status);
                                    },
                                  });
                                } else {
                                  changeRecordStatus(change.status);
                                }
                              }}
                            >
                              {change.status !== Status.Deleted ? (
                                <span className="if icon ui corner-left-round menu-icon" />
                              ) : null}
                              {change.text}
                            </button>
                          </li>
                        );
                      })}
                      {record?.status === Status.Open &&
                        !window.ClientEnvironment.flags.sendAutomaticReminders && (
                          <li>
                            <button
                              className="if"
                              onClick={() => {
                                setActionMenuOpen(false);
                                dispatchsendIntakeLinkReminder();
                              }}
                              disabled={
                                Boolean(record.intakeReminderSmsSentDate) || isSendingIntakeReminder()
                              }
                            >
                              <FormattedMessage id="action.sendIntakeLinkReminder" />
                            </button>
                          </li>
                        )}
                      {sendToCabasEnabled && (
                        <li>
                          <button
                            className="if"
                            onClick={() => {
                              setActionMenuOpen(false);
                              dispatch(Actions.recordSendToCabas(record));
                            }}
                          >
                            <FormattedMessage id="action.sendToCabas" />
                          </button>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
              {isAdministrator && (
                <div className="if actions admin">
                  {getPossibleStatusChanges(record, intl, true).map((change) => (
                    <button
                      className="if button primary status-button"
                      onClick={() => {
                        changeRecordStatus(change.status, false);
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      }}
                      key={change.status}
                    >
                      {change.text}
                    </button>
                  ))}
                  <br />
                  <button
                    className="if button primary status-button"
                    onClick={() => dispatchsendIntakeLinkReminder()}
                  >
                    <FormattedMessage id="action.sendIntakeLinkReminder" />
                  </button>
                  <button
                    className="if button primary delete status-button"
                    onClick={() => dispatch(purgeRecord(record.recordId))}
                    data-cy="purgeRecord"
                  >
                    Delete
                  </button>
                  <button
                    className="if button primary status-button"
                    onClick={() => (document.location.href = record.shortIntakeUrl)}
                  >
                    Intake
                  </button>
                </div>
              )}
            </section>
            <RecordInfo record={record} key={record.recordId} />
            <RecordComments record={record} />
          </div>
          <div className="detail-column photo-detail-column">
            <PhotoViewer record={record} />
          </div>
        </div>
      </div>
    </>
  );
};

const DecisionSwitch: React.FC<{ isLoading; decision; setDecision }> = ({
  isLoading,
  decision,
  setDecision,
}) => {
  return (
    <div className="if segmented-control" role="radiogroup">
      {isLoading ? (
        <>
          <input
            type="radio"
            id="segment-loading"
            name="segment-loading"
            checked={false}
            onChange={() => {}}
          />
          <label htmlFor="segment-loading" className="segment-loading">
            <Loader />
            &nbsp;
          </label>
        </>
      ) : (
        <>
          {showUndecided && (
            <>
              <input
                type="radio"
                id="segment-undecided"
                name="segment-decision"
                checked={decision === WorkshopDecision.Undecided}
                onChange={() => {}}
              />
              <label htmlFor="segment-undecided" onClick={() => setDecision(WorkshopDecision.Undecided)}>
                <FormattedMessage id="decision.Undecided" />
              </label>
            </>
          )}
          <input
            type="radio"
            id="segment-pre-inspection"
            name="segment-decision"
            checked={decision === WorkshopDecision.PreInspection}
            onChange={() => {}}
          />
          <label htmlFor="segment-pre-inspection" onClick={() => setDecision(WorkshopDecision.PreInspection)}>
            <FormattedMessage id="decision.PreInspection" />
          </label>

          <input
            type="radio"
            id="segment-repair"
            name="segment-decision"
            checked={decision === WorkshopDecision.Repair}
            onChange={() => {}}
          />
          <label htmlFor="segment-repair" onClick={() => setDecision(WorkshopDecision.Repair)}>
            <FormattedMessage id="decision.Repair" />
          </label>
        </>
      )}
    </div>
  );
};

export default withAITracking(reactPlugin, DetailsPage);
