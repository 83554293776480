import React from "react";

export const TestLangSelect: React.FC<{ value; onChange; name }> = (props) => {
  const availableLanguages = "SE,FI,NO,DK,EN".split(",");
  return (
    <div className="if input-wrapper" style={{ margin: 0 }}>
      <fieldset className="if">
        <div className="if selection-controls horizontal">
          {availableLanguages.map((lang) => (
            <React.Fragment key={lang}>
              <input
                type="radio"
                id={"lang-" + props.name + "-" + lang}
                className="if selection-control"
                name={"lang-" + props.name + "-" + lang}
                checked={lang === props.value}
                value={lang}
                onChange={(e) => props.onChange(e.target.value)}
              />
              <label htmlFor={"lang-" + props.name + "-" + lang} style={{ marginRight: "1.6em" }}>
                {lang}
              </label>
            </React.Fragment>
          ))}
        </div>
      </fieldset>
    </div>
  );
};
