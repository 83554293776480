import * as Actions from "../actions";
import reducer from "./reducer";
import { IWorkshopFeedback } from "../models";

export default reducer<IWorkshopFeedback>(
  {
    isOpen: false,
  } as IWorkshopFeedback,
  (on) => {
    on(Actions.workshopFeedbackOpen, (_) => {
      return {
        ..._,
        isOpen: true,
        text: "",
      };
    });
    on(Actions.workshopFeedbackClose, (_) => {
      return {
        ..._,
        isOpen: false,
      };
    });
    on(Actions.workshopFeedbackSend, (_) => {
      return {
        ..._,
        isLoading: true,
      };
    });

    on(Actions.workshopFeedbackSendSuccess, (_) => {
      return {
        ..._,
        isLoading: false,
      };
    });

    on(Actions.workshopFeedbackSendError, (_, action) => {
      return {
        ..._,
        isLoading: false,
        loadError: `Failed to send feedback: ${action.payload.message}`,
      };
    });
  }
);
