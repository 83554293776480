import "./notifications.scss";
import React from "react";
import { AppState, INotification, NotificationType } from "../../models";
import { useDispatch, useSelector } from "react-redux";
import { closeNotification } from "../../actions";

export const Notification: React.FC<{ notification: INotification }> = ({ notification }) => {
  let dispatch = useDispatch();
  function close() {
    dispatch(closeNotification(notification));
  }
  function classNames() {
    let classes = "";
    if (notification.fade) {
      classes += "fade-out";
    }
    if (((new Date() as unknown) as number) - ((notification.time as unknown) as number) < 1000) {
      classes += "fade-in";
    }
    return classes;
  }
  let success = notification.type === NotificationType.Success;

  let dataCy = {
    type: NotificationType[notification.type]
  };
  //add data-cy- attributes to the notification
  if (notification.dataCy) {
    dataCy = Object.keys(notification.dataCy).reduce(
      (_, key) => ({
        ..._,
        ["data-cy-" + key]: notification.dataCy[key],
      }),
      dataCy
    );
  }

  return (
    <div
      className={classNames() + " notification " + NotificationType[notification.type]}
      data-cy="notificationMessage"
      aria-live="polite"
      {...dataCy}
    >
      <button className="close" onClick={() => close()}>
        &times;
      </button>
      <span className={"if icon ui " + (success ? "check" : "warning")} />
      {notification.message}
    </div>
  );
};
export const Notifications: React.FC = () => {
  let notifications = useSelector((state: AppState) => state.notifications);
  return (
    <div className="if notifications">
      {notifications.map((notification, i) => (
        <Notification notification={notification} key={i} />
      ))}
    </div>
  );
};
