import * as React from "react";
import { withAITracking, reactPlugin } from "../infrastructure/applicationInsights";
import { useDispatch, useSelector } from "react-redux";
import { AppState, IRecordColumns, IRecords } from "../models";
import TableView from "./shared/tableView";
import { LoadableRecords } from "./shared/loadable";
import ViewHeader from "./shared/viewHeader";
import { fetchRecords } from "../actions";
import { dispatchIfNeeded, filterStatus } from "../utility";
import { FormattedMessage } from "react-intl";
import { Status } from "../backend-models";
import { useEffect } from "react";

const InProgress: React.FC = () => {
  const dispatch = useDispatch();

  const records: IRecords = useSelector((state: AppState) => state.records);

  useEffect(() => {
    dispatchIfNeeded(dispatch, fetchRecords, records);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: IRecordColumns = [
    "customer.name",
    "customer.registrationNumber",
    "customer.phoneNumber",
    "workshopName",
    "dateOfIntake",
    "decision",
  ];

  return (
    <div className="padded-block">
      <ViewHeader>
        <FormattedMessage id="page.inProgress" />
      </ViewHeader>
      {!records?.isLoading && records?.records?.length === 0 ? (
        <div className="if no-items">
          <FormattedMessage id="app.noItemsInProgress" />
        </div>
      ) : (
        <LoadableRecords records={records}>
          <TableView
            columns={columns}
            records={filterStatus(records, Status.InProgress)}
            route="/details/"
            showActionColumn={true}
            pageBase="/inProgress"
          >
            <FormattedMessage id="app.noItemsInProgress" />
          </TableView>
        </LoadableRecords>
      )}
    </div>
  );
};

export default withAITracking(reactPlugin, InProgress);
