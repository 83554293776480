import "react-app-polyfill/ie11";
import "url-search-params-polyfill";
import "./infrastructure/applicationInsights";
import "./infrastructure/dayjs";

import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App, { intakePaths } from "./app";
import { store } from "./store";
import { perfMetrics } from "./utility";
import { AuthProvider } from "./auth/authContext";
require("core-js/features/string");
require("core-js/features/array");
require("core-js/features/object");
require("scrollingelement");
const pathToRegexp = require('path-to-regexp');

const rootElement = document.getElementById("root");

const isIntake = Object.values(intakePaths).some(path => {
  const result = pathToRegexp(path).exec(document.location.pathname);
  console.debug('isIntake', path, result);
  return Boolean(result);
});

perfMetrics.app = perfMetrics.app ?? new Date();
if (window.ClientEnvironment)
  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <AuthProvider requiresAuth={!isIntake}>
          <App />
        </AuthProvider>
      </Provider>
    </StrictMode>,
    rootElement
  );
else rootElement.innerText = "Missing ClientEnvironment";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
