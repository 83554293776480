import { SagaIterator } from "redux-saga";
import { put, takeEvery } from "redux-saga/effects";
import * as Actions from "../actions";
import { showError } from "./common";
import { Action } from "redux-actions";
import { Backend } from "../backend";
import { NotificationType } from "../models";
import { FormattedMessage } from "react-intl";
import moment from 'moment';

function* disableCustomerService({
  payload: { id, startDate, endDate },
}: Action<{ id: string; startDate: Date | null; endDate: Date | null }>) {
  try {    
    let result = yield Backend.Workshop.DisablePhotoInspectionService(
      {
        workshopId: id,
        startDate: moment(startDate).utc(true).toJSON(),
        endDate:moment(endDate).utc(true).toJSON()
      }
    );

    yield put(
      Actions.showNotification({
        message: <FormattedMessage id="app.workshopVacation" />,
        type: NotificationType.Success,
        time: new Date(),
      })
    );
    yield put(Actions.fetchWorkshops());
    yield put(Actions.disableCustomerServiceSuccess(result));
  } catch (err) {
    yield showError(err);
  }
}
export function* workshopToggleServiceSaga(): SagaIterator {
  yield takeEvery(Actions.disableCustomerService, disableCustomerService);
}
