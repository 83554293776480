import { IWorkshopInfo } from "backend-models";
import { IWorkshopFilter } from "models";
import { adminGetWorkshopVisibilityCookie, currentUser } from "utility";

type FilterType = "body" | "glass";

export function getBodyWorkshopFilter(workshops: IWorkshopInfo[]): IWorkshopFilter {
  return getWorkshopFilter(workshops, "body");
}
export function toggleBodyWorkshopFilter(id: string, previous: IWorkshopFilter) {
  return toggleWorkshopFilter(id, previous, "body");
}
export function toggleAllBodyWorkshopFilters(show: boolean, previous: IWorkshopFilter) {
  return toggleAllFilter(show, previous, "body");
}

export function getGlassWorkshopFilter(workshops: IWorkshopInfo[]): IWorkshopFilter {
  return getWorkshopFilter(workshops, "glass");
}
export function toggleGlassWorkshopFilter(id: string, previous: IWorkshopFilter) {
  return toggleWorkshopFilter(id, previous, "glass");
}
export function toggleAllGlassWorkshopFilters(show: boolean, previous: IWorkshopFilter) {
  return toggleAllFilter(show, previous, "glass");
}
export function clearWorkshopFilter(): void {
  storeWorkshopFilter({}, "body");
  storeWorkshopFilter({}, "glass");
}
// export function setGlassWorkshopFilter(filter: IWorkshopFilter): void {
//   storeWorkshopFilter(filter, 'glass');
// }

// export function setBodyWorkshopFilter(filter: IWorkshopFilter): void {
//   storeWorkshopFilter(filter, 'body');
// }

function toggleWorkshopFilter(id: string, previous: IWorkshopFilter, type: FilterType): IWorkshopFilter {
  let workshopSetting = previous[id];

  let filter = {
    ...previous,
    [id]: { ...workshopSetting, show: !workshopSetting.show },
  };
  storeWorkshopFilter(filter, type);

  return filter;
}

function toggleAllFilter(show: boolean, previous: IWorkshopFilter, type: FilterType): IWorkshopFilter {
  let filter = {};
  Object.keys(previous).forEach((k) => {
    filter[k] = { ...previous[k], show };
  });
  storeWorkshopFilter(filter, type);

  return filter;
}

function WorkshopFilterKey(type: string) {
  if (currentUser === "any") console.warn("Current user is 'any'");
  return "if.photo.inspection.workshopFilter." + currentUser + "." + type;
}

function getWorkshopFilter(workshops: IWorkshopInfo[], type: FilterType): IWorkshopFilter {
  let rawValue = window.localStorage.getItem(WorkshopFilterKey(type));
  let filter: IWorkshopFilter = {};

  if (rawValue) {
    filter = JSON.parse(rawValue);
  }

  let changed = false;

  const [adminVisibleWorkshops, adminAll] = adminGetWorkshopVisibilityCookie();

  if (workshops && workshops.length > 0) {
    //add missing
    workshops.forEach((workshop) => {
      if (!adminAll && !adminVisibleWorkshops.includes(workshop.id)) return;
      filter[workshop.id] = { ...workshop, show: filter[workshop.id]?.show ?? true };
    });

    //remove non-existing
    Object.keys(filter).forEach((id) => {
      if (!workshops.some((item) => item.id === id)) {
        changed = true;
        delete filter[id];
      }
    });
  }

  if (changed) {
    storeWorkshopFilter(filter, type);
  }

  return filter;
}

function storeWorkshopFilter(filter: IWorkshopFilter, type: FilterType) {
  window.localStorage.setItem(WorkshopFilterKey(type), JSON.stringify(filter));
}
