//GENERATED AUTOMATICALLY, DO NOT EDIT
//generate-translations.js

const translations = {
  "id": "en",
  "app.error": "Error",
  "app.notFound": "Page not found",
  "app.redirecting": "Redirecting",
  "app.na": "N/A",
  "app.noItemsOnHold": "No items on hold",
  "app.noItemsInProgress": "No items in progress",
  "app.noItemsInInbox": "No new items in Inbox",
  "app.noItemsInArchive": "No new items in Archive",
  "app.noItemsInOpen": "No new items in Open",
  "app.recordNotFound": "Record not found",
  "app.forbiddenRecord": "You don't have permission to open this record: {recordId}",
  "app.adblock": "If you are experiencing any issues, please try turning your ad blocker off.",
  "app.loggedOut": "You have been logged out",
  "app.workshopVacation": "Vacation changes saved",
  "brand.notbranded": "Other insurance",
  "action.logIn": "Log in",
  "action.logOut": "Log out",
  "action.action": "Action",
  "action.close": "Archive",
  "action.OnHold": "On hold",
  "action.moveTo": "Move to",
  "action.delete": "Delete",
  "action.delete.success": "Inspection deleted",
  "action.save": "Save",
  "action.clear": "Clear",
  "table.of": "of",
  "table.old": "days old",
  "page.inbox": "Inbox",
  "page.inProgress": "In progress",
  "page.onHold": "On hold",
  "page.archive": "Archive",
  "page.search": "Search",
  "page.new": "Send inspection",
  "page.open": "Open",
  "page.glass": "Volvia Glass",
  "page.details": "Details",
  "page.statusChanged.message": "Case moved to {status}",
  "page.decisionChanged.message": "Decision changed to {decision}",
  "page.settings": "Vacation settings",
  "page.test": "Test",
  "page.stats": "Stats",
  "field.photoSource": "Source",
  "field.recordId": "ID",
  "field.claimNumber": "Claim number",
  "field.customer.name": "Name",
  "field.customer.registrationNumber": "Reg number",
  "field.customer.phoneNumber": "Phone",
  "field.workshopName": "Workshop",
  "field.workshopId": "Workshop ID",
  "field.customer.email": "E-mail",
  "field.mileage": "Mileage",
  "field.description": "Description",
  "field.status": "Status",
  "field.decision": "Decision",
  "field.decidedBy": "Decided by",
  "field.dateDecided": "Decided at",
  "field.createdBy": "Sent by",
  "field.dateCreated": "Sent at",
  "field.glass.dateCreated": "Created at",
  "field.dateOfIntake": "Created at",
  "field.comments": "Comments",
  "field.intakeLink": "Intake",
  "field.claimDate": "Claim date",
  "field.city": "City",
  "field.availability": "Photo inspection",
  "field.summerWorkshopInstructions": "If your business will be closed for vacation during the summer, please mark the photo inspection service as closed for that period. To avoid any unhandled requests, specify the closure dates one week in advance.",
  "field.workshopDecision": "Decision",
  "link.back": "Back",
  "link.edit": "Edit",
  "link.createNewInspection": "Send inspection",
  "newInspection.claimNumber": "Claim number (optional)",
  "newInspection.customerPhoneNumber": "Customer phone number",
  "newInspection.sendLinkToCustomer": "Send link to customer",
  "newInspection.sent": "New inspection sent by.",
  "newInspection.registrationNumber": "Registration number",
  "newInspection.workshop": "Workshop",
  "newInspection.brand": "Insurance",
  "newInspection.registrationNumber.validationMessage": "Registration number is invalid",
  "newInspection.claimNumber.validationMessage": "Claim number is invalid",
  "newInspection.phone.validationMessage": "Phone is invalid",
  "newInspection.workshop.validationMessage": "Workshop is not selected",
  "newInspection.brand.validationMessage": "Brand needs to be selected",
  "newInspection.select.workshop": "Select workshop",
  "newInspection.select.brand": "Select insurance",
  "newInspection.customerName": "Customer name",
  "newInspection.customerName.validationMessage": "Customer name is invalid",
  "decision.Undecided": "-",
  "decision.Repair": "Repair",
  "decision.PreInspection": "Pre-inspection",
  "glassDecision.NotAvailable": "-",
  "glassDecision.Repair": "Repair",
  "glassDecision.Replace": "Change",
  "status.Open": "Open",
  "status.New": "New",
  "status.InProgress": "In Progress",
  "status.OnHold": "On Hold",
  "status.Closed": "Closed",
  "status.Deleted": "Deleted",
  "photos.count": "Photo {current} of {total}",
  "photos.save": "Save photo",
  "photos.saveAll": "Save all",
  "photos.saveAllPreparing": "Preparing",
  "photos.saveAllDownloaded": "Downloaded",
  "photos.photosProcessing": "Photos are being processed, please come back later",
  "photos.noPhotos": "Waiting on photos from customer",
  "comment.placeholder": "Comment about the case...",
  "comment.buttonAdd": "Add",
  "comment.tooLong": "Comment text is too long (maximum 500 characters)",
  "search.placeHolder": "Search",
  "action.sendIntakeLinkReminder": "Send SMS reminder",
  "action.sendIntakeLinkReminder.success": "Reminder has been sent",
  "action.sendIntakeLinkReminder.hasBeenSent": "Reminder has been sent",
  "action.sendToCabas": "Create calculation (CABAS)",
  "action.sendToCabas.success": "Calculation is being created in CABAS",
  "action.sendToCabas.error": "Calculation could not be created",
  "workshop.ui.page.title": "Bodyshop portal",
  "feedback.title": "Give us feedback",
  "feedback.info": "We always aim to improve our services. Tell us what you think about the Photo Inspection and if there is anything that would make it work better for you.\\nPlease note that if you experience any system errors, please contact the system responsible instead of filling this form.",
  "feedback.input": "Comment",
  "feedback.cancel": "Cancel",
  "feedback.send": "Send feedback",
  "feedback.success": "Thank you for your feedback!",
  "inactive.user.disabled.text": "Your account has been disabled due to inactivity. Please contact",
  "inactive.user.contact.email.address.text": "support",
  "intake.ui.page.title": "Send damage photo",
  "intake.ui.page.title.tabHeader": "Photo Inspection - Send damage photo",
  "intake.LandingStep.header": "Photo inspection",
  "intake.LandingStep.madeBy": "A service provided by",
  "intake.LandingStep.text": "Send photos of the damage to the workshop instead of booking a pre-inspection. It only takes a few minutes. The workshop will contact you afterwards with more information about next step.",
  "intake.LandingStep.text2": "We collect some data.",
  "intake.LandingStep.link.text": "See our pages on personal data management.",
  "intake.LandingStep.consentCheckbox.text": "I've read and consent to",
  "intake.LandingStep.consent.link.text": "the terms of service",
  "intake.LandingStep.privacyPolicy.text": "See our pages on personal data management",
  "intake.LandingStep.privacyPolicy.link.text": "here",
  "intake.LandingStep.accessibilityStatement.link.text": "Accessibility statement",
  "intake.alt.imageCar": "Car",
  "intake.alt.imageSummary.back": "Your photo of the car taken from the rear view",
  "intake.alt.imageSummary.distance": "Photo of the car taken from a distance",
  "intake.alt.imageSummary.closeup": "Photo of the car taken closeup",
  "intake.alt.imageSummary.diagonal-front": "Photo of the car taken from an angle",
  "intake.alt.imageSummary.diagonal-back": "Photo of the car taken from a second angle",
  "intake.alt.back": "Back",
  "intake.alt.imageIllustration.back": "Illustration of a car from the rear view",
  "intake.alt.imageIllustration.distance": "Illustration of a car from a distance",
  "intake.alt.imageIllustration.closeup": "Illustration of a car closeup",
  "intake.alt.imageIllustration.diagonal-front": "Illustration of a car from an angle",
  "intake.alt.imageIllustration.diagonal-back": "Illustration of a car from a second angle",
  "intake.wizard.buttonStart": "Start photo inspection",
  "intake.wizard.buttonNext": "Next",
  "intake.wizard.buttonSubmit": "Send photo inspection",
  "intake.wizard.buttonBack": "Back",
  "intake.wizard.sendingInformation": "Your information is being submitted...",
  "intake.wizard.sendingFile": "Uploading your photos: {current} of {total}",
  "intake.wizard.buttonSave": "Save",
  "intake.ContactInformationStep.header": "Your information",
  "intake.ContactInformationStep.text": "We need some information to get in contact with you and book an appointment.",
  "intake.ContactInformationStep.helpText": "Fields marked with * are mandatory",
  "intake.ContactInformationStep.customer.name.header": "Name",
  "intake.ContactInformationStep.customer.name.validationMessage": "Name cannot be empty",
  "intake.ContactInformationStep.customer.phoneNumber.header": "Phone number",
  "intake.ContactInformationStep.customer.phoneNumber.validationMessage": "Telephone number is not valid.",
  "intake.ContactInformationStep.customer.email.header": "Email",
  "intake.ContactInformationStep.customer.email.validationMessage": "Email address is not valid.",
  "intake.ContactInformationStep.customer.registrationNumber.header": "Registration number",
  "intake.ContactInformationStep.customer.registrationNumber.validationMessage": "Registration number is not valid.",
  "intake.ContactInformationStep.mileage.header": "Car kilometrage",
  "intake.ContactInformationStep.mileage.validationMessage": "Kilometrage is not valid.",
  "intake.ContactInformationStep.claimDate.header": "Claim date",
  "intake.ContactInformationStep.claimDate.validationMessage": "Date is not valid",
  "intake.DescriptionStep.header": "Description of damage",
  "intake.DescriptionStep.help": "Add a short description of the damage and where it's located.",
  "intake.DescriptionStep.description.header": "Description",
  "intake.DescriptionStep.description.validationMessage": "Maximum 200 characters ({fieldLength} too many)",
  "intake.DescriptionStep.description.statusMessage": "Maximum 200 characters ({descriptionLength} remaining)",
  "intake.DescriptionStep.placeholder": "Example: The damage is two small dents located on the drivers side near the back of the wheel and the passenger door.",
  "intake.PhotoStep.header": "Photo upload",
  "intake.PhotoStep.header.back": "Rear view",
  "intake.PhotoStep.text.back": "Take a photo or upload an image from the rear of the car where you include the registration number in the photo.",
  "intake.PhotoStep.header.distance": "The damage from a distance",
  "intake.PhotoStep.text.distance": "Take a photo of the damage from a distance of about 3 meters.",
  "intake.PhotoStep.header.closeup": "Close up of the damage",
  "intake.PhotoStep.text.closeup": "Take a close-up of the damage straight from the front.",
  "intake.PhotoStep.header.diagonal-front": "Close-up from one side",
  "intake.PhotoStep.text.diagonal-front": "Take a close-up of the damage slightly obliquely angled from one direction.",
  "intake.PhotoStep.header.diagonal-back": "Close-up from opposite side of the damage.",
  "intake.PhotoStep.text.diagonal-back": "Take a close-up of the damage slightly obliquely angled from the opposite direction.",
  "intake.PhotoStep.header.extra": "Additional photos (optional)",
  "intake.PhotoStep.text.extra": "Take up to 2 additional photos of the damage. You can also skip this and go directly to the next step.",
  "intake.PhotoStep.add-button": "Take or upload photo",
  "intake.PhotoStep.deleteQuestion": "Delete photo?",
  "intake.PhotoStep.cancelButton": "Cancel",
  "intake.PhotoStep.deleteButton": "Delete",
  "intake.PhotoStep.validationMessage": "You need to add a photo to continue",
  "intake.PhotoStep.validationMessage.tooLargePhoto": "The photo attached is too large. Maximum file size is {fileSize}.",
  "intake.PhotoStep.validationMessage.wrongFileType": "The photo attached has wrong file type. Only PNG or JPG is allowed.",
  "intake.PhotoStep.validationMessage.duplicatePhoto": "This photo has already been added, please try again with another photo.",
  "intake.SummaryStep.header": "Summary",
  "intake.SummaryStep.text": "Almost done! Please review your photos and the information that will be sent to the bodyshop.",
  "intake.SummaryStep.error": "An error has occured, please make sure you have a good network connection.",
  "intake.SummaryStep.photos.header": "Photos",
  "intake.SummaryStep.description.missing": "No additional description added",
  "intake.SummaryStep.information.header": "Your information",
  "intake.SummaryStep.customer.name.header": "Name",
  "intake.SummaryStep.customer.phoneNumber.header": "Phone num.",
  "intake.SummaryStep.customer.email.header": "Email",
  "intake.SummaryStep.customer.registrationNumber.header": "Reg. num.",
  "intake.SummaryStep.mileage.header": "Mileage",
  "intake.SummaryStep.claimDate.header": "Claim date",
  "intake.ConfirmationStep.header": "Thank you!",
  "intake.ConfirmationStep.textIf": "Your photos has been sent to {workShopName}. They'll contact you for the next step.",
  "intake.ConfirmationStep.textWhiteLabel": "Your photo inspection is complete and the photos have been sent to the workshop. {workShopName} will contact you within {slaDays} working days to book the next step.",
  "intake.ConfirmationStep.feedbackHeader": "What did you think about the photo inspection?",
  "intake.ConfirmationStep.feedbackText.header": "Comment (optional)",
  "intake.ConfirmationStep.feedbackText.placeholder": "How was your experience with photo inspection?",
  "intake.ConfirmationStep.feedbackButton": "Send",
  "intake.ConfirmationStep.feedbackConfirmation": "Thank you for your feedback!",
  "intake.invalid.header": "Oops.. Page not found",
  "intake.invalid.expiredOrDone": "This link is no longer valid",
  "intake.invalid.error": "Something went wrong, please try again.",
  "intake.invalid.text": "I'm sorry, the page you tried to reach does not exist. It has either been moved or the URL you used was wrong.",
  "intake.VolviaLandingStep.header": "Photo inspection",
  "intake.VolviaLandingStep.text": "Save time and money. Take pictures of the damage to get a digital assesment and booking from the workshop within 3 days.",
  "intake.VolviaLandingStep.link.text": "Personal data management",
  "intake.InstructionStep.header": "This is how it works",
  "intake.InstructionStep.text": "We will first ask you to enter your contact details, some information about your car and also about the damage.",
  "intake.InstructionStep.text2": "Then you will be asked to take or upload 5 photos of the damage to help the workshop with the assessment. The photos you need are one on the car from behind, one where the whole damage is visible and three close ups of the damage from different angles. See examples below.",
  "confirm.delete.title": "Delete case",
  "confirm.delete.text": "This is a link that is sent to customer by you or advisors at If. If you delete this case, the customer will no longer be able to send photos to your workshop.",
  "confirm.delete.cancel": "Cancel",
  "confirm.delete.yes": "Delete",
  "curityMigrationBannerText": "We have made a technical update to the Photo Inspection. To sign in, please change your password via the email that has been sent to you.  After that you can log in as usual.",
  "section.bodyInspections": "Vehicle body",
  "section.glassInspections": "Glass",
  "section.admin": "Admin panel",
  "glass.customerPhotos": "Customer photos",
  "glass.inbox": "Glass inbox",
  "glass.workshopPhotos": "Workshop photos",
  "photoSource.Customer": "Customer photos",
  "photoSource.Workshop": "Workshop photos"
};
export default translations;