import React from "react";
import MarkdownView from "react-showdown";

export function TermsOfServiceIfDk() {
  // language=Markdown
  let tosMarkdown = `##Brugervilkår for Ifs fotobesigtigelsestjeneste 
Vi i If er glade for, at du har valgt at anvende Ifs fotobesigtigelsestjeneste (”Tjenesten”). Inden du kan benytte dig af Tjenesten, skal du godkende disse brugervilkår. Brugervilkårene udgør en aftale (”Aftalen”) mellem dig og If Skadeforsikring, filial af If Skadeförsäkring AB (publ), Sverige, CVR-nr. 24203212 (”Tjenesteleverandøren”).
1.\tTjenesten

  1.1\tDu får ved brug af Tjenesten mulighed for at overføre fotografier af det motorkøretøj, som er blevet skadet, til det værksted, som du anvender, hvis værkstedet er tilmeldt Tjenesten, og værkstedet har accepteret, at du overfører fotografier gennem Tjenesten.
  
  1.2\tTjenesten leveres i den stand og i den version, som Tjenesten har på det tidspunkt, som du benytter Tjenesten.
   
2.\tBehandling af personoplysninger
 
  2.1\tVed anvendelse af Tjenesten får Tjenesteleverandøren adgang til de oplysninger, som du registrerer i eller uploader til Tjenesten, f.eks. navn, telefonnummer og registreringsnummer på det køretøj, der fotograferes. Tjenesteleverandøren overfører disse oplysninger til det aktuelle værksted på dine vegne og gemmer oplysningerne så længe, som det er nødvendigt til at kunne dokumentere opdraget. Hvis Tjenesten benyttes i forbindelse med et skadesopgør hos Tjenesteleverandøren, gemmes oplysningerne også i den aktuelle skadesag.

  2.2\tDe fotografier, som du uploader til Tjenesten, kan anvendes til analyseformål, f.eks. analyse af køretøjets skader, samt til brug for behandling af skadesager. 

  2.3\tDu kan læse mere om Tjenesteleverandørens håndtering af personoplysninger og dine rettigheder på Ifs håndtering af personoplysninger - Vi passer på dig.
  
3.\tImmaterielle rettigheder og vilkår for anvendelse af Tjenesten
  
  3.1\tAlle immaterielle rettigheder tilknyttet Tjenesten, herunder materiale som vises eller eksisterer i Tjenesten, tilhører Tjenesteleverandøren eller en af dennes underleverandører. Hvis du accepterer og opfylder vilkårene i Aftalen, får du en personlig, ikke-eksklusiv og ikke-overførbar ret til at anvende Tjenesten og funktionerne, som tilbydes derigennem. Denne ret ophører automatisk, hvis Tjenesteleverandøren ophører med at tilbyde Tjenesten eller deaktiverer Tjenesten, eller hvis Aftalen ophører ved opsigelse eller på anden måde. 
  
  3.2\tDu har ikke ret til at:
   
    (a)\tkopiere eller anvende Tjenesten til andre formål end beskrevet i Aftalen,
     
    (b)\tsælge, distribuere eller på anden måde overføre Tjenesten til tredjemand,
     
    (c)\tudarbejde tilpasninger eller ændringer af Tjenesten,
     
    (d)\tanvende Tjenesten til at overføre ulovlige eller krænkende data eller lignende oplysninger, som er en kopi af tredjemands data, eller
     
    (e)\ttillade tredjemand at udføre nogle af de ovennævnte handlinger. 
  3.3\tNår du uploader et fotografi i Tjenesten, giver du samtidig Tjenesteleverandøren en ikke-eksklusiv og overførbar ret til at anvende fotografiet til analyseformål på ubestemt tid.  Eventuelle personoplysninger, som knytter sig til fotografiet, skal ved sådan brug anonymiseres (som eksempelvis navn og adresse på bilejere, registreringsnummer mv.), således at fotografiet ikke længere kan knyttes til en bestemt person. Denne klausul gælder fortsat, også selvom Aftalen måtte blive opsagt eller på anden måde ophører. 
4.\tDatatrafik
Du skal have internetforbindelse for at kunne anvende Tjenesten. Du er ansvarlig for alle omkostninger tilknyttet til internetforbindelsen, herunder omkostninger til datatrafik og roaming. 
5.\tAnsvar og ansvarsbegrænsning
Tjenesteleverandøren tilstræber, at Tjenesten er tilgængelig døgnet rundt. Tjenesten kan imidlertid være utilgængelig grundet service, opdateringer eller tekniske problemer. Fotografier og andet materiale, som du har uploadet i Tjenesten, kan af uforudsete grunde blive slettet og forsvinde for altid. Du er ansvarlig for at opbevare sikkerhedskopier af alt materiale, som du oploader i Tjenesten. Tjenesteleverandøren er ikke ansvarlig for skader eller tab, som kan tilskrives Tjenesten, uafhængig af om tabet er direkte eller indirekte. 
6.\tTvister
Enhver tvist, som måtte opstå mellem dig og Tjenesteleverandøren, vedrørende forhold reguleret i Aftalen, er undergivet dansk ret og skal afgøres ved en dansk domstol. 
7.\tØvrige vilkår
  7.1\tTjenesteleverandøren forbeholder sig retten til at opsige Aftalen når som helst eller at ophøre med at levere Tjenesten. 
  7.2\tBestemmelser i Aftalen, som ifølge sin natur er egnet til at gælde efter Aftalens ophør, skal fortsat være gældende efter Aftalens ophør. 
  7.3\tHvis der er vilkår eller betingelser i Aftalen, som bliver kendt ugyldige, eller som ikke kan håndhæves, skal de øvrige bestemmelser fortsat være gældende eller fortolkes i overensstemmelse med gældende lov for i så vidt omfang som muligt at opnå det oprindelige formål med disse bestemmelser. 
_______________________________
<small>Disse vilkår er opdateret den 7. september 2021.</small> 

<small>If Skadeforsikring, filial af If Skadeförsäkring AB (publ), Sverige
CVR-nr. 24203212<br/>
Stamholmen 159, 2650 Hvidovre<br/>
www.if.dk<br/>
Tlf. 70 12 12 12<br/>
Filial af:<br/>
If Skadeförsäkring AB (publ)<br/>
Hovedkontor: S-106 80 Stockholm<br/> 
Bolagsverket, reg.nr.: 516401-8102<br/>
</small> 

`;
  return (
    <div className="if consent-container">
      <MarkdownView markdown={tosMarkdown} />
    </div>
  );
}
