import "@if-design-system/bundle";
import "./app.scss";
import "what-input";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./infrastructure/applicationInsights";
import { IntlProvider } from "react-intl";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import IntakeUi from "./components/intake/intakeUi";
import WorkshopUi from "./components/workshopUi";
import { messages } from "./translations/messages";
import { history } from "./store";
import { useLocalStorage, hasValueInLocalStorage, mapToLocale } from "./utility";
import { useSelector } from "react-redux";
import { AppState } from "./models";
import { IUserInfo } from "./backend-models";
import Landing from "./components/landing";
import NotFound from "./components/notFound";
import { DevMenu } from "./admin/components/devMenu";
import { useAuth } from "./auth/authContext";
import { IconSprites } from "@ids/react-icon";

export const intakePaths = {
  short: "/:data([a-z0-9_-]{23})",
  long: "/intake/:id/:brand?",
};

const selectedLanguage: string = "if.photo.inspection.selectedLanguage";

const UserLocaleSetter: React.FC<{ locale; setLocale }> = ({ locale, setLocale }) => {
  const userInfo: IUserInfo = useSelector((state: AppState) => state.userInfo);

  useEffect(() => {
    if (
      !userInfo?.isLoading &&
      userInfo?.preferredLanguageCode &&
      !hasValueInLocalStorage(selectedLanguage)
    ) {
      if (locale !== userInfo.preferredLanguageCode) {
        setLocale(userInfo.preferredLanguageCode);
      }
    }
  }, [locale, setLocale, userInfo]);
  return null;
};

function App() {
  const { isAuthenticated } = useAuth();
  const [_key, reset] = useState(0);
  const [locale, setLocaleInternal] = useLocalStorage(selectedLanguage, "en");

  // if the locale in the storage is incorrect ('se' instead of 'sv')
  // this converts the country code to locale and stores it in the
  // local storage
  const mappedLocale = mapToLocale(locale);
  if (locale !== mappedLocale) {
    console.error("Locale set in local storage was not correct. Changing from", locale, "to", mappedLocale);
    setLocaleInternal(mappedLocale);
  }

  console.log("local storage locale:", locale);

  function setLocale(locale: string) {
    locale = mapToLocale(locale);
    setLocaleInternal(locale);
  }

  useEffect(() => {
    Object.keys(window.ClientEnvironment.flags).forEach((flag) => {
      let val = window.ClientEnvironment.flags[flag];
      if (val) {
        document.documentElement.classList.add("flag-" + flag);
      }
    });
  }, []);

  useEffect(() => {
    let dayjs_locale =
      {
        id: "sv-se",
        en: "sv-se",
      }[locale] || locale;
    dayjs.locale(dayjs_locale);
  }, [locale]);

  let intakeRender = () => <IntakeUi setLocale={setLocale} />;

  return (
    <Router history={history}>
      <IconSprites />
      {/* prettier-ignore */}
      <DevMenu reset={() => { reset(_key + 1) }} setLocale={setLocale} />
      <AppInsightsContext.Provider value={reactPlugin}>
        <UserLocaleSetter locale={locale} setLocale={setLocale} />
        <IntlProvider
          locale={locale}
          messages={messages[locale]}
          onError={(err) => {
            if (err.code === "MISSING_TRANSLATION" || err.code === "MISSING_DATA")
              return console.warn(err.message);
            throw err;
          }}
        >
          <Switch>
            <Route exact path="/missing/*" component={NotFound} />
            <Route exact path={intakePaths.short} render={intakeRender} />
            <Route exact path={intakePaths.long} render={intakeRender} />
            {!isAuthenticated && <Route exact path="/" component={Landing} />}
            {isAuthenticated && (
              <Route exact path="/">
                <Redirect to={"/inbox-redirect"} />
              </Route>
            )}
            <Route render={() => <WorkshopUi locale={locale} setLocale={setLocale} />} />
          </Switch>
        </IntlProvider>
      </AppInsightsContext.Provider>
    </Router>
  );
}

export default App;
