import * as React from "react";
import "./tableMenu.scss";
import { useComponentVisible } from "./componentVisibleHelper";

export interface IMenuItem {
  text: JSX.Element | string;
  action?(): void;
  disabled?: boolean;
  tooltipText?: string;
}

interface IProps {
  menuItems: IMenuItem[];
}

const ToolTip = ({ tooltipText, children }) =>
  tooltipText ? <abbr data-tooltip={tooltipText}>{children}</abbr> : children;

const TableMenu: React.FC<IProps> = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const { menuItems } = props;
  return (
    <>
      {menuItems && menuItems.length > 0 && (
        <div ref={ref}>
          {isComponentVisible && (
            <div className={`if menu is-open menuContainer`} data-cy="menuContainer">
              <ul className="if">
                {menuItems.map((element, index) => {
                  if(!element.action)
                  {
                    return <li key={index}>
                      <div className="text menu-item-text">
                        {element.text}
                      </div>
                    </li>
                  }
                  return (
                    <li key={index} className="if" data-cy="menuButton">
                      <ToolTip tooltipText={element.tooltipText}>
                        <button
                          className="menuButton"
                          onClick={() => {
                            setIsComponentVisible(false);
                            element.action();
                          }}
                          disabled={element.disabled}
                        >
                          {element.text}
                        </button>
                      </ToolTip>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <button
            type="button"
            onClick={() => setIsComponentVisible(true)}
            className="menuButton actions button control if"
            data-cy="openMenuButton"
          />
        </div>
      )}
    </>
  );
};

export default TableMenu;
