import React from "react";
import { withAITracking, reactPlugin } from "../infrastructure/applicationInsights";
import { FormattedMessage } from "react-intl";

const UnAuthorized: React.FC = () => {
  return (
    <div className="main-container padded-block" data-cy="unauthorized-container">
      <FormattedMessage id="inactive.user.disabled.text" />
      &nbsp;
      <a
        className="if external-link mail-link"
        href="mailto:fotobesiktning@if.se"
        data-cy="support-link"
      >
        <FormattedMessage id="inactive.user.contact.email.address.text" />
      </a>
      .
    </div>
  );
};

export default withAITracking(reactPlugin, UnAuthorized);
