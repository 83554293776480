import React from "react";

export const TestPrefillCheckbox: React.FC<{ value; onChange; name }> = (props) => {
  return (
    <div className="if selection-controls" style={{ margin: 0 }}>
      <input
        id={"prefill-" + props.name}
        name={"prefill-" + props.name}
        type="checkbox"
        checked={props.value}
        onChange={(e) => props.onChange(e.target.checked)}
        className="if toggle"
      />
      <label htmlFor={"prefill-" + props.name}>Fill with data</label>
    </div>
  );
};
