import React from "react";
import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import * as Actions from "../actions";
import { Action } from "redux-actions";
import { Backend } from "../backend";
import { IRecord } from "../backend-models";
import { NotificationType } from "../models";
import { FormattedMessage } from "react-intl";

function* recordSendToCabas(params: Action<IRecord>) {
  try {
    yield* Backend.Record.SendToCabas(params.payload);
    yield put(Actions.recordSendToCabasSuccess(params.payload));
    yield put(
      Actions.showNotification({
        message: <FormattedMessage id="action.sendToCabas.success" />,
        time: new Date(),
        type: NotificationType.Success,
      })
    );
  } catch (err) {
    yield put(Actions.recordSendToCabasError(params.payload));
    yield put(
      Actions.showNotification({
        message: <FormattedMessage id="action.sendToCabas.error" />,
        time: new Date(),
        type: NotificationType.Error,
      })
    );
  }
}
export function* recordSendToCabasSaga(): SagaIterator {
  yield takeLatest(Actions.recordSendToCabas, recordSendToCabas);
}
