import { SagaIterator } from "redux-saga";
import { all, put, takeLeading } from "redux-saga/effects";
import * as Actions from "../actions";
import { showError } from "./common";
import { Backend } from "../backend";
import { processGlassInspectionsResponse } from "../models";

function* fetchGlassInspections() {
  try {
    let response = yield* Backend.Record.GlassInspections();

    yield put(Actions.fetchGlassInspectionsSuccess(processGlassInspectionsResponse(response)));
  } catch (err) {
    yield showError(err);
    yield all([put(Actions.fetchGlassInspectionsError(err))]);
  }
}
export default function* fetchGlassInspectionsSaga(): SagaIterator {
  yield takeLeading(Actions.fetchGlassInspections, fetchGlassInspections);
}
