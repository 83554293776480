import * as Actions from "../actions";
import reducer from "./reducer";
import { IntakeSendingState } from "../models";
import { IIntakeSubmission } from "../intakeModels";

export default reducer<IIntakeSubmission>(
  {
    sendingState: IntakeSendingState.NotStarted,
    feedback: {},
  } as IIntakeSubmission,
  (on) => {
    on(Actions.submitIntake, (_, action) => {
      return {
        ..._,
        data: action.payload.data,
        sendingState: IntakeSendingState.SendingFiles,
        loadError: undefined,
        filesUploaded: _.filesUploaded ?? 0,
        filesTotal: action.payload.files.length,
        uploadProgres: 0,
      };
    });
    on(Actions.submitIntakeError, (_, action) => {
      return {
        ..._,
        sendingState: IntakeSendingState.NotStarted,
        loadError: `Failed to submit intake: ${action.payload.message}`,
      };
    });
    on(Actions.submitIntakeFilesProgress, (_, action) => {
      return {
        ..._,
        filesUploaded: _.filesUploaded + 1,
        uploadProgres: 0,
      };
    });
    on(Actions.submitIntakeFileUploadProgress, (_, action) => {
      return {
        ..._,
        uploadProgres: action.payload,
      };
    });
    on(Actions.submitIntakeInfo, (_, action) => {
      return {
        ..._,
        sendingState: IntakeSendingState.SendingInfo,
        loadError: undefined,
      };
    });
    on(Actions.submitIntakeFinished, (_, action) => {
      return {
        ..._,
        sendingState: IntakeSendingState.Finished,
      };
    });
    on(Actions.submitIntakeCancel, (_, action) => {
      return {
        ..._,
        sendingState: IntakeSendingState.NotStarted,
      };
    });
    on(Actions.submitIntakeFeedback, (_, action) => {
      return {
        ..._,
        feedback: action.payload.data,
      };
    });
    on(Actions.submitIntakeFeedbackSuccess, (_, action) => {
      return _;
    });
    on(Actions.submitIntakeFeedbackError, (_, action) => {
      return _;
    });
    on(Actions.submitIntakeFilesReset, (_, action) => {
      return {
        ..._,
        filesUploaded: 0,
      };
    });
  }
);
