import React, { useReducer } from "react";
//import "./workshopFeedback.scss";
import { AppState } from "models";
import { useDispatch, useSelector } from "react-redux";
import { adminGetWorkshopVisibilityCookie, adminSetWorkshopVisibilityCookie } from "utility";
import * as Actions from "actions";
import { clearWorkshopFilter } from "workshopFilter";

export const WorkshopVisibility: React.FC = () => {
  const dispatch = useDispatch();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [cookieValue, all] = adminGetWorkshopVisibilityCookie();

  const workshops = useSelector((state: AppState) => state.workshops)?.workshops ?? [];
  React.useEffect(() => {
    dispatch(Actions.fetchWorkshops());
  }, [dispatch]);

  let visible = workshops.map((w) => w.id);
  if (cookieValue) {
    visible = visible.filter((k) => cookieValue.includes(k));
  }

  function setVisibility(data) {
    adminSetWorkshopVisibilityCookie(data);

    clearWorkshopFilter();

    forceUpdate();
  }

  console.log("cookie:", cookieValue);

  function toggleWorkshop(k: string) {
    let i = visible.indexOf(k);
    if (i > -1) {
      visible.splice(i, 1);
    } else {
      visible.push(k);
    }
    setVisibility(visible.join(", "));
  }

  const sorted = [...workshops].sort((a, b) => a.name.localeCompare(b.name));

  // noinspection SpellCheckingInspection
  ["PHINTEST", "VOLVIATEST", "FITEST", "NOTEST", "DKTEST"].forEach((id, idx) => {
    const i = sorted.findIndex((w) => w.id === id);
    const workshop = workshops.find((w) => w.id === id);
    if (i > -1) {
      sorted.splice(i, 1);
      sorted.splice(idx, 0, workshop);
    }
  });

  return (
    <div className="padded-block admin-workshop-feedback">
      <h1>Workshop Visibility</h1>
      <label>
        <input type="checkbox" checked={all} onChange={() => setVisibility(all ? "none" : "all")} />
        All workshops
      </label>
      {!all &&
        sorted.map((workshop) => (
          <label key={workshop.id} style={{ display: "block", marginLeft: "1em" }}>
            <input
              type="checkbox"
              checked={visible.includes(workshop.id)}
              onChange={() => toggleWorkshop(workshop.id)}
            />
            {workshop.name} ({workshop.city} {workshop.id})
          </label>
        ))}
    </div>
  );
};
