import React from "react";
import { purgeTestRecords } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { IUserInfo } from "../../backend-models";
import { AppState } from "../../models";

export const TestCleaning: React.FC = () => {
  const dispatch = useDispatch();
  const userInfo: IUserInfo = useSelector((state: AppState) => state.userInfo);
  return (
    <>
      <h2>Cleaning</h2>
      <button className="if button primary" onClick={() => dispatch(purgeTestRecords())}>
        Delete all my records
      </button>{" "}
      (Workshop=PHINTEST, CreatedBy={userInfo.name})
    </>
  );
};
