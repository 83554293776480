import * as Actions from "../actions";
import reducer from "./reducer";
import { INotification } from "../models";

function match(left: INotification, right: INotification) {
  return left.time === right.time && left.message === right.message;
}

export default reducer<INotification[]>([], (on) => {
  on(Actions.addNotification, (state, action) => {
    return [...state, action.payload];
  });

  on(Actions.fadeNotification, (state, action) => {
    let i = state.findIndex((n) => match(n, action.payload));
    state = [...state];
    let notification = state[i];
    if (notification != null) {
      state[i].fade = true;
    }
    return state;
  });

  on(Actions.removeNotification, (state, { payload }) => {
    return [...state].filter((n) => !match(n, payload));
  });
});
