import { createAction } from "redux-actions";
import {
  IError,
  IGlassInspectionWithWorkshop,
  IGlassInspectionWithWorkshopResponse,
  IInspection,
  IInspectionWithWorkshop,
  IInspectionWithWorkshopResponse,
  INotification,
  IWorkshopFilter,
} from "./models";
import {
  IAddCommentRequest,
  IChangeGlassStatusRequest,
  IChangeStatusRequest,
  IDocument,
  IFeedback,
  IGlassInspectionRequest,
  IInspectionFeedback,
  IIntakeInformation,
  INewInspectionRequest,
  IRecord,
  IUserInfo,
  IWorkshop,
} from "./backend-models";
import { ISubmitIntakeActionArguments } from "./intakeModels";

//fetchRecords
export const fetchRecords = createAction("FETCH_RECORDS");
export const fetchRecordsSuccess = createAction<IInspectionWithWorkshopResponse>("FETCH_RECORDS_SUCCESS");
export const fetchRecordsError = createAction<IError>("FETCH_RECORDS_ERROR");

//fetchWorkshop
export const fetchWorkshops = createAction("FETCH_WORKSHOPS");
export const fetchWorkshopsSuccess = createAction<IWorkshop[]>("FETCH_WORKSHOPS_SUCCESS");
export const fetchWorkshopsError = createAction<IError>("FETCH_WORKSHOPS_ERROR");

//fetchRecord
export const fetchRecord = createAction<string>("FETCH_RECORD");
export const fetchRecordSuccess = createAction<IInspectionWithWorkshop>("FETCH_RECORD_SUCCESS");
export const fetchRecordError = createAction<IError>("FETCH_RECORD_ERROR");

//fetchPhotos
export const fetchPhotos = createAction<IInspection>("FETCH_PHOTOS");
export const fetchPhotosSuccess = createAction<IDocument[]>("FETCH_PHOTOS_SUCCESS");
export const fetchPhotosError = createAction<IError>("FETCH_PHOTOS_ERROR");

export const updatePhoto = createAction<IDocument>("UPDATE_PHOTO");
export const updatePhotoSuccess = createAction<IDocument>("UPDATE_PHOTO_SUCCESS");

//addRecord
export const addRecord = createAction<IRecord>("ADD_RECORD");
export const addRecordSuccess = createAction<IInspectionWithWorkshop>("ADD_RECORD_SUCCESS");
export const addRecordError = createAction<IError>("ADD_RECORD_ERROR");

//newInspection
export const newInspection = createAction<INewInspectionRequest | IGlassInspectionRequest>("NEW_INSPECTION");
export const newInspectionSuccess = createAction("NEW_INSPECTION_SUCCESS");
export const newInspectionError = createAction<IError>("NEW_INSPECTION_ERROR");
export const newInspectionReset = createAction("NEW_INSPECTION_RESET");

//sendIntakeLinkReminder
export const sendIntakeLinkReminder = createAction<{ record: IRecord; intl }>("RESEND_SMS");
export const sendIntakeLinkReminderSuccess = createAction<IInspectionWithWorkshop>("RESEND_SMS_SUCCESS");
export const sendIntakeLinkReminderError = createAction<IError>("RESEND_SMS_ERROR");

//updateRecord
export const updateRecord = createAction<IRecord>("UPDATE_RECORD");
export const changeRecordStatus = createAction<IChangeStatusRequest>("CHANGE_RECORD_STATUS");
export const updateRecordSuccess = createAction<IInspectionWithWorkshop>("UPDATE_RECORD_SUCCESS");
export const updateRecordError = createAction<IError>("UPDATE_RECORD_ERROR");

//notification
export const showNotification = createAction<INotification>("SHOW_NOTIFICATION");
export const closeNotification = createAction<INotification>("CLOSE_NOTIFICATION");
export const fadeNotification = createAction<INotification>("FADE_NOTIFICATION");
export const addNotification = createAction<INotification>("ADD_NOTIFICATION");
export const removeNotification = createAction<INotification>("HIDE_NOTIFICATION");

//addComment
export const addComment = createAction<IAddCommentRequest>("ADD_COMMENT");
export const addCommentSuccess = createAction<IInspectionWithWorkshop>("ADD_COMMENT_SUCCESS");
export const addCommentError = createAction<IError>("ADD_COMMENT_ERROR");

export const recordSendToCabas = createAction<IRecord>("RECORD_SEND_TO_CABAS");
export const recordSendToCabasSuccess = createAction<IRecord>("RECORD_SEND_TO_CABAS_SUCCESS");
export const recordSendToCabasError = createAction<IRecord>("RECORD_SEND_TO_CABAS_ERROR");

//searchRecords
export const searchRecords = createAction<string>("SEARCH_RECORDS");
export const searchRecordsSuccess = createAction<IInspectionWithWorkshopResponse>("SEARCH_RECORDS_SUCCESS");
export const searchRecordsError = createAction<IError>("SEARCH_RECORDS_ERROR");

export const fetchGlassInspections = createAction("FETCH_GLASS_INSPECTIONS");
export const fetchGlassInspectionsSuccess = createAction<IGlassInspectionWithWorkshopResponse>(
  "FETCH_GLASS_INSPECTIONS_SUCCESS"
);
export const fetchGlassInspectionsError = createAction<IError>("FETCH_GLASS_INSPECTIONS_ERROR");

export const fetchGlassInspection = createAction<string>("FETCH_GLASS_INSPECTION");
export const fetchGlassInspectionSuccess = createAction<IGlassInspectionWithWorkshop>("FETCH_GLASS_INSPECTION_SUCCESS");
export const fetchGlassInspectionError = createAction<IError>("FETCH_GLASS_INSPECTION_ERROR");

export const changeGlassInspectionStatus = createAction<IChangeGlassStatusRequest>("CHANGE_GLASS_STATUS");
export const updateGlassInspectionSuccess = createAction<IGlassInspectionWithWorkshop>("UPDATE_GLASS_SUCCESS");
export const updateGlassInspectionError = createAction<IError>("UPDATE_GLASS_ERROR");

//addGlassComment
export const addGlassComment = createAction<IAddCommentRequest>("ADD_GLASS_COMMENT");
export const addGlassCommentSuccess = createAction<IGlassInspectionWithWorkshop>("ADD_GLASS_COMMENT_SUCCESS");
export const addGlassCommentError = createAction<IError>("ADD_GLASS_COMMENT_ERROR");

//fetchIntakeInfo
export const fetchIntakeInfo = createAction<{ id; customer? }>("FETCH_INTAKE_INFO");
export const fetchIntakeInfoSuccess = createAction<IIntakeInformation>("FETCH_INTAKE_INFO_SUCCESS");
export const fetchIntakeInfoError = createAction<IError>("FETCH_INTAKE_INFO_ERROR");

export const clearUserInfo = createAction("CLEAR_USER_INFO");
export const fetchUserInfo = createAction("FETCH_USER_INFO");
export const fetchUserInfoSuccess = createAction<IUserInfo>("FETCH_USER_INFO_SUCCESS");
export const fetchUserInfoError = createAction<IError>("FETCH_USER_INFO_ERROR");

//submitIntake
export const submitIntake = createAction<ISubmitIntakeActionArguments>("SUBMIT_INTAKE");
export const submitIntakeFilesProgress = createAction("SUBMIT_INTAKE_FILES_PROGRESS");
export const submitIntakeFilesReset = createAction("SUBMIT_INTAKE_FILES_REST");
export const submitIntakeFileUploadProgress = createAction<number>("SUBMIT_INTAKE_FILE_UPLOAD_PROGRESS");
export const submitIntakeInfo = createAction("SUBMIT_INTAKE_INFO_SUCCESS");
export const submitIntakeFinished = createAction("SUBMIT_INTAKE_FINISHED");
export const submitIntakeCancel = createAction("SUBMIT_INTAKE_CANCEL");

export const submitIntakeError = createAction<IError>("SUBMIT_INTAKE_ERROR");

export const submitIntakeFeedback = createAction<{ id; data: IInspectionFeedback }>("SUBMIT_INTAKE_FEEDBACK");
export const submitIntakeFeedbackSuccess = createAction("SUBMIT_INTAKE_FEEDBACK_SUCCESS");
export const submitIntakeFeedbackError = createAction<IError>("SUBMIT_INTAKE_FEEDBACK_ERROR");

//toggleWorkshopFilter
export const toggleBodyWorkshopFilter = createAction<keyof IWorkshopFilter>("TOGGLE_BODY_WORKSHOP_FILTER");
export const toggleAllBodyWorkshopFilter = createAction<boolean>("TOGGLE_ALL_BODY_WORKSHOP_FILTER");
export const toggleGlassWorkshopFilter = createAction<keyof IWorkshopFilter>("TOGGLE_GLASS_WORKSHOP_FILTER");
export const toggleAllGlassWorkshopFilter = createAction<boolean>("TOGGLE_ALL_GLASS_WORKSHOP_FILTER");

//workshop feedback
export const workshopFeedbackOpen = createAction("WORKSHOP_FEEDBACK_OPEN");
export const workshopFeedbackClose = createAction("WORKSHOP_FEEDBACK_CLOSE");
export const workshopFeedbackSend = createAction<IFeedback>("WORKSHOP_FEEDBACK_SEND");
export const workshopFeedbackSendSuccess = createAction("WORKSHOP_FEEDBACK_SEND_SUCCESS");
export const workshopFeedbackSendError = createAction("WORKSHOP_FEEDBACK_SEND_ERROR");

//disable and enable customer service
export const disableCustomerService = createAction<{
  id: string;
  startDate: Date | null;
  endDate: Date | null;
}>("DISABLE_CUSTOMER_SERVICE");
export const disableCustomerServiceSuccess = createAction("DISABLE_CUSTOMER_SERVICE_SUCCESS");
export const disableCustomerServiceError = createAction("DISABLE_CUSTOMER_SERVICE_ERROR");
