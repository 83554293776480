import * as React from "react";
import { IWizardStepProps } from "../../../intakeModels";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "../../../components/shared/loader";
import "./instructionStep.scss";

const InstructionStep: React.FC<IWizardStepProps> = (props) => {
  let intl = useIntl();
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    if (props.wizard.nextClicked) {
      props.goStep(1);
    }
    if (props.wizard.prevClicked) props.goStep(-1);
  }, [props]);

  const instructionPhotoKeys = props.wizard.photoStepKeys.filter((k) => k !== "extra");

  return (
    <div data-cy="instructionStep">
      <h1 className="if header-text-with-back-button" data-cy="instructionHeader">
        <FormattedMessage id="intake.InstructionStep.header" />
      </h1>
      <p className="if basic-text">
        <FormattedMessage id="intake.InstructionStep.text" />
      </p>
      <p className="if basic-text">
        <FormattedMessage id="intake.InstructionStep.text2" />
      </p>
      <div className="instruction-photos-container">
        {instructionPhotoKeys.map((photokey) => (
            <div className="image" key={photokey}>
              {imageLoading && (
                <div className="loader-container" style={{ display: imageLoading ? "block" : "none" }}>
                  <Loader />
                </div>
              )}
              <div className="image-container" style={{ display: imageLoading ? "none" : "block" }}>
                <img
  
                  src={`${"/img/if/" + photokey + ".jpg"}`}
                  alt={intl.formatMessage({ id: "intake.alt.imageIllustration." + photokey })}
                  onLoad={() => setImageLoading(false)}
                />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};
export default InstructionStep;
